import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-step-menu',
  templateUrl: './step-menu.component.html',
  styleUrls: ['./step-menu.component.scss']
})
export class StepMenuComponent implements OnInit {

  @Input() menuItems: string[] = [];
  @Input() selectedItem?: string;
  @Input() smaller?: boolean;
  @Input() noCircleContent?: boolean;
  @Input() changeStepManually = false;
  @Output() selectedItemChange = new EventEmitter<string>();
  @Output() stepChangeRequested = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  selectItem(name: string) {
    if(!this.changeStepManually) {
      this.selectedItem = name;
      this.selectedItemChange.emit(name);
    }
    else {
      this.stepChangeRequested.emit(name);
    }
  }

}
