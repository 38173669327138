<div class="list-item-column fs-16">

  <div class="list-item-row-header" [ngClass]="{'opened' : opened}">
    <div class="flex-basis-25 text-block fs-16">{{item?.registration}}</div>
    <div class="flex-basis-50 text-block text-center fs-16">{{acTypes[item?.acTypeId].name}}</div>
    <div class="flex-basis-25 text-block text-center fs-16">{{item?.description}}</div>
  </div>

  <ng-container *ngIf="opened">
    <div class="list-item-row">
      <div class="flex-basis-30 text-block fs-16">A/C Name</div>
      <div class="flex-basis-20 text-block fs-16">{{item?.name}}</div>

      <div class="flex-basis-30 text-block fs-16">Mob. Nr</div>
      <div class="flex-basis-20 text-block fs-16">{{item?.mobNr}}</div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-30 text-block fs-16">Nose Code</div>
      <div class="flex-basis-20 text-block fs-16">{{item?.noseCode}}</div>

      <div class="flex-basis-30 text-block fs-16">MTOW</div>
      <div class="flex-basis-20 text-block fs-16">{{item?.mtow | number: '':'de-DE'}}</div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-30 text-block fs-16">Calibration (kg/lbs)</div>
      <div class="flex-basis-20 text-block fs-16">{{item?.calibration}}</div>

      <div class="flex-basis-30 text-block fs-16">A/C Delivery Date</div>
      <div class="flex-basis-20 text-block fs-16">{{ getFormattedDeliveryDate() }}</div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-30 text-block fs-16">Seating Configuration</div>
      <div class="flex-basis-20 text-block fs-16">{{item?.seatingConfigurations | seatObjectToString}}</div>

      <div class="flex-basis-30 text-block fs-16">SELCAL</div>
      <div class="flex-basis-20 text-block fs-16">{{item?.selcal}}</div>
    </div>


  </ng-container>
</div>
