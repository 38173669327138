<form [formGroup]="filterForm">
  <div class="filter-container mx-1 flex flex-row">
    <div class="filterItem" [ngClass]="{ 'date-picker': dateFilter }">
      <select class="form-control form-select selectItem light-blue-border" id="filterTimeRange" formControlName="timespan" *ngIf="!dateFilter" [ngClass]="{ 'selected-color': filterForm.value.timespan !== null && filterForm.value.timespan !== 'null' }">
        <option [value]="null">Time Span</option>
        <option value="next8Hours">next 8 hours</option>
        <option value="next12Hours">next 12 hours</option>
        <option value="next24Hours">next 24 hours</option>
      </select>
      <div class="input-group" *ngIf="dateFilter">
        <input class="form-control light-blue-border filter-background" [placeholder]="datePlaceholder"
               name="dp" formControlName="date" ngbDatepicker #dp="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar date-selector-btn"
                  (click)="dp.toggle()" type="button"></button>
        </div>
      </div>
    </div>
    <div class="filterItem">
      <input class="form-control custom-input light-blue-border" placeholder="AC Registration" id="acRegistration" formControlName="acRegistration"/>
    </div>
    <div class="filterItem">
      <input class="form-control custom-input light-blue-border" placeholder="Flight Number" id="flightNumber" formControlName="flightNumber"/>
    </div>
    <div class="filterItem">
      <ng-select class="new-select" placeholder="Airport" id="filterAirport" formControlName="station">
        <ng-container *ngIf="user?.role !== 'DUTY_MANAGER'; else dutyManagerAirportFilter">
          <ng-option *ngFor="let station of stations" [value]="station">{{station}}</ng-option>
        </ng-container>
        <ng-template #dutyManagerAirportFilter>
          <ng-option selected [value]="user?.location">{{user?.location}}</ng-option>
        </ng-template>
      </ng-select>
    </div>

    <div class="filterItem">
      <ng-select class="new-select" placeholder="Status" id="filterStatus" formControlName="status">
        <ng-option value="OK">Green</ng-option>
        <ng-option value="WARNING">Amber</ng-option>
        <ng-option value="ERROR">Red</ng-option>
      </ng-select>
    </div>

    <div class="filterItem">
      <ng-select class="new-select" placeholder="Alerts" id="filterAlerts" formControlName="alerts">
        <ng-option value="true">with Alerts</ng-option>
        <ng-option value="false">without Alerts</ng-option>
      </ng-select>
    </div>

    <!-- <div class="filterItem">
      <select class="form-control form-select selectItem" id="filterSortBy" formControlName="sortBy">
        <option [value]="null">Default</option>
        <option value="ETA">Sort by ETA</option>
        <option value="STD">Sort by STD</option>
        <option value="ETD">Sort by ETD</option>
        <option value="STA">Sort by STA</option>
        <option value="REG">Sort by REG</option>
      </select>
    </div> -->


    <div class="filterItem hide-unpaired">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="show-unpaired" formControlName="showUnpaired">
        <label class="form-check-label dark-blue" for="show-unpaired">Show unpaired sectors</label>
      </div>
    </div>
  </div>

</form>
