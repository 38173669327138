<div id="pair" class="light-blue-border rounded-left rounded-lg leg-outer overflow-hidden" [ngClass]="getBgColor(isActive)" (click)="toggleDetails(); this.mainComponentClicked.emit()">
  <div class="leg-left-part border-color-dark-gray text-center">
      {{(pairOverview?.dateOfOperation | date:'dd LLL yy':timezone)?.toUpperCase() }}
  </div>
  <div class="icon-area align-items-center">
      <app-icon-text-item iconHeader="icon-plane-front" [text]="pairOverview?.acRegistration ? pairOverview.acRegistration : pairOverview?.acRegistration ? pairOverview.acRegistration : '-'"
                          contentType="acRegistration"
                          [state]="registrationNrState"></app-icon-text-item>

      <app-icon-text-item iconHeader="icon-plane-arriving" [text]="isFirestore ? (pairDetail.arrivalLeg?.airlineDesignator ? pairDetail.arrivalLeg?.airlineDesignator + pairDetail.arrivalLeg?.flightNumber : '-') : pairOverview?.flightNumberArriving ? pairOverview.airlineDesignator + pairOverview.flightNumberArriving : '-'"
                          contentType="flightNr"
                          [state]="flightFromNrState"></app-icon-text-item>

      <!-- time of arrival START -->

    <app-icon-text-item *ngIf="!pairOverview?.eta && !pairOverview?.ata"
                          textHeader="STA" [text]="pairOverview?.sta ? (pairOverview.sta | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="etaState"
                          [indicator]="0 !== calcDateDifferenceInDays(pairOverview?.dateOfOperation, pairOverview?.sta, timezone)"
                          indicatorType="text"
                          [indicatorText]="calcDateDifferenceString(pairOverview?.dateOfOperation, pairOverview?.sta, timezone)"
      ></app-icon-text-item>

    <app-icon-text-item *ngIf="pairOverview?.eta && !pairOverview?.ata && !pairOverview?.landingTime"
                          textHeader="ETA" [text]="pairOverview?.eta ? (pairOverview.eta | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="etaState"
                          [indicator]="0 !== calcDateDifferenceInDays(pairOverview?.dateOfOperation, pairOverview?.eta, timezone)"
                          indicatorType="text"
                          [indicatorText]="calcDateDifferenceString(pairOverview?.dateOfOperation, pairOverview?.eta, timezone)"
      ></app-icon-text-item>

    <app-icon-text-item *ngIf="pairOverview?.landingTime && !pairOverview?.ata"
                        textHeader="LND" [text]="pairOverview?.landingTime ? (pairOverview.landingTime | date:'HH:mm':timezone) : '-'"
                        contentType="time"
                        [state]="etaState"
                        [indicator]="0 !== calcDateDifferenceInDays(pairOverview?.dateOfOperation, pairOverview?.landingTime, timezone)"
                        indicatorType="text"
                        [indicatorText]="calcDateDifferenceString(pairOverview?.dateOfOperation, pairOverview?.landingTime, timezone)"
    ></app-icon-text-item>

      <app-icon-text-item *ngIf="pairOverview?.ata"
                          textHeader="ATA" [text]="pairOverview?.ata ? (pairOverview.ata | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="etaState"
                          [indicator]="0 !== calcDateDifferenceInDays(pairOverview?.dateOfOperation, pairOverview?.ata, timezone)"
                          indicatorType="text"
                          [indicatorText]="calcDateDifferenceString(pairOverview?.dateOfOperation, pairOverview?.ata, timezone)"
      ></app-icon-text-item>

      <!-- time of arrival END -->

      <app-icon-text-item iconHeader="icon-icons8-synchronize"
                          [text]="(pairOverview?.groundTime && pairOverview.groundTime !== 0) ? (pairOverview.groundTime | timeFromMinutes) : '-'"
                          contentType="time"
                          [state]="turnaroundDurationState" [textClass]="pairOverview.groundTime < 0 ? 'text-danger' : ''" [taInProgress]="pairOverview?.turnaroundInProgress || pairDetail?.turnaround?.currentState === 'IN_PROGRESS' || pairDetail?.turnaround?.currentState === 'RESUMED'"></app-icon-text-item>

      <app-icon-text-item iconHeader="icon-plane-departing" [text]="isFirestore ? (pairDetail.departureLeg?.airlineDesignator ? pairDetail.departureLeg?.airlineDesignator + pairDetail.departureLeg?.flightNumber : '-') : pairOverview?.flightNumberDeparting ? pairOverview.airlineDesignator + pairOverview.flightNumberDeparting : '-'"
                          contentType="flightNr"
                          [state]="flightToNrState"></app-icon-text-item>

      <app-icon-text-item textHeader="STD" [text]="pairOverview?.std ? (pairOverview.std | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="stdState"
                          [indicator]="0 !== calcDateDifferenceInDays(pairOverview?.std, pairOverview?.dateOfOperation, timezone)"
                          indicatorType="text"
                          [indicatorText]="calcDateDifferenceString(pairOverview?.dateOfOperation, pairOverview?.std, timezone)"
      ></app-icon-text-item>

      <!-- estimated / actual time of arrival START -->

      <app-icon-text-item *ngIf="!pairOverview?.atd"
                          textHeader="ETD" [text]="pairOverview?.etd ? (pairOverview.etd | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="etdState"
                          [indicator]="0 !== calcDateDifferenceInDays(pairOverview?.dateOfOperation, pairOverview?.etd, timezone)"
                          indicatorType="text"
                          [indicatorText]="calcDateDifferenceString(pairOverview?.dateOfOperation, pairOverview?.etd, timezone)"
      ></app-icon-text-item>

      <app-icon-text-item *ngIf="pairOverview?.atd"
                          textHeader="ATD" [text]="pairOverview?.atd ? (pairOverview.atd | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="atdState"
                          [indicator]="0 !== calcDateDifferenceInDays(pairOverview?.dateOfOperation, pairOverview?.atd, timezone)"
                          indicatorType="text"
                          [indicatorText]="calcDateDifferenceString(pairOverview?.dateOfOperation, pairOverview?.atd, timezone)"
      ></app-icon-text-item>

      <!-- estimated / actual time of arrival END -->

      <app-icon-text-item textHeader="CTOT" [slcMessage]="pairDetail ? pairDetail.departureLeg?.ctotCancelled : pairOverview?.ctotCancelled" [text]="pairOverview?.ctot ? (pairOverview.ctot | date: 'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="ctotState"
      ></app-icon-text-item>
  </div>
  <div class="leg-right-part float-end" [ngClass]="pairOverview?.atd ? 'ATD' : pairOverview?.status | uppercase">
    <!--  <object type="image/svg+xml" data="../../../../content/images/icons/icons8-leg-indicator.svg" width="50" height="40"></object> -->
    <div class="leg-right-part-inner border-color-dark-gray">
      <div class="flex flex-column mini-icons-container">
        <div class="flex flex-row mini-icon-container-row">
          <div *ngIf="pairOverview?.locked" class="mini-icon-container rounded-circle flex">
            <i class="demo-icon icon-icons8-checkmark avitium-color mini-icon"></i>
          </div>
        </div>
        <div class="flex flex-row mini-icon-container-row">
          <div *ngIf="pairOverview?.hasMemo" class="mini-icon-container rounded-circle flex">
            <app-indicator-dot *ngIf="false"></app-indicator-dot>
            <i class="demo-icon icon-icons8-note avitium-color mini-icon"></i>
          </div>
          <div *ngIf="pairOverview?.hasMessage" class="mini-icon-container rounded-circle flex">
            <app-indicator-dot *ngIf="indicatorMessage"></app-indicator-dot>
            <i class="demo-icon icon-icons8-new-post avitium-color mini-icon"></i>
          </div>
        </div>
        <div class="flex flex-row mini-icon-container-row">
          <div *ngIf="pairOverview?.hasAlerts" class="mini-icon-container rounded-circle flex ">
            <app-indicator-dot *ngIf="indicatorWarning"></app-indicator-dot>
            <i class="demo-icon icon-icons8-error color-red mini-icon"></i>
          </div>
          <div *ngIf="pairOverview" class="mini-icon-container rounded-circle flex" (click)="openLegInformationModal();$event.stopPropagation()">
            <app-indicator-dot *ngIf="indicatorInfo"></app-indicator-dot>
            <i class="demo-icon mini-icon avitium-color">i</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-leg-detail *ngIf="isActive" [isActive]="isActive"
                [equipmentList]="equipments"
                [flightPair]="null"
                [pairId]="this.pairOverview.id"
                [passengerClasses]="passengerClasses"
                [timezone]="timezone"
                [pairOverview]="pairOverview"
                [(pairDetail)]="pairDetail"
                [refreshSql]="refreshSql"
></app-leg-detail>
