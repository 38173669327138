<div class="settings-container flex flex-column">
  <div class="settings-row">
    <div class="flex-basis-30">
      Airline Main Base
    </div>
    <div class="flex-basis-20 h-30">
      {{generalSettings?.airlineMainBase}}
    </div>
    <div class="flex-basis-30">
      Date Format
    </div>
    <div class="flex-basis-20 h-30">
      {{generalSettings?.dateFormat}}
    </div>
  </div>
  <div class="settings-row">
    <div class="flex-basis-30">
      Airline Codes
    </div>
    <div class="flex-basis-20 h-30">
      <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewAirlineCodes()"></i>
    </div>
    <div class="flex-basis-30">
      Measurement Units
    </div>
    <div class="flex-basis-20 h-30">
      <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewMeasurementUnits()"></i>
    </div>
  </div>

  <div class="settings-row">
    <div class="flex-basis-30">
      Passenger Classes
    </div>
    <div class="flex-basis-20 h-30">
      <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewPassengerClasses()"></i>
    </div>
    <div class="flex-basis-30">
      Turnaround Time Type
    </div>
    <div class="flex-basis-20">
      {{generalSettings ? (''+generalSettings.turnaroundTimeType | titlecase) : '-'}}
    </div>
  </div>

  <div class="settings-row">
    <div class="flex-basis-30">
      Flight Service Types
    </div>
    <div class="flex-basis-20 h-30">
      <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewFlightServiceTypes()"></i>
    </div>
    <div class="flex-basis-30">
      Turnaround Threshold
    </div>
    <div class="flex-basis-20 h-30">
      <i class="demo-icon icon-icons8-eye fs-30 clickable" [ngClass]="{'clickable' : generalSettings?.turnaroundTimeType === turnaroundTypes.STANDARD, 'notClickable': generalSettings?.turnaroundTimeType !== turnaroundTypes.STANDARD}" (click)="openViewTurnaroundThreshold()"></i>
    </div>
  </div>

  <div class="settings-row">
    <div class="flex-basis-30">
      Delay Codes
    </div>
    <div class="flex-basis-20 h-30">
      <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewDelayCodes()"></i>
    </div>
    <div class="flex-basis-30">
      Minimum Passenger Connection Time
    </div>
    <div class="flex-basis-20 h-30">
      {{generalSettings?.minimumPassengerConnectionTimeInMinutes | timeFromMinutes}}
    </div>
  </div>

  <div class="button-row justify-content-center">
    <div class="btn edit-button btn-outline-secondary flex-basis-35 border-radius-5" (click)="doEdit()">
      Edit
    </div>
  </div>
</div>
