import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TurnaroundType } from '../../../../../shared/constants/turnaround-type.constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { SettingsGeneralMainFormBuilder } from '../../../../../shared/forms/formBuilders/settings/settings-general-main-form-builder';
import { ManageDelayCodeDialogComponent } from '../manage-delay-code-dialog/manage-delay-code-dialog.component';
import { ManagePassengerClassesDialogComponent } from '../manage-passenger-classes-dialog/manage-passenger-classes-dialog.component';
import { ManageFlightServiceTypesDialogComponent } from '../manage-flight-service-types-dialog/manage-flight-service-types-dialog.component';
import { ManageMeasurementUnitsDialogComponent } from '../manage-measurement-units-dialog/manage-measurement-units-dialog.component';
import { ManageAirlineCodesDialogComponent } from '../manage-airline-codes-dialog/manage-airline-codes-dialog.component';
import { AddEditTurnaroundThresholdDialogComponent } from '../add-edit-turnaround-threshold-dialog/add-edit-turnaround-threshold-dialog.component';
import { IGeneralSettingsModel } from '../../../../../shared/models/general-settings.model';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { TimeFromMinutesPipe } from '../../../../../shared/pipes/time-from-minutes.pipe';
import { MinutesFromTimePipe } from '../../../../../shared/pipes/minutes-from-time.pipe';
import { ToastService } from '../../../../../services/toast.service';
import { IGenericContainerObject } from 'src/app/shared/models/genericContainerObject.model';
import { ISimpleData } from 'src/app/shared/models/simpleData.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-general-settings-dialog',
  templateUrl: './edit-general-settings-dialog.component.html',
  styleUrls: ['./edit-general-settings-dialog.component.scss'],
})
export class EditGeneralSettingsDialogComponent implements OnInit, OnDestroy {

  @Input() title?: string;
  @Input() data?: IGeneralSettingsModel;
  passengerClasses: IGenericContainerObject<ISimpleData>;
  turnaroundTypes = TurnaroundType;
  form?: FormGroup;
  dateFormat = 'DD/MM/YYYY';
  isBusy = false;
  unsubscribe$ = new Subject();

  constructor(private generalSettingsService: GeneralSettingsService, private modalService: NgbModal, private fb: FormBuilder,
              private activeModal: NgbActiveModal, private timeFromMinutesPipe: TimeFromMinutesPipe, private minutesFromTimePipe: MinutesFromTimePipe,
              private toastService: ToastService) {
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  get turnaroundTimeTypeValue(): TurnaroundType | null {
    if (this.form && this.form.get('turnaroundTimeType')) {
      return this.form?.get('turnaroundTimeType')?.value;
    }
    return null;
  }

  ngOnInit() {
    this.generalSettingsService.generalSettings.pipe(takeUntil(this.unsubscribe$)).subscribe((settings) => {
      this.dateFormat = settings.dateFormat;
    });
    this.constructForm();
    this.fetchSettings();
    //
    // if (this.data) {
    //   this.form?.patchValue(this.data);
    // }
  }

  fetchSettings() {
    this.generalSettingsService.getGeneralSettings().subscribe((result) => {
      if (result?.length > 0) {
        this.data = {...result[0]};
        delete result[0].minimumPassengerConnectionTimeInMinutes;
        this.form?.patchValue(result[0]);
        this.form?.get('minimumPassengerConnectionTimeInMinutes').setValue(this.timeFromMinutesPipe.transform(this.data?.minimumPassengerConnectionTimeInMinutes));
      }
    });
  }

  constructForm() {
    this.form = SettingsGeneralMainFormBuilder.constructForm(
      this.fb,
      this.dateFormat,
    );
  }

  openManagePassengerClasses() {
    const modalRef = this.modalService.open(ManagePassengerClassesDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'Manage Passenger Classes';
    modalRef.componentInstance.readonly = false;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      if (this.passengerClasses) {
        for (const key in this.passengerClasses) {
          delete this.passengerClasses[key];
        }
        this.generalSettingsService.getPassengerClasses().subscribe((results) => {
          for (const pClasses of results) {
            this.passengerClasses[pClasses.code] = pClasses;
          }
        });
      }
    });
  }

  openManageFlightServiceTypes() {
    const modalRef = this.modalService.open(ManageFlightServiceTypesDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'Manage Flight Service Types';
    modalRef.componentInstance.readonly = false;
  }

  openManageDelayCodes() {
    const modalRef = this.modalService.open(ManageDelayCodeDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'Manage Delay Codes';
    modalRef.componentInstance.readonly = false;
  }

  openManageMeasurementUnits() {
    const modalRef = this.modalService.open(ManageMeasurementUnitsDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'Manage Measurement Units';
    modalRef.componentInstance.readonly = false;
  }

  openManageAirlineCodes() {
    const modalRef = this.modalService.open(ManageAirlineCodesDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'Manage Airline Codes';
    modalRef.componentInstance.readonly = false;
  }

  openManageTurnaroundThreshold(): any {
    // if (this.turnaroundTimeTypeValue !== TurnaroundType.STANDARD) {
    //   return false;
    // }
    // const modalRef = this.modalService.open(SettingsAddEditModalComponent, {size: 'lg'});
    // modalRef.componentInstance.title = 'Manage Turnaround Threshold';
    // modalRef.componentInstance.formType = FormTypes.SETTINGS_TURNAROUND_THRESHOLD_EDIT;
    // modalRef.componentInstance.data = {
    //   form: this.form?.get('turnaroundThreshold'),
    //   turnaroundType: this.turnaroundTimeTypeValue
    // };
    // const saveClickedSubscription = modalRef.componentInstance.saveClicked.subscribe(() => this.saveClicked.emit(this.form?.value));
    //
    // modalRef.result.then().catch().finally(() => saveClickedSubscription.unsubscribe());
    const modalRef = this.modalService.open(AddEditTurnaroundThresholdDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'Manage Turnaround Threshold';
    modalRef.componentInstance.settings = this.data;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchSettings();
    })
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    this.isBusy = true;
    this.generalSettingsService.saveGeneralSettings({
      ...this.data,
      airlineMainBase: this.form.get('airlineMainBase').value,
      dateFormat: this.form.get('dateFormat').value,
      turnaroundTimeType: this.form.get('turnaroundTimeType').value,
      minimumPassengerConnectionTimeInMinutes: this.minutesFromTimePipe.transform(this.form.get('minimumPassengerConnectionTimeInMinutes').value),
    }).subscribe((result) => {
      if (result.id) {
        this.toastService.showSuccess("General settings have been saved");
        this.closeModal();
      }
      this.isBusy = false;
    });

  }

}
