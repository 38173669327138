<div id="pair" class="rounded-left mx-3 rounded-lg leg-outer" [ngClass]="getBgColor(isActive)" (click)="toggleDetails()" #pair>
  <div class="leg-left-part border-color-dark-gray text-center">
    <ng-container *ngIf="!flightPair">
      28 FEB 19
    </ng-container>
    <ng-container *ngIf="flightPair">
      {{((flightPair?.arrivalLeg ? flightPair?.arrivalLeg?.std : flightPair?.departureLeg?.std) | date:'dd LLL yy':timezone).toUpperCase()}}
    </ng-container>

  </div>
  <div class="icon-area">
    <ng-container *ngIf="!flightPair">
      <app-icon-text-item iconHeader="icon-icons8-airplane" text="OE-MCB"
                          contentType="acRegistration"
                          [state]="registrationNrState"></app-icon-text-item>

      <app-icon-text-item iconHeader="icon-icons8-airplane-landing" text="NR104"
                          contentType="flightNr"
                          [state]="flightFromNrState"></app-icon-text-item>

      <app-icon-text-item textHeader="ETA" text="11:05"
                          [state]="etaState"
                          [indicator]="true"
                          indicatorType="text"
                          indicatorText="+1"
      ></app-icon-text-item>
      <app-icon-text-item iconHeader="icon-icons8-synchronize" text="00:50"
                          contentType="time"
                          [state]="turnaroundDurationState"></app-icon-text-item>
      <app-icon-text-item iconHeader="icon-icons8-airplane-take-off" text="NR105"
                          contentType="flightNr"
                          [state]="flightToNrState"></app-icon-text-item>
      <app-icon-text-item textHeader="STD" text="11:45"
                          contentType="time"
                          [state]="stdState"></app-icon-text-item>
      <app-icon-text-item textHeader="ETD" text="11:55"
                          contentType="time"
                          [state]="etdState"
                          [indicator]="true"
                          indicatorType="text"
                          indicatorText="+1"
      ></app-icon-text-item>
      <app-icon-text-item textHeader="CTOT" text="12:00"
                          contentType="time"
                          [state]="ctotState"
                          [indicator]="true"
                          indicatorType="text"
                          indicatorText="+1"
      ></app-icon-text-item>
    </ng-container>

    <ng-container *ngIf="flightPair">
      <app-icon-text-item iconHeader="icon-icons8-airplane" [text]="flightPair?.arrivalLeg?.acRegistration ? flightPair.arrivalLeg.acRegistration : flightPair?.departureLeg?.acRegistration ? flightPair.departureLeg.acRegistration : '-'"
                          contentType="acRegistration"
                          [state]="registrationNrState"></app-icon-text-item>

      <app-icon-text-item iconHeader="icon-icons8-airplane-landing" [text]="flightPair?.arrivalLeg?.flightNumber ? flightPair.arrivalLeg.airlineDesignator + flightPair.arrivalLeg.flightNumber : '-'"
                          contentType="flightNr"
                          [state]="flightFromNrState"></app-icon-text-item>

      <!-- time of arrival START -->

      <app-icon-text-item *ngIf="!flightPair?.arrivalLeg?.eta && !flightPair?.arrivalLeg?.ata"
                          textHeader="STA" [text]="flightPair?.arrivalLeg?.sta ? (flightPair.arrivalLeg.sta | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="etaState"
                          [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.std, flightPair?.arrivalLeg?.sta, timezone)"
                          indicatorType="text"
                          [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.std, flightPair?.arrivalLeg?.sta, timezone)"
      ></app-icon-text-item>

      <app-icon-text-item *ngIf="flightPair?.arrivalLeg?.eta && !flightPair?.arrivalLeg?.ata"
                          textHeader="ETA" [text]="flightPair?.arrivalLeg?.eta ? (flightPair.arrivalLeg.eta | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="etaState"
                          [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.std, flightPair?.arrivalLeg?.eta, timezone)"
                          indicatorType="text"
                          [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.std, flightPair?.arrivalLeg?.eta, timezone)"
      ></app-icon-text-item>

      <app-icon-text-item *ngIf="flightPair?.arrivalLeg?.ata"
                          textHeader="ATA" [text]="flightPair?.arrivalLeg?.ata ? (flightPair.arrivalLeg.ata | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="etaState"
                          [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.std, flightPair?.arrivalLeg?.ata, timezone)"
                          indicatorType="text"
                          [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.std, flightPair?.arrivalLeg?.ata, timezone)"
      ></app-icon-text-item>

      <!-- time of arrival END -->

      <app-icon-text-item iconHeader="icon-icons8-synchronize"
                          [text]="(flightPair?.gt) ? flightPair.gt : '-'"
                          contentType="time"
                          [state]="turnaroundDurationState"></app-icon-text-item>

      <app-icon-text-item iconHeader="icon-icons8-airplane-take-off" [text]="flightPair?.departureLeg?.flightNumber ? flightPair.departureLeg.airlineDesignator + flightPair.departureLeg.flightNumber : '-'"
                          contentType="flightNr"
                          [state]="flightToNrState"></app-icon-text-item>

      <app-icon-text-item textHeader="STD" [text]="flightPair?.departureLeg?.std ? (flightPair.departureLeg.std | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="stdState"
                          [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.std, flightPair?.departureLeg?.std, timezone)"
                          indicatorType="text"
                          [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.std, flightPair?.departureLeg?.std, timezone)"
      ></app-icon-text-item>

      <!-- estimated / actual time of arrival START -->

      <app-icon-text-item *ngIf="!flightPair?.departureLeg?.atd"
                          textHeader="ETD" [text]="flightPair?.departureLeg?.etd ? (flightPair.departureLeg.etd | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="etdState"
                          [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.etd, flightPair?.departureLeg?.std, timezone)"
                          indicatorType="text"
                          [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.etd, flightPair?.departureLeg?.std, timezone)"
      ></app-icon-text-item>

      <app-icon-text-item *ngIf="flightPair?.departureLeg?.atd"
                          textHeader="ATD" [text]="flightPair?.departureLeg?.atd ? (flightPair.departureLeg.atd | date:'HH:mm':timezone) : '-'"
                          contentType="time"
                          [state]="atdState"
                          [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.atd, flightPair?.departureLeg?.std, timezone)"
                          indicatorType="text"
                          [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.atd, flightPair?.departureLeg?.std, timezone)"
      ></app-icon-text-item>

      <!-- estimated / actual time of arrival END -->

      <app-icon-text-item textHeader="CTOT" [text]="flightPair?.ctot ? flightPair.ctot : '-'"
                          contentType="time"
                          [state]="ctotState"
      ></app-icon-text-item>
    </ng-container>
  </div>
  <div *ngIf="!flightPair" class="leg-right-part float-end" [ngClass]="legState">
    <!--  <object type="image/svg+xml" data="../../../../content/images/icons/icons8-leg-indicator.svg" width="50" height="40"></object> -->
    <div class="leg-right-part-inner border-color-dark-gray">
      <div class="flex flex-column mini-icons-container">
        <div class="flex flex-row mini-icon-container-row">
          <div *ngIf="iconCheck" class="mini-icon-container rounded-circle flex">
            <i class="demo-icon icon-icons8-checkmark avitium-color mini-icon"></i>
          </div>
        </div>
        <div class="flex flex-row mini-icon-container-row">
          <div *ngIf="iconMemo" class="mini-icon-container rounded-circle flex">
            <app-indicator-dot *ngIf="true"></app-indicator-dot>
            <i class="demo-icon icon-icons8-note avitium-color mini-icon"></i>
          </div>
          <div *ngIf="iconMessage" class="mini-icon-container rounded-circle flex">
            <app-indicator-dot *ngIf="indicatorMessage"></app-indicator-dot>
            <i class="demo-icon icon-icons8-new-post avitium-color mini-icon"></i>
          </div>
        </div>
        <div class="flex flex-row mini-icon-container-row">
          <div *ngIf="iconWarning" class="mini-icon-container rounded-circle flex ">
            <app-indicator-dot *ngIf="indicatorWarning"></app-indicator-dot>
            <i class="demo-icon icon-icons8-error color-red mini-icon"></i>
          </div>
          <div *ngIf="iconInfo" class="mini-icon-container rounded-circle flex" (click)="openLegInformationModal();$event.stopPropagation()">
            <app-indicator-dot *ngIf="indicatorInfo"></app-indicator-dot>
            <i class="demo-icon mini-icon avitium-color">i</i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="flightPair" class="leg-right-part float-end" [ngClass]="flightPair?.status | uppercase">
    <!--  <object type="image/svg+xml" data="../../../../content/images/icons/icons8-leg-indicator.svg" width="50" height="40"></object> -->
    <div class="leg-right-part-inner border-color-dark-gray">
      <div class="flex flex-column mini-icons-container">
        <div class="flex flex-row mini-icon-container-row">
          <div *ngIf="flightPair?.locked" class="mini-icon-container rounded-circle flex">
            <i class="demo-icon icon-icons8-checkmark avitium-color mini-icon"></i>
          </div>
        </div>
        <div class="flex flex-row mini-icon-container-row">
          <div *ngIf="flightPair?.memos?.length > 0" class="mini-icon-container rounded-circle flex">
            <app-indicator-dot *ngIf="true"></app-indicator-dot>
            <i class="demo-icon icon-icons8-note avitium-color mini-icon"></i>
          </div>
          <div *ngIf="flightPair?.messages?.length > 0" class="mini-icon-container rounded-circle flex">
            <app-indicator-dot *ngIf="indicatorMessage"></app-indicator-dot>
            <i class="demo-icon icon-icons8-new-post avitium-color mini-icon"></i>
          </div>
        </div>
        <div class="flex flex-row mini-icon-container-row">
          <div *ngIf="flightPair?.errors?.length > 0" class="mini-icon-container rounded-circle flex ">
            <app-indicator-dot *ngIf="indicatorWarning"></app-indicator-dot>
            <i class="demo-icon icon-icons8-error color-red mini-icon"></i>
          </div>
          <div *ngIf="iconInfo" class="mini-icon-container rounded-circle flex" (click)="openLegInformationModal();$event.stopPropagation()">
            <app-indicator-dot *ngIf="indicatorInfo"></app-indicator-dot>
            <i class="demo-icon mini-icon avitium-color">i</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-deprecated-leg-detail [isActive]="isActive" [equipmentList]="equipments" [flightPair]="flightPair" [timezone]="timezone"></app-deprecated-leg-detail>
