import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-setting-menu-item',
  templateUrl: './settings-menu-item.component.html',
  styleUrls: ['./settings-menu-item.component.scss']
})
export class SettingsMenuItemComponent implements OnInit {

  @Input() indicator: string;
  @Input() title: string;
  @Input() selected: boolean;

  constructor() { }

  ngOnInit() {
  }

  getCSSClass(indicator: string) {
    switch (indicator) {
      case 'top': return 'settings-menu-item-point-top-line';
      case 'bottom': return 'settings-menu-item-point-bottom-line';
      default: return 'settings-menu-item-point-top-bottom-line';
    }
  }

}
