import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { ChatService } from '../../../../services/chat.service';
import { IChatMessageModel } from '../../../models/chat-message.model';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';
import { ToastService } from '../../../../services/toast.service';
import { IUser } from '../../../models/user.model';

@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss']
})
export class ChatFormComponent implements OnInit {
  @Input() conversation: IChatMessageModel[];
  @Input() chatGroupId: number;
  @Output() chatGroupIdChange = new EventEmitter<number>();
  @Input() numberOfUnreadMessages: number;
  @Input() user: IUser;
  @Output() newMessage = new EventEmitter<IChatMessageModel>();

  formGroup = new FormGroup({
    text: new FormControl('')
  });

  likeIcon: IconDefinition = faThumbsUp;

  constructor(private chatService: ChatService, private authService: AuthService, private toastService: ToastService) { }

  ngOnInit() {
  }

  async submit(overrideMessage?: string) {
    const message = overrideMessage ?? this.formGroup.get('text').value?.trim();
    if (!message?.length)
    {
      return;
    }
    this.formGroup.reset();
    if (!this.chatGroupId) {
      const chatGroupId = await firstValueFrom(this.chatService.createChatGroup([this.authService.user.id, this.user.id]));
      if (!chatGroupId) {
        return;
      }
      this.chatGroupId = chatGroupId;
      this.chatGroupIdChange.emit(chatGroupId);
    }
    this.chatService.sendMessage(this.chatGroupId, message).subscribe((result) => {
      if (!result.id) {
        this.toastService.showError('There was a problem sending your message. Please try again');
      }
      else {
        this.newMessage.emit(result);
      }
    });
  }

  submitKeyboard(evt: KeyboardEvent) {
    if(evt.key === 'Enter') {
      this.submit();
    }
  }
}
