import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { firstValueFrom, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FlightsIssueEtdFormBuilder } from '../../forms/formBuilders/flights/flights-issue-etd-form-builder';
import { IUser } from '../../models/user.model';
import { takeUntil } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { ToastService } from 'src/app/services/toast.service';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSettingsService } from 'src/app/services/general-settings.service';
import { ISimpleData } from '../../models/simpleData.model';
import { IGenericContainerObject } from '../../models/genericContainerObject.model';
import { LegsService } from 'src/app/services/legs.service';
import { PairLegTimesLogService } from 'src/app/services/pair-leg-times-log.service';
import { IPairDetail } from '../../models/pair-detail.model';
import { ILegsModel } from '../../models/legs.model';
import { IPairOverview } from '../../models/pair-overview.model';
import { calculateETADate, dayjsToNgbDate, ngbDateToDayjs, ngbDateToFormat } from '../../utils/utils';
import { LegDelaysLogService } from '../../../services/leg-delays-log.service';
import { TimeTypesService } from '../../../services/time-types.service';


@Component({
  selector: 'app-issue-etd-dialog',
  templateUrl: './issue-etd-dialog.component.html',
  styleUrls: ['./issue-etd-dialog.component.scss']
})
export class IssueEtdDialogComponent implements OnInit {

  isBusy = false;
  leg?: ILegsModel;
  title?: string;
  pair?: IPairDetail;

  form?: FormGroup;
  delayInMinutes = 0;
  delayCodes?: ISimpleData[];

  destroySubject = new Subject();

  user?: IUser;
  pairOverview: IPairOverview;
  dateFormat = 'dd/mm/yyyy';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private activeModal: NgbActiveModal,
    private generalSettingsService: GeneralSettingsService,
    private legService: LegsService,
    private pairLegTimesLogService: PairLegTimesLogService,
    private legDelaysLogService: LegDelaysLogService,
    private timeTypesService: TimeTypesService) {}

  ngOnInit() {
    this.dateFormat = this.generalSettingsService.generalSettings.value.dateFormat;
    this.fetchDelayCodes();
    this.generateForm();
    this.patchForm();
    this.form?.markAsDirty();
  }

  closeModal(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  fetchDelayCodes () {
    this.generalSettingsService.getDelayCodes().subscribe((result) => {
      this.delayCodes = result;
    });
  }

  generateForm() {
    this.form = FlightsIssueEtdFormBuilder.constructForm(this.fb, this.dateFormat);
    this.form.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(item => this.updateDelayTimespan(item));
  }

  patchForm() {
    let predefinedEtd = null;
    if (this.leg && this.leg.tod && dayjs(this.leg.tod).isValid()) {
      predefinedEtd = dayjs(this.leg.tod).utc();
    }
    this.form?.patchValue({
      etdDate: predefinedEtd ? dayjsToNgbDate(predefinedEtd) : null,
      std: this.leg && this.leg.std ? this.leg.std : null
    });
  }

  updateDelayTimespan(item: { etdDate: NgbDateStruct, etdTime: string, code: string }) {
    if (!this.leg || !item || !item.etdDate || !item.etdTime
      || !dayjs([ item.etdDate.year, item.etdDate.month-1, item.etdDate.day]).isValid() || item.etdTime.length !== 5) {
      return;
    }
    const newDelayMoment = dayjs(ngbDateToFormat(item.etdDate, this.dateFormat) + ' ' + item.etdTime + '+00:00', this.dateFormat + ' HH:mmZ');
    const stdMoment = dayjs(this.leg.std);
    this.delayInMinutes = newDelayMoment.diff(stdMoment, 'minutes');
  }

  // handleFormChanges() {
  //   this.form?.statusChanges.pipe(takeUntil(this.destroySubject)).subscribe(status => this.statusChanged.emit(status));
  // }

  async onSaveClick() {
      if (!this.form || this.form.invalid || !this.leg) {
        return;
      }
      this.isBusy = true;
      const formValue = this.form.value;
      const etdTimeSplit = formValue.etdTime.split(':');
      const newEtd = ngbDateToDayjs(formValue.etdDate).utc(true).hour(etdTimeSplit[0]).minute(etdTimeSplit[1]).toDate();
      let newEta = calculateETADate({ ...this.leg, etd: newEtd });
      if (newEta && dayjs.utc(newEta).isSame(this.leg.sta)) {
        newEta = null;
      }
      const saveResult = await firstValueFrom(this.legService.saveLeg({...this.leg, etd: newEtd, tod: newEtd, eta: newEta } as any));
      if (!saveResult) {
        this.isBusy = false;
        return;
      }
      this.toastService.showSuccess('Leg has been saved');
      const timeTypes = await firstValueFrom(this.timeTypesService.getTimeTypes());
      if (!timeTypes) {
        this.isBusy = false;
        return;
      }
      if (this.delayInMinutes !== 0) {
        const pairLegTimesLogId = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({ isActive: true, legId: this.leg.id, timeTypeId: timeTypes.filter((item) => item.identifier === 'ETD')[0]?.id }));
        const delayResult = await firstValueFrom(this.legDelaysLogService.saveLegDelayLog({
          legId: this.leg.id,
          delayCodeId: formValue.delayCode,
          minutes: this.delayInMinutes,
          pairLegTimesLogId: pairLegTimesLogId[0]?.id ?? null,
          issueEtd: true,
        } as any));
        if (delayResult) {
          this.toastService.showSuccess('Leg delay has been saved');
        }
      }
      this.closeModal('repair');
      this.isBusy = false;
    }

}
