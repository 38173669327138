import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Observable, of} from 'rxjs';
import {ISettingsSlaGseLevelRequest} from '../../shared/models/requests/settings-sla-gse-level-request.model';

@Injectable({
  providedIn: 'root'
})
export class SlaGseFunctionService {

  constructor(private fns: AngularFireFunctions) {
  }

  addEditSlaGse(request: ISettingsSlaGseLevelRequest): Observable<any> {
    if (!request) {
      return of({success: false});
    }

    const callable = this.fns.httpsCallable('updateSlaAndGseByRequest');
    return callable(request);

    /*
    const data$ = callable(request);

    data$.pipe(take(1)).subscribe(response => {
      this.statusChanged.emit('VALID');
      console.log('add edit gse response: ', response);

      if (response && response.data && response.data.success) {
        this.saveClicked.emit();
      }
    }); */
  }

  deleteSlaGse(request: ISettingsSlaGseLevelRequest) {
    if (!request) {
      return of({success: false});
    }

    const callable = this.fns.httpsCallable('deleteSlaAndGseByRequest');
    return callable(request);

  }
}
