<div class="list-item-column">

  <div class="list-item-row-header" [ngClass]="{'opened' : opened}">
    <div class="flex-basis-25 text-block fs-16">{{item?.alias}}</div>
    <div class="flex-basis-50 text-block text-center fs-16">{{item?.name}}</div>
    <div class="flex-basis-25 text-block text-center fs-16">MTOW {{item?.mtow | number: '':'de-DE'}}</div>
  </div>

  <ng-container *ngIf="opened">
    <div class="list-item-row">
      <div class="flex-basis-35 text-block fs-16">IATA Code</div>
      <div class="flex-basis-15 text-block fs-16">{{item?.iata}}</div>

      <div class="flex-basis-25 text-block fs-16">Fire Category</div>
      <div class="flex-basis-25 text-block fs-16">{{item?.fireCategory}}</div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-35 text-block fs-16">ICAO Code</div>
      <div class="flex-basis-15 text-block fs-16">{{item?.icao}}</div>

      <div class="flex-basis-25 text-block fs-16">Seating Configuration</div>
      <div class="flex-basis-25 text-block fs-16">{{filteredSeats | seatObjectToString}}</div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-35 text-block fs-16">Calibration (kg/lbs)</div>
      <div class="flex-basis-15 text-block fs-16">{{item?.calibration}}</div>

      <div class="flex-basis-25 text-block fs-16">Wake Turbulence</div>
      <div class="flex-basis-25 text-block fs-16">{{item?.wakeTurbulence}}</div>
    </div>

    <div class="list-item-row">
    <div class="flex-basis-35 text-block fs-16">{{'' + turnaroundType | titlecase}} Turnaround Time</div>
    <div class="flex-basis-15 text-block h-30 flex fs-16">
      {{item?.defaultMinTurnaroundTimeInMinutes | timeFromMinutes }}
      <i class="demo-icon icon-icons8-eye fs-30 clickable ms-2" (click)="openViewAcTypeMinimalTurnaroundTimes()"></i>
    </div>
    </div>

  </ng-container>
</div>
