import { Directive } from '@angular/core';

@Directive({
  selector: '[appTestAttr]'
})
export class TestAttrDirective {

  constructor() { }

}
