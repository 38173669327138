import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IAcType } from '../../../../../shared/models/ac-type.model';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { ISimpleData } from '../../../../../shared/models/simpleData.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SettingsAcTypeMainFormBuilder } from '../../../../../shared/forms/formBuilders/settings/settings-ac-type-main-form-builder';
import { IGeneralSettingsModel } from '../../../../../shared/models/general-settings.model';
import { ToastService } from '../../../../../services/toast.service';
import { AcTypesService } from '../../../../../services/ac-types.service';
import { TimeFromMinutesPipe } from '../../../../../shared/pipes/time-from-minutes.pipe';
import { SeatingConfigurationService } from '../../../../../services/seating-configuration.service';
import { ISeatingConfigurationModel } from '../../../../../shared/models/seating-configuration.model';
import { BehaviorSubject, forkJoin, map, Observable, of, Subject, tap } from 'rxjs';
import { MinutesFromTimePipe } from '../../../../../shared/pipes/minutes-from-time.pipe';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TurnaroundType } from '../../../../../shared/constants/turnaround-type.constants';
import { ITurnaroundTimes } from '../../../../../shared/models/turnaround-times.model';
import { FlightType } from '../../../../../shared/constants/flight-types.constants';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { AirportsService } from '../../../../../services/airports.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { areObjectsSame, dayjsToNgbDate, ngbDateToDayjs } from '../../../../../shared/utils/utils';
import * as dayjs from 'dayjs';
import { IAirlineDesignator } from '../../../../../shared/models/airline-designator.model';

@Component({
  selector: 'app-add-edit-ac-type-dialog',
  templateUrl: './add-edit-ac-type-dialog.component.html',
  styleUrls: ['./add-edit-ac-type-dialog.component.scss']
})
export class AddEditAcTypeDialogComponent implements OnInit, OnDestroy {

  @Input() acType?: IAcType;
  @Input() title?: string;
  @Input() passengerClasses?: ISimpleData[];
  @Input() generalSettings?: IGeneralSettingsModel;
  @Input() measurementUnits?: ISimpleData[];
  airlineDesignators: IAirlineDesignator[];
  passengerLetters: { [p: string]: number };
  seatingConfigurations: IGenericContainerObject<ISeatingConfigurationModel>;
  isBusy = false;
  form?: FormGroup;
  acTypeId: number
  turnaroundTypes: TurnaroundType;
  unsubscribe$ = new Subject();

  flightServiceTypes: { [p: number]: ISimpleData };
  turnaroundTimes: { [key: number]: { [key: number]: ITurnaroundTimes } };
  flightServiceTypeLetters: { [p: string]: number };
  forms: { [p: string]: FormGroup };
  flightServiceTypesArr: ISimpleData[];
  flightTypes = FlightType;
  turnaroundTime: string = '00:00';

  constructor(private fb: FormBuilder,
              private toastService: ToastService,
              private acTypesService: AcTypesService,
              private seatingConfigurationService: SeatingConfigurationService,
              private timeFromMinutesPipe: TimeFromMinutesPipe,
              private minutesFromTimePipe: MinutesFromTimePipe,
              private route: ActivatedRoute,
              private generalSettingsService: GeneralSettingsService,
              private airportsService: AirportsService,
              private router: Router) {

    this.acTypeId = Number((this.route.params as BehaviorSubject<any>).value.id) || null;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  buildSeatingConfigurationMap() {
    const seatingConfigurationsForAc: ISeatingConfigurationModel[] = this.acType?.seatingConfigurations?.filter((item) => item.acRegistrationId === null) || [];
    const maxVersion = seatingConfigurationsForAc.reduce((maxOrder, curr) => !isNaN(curr.order) ? Math.max(maxOrder, curr.order) : 1, 0);
    this.seatingConfigurations = {};
    for(let i = 1; i <= maxVersion; i++) {
      this.seatingConfigurations[i] = {};
    }
    for (const pClass of Object.values(this.passengerClasses)) {
      this.passengerLetters[pClass.code] = pClass.id;
      for(let i = 1; i <= maxVersion; i++) {
        const val = seatingConfigurationsForAc.find((seat) => seat.code === pClass.code && seat.order === i);
        this.seatingConfigurations[i][pClass.code] = val || {};
        if (val) {
          this.seatingConfigurations[i].validFrom = val.validFrom ? dayjsToNgbDate(dayjs.utc(val.validFrom)) as any : null;
          this.seatingConfigurations[i].validTo = val.validTo ? dayjsToNgbDate(dayjs.utc(val.validTo)) as any : null;
          this.seatingConfigurations[i].operatedBy = val.operatedBy;
        }
      }
    }
  }

  ngOnInit() {
    const observables: Observable<any>[] = [this.generalSettingsService.getMeasurementUnits(), this.generalSettingsService.getGeneralSettings(), this.generalSettingsService.getFlightServiceTypes(), this.generalSettingsService.getPassengerClasses(), this.generalSettingsService.getAirlineDesignators()];
    if (this.acTypeId) {
      observables.push(this.acTypesService.fetchAcTypes({
        id: this.acTypeId,
        isActive: true,
      }));
    }
    forkJoin(observables).subscribe((results) => {
      this.measurementUnits = results[0];
      this.generalSettings = results[1]?.length ? results[1][0] : {};
      this.flightServiceTypesArr = results[2];
      this.passengerClasses = results[3];
      this.airlineDesignators = results[4];

      this.flightServiceTypeLetters = {};
      this.flightServiceTypes = {};
      for (const flightServiceType of this.flightServiceTypesArr) {
        this.flightServiceTypes[flightServiceType.id] = flightServiceType;
        this.flightServiceTypeLetters[flightServiceType.code] = flightServiceType.id;
      }

      if (this.acTypeId) {
        if (results[5]?.length) {
          this.acType = results[5][0];
        } else {
          this.router.navigate(['..'], { relativeTo: this.route });
          return;
        }
      }

      this.turnaroundTime = this.acType ? this.timeFromMinutesPipe.transform(this.acType.defaultMinTurnaroundTimeInMinutes) : '00:00';
      this.passengerLetters = {};
      const seatingConfigurationsForAc: ISeatingConfigurationModel[] = this.acType?.seatingConfigurations?.filter((item) => item.acRegistrationId === null) || [];
      const maxVersion = seatingConfigurationsForAc.reduce((maxOrder, curr) => !isNaN(curr.order) ? Math.max(maxOrder, curr.order) : 1, 0);
      this.buildSeatingConfigurationMap();

      this.form = SettingsAcTypeMainFormBuilder.constructForm(this.fb, this.passengerClasses, maxVersion);
      this.form.get('defaultMinTurnaroundTimeInMinutes').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => {
        this.turnaroundTime = val;
      })
      if (this.acType) {
        this.form?.patchValue(this.acType);
        this.form.get('seatingConfig').get('seats').patchValue(Object.values(this.seatingConfigurations));
        if (this.acType?.defaultMinTurnaroundTimeInMinutes) {
          this.form.get('defaultMinTurnaroundTimeInMinutes').setValue(this.timeFromMinutesPipe.transform(this.acType?.defaultMinTurnaroundTimeInMinutes));
        }
      }
      this.forms = {};
      this.createForm(FlightType.DOMDOM);
      this.createForm(FlightType.DOMINT);
      this.createForm(FlightType.INTINT);
      this.createForm(FlightType.INTDOM);

      if (this.acTypeId) {
        this.fetchTurnaroundTimes();
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    const arr = this.form.get('seatingConfig').get('seats') as FormArray;
    moveItemInArray(arr.controls, event.previousIndex, event.currentIndex);
  }

  get seats() {
    return this.form.get('seatingConfig').get('seats') as FormArray;
  }

  createForm(flightType: FlightType) {
    this.forms[flightType] = new FormGroup({});
    for (const flightServiceType of this.flightServiceTypesArr) {
      for (const flightServiceType2 of this.flightServiceTypesArr) {
        this.forms[flightType].addControl(flightServiceType.code, new FormGroup({}));
        (this.forms[flightType].get(flightServiceType.code) as FormGroup).addControl(flightServiceType2.code, new FormGroup({time: new FormControl(''), id: new FormControl(0)}));
      }
    }
  }

  fetchTurnaroundTimes() {
    if (!this.acTypeId) {
      return;
    }
    this.airportsService.fetchTurnaroundTimes({
      acTypeId: this.acTypeId,
      isActive: true,
    }).subscribe((result) => {
      this.turnaroundTimes = {};
      for (const flightServiceTypeHeader in this.flightServiceTypes) {
        for (const flightServiceType in this.flightServiceTypes) {
          if (!this.turnaroundTimes[flightServiceTypeHeader]) {
            this.turnaroundTimes[flightServiceTypeHeader] = {};
          }
          this.turnaroundTimes[flightServiceTypeHeader][flightServiceType] = {};
        }
      }
      for (const turnaroundTime of result) {
        const from = this.flightServiceTypesArr.findIndex((fs) => fs.id === turnaroundTime.flightServiceTypeFrom);
        const to = this.flightServiceTypesArr.findIndex((fs) => fs.id === turnaroundTime.flightServiceTypeTo);
        if (from === -1 || to === -1) {
          continue;
        }
        this.turnaroundTimes[turnaroundTime.flightServiceTypeFrom][turnaroundTime.flightServiceTypeTo] = turnaroundTime;
        this.forms[turnaroundTime.flightType].get(this.flightServiceTypes[turnaroundTime.flightServiceTypeFrom].code).get(this.flightServiceTypes[turnaroundTime.flightServiceTypeTo].code).setValue({time: this.timeFromMinutesPipe.transform(turnaroundTime.timeInMinutes), id: turnaroundTime.id});
      }
    });
  }

  onSaveClick() {
    if(!this.form.valid) {
      return;
    }
    this.save();
  }

  save() {
    const observables: Observable<any>[] = [];
    this.isBusy = true;
    this.saveACType().subscribe((result) => {
      if (result?.id) {
        this.acType = result;
        observables.push(this.saveSeatingConfiguration());
        observables.push(...this.saveTurnaroundTimeObservables(FlightType.DOMDOM));
        observables.push(...this.saveTurnaroundTimeObservables(FlightType.DOMINT));
        observables.push(...this.saveTurnaroundTimeObservables(FlightType.INTINT));
        observables.push(...this.saveTurnaroundTimeObservables(FlightType.INTDOM));
        forkJoin(observables).subscribe((results) => {
          this.isBusy = false;
          this.acTypesService.fetchAcTypes().subscribe((result) => {
            this.acType = result.find((type) => type.id === this.acType.id);
            this.buildSeatingConfigurationMap();

          });
          const errors = results?.filter((item) => !item);
          if (errors?.length) {
            this.toastService.showError("There was an error during saving");
          }
        });
      }

    });
  }

  saveSeatingConfiguration(): Observable<any> {
    const changedSeatConfigs: ISeatingConfigurationModel[] = [];
    //this.removeEmptySets(changedSeatConfigs);
    const seatingConfigGroup = this.form.get('seatingConfig') as FormGroup;
    const seatingConfigs = seatingConfigGroup.get('seats') as FormArray;
    let orderNr = 1;
    let hasValue = false;
    for (let i = 1; i <= seatingConfigs.controls.length; i++) {
      hasValue = false;
      const configGroup = seatingConfigs.controls[i-1] as FormGroup;
      for (const seatingConfig of this.passengerClasses.map((pClass) => pClass.code)) {
        const valueNr: number = Number(configGroup.controls[seatingConfig].value.description) || 0;
        if (valueNr) {
          hasValue = true;
        }
        if ((!this.seatingConfigurations[i] || !this.seatingConfigurations[i][seatingConfig].id) && valueNr) {
          changedSeatConfigs.push({...configGroup.controls[seatingConfig].value, id: undefined, code: seatingConfig, acTypeId: this.acType?.id, order: orderNr, validFrom: configGroup.get('validFrom').value, validTo: configGroup.get('validTo').value, operatedBy: configGroup.get('operatedBy').value });
        }
        if (!this.seatingConfigurations[i]) {
          continue;
        }

         if (this.seatingConfigurations[i][seatingConfig].id && (+this.seatingConfigurations[i][seatingConfig]?.description !== valueNr
          || this.seatingConfigurations[i][seatingConfig]?.operatedBy !== configGroup.get('operatedBy').value
          || !areObjectsSame(this.seatingConfigurations[i]?.validTo, configGroup.get('validTo').value)
          || !areObjectsSame(this.seatingConfigurations[i]?.validFrom, configGroup.get('validFrom').value)
          || this.seatingConfigurations[i][seatingConfig].order !== orderNr)) {
          changedSeatConfigs.push({...configGroup.controls[seatingConfig].value, id: this.seatingConfigurations[i][seatingConfig]?.id, order: orderNr, validFrom: configGroup.get('validFrom').value, validTo: configGroup.get('validTo').value, operatedBy: configGroup.get('operatedBy').value})
        }
      }
      if (hasValue) {
        orderNr++;
      }
    }

    if (changedSeatConfigs.length > 0) {
      const observables: Observable<any>[] = [];
      for (const seat of changedSeatConfigs) {
        if (seat.id) {
          observables.push(this.seatingConfigurationService.deleteSeatingConfiguration(seat.id).pipe(map((item) => !!item)));
        }
        if(String(seat?.description || '').trim().length > 0)
        {
          observables.push(this.seatingConfigurationService.saveSeatingConfiguration({ ...seat, id: undefined, acTypeId: this.acType.id, validFrom: seat?.validFrom ? ngbDateToDayjs(seat.validFrom as any).toDate() : null, validTo: seat?.validTo ? ngbDateToDayjs(seat.validTo as any).toDate() : null }).pipe(map((item) => !!item)));
        }

      }
      return forkJoin(observables);
    }
    return of({});
  }

  saveTurnaroundTimeObservables(flightType: FlightType): Observable<any>[] {
    const observables: Observable<any>[] = [];
    for (let key1 in this.forms[flightType].controls) {
      for (const key2 in (this.forms[flightType].controls[key1] as FormGroup).controls) {
        const control: FormControl = ((this.forms[flightType].controls[key1] as FormGroup).controls[key2] as FormGroup).get('time') as FormControl;
        if (!control.dirty) {
          continue;
        }
        const time: string = ((this.forms[flightType].controls[key1] as FormGroup).controls[key2] as FormGroup).get('time').value;
        const id: number = ((this.forms[flightType].controls[key1] as FormGroup).controls[key2] as FormGroup).get('id').value;
        if (id && !time.trim().length) {
          observables.push(this.airportsService.deleteTurnaroundTime(id));
          continue;
        }
        const timeInMinutes = this.minutesFromTimePipe.transform(time);
        if(timeInMinutes === 0)
        {
          if(id) {
            observables.push(this.airportsService.deleteTurnaroundTime(id));
          }
          continue;
        }

        const fromId = this.flightServiceTypeLetters[key1];
        const toId = this.flightServiceTypeLetters[key2];
        if (!fromId || !toId) {
          this.toastService.showError("Saving turnaround data failed.");
          return;
        }
        observables.push(this.airportsService.saveTurnaroundTime({
          id: id !== 0 ? id : undefined, timeInMinutes: timeInMinutes, acTypeId: this.acType?.id || undefined, flightServiceTypeFrom: fromId, flightServiceTypeTo: toId, flightType
        }).pipe(map((item) => !!item)));
      }
    }
    return observables;
  }

  saveACType(): Observable<any> {
    const data = {...this.form.value, id: this.acType?.id || undefined, seatingConfig: undefined, name: ''};
    data.defaultMinTurnaroundTimeInMinutes = this.minutesFromTimePipe.transform(data.defaultMinTurnaroundTimeInMinutes);
    return this.acTypesService.saveAcType(data).pipe(tap((result) => {
      if (result?.id) {
        this.toastService.showSuccess("AC Type has been saved");
        this.acTypesService.fetchAcTypes().subscribe(() => {});
      } else {
        this.toastService.showError("AC Type saving has failed");
      }
    }));
  }

  applyTimeToAll(flightType: FlightType) {
    if(!this.turnaroundTime || this.turnaroundTime.length !== 5 || !/[0-9][0-9]:[0-9][0-9]/.test(this.turnaroundTime)) {
      return;
    }
    for(const ctrl of Object.values(this.forms[flightType].controls) as FormGroup[]) {
      for(const ctrl2 of Object.values(ctrl.controls) as FormGroup[]) {
        ctrl2.patchValue({ time: this.turnaroundTime });
        ctrl2.get('time').markAsDirty();
      }
    }
  }

  public readonly FlightType = FlightType;

  addNewSeat() {
    if (this.seats.controls.length) {
      const val = this.seats.controls[this.seats.controls.length-1].value;
      let hasValue = false;
      for (const pClass of this.passengerClasses) {
        if (val[pClass.code] && val[pClass.code].description !== null && String(val[pClass.code].description).trim()?.length) {
          hasValue = true;
        }
      }
      if (!hasValue) {
        return;
      }
    }
    const fg = new FormGroup({
      validFrom: new FormControl(dayjsToNgbDate(dayjs.utc())),
      validTo: new FormControl(),
      operatedBy: new FormControl()
    });
    this.passengerClasses.forEach((item: ISimpleData) => {
      fg.addControl(item.code, new FormGroup({
        code: new FormControl(item.code),
        description: new FormControl(),
        id: new FormControl(),
      }));
    });
    this.seats.push(fg);
  }
}
