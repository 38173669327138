import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertListComponent } from '../alert-list/alert-list.component';

@Component({
  selector: 'app-remind-me-dialog',
  templateUrl: './remind-me-dialog.component.html',
  styleUrls: ['./remind-me-dialog.component.scss']
})
export class RemindMeDialogComponent implements OnInit {
  alertList: AlertListComponent;
  alertId: number;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onKeyPress(evt: KeyboardEvent, hours: number, minutes: number) {
    if (evt.key.toLowerCase() === 'enter') {
      this.remindMe(hours, minutes);
    }
  }

  remindMe(hours: number, minutes: number) {
    this.alertList.remindLater({id: this.alertId, hours, minutes})
    this.activeModal.dismiss();
  }
}
