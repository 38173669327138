import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { AirportsService } from '../../../../../services/airports.service';
import { TurnaroundTimesService } from '../../../../../services/turnaround-times.service';
import { FlightType } from '../../../../../shared/constants/flight-types.constants';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { ISimpleData } from '../../../../../shared/models/simpleData.model';
import { ITurnaroundTimes } from '../../../../../shared/models/turnaround-times.model';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';

@Component({
  selector: 'app-view-airport-global-turnaround-time-dialog',
  templateUrl: './view-airport-global-turnaround-time-dialog.component.html',
  styleUrls: ['./view-airport-global-turnaround-time-dialog.component.scss']
})
export class ViewAirportGlobalTurnaroundTimeDialogComponent implements OnInit {

  DOMDOM = 'DOMDOM';
  DOMINT = 'DOMINT';
  INTDOM = 'INTDOM';
  INTINT = 'INTINT';

  @Input() acTypeId?: number;
  @Input() airportId?: number;
  // Key is flight service type ID value
  @Input() flightServiceTypes: { [p: number]: ISimpleData };
  @Input() turnaroundTimes: { [key: number]: { [key: number]: ITurnaroundTimes } };
  @Input() title?: string;
  forms: { [p: string]: FormGroup };

  steps = [this.DOMDOM, this.DOMINT, this.INTDOM, this.INTINT];

  step = this.DOMDOM;

  constructor(private activeModal: NgbActiveModal, private airportsService: AirportsService, private generalSettingsService: GeneralSettingsService) {
  }

  ngOnInit() {
    this.fetchFlightServiceTypes().subscribe((result) => {
      this.flightServiceTypes = {};
      for (const flightServiceType of result) {
        this.flightServiceTypes[flightServiceType.id] = flightServiceType;
      }
      this.fetchData(FlightType.DOMDOM);
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  fetchData(flightType: FlightType) {
    let obs;
    if(this.airportId)
    {
      obs = this.airportsService.fetchTurnaroundTimesByAirport(this.airportId, flightType);
    }
    else if(this.acTypeId)
    {
      obs = this.airportsService.fetchTurnaroundTimesByAcType(this.acTypeId, flightType);
    }
    else {
      console.error('No airport id / actype id defined in turnaround times table');
      return;
    }
    obs.subscribe((result) => {
      this.turnaroundTimes = {};
      for (const flightServiceTypeHeader of Object.keys(this.flightServiceTypes)) {
        for(const flightServiceType of Object.keys(this.flightServiceTypes)) {
          if(!this.turnaroundTimes[flightServiceTypeHeader]) {
            this.turnaroundTimes[flightServiceTypeHeader] = {};
          }
          this.turnaroundTimes[flightServiceTypeHeader][flightServiceType] = {};
        }
      }
      for (const turnaroundTime of result) {
        this.turnaroundTimes[turnaroundTime.flightServiceTypeFrom][turnaroundTime.flightServiceTypeTo] = turnaroundTime;
      }
      //this.forms[this.DOMDOM].setValue(this.turnaroundTimes);
    });
  }

  fetchFlightServiceTypes() {
    return this.generalSettingsService.getFlightServiceTypes();
  }

  // get domdom(): FormGroup | null {
  //   if (this.data && this.data.turnaroundTimesForm) {
  //     return this.data.turnaroundTimesForm.get('domdom') as FormGroup;
  //   }
  //   return null;
  // }

  // get domint(): FormGroup | null {
  //   if (this.data && this.data.turnaroundTimesForm) {
  //     return this.data.turnaroundTimesForm.get('domint') as FormGroup;
  //   }
  //   return null;
  // }

  // get intdom(): FormGroup | null {
  //   if (this.data && this.data.turnaroundTimesForm) {
  //     return this.data.turnaroundTimesForm.get('intdom') as FormGroup;
  //   }
  //   return null;
  // }

  // get intint(): FormGroup | null {
  //   if (this.data && this.data.turnaroundTimesForm) {
  //     return this.data.turnaroundTimesForm.get('intint') as FormGroup;
  //   }
  //   return null;
  // }

  async onTypeChange(newType: string) {
    //const data = await firstValueFrom(this.turnaroundTimesService.getTurnaroundTimes(this.data.acTypeId, newType as FlightType).pipe(take(1)));

    this.fetchData(newType as FlightType);
    // this.airportsService.getTurnaroundTimesByAirport(this.airportId, newType as FlightType).subscribe((response: ITurnaroundTimes[]) => {
    //   this.turnaroundTimes = response;
    //   // if(this.data.turnaroundTimesForm)
    //   // {
    //   //   this.data.turnaroundTimes = response.data;
    //   //   console.log('form exists');
    //   // }
    //});
  }
}
