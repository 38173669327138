import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { IPairsProcessesModel } from '../shared/models/pairs-processes.model';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class PairsProcessesService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  deletePairsProcess(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}pairs-processes/${id}`, {}).pipe(
      catchError((error) => {
        this.toastService.showError("Error deleting pairs process");
        return of({});
      })
    );
  }

  getPairsProccessesByPairId(pairId: number | number[]): Observable<IPairsProcessesModel[]>  {
    return this.httpClient.post(`${environment.api_base_url}pairs-processes/search`, { isActive: true, pairId: pairId }, {}).pipe(
      map((item: IResponse<IPairsProcessesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  savePairProcess(pairProcess: IPairsProcessesModel): Observable<IPairsProcessesModel> {
    let obs;
    if (pairProcess.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}pairs-processes/${pairProcess.id}`, pairProcess, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}pairs-processes/create`, pairProcess, {})
    }
    return obs.pipe(
      map((item: IResponse<IPairsProcessesModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving pair processes");
        return of(null);
      })
    );
  }
}
