import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { ISimpleData } from '../../shared/models/simpleData.model';
import { FlightPairService } from '../../services/flight-pair.service';
import { TimezoneService } from '../../services/timezone.service';
import { SettingsService } from '../../services/settings.service';
import { take, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { IUser } from '../../shared/models/user.model';
import { NavigationEnd, Router } from '@angular/router';
import { IPairsModel } from '../../shared/models/pairs.model';
import { PairsService } from '../../services/pairs.service';
import { LegsService } from '../../services/legs.service';
import { FlightsFilterService } from '../../services/flights-filter.service';
import { FlightsFilter } from '../../shared/models/filters/flights-filter.model';
import { IPairDetail } from '../../shared/models/pair-detail.model';
import { stationsFromPair } from '../../shared/utils/utils';

@Component({
  selector: 'app-flights',
  templateUrl: './flights.component.html',
  styleUrls: ['./flights.component.scss']
})
export class FlightsComponent implements OnInit, OnDestroy {
  timezone$: Observable<string>;
  chatOpened: boolean;
  pairsSql$: Observable<IPairsModel[]>;
  passengerClasses$: Observable<ISimpleData[]>;
  currentUser: IUser;
  chatUser: IUser;
  firestorePairs$: Observable<any>;
  unsubscribe$ = new Subject();
  routePath = '/flights';
  sqlPage = 1;
  hasNextPageSql = true;
  activePairIndex: string;
  selectedFlight: IPairDetail;
  flightStations: string[] = [];
  refreshListener: EventEmitter<any> = new EventEmitter();

  constructor(
    private flightPairService: FlightPairService,
    private settingsService: SettingsService,
    private tzService: TimezoneService,
    private authService: AuthService,
    private router: Router,
    private pairsService: PairsService,
    private legsService: LegsService,
    private flightsFilterService: FlightsFilterService) {
  }

  ngOnInit(): void {
    this.activePairIndex = '';
    this.getPairs(false, null);
    this.timezone$ = this.tzService.getTimezone();
    this.subscribeToCurrentUser();
    this.routePath = this.router.routerState.snapshot.url;
    if(this.routePath === '/flights')
    {
      this.getPairs(false, null);
    }
    else if(this.routePath === '/flights/all')
    {
      this.getPairsSql();
    }
    this.refreshListener.subscribe(() => {
      this.activePairIndex = '';
      this.getPairsSql();
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectedFlight = undefined;
        this.flightStations = [];
        this.activePairIndex = '';
        this.routePath = event.urlAfterRedirects;
        if(this.routePath === '/flights')
        {
          this.getPairs(false, null);
        }
        else if(this.routePath === '/flights/all')
        {
          this.sqlPage = 1;
          this.getPairsSql();
        }
      }
    });

    this.flightsFilterService.getFilterValues().subscribe((filter) => {
      if(this.routePath === '/flights/all')
      {
        this.sqlPage = 1;
        this.getPairsSql();
      }
      else
      {
        this.flightPairService.pageNumber = 1;
        this.flightPairService.firestoreLastSnapshot = undefined;
        this.flightPairService.flightsFilterValues = new FlightsFilter(filter);
        this.getPairs(false, null);
      }
    });
  }

  setSelectedFlight(flight: IPairDetail) {
    console.log('Set selected flight called');
    // this.formGroup.get('stations').setValue([]);
    this.selectedFlight = flight;
    setTimeout(() => {
      this.flightStations = stationsFromPair(flight);
    }, 1);

  }

  ngOnDestroy(): void {
    this.flightsFilterService.resetFilters();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    console.log('Filters were reset!');
  }

  getPairsSql()
  {
    this.pairsSql$ = this.pairsService.getPairsOverview(this.sqlPage);
    this.pairsSql$.pipe(tap((result) => {
      this.hasNextPageSql = result?.length === 21;
    }));
  }

  getPairs(previous: boolean, flatPair: IPairDetail) {
    // this.pairs$ = this.flightPairService.getPairs(previous, dateKey);
    this.firestorePairs$ = this.flightPairService.getPairsFirestore(previous, flatPair);
    // this.pairs$ = of(demoFlightPairList);

    this.firestorePairs$.pipe(take(2)).subscribe(item => {
      console.log('Item:', item); // works here
    });
  }

  subscribeToCurrentUser() {
    this.authService.userSubject.asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => this.currentUser = user);
  }

  getPassengerClasses() {
    this.passengerClasses$ = this.settingsService.getPassengerClasses();
  }



  setActivePairIndex(index: string) {
    console.log('setActivePairIndexInFlights called with index ' + index);
    this.activePairIndex = index;
  }

  getPreviousFlights(flatPair: IPairDetail) {
    console.log('get previous flights by flatPair ' + flatPair);
    this.getPairs(true, flatPair);
  }

  getNextFlights(flatPair: IPairDetail) {
    console.log('get next flights by flatPair ' + flatPair);
    this.getPairs(false, flatPair);
  }

  getNextSQLFlights() {
    if(!this.hasNextPageSql)
    {
      return;
    }
    this.sqlPage++;
    this.getPairsSql();
  }

  getPreviousSQLFlights() {
    if(this.sqlPage < 2)
    {
      return;
    }
    this.sqlPage--;
    this.getPairsSql();
  }
}
