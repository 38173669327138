import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['app-navbar.scss']
})
export class AppNavbarComponent implements OnInit {
  inProduction?: boolean;
  isNavbarCollapsed?: boolean;
  languages: any[] = [];
  swaggerEnabled?: boolean;
  modalRef?: NgbModalRef;
  version?: string;

  constructor(
    private sessionStorage: SessionStorageService,
    private router: Router
  ) {
    this.isNavbarCollapsed = true;
  }

  ngOnInit() {
  }

  collapseNavbar() {
    this.isNavbarCollapsed = true;
  }

  login() {
 //   this.modalRef = this.loginModalService.open();
  }

  logout() {
 /*   this.collapseNavbar();
    this.loginService.logout();
    this.router.navigate(['']); */
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }
}
