<div class="container header py-1 hover-item" [ngClass]="{'isActive': isActive}">
  <div class="row">
    <div class="col-10">
      <div class="container p-0">
        <div class="row p-0">
          <div class="col-12 fw-bolder">
            <!-- {{pairKey ? pairKey : 'Error'}} -->
            {{title ? title : 'Error'}}
          </div>
          <div class="col-12">
            {{text}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 center-y">
      <i class="demo-icon icon-icons8-eye fw-bold size-30"></i>
    </div>
  </div>
</div>
