import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UserService } from '../../../services/user.service';
import { IGenericContainerObject } from '../../models/genericContainerObject.model';
import { IUser } from '../../models/user.model';
import { ChatService } from '../../../services/chat.service';

@Component({
  selector: 'app-chat-area',
  templateUrl: './chat-area.component.html',
  styleUrls: ['./chat-area.component.scss']
})
export class ChatAreaComponent implements OnInit {
  @Input() user: any;
  @Output() closeChatAction = new EventEmitter();
  chatGroupId: number;

  users: IGenericContainerObject<IUser> = {};

  constructor(private userService: UserService, public chatService: ChatService) { }

  ngOnInit() {
    this.userService.fetchUsers().subscribe((result) => {
      for (const user of result) {
        this.users[user.id] = user;
      }
    });
  }
}
