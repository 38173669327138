import {Component, Input, OnInit} from '@angular/core';
import {IFlightPair} from '../../../models/flight-pair.model';

@Component({
  selector: 'app-flights-view-further-information',
  templateUrl: './flights-view-further-information.component.html',
  styleUrls: ['./flights-view-further-information.component.scss']
})
export class FlightsViewFurtherInformationComponent implements OnInit {

  @Input() data?: {
    pair: IFlightPair
  };

  constructor() {
  }

  ngOnInit() {
  }

}
