<div class="modal-header">
  <span class="modal-title">Confirm {{term}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal('Cross click')">
    <!--<span aria-hidden="true" class="colorWhite">&times;</span> -->
  </button>
</div>
<div class="modal-body">
<!--  <ng-container [ngSwitch]="formType">-->
<!--    &lt;!&ndash; <ng-container *ngSwitchCase="formTypes.DELETE_MEMO_FORM">-->
<!--      <app-memo-delete-form [data]="data" (deleteClicked)="nonClosingDeleteAction($event)"  [outerDeleteClicked]="eventsSubject.asObservable()" (statusChanged)="formStatusChanged($event)"></app-memo-delete-form>-->
<!--    </ng-container> &ndash;&gt;-->

<!--    <ng-container *ngSwitchDefault>-->
<!--      You are about to {{term}}. Are you sure?-->
<!--    </ng-container>-->
<!--  </ng-container>-->
  {{ text }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" ngbAutofocus (click)="closeModal('Close click')">{{ cancelButtonText }}</button>
  <button type="button" class="btn button-blue settings-modal-button" (click)="confirm()">{{ confirmButtonText }}</button>
</div>
