import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { IAlertsModel } from '../shared/models/alerts.model';
import { IAlertTypesModel } from '../shared/models/alert-types.model';
import { IGenericContainerObject } from '../shared/models/genericContainerObject.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alertTypes: IGenericContainerObject<IAlertTypesModel> = {};
  constructor(private httpClient: HttpClient, private toastService: ToastService) {
    this.getAlertTypes().subscribe((results) => {
      results.forEach((alertType) => {
        this.alertTypes[alertType.id] = alertType;
      });
    });
  }

  getAlerts(): Observable<IAlertsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}alerts/search`, { isActive: true, isResolved: false }, {}).pipe(
      map((item: IResponse<IAlertsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getAlertTypes(): Observable<IAlertTypesModel[]> {
    return this.httpClient.post(`${environment.api_base_url}alert-types/search`, { isActive: true }, {}).pipe(
      map((item: IResponse<IAlertTypesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  saveAlert(alert: IAlertsModel): Observable<IAlertsModel> {
    return this.httpClient.patch(`${environment.api_base_url}alerts/${alert.id}`, alert, {}).pipe(
      map((item: IResponse<IAlertsModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving Alert");
        return of({});
      })
    );
  }

  markAsResolvedAlert(alert: IAlertsModel): Observable<IAlertsModel> {
    console.log('Mark as Resolved was Called');

    return this.httpClient.post(`${environment.api_base_url}alerts/resolve/${alert.id}`, alert, {}).pipe(
      map((item: IResponse<IAlertsModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving Alert");
        return of({});
      })
    );
  }
}
