<ng-container *ngIf="formGroup && timezoneFormGroup">

<app-settings-header [backButton]="true" backButtonUrl=".." [text]="loaded ? (airport ? 'Edit Airport ' + airport?.iata : 'Add Airport') : 'Loading...'" [showSaveButton]="true" [textSize]="20" (onSaveClicked)="onSaveClick()" [saveButtonDisabled]="formGroup.invalid"
                     [spinner]="isBusy"></app-settings-header>
<div class="settings-container mt-3 flex flex-column avitium-scrollbar">
  <div class="flex flex-column gap-2">
    <app-settings-card sectionTitle="General" [formGroup]="formGroup">
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="iata" class="form-label">IATA Code</label>
          <input type="text" class="form-control" id="iata" formControlName="iata">
        </div>
        <div class="flex-basis-30">
          <label for="title" class="form-label">Airport Name</label>
          <input type="text" class="form-control" id="title" formControlName="title">
        </div>
        <div class="flex-basis-30">
          <label for="title" class="form-label">Country</label>
          <input type="text" class="form-control" id="settings-airport-country" placeholder="" formControlName="country" [ngbTypeahead]="searchCountry" (blur)="onCountryBlur()">
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="description" class="form-label">Coordinates Latitude</label>
          <input type="text" class="form-control" id="description" formControlName="latitude">
        </div>
        <div class="flex-basis-30">
          <label for="mtow" class="form-label">Coordinates Longitude</label>
          <input type="text" class="form-control" id="mtow" formControlName="longitude">
        </div>
        <div class="flex-basis-30">
          <label for="settings-airport-slot-coordination-level" class="form-label">Slot Coordination Level</label>
          <select class="form-select light-blue-border dark-blue" id="settings-airport-slot-coordination-level" formControlName="slotCoordinationLevel">
            <option value="Level 1">Level 1</option>
            <option value="Level 2">Level 2</option>
            <option value="Level 3">Level 3</option>
          </select>
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="icao" class="form-label">ICAO Code</label>
          <input type="text" class="form-control" id="icao" formControlName="icao">
        </div>
        <div class="flex-basis-30">
          <label for="fireCategory" class="form-label">Fire Category</label>
          <input type="text" class="form-control" id="fireCategory" formControlName="fireCategory">
        </div>
        <div class="flex-basis-30"></div>
      </div>
    </app-settings-card>

    <app-settings-card (onAddClicked)="openManageAirportTerminals()" sectionTitle="Terminals" addText="Add Terminal" *ngIf="this.airport">
        <app-settings-data-table class="mt-3" [columns]="['Code', 'Description']" [data]="airportTerminals" [variableNames]="['alias', 'name']" (onEditClicked)="openManageAirportTerminals($event)" (onDeleteClicked)="deleteTerminal($event)"></app-settings-data-table>
    </app-settings-card>

    <app-settings-card sectionTitle="Time Zone" [formGroup]="timezoneFormGroup">
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="utcWithoutDst" class="form-label">Non-DST Variation</label>
          <div class="flex gap-2">
            <select class="form-control form-select" id="settings-airport-timezone-non-dst-variation-operator"
                    formControlName="utcWithoutDstOperator">
              <option value="">+</option>
              <option value="-">-</option>
            </select>
            <ngb-timepicker [spinners]="false" id="utcWithoutDst" formControlName="utcWithoutDst"></ngb-timepicker>
          </div>
        </div>
        <div class="flex-basis-30">
          <label for="utcWithDst" class="form-label">DST Variation</label>
          <div class="flex gap-2">
            <select class="form-control form-select" id="settings-airport-timezone-dst-variation-operator"
                    formControlName="utcWithDstOperator">
              <option value="">+</option>
              <option value="-">-</option>
            </select>
            <ngb-timepicker [spinners]="false" id="utcWithDst" formControlName="utcWithDst"></ngb-timepicker>
          </div>
        </div>
        <div class="flex-basis-30 align-self-center">
          <div class="form-check form-switch dst-switch-container">
            <label class="form-check-label dark-blue" for="dstApplies">DST applies</label>
            <input class="form-check-input" type="checkbox" role="switch" id="dstApplies" formControlName="dstApplies">
          </div>
        </div>
      </div>
      <ng-container *ngIf="timezoneFormGroup?.value?.dstApplies">
        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="dstStartTime" class="form-label">DST Start</label>
            <ngb-timepicker [spinners]="false" id="dstStartTime" formControlName="dstStartTime"></ngb-timepicker>
          </div>
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-start-month" class="form-label">DST Start Month</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-start-month"
                    formControlName="dstStartMonth">
              <option value="JAN">January</option>
              <option value="FEB">February</option>
              <option value="MAR">March</option>
              <option value="APR">April</option>
              <option value="MAY">May</option>
              <option value="JUN">June</option>
              <option value="JUL">July</option>
              <option value="AUG">August</option>
              <option value="SEP">September</option>
              <option value="OCT">October</option>
              <option value="NOV">November</option>
              <option value="DEC">December</option>
            </select>
          </div>
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-start-weekday" class="form-label">DST Start Weekday</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-start-weekday"
                    formControlName="dstStartWeekday">
              <option value="MON">Monday</option>
              <option value="TUE">Tuesday</option>
              <option value="WED">Wednesday</option>
              <option value="THU">Thursday</option>
              <option value="FRI">Friday</option>
              <option value="SAT">Saturday</option>
              <option value="SUN">Sunday</option>
            </select>
          </div>
        </div>
        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-start-weekday-counter" class="form-label">Week of the month</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-start-weekday-counter"
                    formControlName="dstStartWeekOfMonth">
              <option value="First">First</option>
              <option value="Second">Second</option>
              <option value="Third">Third</option>
              <option value="Last">Last</option>
            </select>
          </div>
          <div class="flex-basis-30">
          </div>
          <div class="flex-basis-30">
          </div>
        </div>


        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="dstEndTime" class="form-label">DST End</label>
            <ngb-timepicker [spinners]="false" id="dstEndTime" formControlName="dstEndTime"></ngb-timepicker>
          </div>
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-end-month" class="form-label">DST End Month</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-end-month"
                    formControlName="dstEndMonth">
              <option value="JAN">January</option>
              <option value="FEB">February</option>
              <option value="MAR">March</option>
              <option value="APR">April</option>
              <option value="MAY">May</option>
              <option value="JUN">June</option>
              <option value="JUL">July</option>
              <option value="AUG">August</option>
              <option value="SEP">September</option>
              <option value="OCT">October</option>
              <option value="NOV">November</option>
              <option value="DEC">December</option>
            </select>
          </div>
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-end-weekday" class="form-label">DST End Weekday</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-end-weekday"
                    formControlName="dstEndWeekday">
              <option value="MON">Monday</option>
              <option value="TUE">Tuesday</option>
              <option value="WED">Wednesday</option>
              <option value="THU">Thursday</option>
              <option value="FRI">Friday</option>
              <option value="SAT">Saturday</option>
              <option value="SUN">Sunday</option>
            </select>
          </div>
        </div>
        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-end-weekday-counter" class="form-label">Week of the month</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-end-weekday-counter"
                    formControlName="dstEndWeekOfMonth">
              <option value="First">First</option>
              <option value="Second">Second</option>
              <option value="Third">Third</option>
              <option value="Last">Last</option>
            </select>
          </div>
          <div class="flex-basis-30">
          </div>
          <div class="flex-basis-30">
          </div>
        </div>
      </ng-container>

    </app-settings-card>

    <app-settings-card (onAddClicked)="openManageAirportContacts()" sectionTitle="Contacts" addText="Add Contact" *ngIf="this.airport">
      <app-settings-data-table class="mt-3" [columns]="['Name', 'Surname', 'Company', 'Department', 'Tel.Nr.', 'Email']" [data]="airportContacts" [variableNames]="['firstname', 'lastname', 'company', 'department', 'mobile', 'email']" (onEditClicked)="openManageAirportContacts($event)" (onDeleteClicked)="deleteContact($event)"></app-settings-data-table>
    </app-settings-card>

    <app-settings-card sectionTitle="Slot Time Tolerance" [formGroup]="slotTimeToleranceFormGroup" *ngIf="slotTimeToleranceFormGroup">
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-25">
          <label for="departureEarlyInMinutes" class="form-label">Early Departure Slot Tolerance</label>
          <ngb-timepicker [spinners]="false" id="departureEarlyInMinutes" formControlName="departureEarlyInMinutes"></ngb-timepicker>
        </div>
        <div class="flex-basis-25">
          <label for="departureLateInMinutes" class="form-label">Late Departure Slot Tolerance</label>
          <ngb-timepicker [spinners]="false" id="departureLateInMinutes" formControlName="departureLateInMinutes"></ngb-timepicker>
        </div>
        <div class="flex-basis-25">
          <label for="arrivalEarlyInMinutes" class="form-label">Early Arrival Slot Tolerance</label>
          <ngb-timepicker [spinners]="false" id="arrivalEarlyInMinutes" formControlName="arrivalEarlyInMinutes"></ngb-timepicker>
        </div>
        <div class="flex-basis-25">
          <label for="arrivalLateInMinutes" class="form-label">Late Arrival Slot Tolerance</label>
          <ngb-timepicker [spinners]="false" id="arrivalLateInMinutes" formControlName="arrivalLateInMinutes"></ngb-timepicker>
        </div>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Curfew Times" *ngIf="curfewTimesFormGroup && this.airport" [formGroup]="curfewTimesFormGroup">
      <div class="flex flex-column dark-blue gap-2">
        <div class="form-check form-switch">
          <label class="form-check-label dark-blue" for="curfewLocal">Local</label>
          <input class="form-check-input" type="checkbox" role="switch" id="curfewLocal" formControlName="isUtc">
        </div>
        <div class="flex justify-content-between align-items-end">
          <div class="flex-basis-20">
            <label class="form-label" for="d">From Date</label>
            <div class="flex">
              <input class="form-control light-blue-border dark-blue dp-minw" type="text" id="d" ngbDatepicker formControlName="validFrom" #d="ngbDatepicker" displayMonths="2" outsideDays="hidden"/>
              <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"
                      [ngClass]="{'active': d.isOpen()}"
                      (click)="d.open()"></button>
            </div>
          </div>


          <div class="flex-basis-20">
            <label class="form-label" for="d">To Date</label>
            <div class="flex">
              <input class="form-control light-blue-border dark-blue dp-minw" type="text" id="d2" ngbDatepicker formControlName="validTo" #d2="ngbDatepicker" displayMonths="2" outsideDays="hidden"/>
              <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"
                      [ngClass]="{'active': d2.isOpen()}"
                      (click)="d2.open()"></button>
            </div>
          </div>

          <div class="flex-basis-20">
            <label for="openingTime" class="form-label">Opening Time</label>
            <ngb-timepicker [spinners]="false" id="openingTime" formControlName="openingTime"></ngb-timepicker>
          </div>

          <div class="flex-basis-20">
            <label for="closingTime" class="form-label">Closing Time</label>
            <ngb-timepicker [spinners]="false" id="closingTime" formControlName="closingTime"></ngb-timepicker>
          </div>

        </div>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: Domestic to Domestic" addText="Edit minimum turnaround times" (onAddClicked)="editTurnaroundTimes()" *ngIf="this.airport">
      <div class="flex flex-column dark-blue gap-3" *ngIf="DOMDOM[activeAcTypeId]">
        <div class="flex flex-column gap-2">
          <div class="ac-type">Aircraft ICAO Code</div>
          <div class="ac-select">
            <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()">
              <option *ngFor="let acType of acTypes" [ngValue]="acType">{{ acType.icao }}</option>
            </select>
          </div>
        </div>
        <div class="overflow-auto avitium-scrollbar">
          <table class="table table-striped turnaround-table dark-blue">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
                Outbound {{flightServiceType?.code}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flightServiceType of flightServiceTypes">
               <td class="text-center">Inbound {{flightServiceType?.code}}</td>
              <td *ngFor="let serviceType of flightServiceTypes">
                {{ DOMDOM?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id]?.timeInMinutes | timeFromMinutes }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: Domestic to International" addText="Edit minimum turnaround times" (onAddClicked)="editTurnaroundTimes()" *ngIf="this.airport">
      <div class="flex flex-column dark-blue gap-3" *ngIf="DOMINT[activeAcTypeId]">
        <div class="flex flex-column gap-2">
          <div class="ac-type">Aircraft ICAO Code</div>
          <div class="ac-select">
            <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()">
              <option *ngFor="let acType of acTypes" [ngValue]="acType">{{ acType.icao }}</option>
            </select>
          </div>
        </div>

        <div class="overflow-auto avitium-scrollbar">
          <table class="table table-striped turnaround-table dark-blue">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
                Outbound {{flightServiceType?.code}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flightServiceType of flightServiceTypes">
               <td class="text-center">Inbound {{flightServiceType?.code}}</td>
              <td *ngFor="let serviceType of flightServiceTypes">
                {{ DOMINT?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id]?.timeInMinutes | timeFromMinutes }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-settings-card>




    <app-settings-card sectionTitle="Turnaround Times: International to International" addText="Edit minimum turnaround times" (onAddClicked)="editTurnaroundTimes()" *ngIf="this.airport">
      <div class="flex flex-column dark-blue gap-3" *ngIf="INTINT[activeAcTypeId]">
        <div class="flex flex-column gap-2">
          <div class="ac-type">Aircraft ICAO Code</div>
          <div class="ac-select">
            <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()">
              <option *ngFor="let acType of acTypes" [ngValue]="acType">{{ acType.icao }}</option>
            </select>
          </div>
        </div>

        <div class="overflow-auto avitium-scrollbar">
          <table class="table table-striped turnaround-table dark-blue">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
                Outbound {{flightServiceType?.code}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flightServiceType of flightServiceTypes">
               <td class="text-center">Inbound {{flightServiceType?.code}}</td>
              <td *ngFor="let serviceType of flightServiceTypes">
                {{ INTINT?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id]?.timeInMinutes | timeFromMinutes }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: International to Domestic" addText="Edit minimum turnaround times" (onAddClicked)="editTurnaroundTimes()" *ngIf="this.airport">
      <div class="flex flex-column dark-blue gap-3" *ngIf="INTDOM[activeAcTypeId]">
        <div class="flex flex-column gap-2">
          <div class="ac-type">Aircraft ICAO Code</div>
          <div class="ac-select">
            <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()">
              <option *ngFor="let acType of acTypes" [ngValue]="acType">{{ acType.icao }}</option>
            </select>
          </div>
        </div>

        <div class="overflow-auto avitium-scrollbar">
          <table class="table table-striped turnaround-table dark-blue">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
                Outbound {{flightServiceType?.code}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flightServiceType of flightServiceTypes">
               <td class="text-center">Inbound {{flightServiceType?.code}}</td>
              <td *ngFor="let serviceType of flightServiceTypes">
                {{ INTDOM?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id]?.timeInMinutes | timeFromMinutes }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-settings-card>
  </div>
</div>



</ng-container>
