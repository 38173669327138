import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-flights-add-flights-view',
  templateUrl: './flights-add-flights-view.component.html',
  styleUrls: ['./flights-add-flights-view.component.scss']
})
export class FlightsAddFlightsViewComponent implements OnInit {

  @Output() closeWindow = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  addFlights() {
    this.closeWindow.emit();
  }

}
