<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup">
      <div class="container">
        <div class="row">
          <div class="col-3 form-field-header">
            Has GSE
          </div>
          <div class="col-3">
            <div class="mb-3">
              <input type="checkbox" id="sla-gse-level-gse-has-gse" formControlName="hasGse">
            </div>
          </div>
          <div *ngIf="formGroup.get('hasGse').value" class="col-3 form-field-header">
            GSE Type*
          </div>
          <div *ngIf="formGroup.get('hasGse').value" class="col-3">
            <div class="mb-3">
              <span *ngIf="!isNew" class="form-field-content">{{gse.isNone ? 'none' : gse?.title}}</span>

              <select *ngIf="isNew" class="form-control form-select" id="arrival-flight-departure-delay-code-1" formControlName="title">
                <option *ngFor="let gse of definedGses | keyvalue" [value]="gse?.value.equipmentIdentifier" selected>{{gse?.value?.title}}</option>
              </select>
            </div>
          </div>

          <div *ngIf="formGroup.get('hasGse').value" class="col-3 form-field-header">
            SLA
          </div>
          <div *ngIf="formGroup.get('hasGse').value" class="col-3">
            <div class="mb-3">
              <input type="checkbox" id="sla-gse-level-gse-is-sla" formControlName="isSla">
            </div>
          </div>
          <div class="col-3 form-field-header">
            Max. process time (minutes)*
          </div>
          <div class="col-3">
            <div class="mb-3">
              <input type="number" id="sla-gse-level-gse-max-process-time" class="form-control" formControlName="maximumProcessTimeInMinutes"/>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="formGroup?.disabled || formGroup?.invalid || isBusy">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
