<ng-container *ngIf="form">
  <form [formGroup]="form">
    <div class="container container-95">
      <div class="row">
        <div class="col-2 header-border-right">
          &nbsp;
        </div>
        <div class="col-10 form-field-header text-center">
          Outbound Flight
        </div>
      </div>
      <div class="row header-border-bottom">
        <div class="col-2 form-field-header header-border-right">
          Inbound Flight
        </div>
        <div class="col-10 form-field-content">
          <div class="flex justify-content-around form-field-header">
            <div class="contentCell text-center"
                 *ngFor="let flightServiceType of form?.controls | keyvalue">{{flightServiceType?.key}}</div>
          </div>
        </div>
      </div>
      <div class="row content-border-bottom" *ngFor="let flightServiceType of this.form?.controls | keyvalue">
        <div class="col-2 form-field-header header-border-right text-center">
          {{flightServiceType?.key}}
        </div>
        <ng-container *ngIf="flightServiceType?.value">
          <div class="col-10 form-field-content" [formGroupName]="flightServiceType.key"> <!-- First key, eg C -->
            <!-- TODO: UNCOMMENT AND FIXME
              <div class="flex justify-content-around form-field-content">
              <div class="contentCell" *ngFor="let fstFormControl of flightServiceType.value?.controls | keyvalue; let j = index">
                <input type="text" class="form-control" id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{fstFormControl?.key}}"
                       placeholder="" [formControlName]="fstFormControl?.key">
              </div>
            </div> -->
            <div class="flex justify-content-around form-field-content">
              <div class="contentCell" *ngFor="let fstFormControl of flightServiceType.value?.controls | keyvalue; let j = index" [formGroupName]="fstFormControl?.key">
<!--                <input type="text" class="form-control" id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{fstFormControl?.key}}"-->
<!--                       placeholder="" formControlName="time">-->
                <input type="text" class="form-control" id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{fstFormControl?.key}}"
                       placeholder="" formControlName="time" pattern="[0-9][0-9]\:[0-9][0-9]">
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </form>

</ng-container>

