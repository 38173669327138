import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {

  @Input() text?: string;
  @Input() title?: string;
  @Input() buttonText = "OK";

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  closeModal(reason: string) {
    this.activeModal.dismiss(reason);
  }

}
