import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IRespondEtdExtension } from '../shared/models/turnaround-ext-response.model';
import { catchError, map, Observable, of } from 'rxjs';
import { IResponse } from '../shared/models/IResponse.model';
import { ToastService } from './toast.service';
import { ITurnaroundControl } from '../shared/models/turnaround-control.model';

@Injectable({
  providedIn: 'root'
})
export class TurnaroundService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) {
  }

  resumeTurnaround(model: ITurnaroundControl): Observable<boolean> {
    return this.httpClient.post(`${environment.api_base_url}turnaround/resume`, model, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        this.toastService.showError("Error responding to ETD Extension request");
        return of(false);
      }));
  }

  respondToRequest(model: IRespondEtdExtension): Observable<boolean> {
    return this.httpClient.post(`${environment.api_base_url}turnaround/etd-extension/${model?.accepted ? 'grant' : 'decline'}`, model, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        this.toastService.showError("Error responding to ETD Extension request");
        return of(false);
      }));
  }
}
