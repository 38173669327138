import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { States } from '../../../constants/state.constants';
import { IFlightPair } from '../../../models/flight-pair.model';
import { ISimpleData } from '../../../models/simpleData.model';
import { FlightPairService } from '../../../../services/flight-pair.service';
import { IPairDetail } from '../../../models/pair-detail.model';
import { FlightsFilterService } from '../../../../services/flights-filter.service';
import { IFlightsFilter } from '../../../models/filters/flights-filter.model';
import dayjs from 'dayjs';
import { getLatestLegTime } from '../../../utils/utils';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-leg-list',
  templateUrl: './leg-list.component.html',
  styleUrls: ['./leg-list.component.scss']
})
export class LegListComponent implements OnInit {

  isLoadingTop: boolean;
  isLoadingBottom: boolean;
  lastListLoadAction = '';
  list: IFlightPair[] = [];
  @Input() timezone: string;
  @Input() userRole: string;
  @Input() passengerClasses: ISimpleData[];
  @Input() activePairIndex: string;
  @Output() resetPairIndex = new EventEmitter();
  @Output() propagateAlerts = new EventEmitter();
  @Output() setPairIndex = new EventEmitter();
  @Output() getFlightsBefore = new EventEmitter();
  @Output() getFlightsAfter = new EventEmitter();
  @Output() setActiveFlight = new EventEmitter<IPairDetail>();
  states = States;
  selectedPair: IPairDetail;
  filteredFlights: IPairDetail[] = [];
  visibleFlights: IPairDetail[] = [];
  testPage = 1;

  constructor(public flightPairService: FlightPairService, private flightFilterService: FlightsFilterService, @Inject(DOCUMENT) private doc: Document, private authService: AuthService) {
  }

  _legList: IFlightPair[];

  get legList(): IFlightPair[] {
    return this._legList;
  }

  @Input('legList')
  set legList(val: IFlightPair[]) {
    console.log('leg list:', val);
    this._legList = val;
    if (!val || val.length === 0) {
      this.isLoadingTop = false;
      this.isLoadingBottom = false;
      console.log('returned list is empty');
      return;
    }
    console.log('list length = ' + this.list.length);
    console.log('action = ' + this.lastListLoadAction);
    switch (this.lastListLoadAction) {
      case '':
        this.list = this._legList;
        this.lastListLoadAction = '';
        break;
      case 'before':
        this.list = [...this._legList, ...this.list];
        this.lastListLoadAction = '';
        this.isLoadingTop = false;
        console.log('stops loading top');
        break;
      case 'next':
        this.list = [...this.list, ...this._legList];
        this.lastListLoadAction = '';
        this.isLoadingBottom = false;
        console.log('stops loading bottom');
        break;
      default:
        console.log('invalid lastListLoadAction given - ' + this.lastListLoadAction);
        this.lastListLoadAction = '';
        break;
    }
    console.log('new list length = ' + this.list.length);
  }

  _pairDetails: IPairDetail[];

  get pairDetails() {
    return this._pairDetails;
  }

  @Input('pairDetailsList')
  set pairDetails(val: IPairDetail[]) {
    console.log('pair details list',val);
    this._pairDetails = val;
    this.localFiltering(this.flightFilterService.filterValuesSubject.getValue());
    this.setTestPage(this.testPage);

    // if (!val || val.length === 0 || !this.flightPairService) {
    //   this._pairDetails = [];
    //   return;
    // }
    //
    // this._pairDetails = val.map((item: IPairDetail) => {
    //   console.log('Item:', item);
    //   //item.pair$ = this.flightPairService.getPair(item?.id);
    //   return item;
    // });

    this.isLoadingTop = false;
    this.isLoadingBottom = false;
  }

  ngOnInit() {
    this.flightFilterService.getFilterValues().subscribe((filters) => {
      this.localFiltering(filters);
      this.setTestPage(this.testPage);
    });
    //this.filteredFlights = this.allFlights.slice(this.testPage * 20, (this.testPage+1) * 20);
    this.setTestPage(1);
  }

  localFiltering(filters: IFlightsFilter) {
    if (!this.pairDetails || !this.pairDetails?.length) {
      return;
    }
    this.filteredFlights = [...this.pairDetails];
    if (!filters.showUnpaired) {
      this.filteredFlights = this.filteredFlights.filter((val) => Object.keys(val.arrivalLeg).length && Object.keys(val.departureLeg).length);
    }
    if (filters.station && filters.station !== 'null' || this.userRole === 'DUTY_MANAGER') {
      const station = this.userRole === 'DUTY_MANAGER' ? this.authService.user.location : filters.station;
      console.log('Role:', this.userRole);
      console.log('Looking for station', station);
      this.filteredFlights = this.filteredFlights.filter((val) => val.arrivalLeg?.arrivalStation === station || val.departureLeg?.departureStation === station)
    }
    if (filters.alerts && filters.alerts !== 'null') {
      this.filteredFlights = this.filteredFlights.filter((val) => val.hasAlerts === (filters?.alerts === 'true'));
    }
    if (filters.timespan && filters.timespan !== 'null') {
      let date: dayjs.Dayjs;
      switch (filters.timespan) {
        case 'next8Hours':
          date = dayjs().utc().add(8, 'hours');
          break;
        case 'next12Hours':
          date = dayjs().utc().add(12, 'hours');
          break;
        case 'next24Hours':
          date = dayjs().utc().add(24, 'hours');
          break;
      }
      this.filteredFlights = this.filteredFlights.filter((val) => {
      return (Object.keys(val.arrivalLeg).length &&
      dayjs(getLatestLegTime(val.arrivalLeg,true)).utc().isBefore(date) &&
      dayjs(getLatestLegTime(val.arrivalLeg,true)).utc().isAfter(dayjs().utc())) ||
      (Object.keys(val.departureLeg).length &&
      dayjs(getLatestLegTime(val.departureLeg,false)).utc().isBefore(date) &&
      dayjs(getLatestLegTime(val.departureLeg,false)).utc().isAfter(dayjs().utc()))});
    }
    if (filters.status && filters.status !== 'null') {
      this.filteredFlights = this.filteredFlights.filter((val) => val?.status === filters.status && !val?.atd);
    }
    if(filters.acRegistration?.length) {
      this.filteredFlights = this.filteredFlights.filter((val) => val?.arrivalLeg?.acRegistration?.toLowerCase()?.includes(filters.acRegistration?.toLowerCase()) || val?.departureLeg?.acRegistration?.toLowerCase()?.includes(filters.acRegistration?.toLowerCase()));
    }
    if(filters.flightNumber?.length) {
      this.filteredFlights = this.filteredFlights.filter((val) => val?.arrivalLeg?.flightNumber?.toLowerCase()?.includes(filters.flightNumber?.toLowerCase()) || val?.departureLeg?.flightNumber?.toLowerCase()?.includes(filters.flightNumber?.toLowerCase()));
    }
    switch(filters?.sortBy) {
      case "STA":
        this.filteredFlights.sort((a,b) => (a?.arrivalLeg?.sta ?? Number.MAX_SAFE_INTEGER) < (b?.arrivalLeg?.sta ?? Number.MAX_SAFE_INTEGER) ? -1 : 1);
        break;
      case "ETA":
        this.filteredFlights.sort((a,b) => (a?.arrivalLeg?.eta ?? Number.MAX_SAFE_INTEGER) < (b?.arrivalLeg?.eta ?? Number.MAX_SAFE_INTEGER) ? -1 : 1);
        break;
      case "STD":
        this.filteredFlights.sort((a,b) => (a?.departureLeg?.std ?? Number.MAX_SAFE_INTEGER) < (b?.departureLeg?.std ?? Number.MAX_SAFE_INTEGER) ? -1 : 1);
        break;
      case "ETD":
        this.filteredFlights.sort((a,b) => (a?.departureLeg?.etd ?? Number.MAX_SAFE_INTEGER) < (b?.departureLeg?.etd ?? Number.MAX_SAFE_INTEGER) ? -1 : 1);
        break;
      case "REG":
        this.filteredFlights.sort((a,b) => (a?.departureLeg?.acRegistration ?? Number.MAX_SAFE_INTEGER) < (b?.departureLeg?.acRegistration ?? Number.MAX_SAFE_INTEGER) ? -1 : 1);
        break;
      default:
        this.filteredFlights.sort((a,b) => (a?.sortingDatetime?.getTime() ?? Number.MAX_SAFE_INTEGER) < (b?.sortingDatetime?.getTime() ?? Number.MAX_SAFE_INTEGER) ? -1 : 1);
        break;
    }
  }

  setTestPage(page: number) {
    this.testPage = page;
    this.visibleFlights = this.filteredFlights.slice((this.testPage - 1) * 20, this.testPage * 20);
  }

  setSelectedPair(pair: IPairDetail) {
    this.selectedPair = pair;
    this.setActiveFlight.emit(pair);
  }

  doResetPairIndex() {
    this.resetPairIndex.emit();
  }

  doSetPairIndex(pairKey: string) {
    if (!pairKey || this.activePairIndex === pairKey) {
      this.activePairIndex = '';
    } else {
      this.activePairIndex = pairKey;
    }
    this.setPairIndex.emit(this.activePairIndex);
  }

  onScrollDown(): void {
    console.log('scrolled down');
    console.log('leglist is ' + (this.pairDetails) ? 'not null' : 'null');
    this.lastListLoadAction = 'next';
    if (this.pairDetails && this.pairDetails.length > 0) {
      this.isLoadingBottom = true;
      this.getFlightsAfter.emit(this.pairDetails[this.pairDetails.length - 1]);
      this.flightPairService.pageNumber++;
      console.log('is loading bottom');
    } else {
      // this.getFlightsAfter.emit(null);
      console.log('is loading next but no search key is given because list is empty');
    }
  }

  onScrollUp(): void {
    console.log('scrolled up');
    console.log('leglist is ' + (this.pairDetails) ? 'not null' : 'null');
    this.lastListLoadAction = 'before';
    if (this.pairDetails && this.pairDetails.length > 0) {
      this.isLoadingTop = true;
      this.getFlightsBefore.emit(this.pairDetails[0]);
      this.flightPairService.pageNumber--;
      console.log('is loading top');
    } else {
      // this.getFlightsBefore.emit(null);
      console.log('is loading before but no search key is given because list is empty');
    }
  }

  // paginateBackward() {
  //   this.onScrollUp();
  // }
  //
  // paginateForward() {
  //   this.onScrollDown();
  // }
  paginateBackward() {
    this.setTestPage(this.testPage - 1);
    this.doc.scrollingElement.scrollIntoView({behavior: "smooth"});
  }

  paginateForward() {
    this.setTestPage(this.testPage + 1);
    this.doc.scrollingElement.scrollIntoView({behavior: "smooth"});
  }

  pairTrackById(index: number, pair: IPairDetail) {
    return pair.id;
  }

}
