import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueOrDash'
})
export class ValueOrDashPipe implements PipeTransform {

  transform(value: any): any {
    return value ? value : '-';
  }
}
