<div class="modal-header">
    <span class="modal-title">{{title}}</span>
    <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
    </button>
  </div>
<div class="modal-body">
<form [formGroup]="form">
    <div>
      <div class="row">
        <div class="col-12">
          <div>
            <div class="mb-3 flightInfo dark-blue">
             <b>{{leg?.airlineDesignator}}{{leg?.flightNumber}} / {{leg?.departureStation}} {{leg?.std | date:'HH:mm':'+00:00'}}z - {{leg?.arrivalStation}} {{leg?.sta | date:'HH:mm':'+00:00'}}z ({{leg?.acType}})</b>
            </div>
            <div class="row">
              <div class="col-5 form-field-header">
                New ETD*
              </div>
              <div class="col-4">
                <div class="mb-3">
                  <div class="input-group">
                    <input class="form-control" [placeholder]="dateFormat"
                           name="dp" formControlName="etdDate" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <ngb-timepicker name="newEtdTime" formControlName="etdTime" [spinners]="false"></ngb-timepicker>
              </div>
            </div>
          </div>
          <div>
            <div class="row mt-6">
              <div class="col-5 form-field-header">
                Delay Code*
              </div>
              <div class="col-4">
                <div class="mb-3">
                  <div class="mb-3">
                    <ng-select formControlName="delayCode">
                      <ng-option *ngFor="let delayCode of delayCodes" [value]="delayCode.id">{{delayCode.code}} {{ delayCode.description }}</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="mb-3">
                  <input type="text" class="form-control" id="arrival-flight-passenger-gate" placeholder="" [value]="delayInMinutes | timeFromMinutes" disabled>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary settings-modal-button" [disabled]="form?.disabled || isBusy" (click)="closeModal()">Cancel
    </button>
    <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
            [disabled]="form?.disabled || form?.invalid || isBusy">
      {{ isBusy ? '' : 'Save' }}
      <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
    </button>
  </div>
