import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IGenericContainerObject } from '../../../models/genericContainerObject.model';
import { TimeFromMinutesPipe } from '../../../pipes/time-from-minutes.pipe';
import { ISimpleData } from '../../../models/simpleData.model';

export class FlightsChangeFlightDetailsArrivalDepartureFlightFormBuilder {

  static constructForm(
    fb: FormBuilder,
    timeFromMinutesPipe: TimeFromMinutesPipe,
    passengerClassesArray: ISimpleData[] = [],
  ): FormGroup {

    const seatConfigFormGroup = new FormGroup({});

    passengerClassesArray.forEach((item: ISimpleData) => {
      if (item?.code) {
        seatConfigFormGroup.addControl(item.code, new FormGroup({
          code: new FormControl(item.code, Validators.required),
          description: new FormControl()
        }));
      }
    });

    return fb.group({
      etdDate: [{value: null, disabled: true}],
      etdTime: [{value: null, disabled: true}, Validators.pattern('\\d{2}[:]\\d{2}')],
      atdDate: [null],
      atdTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      totTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      etaTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      lndTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      ataTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      ctotTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      seatConfig: seatConfigFormGroup,
      gate: [null],
      stand: [null],
      niTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      departureDelays: new FormGroup({}),
      arrivalDelays: new FormGroup({}),
      arrivalDelaysNew: new FormGroup({
        arrival1: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        arrival2: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        })
      }),
      departureDelaysNew: new FormGroup({
        departure1: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure2: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure3: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure4: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
      })
    });
  }
}
