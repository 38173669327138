<div class="flex contact gap-3">
  <app-user-photo class="p-1" [user]="user" [size]="40" circleBackground="#F2F2F2" textColor="black"></app-user-photo>
  <div class="flex flex-column align-self-center">
    <div class="flex justify-content-between gap-1">
      <div class="name dark-blue">{{ user?.firstname }} {{user?.lastname}}</div>
      <div class="location dark-blue">({{ user?.location }})</div>
    </div>
    <div class="role">{{ user?.role | i18nSelect: userRoleObject.DISPLAY_TITLE_MAP }}</div>
  </div>
</div>
