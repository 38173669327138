<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="formGroup">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="container">
            <div class="row form-field-header h-54">
              <div class="col-12 flex">
                <div class="mb-3 form-check me-3">
                  <input class="form-check-input" type="radio" id="timezoneZulu" formControlName="isUtc" [value]="true">
                  <label class="form-check-label" for="timezoneZulu">UTC</label>
                </div>
                <div class="mb-3 form-check">
                  <input class="form-check-input" type="radio" id="timezoneLocal" formControlName="isUtc" [value]="false">
                  <label class="form-check-label" for="timezoneLocal">Local</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="container dateRangeContainer">
            <div class="row">
              <div class="col-6 form-field-header">
                Date range
              </div>
              <div class="col-6 mb-2">
                <div>
                  <div class="flex-row-space-between-align-center">
                    <span>{{getDateString(this.formGroup?.get('validFrom').value)}} - {{getDateString(this.formGroup?.get('validTo').value)}}</span>
                    <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"
                            [ngClass]="{'active': drp}"
                            (click)="drp = !drp"></button>
                  </div>

                  <ngb-datepicker *ngIf="drp" (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t"
                                  outsideDays="hidden">
                  </ngb-datepicker>


                  <ng-template #t let-date let-focused="focused">
                    <span class="custom-day"
                          [class.focused]="focused"
                          [class.range]="isRange(date)"
                          [class.faded]="isHovered(date) || isInside(date)"
                          (mouseenter)="hoveredDate = date"
                          (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="container h-54">
            <div class="row">
              <div class="col-6 form-field-header">
                Weekdays
              </div>
              <div class="col-6 flex weekdays-container mb-3 pb-3">
                <button class="btn daySelector" [ngClass]="{'button-blue': mon, 'btn-outline-secondary' : !mon}"
                        (click)="toggleWeekday('mon')">Mon
                </button>
                <button class="btn daySelector" [ngClass]="{'button-blue': tue, 'btn-outline-secondary' : !tue}"
                        (click)="toggleWeekday('tue')">Tue
                </button>
                <button class="btn daySelector" [ngClass]="{'button-blue': wed, 'btn-outline-secondary' : !wed}"
                        (click)="toggleWeekday('wed')">Wed
                </button>
                <button class="btn daySelector" [ngClass]="{'button-blue': thu, 'btn-outline-secondary' : !thu}"
                        (click)="toggleWeekday('thu')">Thu
                </button>
                <button class="btn daySelector" [ngClass]="{'button-blue': fri, 'btn-outline-secondary' : !fri}"
                        (click)="toggleWeekday('fri')">Fri
                </button>
                <button class="btn daySelector" [ngClass]="{'button-blue': sat, 'btn-outline-secondary' : !sat}"
                        (click)="toggleWeekday('sat')">Sat
                </button>
                <button class="btn daySelector" [ngClass]="{'button-blue': sun, 'btn-outline-secondary' : !sun}"
                        (click)="toggleWeekday('sun')">Sun
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 border-start">
          <div class="container h-54">
            <div class="row">
              <div class="col-6 form-field-header">
                Closing time
              </div>
              <div class="col-6 flex">
                <ngb-timepicker [spinners]="false" formControlName="closingTime"></ngb-timepicker>
              </div>
            </div>
          </div>
          <div class="container h-54">
              <div class="row">
                <div class="col-6 form-field-header">
                  Opening time
                </div>
                <div class="col-6 flex">
                  <ngb-timepicker [spinners]="false" formControlName="openingTime"></ngb-timepicker>
                </div>
              </div>
            </div>
        </div>
      <!--  <div class="col-12 mt-3">
          <div class="container h-54">
            <div class="row">
              <div class="col-3 form-field-header">
                Add Curfew Time
              </div>
              <div class="col-2 form-field-subheader">
                Closing time
              </div>
              <div class="col-2">
                <ngb-timepicker [(ngModel)]="closingTime" [spinners]="false" name="closingTime"></ngb-timepicker>
              </div>
              <div class="col-2 form-field-subheader">
                Opening time
              </div>
              <div class="col-2">
                <ngb-timepicker [(ngModel)]="openingTime" [spinners]="false" name="openingTime"></ngb-timepicker>
              </div>
              <div class="col-1">
                <div class="icon-button-container icon-plus-container-bg-color"
                     (click)="addTime(openingTime, closingTime)">
                  <span class="iconized">+</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!--  <div class="col-12 form-field-header ms-3 mt-3">
          Curfew Times
        </div>
        <ng-container *ngIf="times && times.length > 0">
          <div class="col-12 form-field-content" *ngFor="let time of times; let i = index">
            <div class="timeContainer" *ngIf="times.length > 0">
              closed from {{time?.closedFromTime}} until {{time?.closedUntilTime}}
              <div class="icon-button-container icon-minus-container-bg-color ms-3" (click)="removeTime(i)">
                <span class="iconized">-</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!times || times.length === 0">
          <div class="col-12 form-field-content">
            <div class="timeContainer" *ngIf="times.length === 0">
              none
            </div>
          </div>
        </ng-container> -->
      </div>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="formGroup?.disabled || formGroup?.invalid || isBusy">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
