<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
<form>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="container">
          <div class="row h-54">
            <div class="col-6 form-field-header">
              Non DST Variation
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.utcWithoutDst | utcVariationVisualizer: true}}
            </div>
          </div>
          <div *ngIf="timezoneData?.dstApplies" class="row h-54">
            <div class="col-6 form-field-header">
              DST Variation
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.utcWithDst | utcVariationVisualizer: true}}
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="container">
          <div class="row h-54">
            <div class="col-6 form-field-header">
              DST applies
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.dstApplies | titlecase}}
            </div>
          </div>
          <div class="row h-54">
            <div class="col-6 form-field-header"></div>
            <div class="col-6 form-field-content"></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="timezoneData?.dstApplies === 'YES'" class="row">
      <div class="col-6">
        <div class="container">
          <div class="row">
            <div class="col-12 form-field-header colorBlack mb-3">
              DST Start
            </div>
          </div>
          <div class="row h-54">
            <div class="col-6 form-field-header">
              Time
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.dstStartTime | utcVariationVisualizer}}
            </div>
          </div>
          <div class="row h-54">
            <div class="col-6 form-field-header">
              Month
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.dstStartMonth | fullMonthFromThreeChars}}
            </div>
          </div>
          <div class="row h-54">
            <div class="col-6 form-field-header">
              Weekday
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.dstStartWeekday | fullWeekdayFromThreeChars}}
            </div>
          </div>
          <div class="row h-54">
            <div class="col-6 form-field-header">
              Week of the month
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.dstStartWeekOfMonth | valueOrDash}}
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 border-start">
        <div class="container">
          <div class="row">
            <div class="col-12 form-field-header colorBlack mb-3">
              DST End
            </div>
          </div>
          <div class="row h-54">
            <div class="col-6 form-field-header">
              Time
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.dstEndTime | utcVariationVisualizer}}
            </div>
          </div>
          <div class="row h-54">
            <div class="col-6 form-field-header">
              Month
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.dstEndMonth | fullMonthFromThreeChars}}
            </div>
          </div>
          <div class="row h-54">
            <div class="col-6 form-field-header">
              Weekday
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.dstEndWeekday | fullWeekdayFromThreeChars}}
            </div>
          </div>
          <div class="row h-54">
            <div class="col-6 form-field-header">
              Week of the month
            </div>
            <div class="col-6 form-field-content">
              {{timezoneData?.dstEndWeekOfMonth | valueOrDash}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</div>
