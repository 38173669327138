import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss']
})
export class ChatHeaderComponent implements OnInit {

  @Output() closeChatAction = new EventEmitter();

  participantsImages = [
    './content/images/avatar2.png',
    './content/images/avatar2.png',
    './content/images/avatar2.png'
  ];

  names = 'Sabrina, Nik, Christopher';

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.closeChatAction.emit();
  }
}
