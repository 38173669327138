import { FormBuilder, FormGroup } from '@angular/forms';

export class SettingsGeneralMainFormBuilder {

  static constructForm(
    fb: FormBuilder,
    dateFormat: string): FormGroup {


    return fb.group({
      airlineMainBase: [''],
      dateFormat: [dateFormat],
      turnaroundTimeType: ['MINIMUM'],
      turnaroundThreshold: [''],
      minimumPassengerConnectionTimeInMinutes: [''],
    });
  }
}
