export interface ExitScreenMvtMessage {
    delayCode?: boolean,
    extraDelayCode?: boolean,
    delayMinutes?: boolean,
    extraDelayMinutes?: boolean,
    etdDate?: boolean,
    etdTime?: boolean,
    niDate?: boolean,
    niTime?: boolean,
    atdDate?: boolean,
    atdTime?: boolean,
    totDate?: boolean,
    totTime?: boolean,
    etaTime?: boolean,
    arrAirport?: boolean,
    ataDate?: boolean,
    ataTime?: boolean,
    landDate?: boolean,
    landTime?: boolean,
    pax?: boolean,
    si?: boolean,
}

export const MVT_INITIAL_EXIT_TYPE:ExitScreenMvtMessage = {
    delayCode: false,
    delayMinutes: false,
    etdTime: false,
    etdDate: false,
    niDate: false,
    niTime: false,
    atdDate: false,
    atdTime: false,
    totDate: false,
    totTime: false,
    etaTime: false,
    arrAirport: false,
    ataDate: false,
    ataTime: false,
    landDate: false,
    landTime: false,
    pax: false,
    si: false,
};
export const MVT_ED_EXIT_TYPE:ExitScreenMvtMessage = {
    delayCode: true,
    delayMinutes: false,
    etdTime: true,
    etdDate: true,
    niDate: false,
    niTime: false,
    atdDate: false,
    atdTime: false,
    totDate: false,
    totTime: false,
    etaTime: false,
    arrAirport: false,
    ataDate: false,
    ataTime: false,
    landDate: false,
    landTime: false,
    pax: false,
    si: true,
};

export const MVT_NI_EXIT_TYPE:ExitScreenMvtMessage = {
    delayCode: false,
    delayMinutes: false,
    etdTime: false,
    etdDate: false,
    niDate: true,
    niTime: true,
    atdDate: false,
    atdTime: false,
    totDate: false,
    totTime: false,
    etaTime: false,
    arrAirport: false,
    ataDate: false,
    ataTime: false,
    landDate: false,
    landTime: false,
    pax: false,
    si: true,
};

export const MVT_AD_EXIT_TYPE:ExitScreenMvtMessage = {
    delayCode: true,
    extraDelayCode: true,
    delayMinutes: true,
    extraDelayMinutes: true,
    etdTime: false,
    etdDate: false,
    niDate: false,
    niTime: false,
    atdDate: true,
    atdTime: true,
    totDate: true,
    totTime: true,
    etaTime: true,
    arrAirport: true,
    ataDate: false,
    ataTime: false,
    landDate: false,
    landTime: false,
    pax: true,
    si: true,
};

export const MVT_AA_EXIT_TYPE:ExitScreenMvtMessage = {
    delayCode: false,
    delayMinutes: false,
    etdTime: false,
    etdDate: false,
    niDate: false,
    niTime: false,
    atdDate: false,
    atdTime: false,
    totDate: false,
    totTime: false,
    etaTime: false,
    arrAirport: false,
    ataDate: true,
    ataTime: true,
    landDate: true,
    landTime: true,
    pax: false,
    si: true,
};

export const MVT_AD_REQUIRED_FIELDS: Partial<ExitScreenMvtMessage> = {
  delayCode: false,
  delayMinutes: false,
  etdTime: false,
  etdDate: false,
  niDate: false,
  niTime: false,
  atdDate: false,
  atdTime: true,
  totDate: false,
  totTime: true,
  etaTime: true,
  arrAirport: true,
  ataDate: false,
  ataTime: false,
  landDate: false,
  landTime: false,
};

export const MVT_AA_REQUIRED_FIELDS:Partial<ExitScreenMvtMessage> = {
  delayCode: false,
  delayMinutes: false,
  etdTime: false,
  etdDate: false,
  niDate: false,
  niTime: false,
  atdDate: false,
  atdTime: false,
  totDate: false,
  totTime: false,
  etaTime: false,
  arrAirport: false,
  ataDate: false,
  ataTime: true,
  landDate: false,
  landTime: true,
};

export const MVT_ED_REQUIRED_FIELDS:Partial<ExitScreenMvtMessage> = {
  delayCode: true,
  delayMinutes: true,
  etdTime: true,
  etdDate: true,
  niDate: false,
  niTime: false,
  atdDate: false,
  atdTime: false,
  totDate: false,
  totTime: false,
  etaTime: false,
  arrAirport: false,
  ataDate: false,
  ataTime: false,
  landDate: false,
  landTime: false,
};

export const MVT_NI_REQUIRED_FIELDS:Partial<ExitScreenMvtMessage> = {
  delayCode: false,
  delayMinutes: false,
  etdTime: false,
  etdDate: false,
  niDate: true,
  niTime: true,
  atdDate: false,
  atdTime: false,
  totDate: false,
  totTime: false,
  etaTime: false,
  arrAirport: false,
  ataDate: false,
  ataTime: false,
  landDate: false,
  landTime: false,
};
