import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { IPairsModel } from '../shared/models/pairs.model';
import { FlightsFilterService } from './flights-filter.service';
import { IPairOverview } from '../shared/models/pair-overview.model';
import { IPairDetail } from '../shared/models/pair-detail.model';
import { take } from 'rxjs/operators';
import dayjs from 'dayjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Searchable } from '../shared/utils/searchable.type';

@Injectable({
  providedIn: 'root'
})
export class PairsService {

  constructor(private httpClient: HttpClient, private toastService: ToastService, private flightsFilterService: FlightsFilterService) { }

  getPairsOverview(page = 1): Observable<IPairOverview[]> {
    return this.flightsFilterService.getFilterValues().pipe(take(1), mergeMap((filters) => {
      let date;
      if (filters.date) {
        const dateObj: NgbDateStruct = filters.date as unknown as NgbDateStruct;
        date = dayjs([dateObj.year, dateObj.month-1, dateObj.day]).format('DD.MM.YYYY');
      }


      return this.httpClient.post(`${environment.api_base_url}pairs/overview/${page}`, { isActive: true, filters: { ...filters, date: date, timespan: null } }, {}).pipe(
        map((item: IResponse<IPairOverview[]>) => {
          if(item?.isError){
            console.log(item.message);
          }
          return item?.data || [];
        }),
        catchError((error) => {
          console.log(error);
          return of([]);
        })
      );
    }));
  }

  getPairDetail(id: number): Observable<IPairDetail> {
    return this.httpClient.get(`${environment.api_base_url}pairs/detail/${id}`, {}).pipe(
      map((item: IResponse<IPairDetail>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        console.log(error);
        return of({});
      })
    );
  }

  getPairsByFilter(filter: IPairsModel = { isActive: true }): Observable<IPairsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}pairs/search`, filter, {}).pipe(
      map((item: IResponse<IPairsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getPairDetailsByFilter(filter: Searchable<IPairsModel> = { isActive: true }): Observable<IPairsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}pairs/search-detail`, filter, {}).pipe(
      map((item: IResponse<IPairsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getById(id: number): Observable<IPairsModel[]> {
    return this.httpClient.get(`${environment.api_base_url}pairs/${id}`, {}).pipe(
      map((item: IResponse<IPairsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

    getPairsForReports(filter: any): Observable<IPairsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}pairs/reports-search`, filter, {}).pipe(
      map((item: IResponse<IPairsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }


  deleteAll(): Observable<boolean> {
    return this.httpClient.delete(`${environment.api_base_url}pairs/testing/all`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }
}
