<div class="container container-95">
  <div class="row">
    <div class="col-2 header-border-right">
      &nbsp;
    </div>
    <div style="text-align:center;" class="col-10 form-field-header">
      Outbound Flight
    </div>
  </div>
  <div class="row header-border-bottom">
    <div class="col-2 form-field-header header-border-right">
      Inbound Flight
    </div>
    <div class="col-10 form-field-content">
      <div class="flex justify-content-around form-field-header">
        <div class="contentCell text-center" *ngFor="let horizontalFlightServiceTypeHeader of flightServiceTypes | keyvalue">{{horizontalFlightServiceTypeHeader?.value.code}}</div>
      </div>
    </div>
  </div>
  <div class="row content-border-bottom" *ngFor="let verticalFlightServiceTypeHeader of flightServiceTypes | keyvalue">
    <div class="col-2 form-field-header header-border-right text-center">
      {{verticalFlightServiceTypeHeader?.value.code}}
    </div>
    <div class="col-10 form-field-content">
      <div class="flex justify-content-around form-field-content">
        <div class="contentCell" *ngFor="let horizontalFlightServiceType of flightServiceTypes | keyvalue">
          {{getSpecificTurnaroundTime(+verticalFlightServiceTypeHeader?.key, +horizontalFlightServiceType?.key)}}
        </div>
      </div>
    </div>
  </div>
</div>
