import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {States} from '../../constants/state.constants';
import {IFlightPair} from '../../models/flight-pair.model';
import {Equipment} from '../../constants/equipment.constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import {ViewModalComponent} from '../view-modal/view-modal.component';
import {ViewTypes} from '../../constants/viewTypes.constants';

@Component({
  selector: 'app-deprecated-leg',
  templateUrl: './deprecated-leg.component.html',
  styleUrls: ['./deprecated-leg.component.scss']
})
export class DeprecatedLegComponent implements OnInit {

  @Input() iconCheck: boolean;
  @Input() iconMemo: boolean;
  @Input() iconMessage: boolean;
  @Input() iconWarning: boolean;
  @Input() iconInfo: boolean;

  @Input() legState: States;
  @Input() indicatorMemo: boolean;
  @Input() indicatorMessage: boolean;
  @Input() indicatorWarning: boolean;
  @Input() indicatorInfo: boolean;

  @Input() flightPair: any; // IFlightPair
  @Input() timezone: string;

  @Input() registrationNrState: States;
  @Input() flightFromNrState: States;
  @Input() etaState: States;
  @Input() turnaroundDurationState: States;
  @Input() flightToNrState: States;
  @Input() stdState: States;
  @Input() etdState: States;
  @Input() atdState: States;
  @Input() ctotState: States;

  @ViewChild('pair') legElement: ElementRef;

  _isActive: boolean;

  get isActive(): boolean {
    return this._isActive;
  }

  @Input('isActive')
  set isActive(value: boolean) {
    if (value && !this._isActive) {
      this._isActive = value;
      this.legElement.nativeElement.scrollIntoView({behavior: 'smooth'});
    } else {
      this._isActive = value;
    }
  }

  @Output() deactivatePair = new EventEmitter();

  states = States;

  equipments = [...Equipment.LIST];

  constructor(private modalService: NgbModal, private renderer: Renderer2) {
  }

  ngOnInit() {
  }

  calcDateDifferenceInDays(date1: string, date2: string, tz: string): number {

    if (!date1 || !date2) {
      return 0;
    }

    let d1 = dayjs(date1);
    let d2 = dayjs(date2);

    if (d1 && d2 && d1.isValid() && d2.isValid()) {
      if (tz === '+0000') {
        d1 = dayjs(d1.utc().format('DD-MM-YYYY'), 'DD-MM-YYYY');
        d2 = dayjs(d2.utc().format('DD-MM-YYYY'), 'DD-MM-YYYY');
      }
      return d2.diff(d1, 'days');
    }
    return 0;
  }

  getBgColor(isActive: boolean): string {
    if (isActive) {
      return 'bg-baby-blue';
    }
    return 'bg-white';
  }

  toggleDetails() {
    this._isActive = !this.isActive;
    this.deactivatePair.emit();
  }

  openLegInformationModal() {
    const modalRef = this.modalService.open(ViewModalComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'View Further Information';
    modalRef.componentInstance.viewType = ViewTypes.FLIGHTS_VIEW_FURTHER_INFORMATION;
  }

}
