import { Component, OnInit } from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {TurnaroundStatus} from '../../shared/constants/turnaround-status.constants';
import * as dayjs from 'dayjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-dummies',
  templateUrl: './dummies.component.html',
  styleUrls: ['./dummies.component.scss']
})
export class DummiesComponent implements OnInit {
  version = "0.0.1";

  constructor(private fns: AngularFireFunctions) { }

  ngOnInit() {
  }

  changeTurnaround(state: string) {
    let newState = TurnaroundStatus.UPCOMING;

    if (state === 'start') {
      newState = TurnaroundStatus.IN_PROGRESS;
    } else if (state === 'pause') {
      newState = TurnaroundStatus.PAUSED;
    } else if (state === 'resume') {
      newState = TurnaroundStatus.RESUMED;
    } else if (state === 'finish') {
      newState = TurnaroundStatus.FINISHED;
    }

    const datetime = dayjs.utc().format();
    const user = 'Anna Black';
    const pairKey = 'ZRH-VIE-20200314-0100-NRA2001-VIE-ATH-20200314-0400-NRA3001';

    const params = {
      newState,
      user,
      pairKey,
      datetime
    };
    const callable = this.fns.httpsCallable('rampChangeTurnaroundState');
    // callable(null).pipe(take(1)).subscribe(item => console.log('change turnaround state result:', item));


    const data$ = callable(params);

    data$.pipe(take(1)).subscribe(response => console.log(response));
  }

}
