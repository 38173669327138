<div class="change-flight-details-content-page">
  <div class="accordion-item"
       [ngClass]="{
       'opened': selectedMenuItem === flightDetailsConstants.ARRIVING_FLIGHT_INFORMATION,
       'disabled': !data?.pair?.arrivalLeg
       }"
       (click)="data?.pair?.arrivalLeg ? setSelectedMenuItem(flightDetailsConstants.ARRIVING_FLIGHT_INFORMATION) : false">
    <div class="accordion-icon">
      {{(selectedMenuItem === flightDetailsConstants.ARRIVING_FLIGHT_INFORMATION ? '-' : '+')}}
    </div>
    <div class="accordion-text">
      {{flightDetailsConstants.ARRIVING_FLIGHT_INFORMATION}}
    </div>
  </div>
  <ng-container *ngIf="selectedMenuItem === flightDetailsConstants.ARRIVING_FLIGHT_INFORMATION">
    <div class="accordion-item-content-container">
      <app-change-flights-arriving-flight-information
        [leg]="data?.pair?.arrivalLeg"
        [timezone]="data?.timezone"
        [passengerClasses]="passengerClasses"
        [delayCodes]="delayCodes"
        (saveClicked)="closeWindow.emit()">

      </app-change-flights-arriving-flight-information>
    </div>
  </ng-container>
  <div class="accordion-item"
       [ngClass]="{
       'opened': selectedMenuItem === flightDetailsConstants.DEPARTING_FLIGHT_INFORMATION,
       'disabled': !data?.pair?.departureLeg
       }"
       (click)="data?.pair?.departureLeg ? setSelectedMenuItem(flightDetailsConstants.DEPARTING_FLIGHT_INFORMATION) : false">
    <div class="accordion-icon">
      {{(selectedMenuItem === flightDetailsConstants.DEPARTING_FLIGHT_INFORMATION ? '-' : '+')}}
    </div>
    <div class="accordion-text">
      {{flightDetailsConstants.DEPARTING_FLIGHT_INFORMATION}}
    </div>
  </div>
  <ng-container *ngIf="selectedMenuItem === flightDetailsConstants.DEPARTING_FLIGHT_INFORMATION">
    <div class="accordion-item-content-container">
      <app-change-flights-departing-flight-information
        [leg]="data?.pair?.departureLeg"
        [timezone]="data?.timezone"
        [passengerClasses]="passengerClasses"
        [delayCodes]="delayCodes"
        (saveClicked)="closeWindow.emit()"

      ></app-change-flights-departing-flight-information>
    </div>
  </ng-container>
  <div class="accordion-item"
       [ngClass]="{'opened': selectedMenuItem === flightDetailsConstants.TURNAROUND_PROCESSES_AND_GSES}"
       (click)="setSelectedMenuItem(flightDetailsConstants.TURNAROUND_PROCESSES_AND_GSES)">
    <div class="accordion-icon">
      {{(selectedMenuItem === flightDetailsConstants.TURNAROUND_PROCESSES_AND_GSES ? '-' : '+')}}
    </div>
    <div class="accordion-text">
      {{flightDetailsConstants.TURNAROUND_PROCESSES_AND_GSES}}
    </div>
  </div>
  <ng-container *ngIf="selectedMenuItem === flightDetailsConstants.TURNAROUND_PROCESSES_AND_GSES">
    <!-- <div class="accordion-item-content-container">
      <app-change-flights-turnaround-processes
        [pairKey]="(data?.pair?.arrivalLegKey ? data?.pair?.arrivalLegKey : 'null') + '-' + (data?.pair?.departureLegKey ? data?.pair?.departureLegKey : 'null')"
        [airportId]="data?.pair?.arrivalLeg?.arrivalStation ? data?.pair?.arrivalLeg?.arrivalStation : data?.pair?.departureLeg?.departureStation"
        [acTypeId]="data?.pair?.arrivalLeg?.acType ? data?.pair?.arrivalLeg?.acType : data?.pair?.departureLeg?.acType"
        [pairProcesses]="data?.pair?.processes"></app-change-flights-turnaround-processes>
    </div> -->
  </ng-container>

  <!--
  <div class="accordion-item" [ngClass]="{'opened': selectedMenuItem === flightDetailsConstants.TURNAROUND_FACILITIES}"
       (click)="setSelectedMenuItem(flightDetailsConstants.TURNAROUND_FACILITIES)">
    <div class="accordion-icon">
      {{(selectedMenuItem === flightDetailsConstants.TURNAROUND_FACILITIES ? '-' : '+')}}
    </div>
    <div class="accordion-text">
      {{flightDetailsConstants.TURNAROUND_FACILITIES}}
    </div>
  </div>

  <ng-container *ngIf="selectedMenuItem === flightDetailsConstants.TURNAROUND_FACILITIES">
    <div class="accordion-item-content-container">
      <app-change-flights-turnaround-facilities
        [pairKey]="(data?.pair?.arrivalLegKey ? data.pair.arrivalLegKey: 'null') + '-' + (data?.pair?.departureLegKey ? data.pair.departureLegKey : 'null')"
        [pairProcesses]="data?.pair?.processes"
        (saveClicked)="closeWindow.emit()"></app-change-flights-turnaround-facilities>
    </div>
  </ng-container> -->
</div>
