import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minutesFromTime'
})
export class MinutesFromTimePipe implements PipeTransform {

  transform(time: string): number {
    if (!time || !time.includes(':')) {
      return 0;
    }
    const splitTime = time.split(':');

    if (!splitTime[0].match('\\d{2}') || !splitTime[1].match('\\d{2}')) {
      return 0;
    }

    return (+splitTime[0] * 60) + (+splitTime[1]);
  }

}
