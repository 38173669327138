import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-flights-modify-flights-view',
  templateUrl: './flights-modify-flights-view.component.html',
  styleUrls: ['./flights-modify-flights-view.component.scss']
})
export class FlightsModifyFlightsViewComponent implements OnInit {

  @Output() closeWindow = new EventEmitter();

  SELECT = 'Select';
  SEARCH = 'Search';
  MODIFY = 'Modify';

  step = this.SEARCH;

  steps = [this.SEARCH, this.SELECT, this.MODIFY];

  constructor() {
  }

  ngOnInit() {
  }

  searchFlights() {
    this.step = this.SELECT;
  }

  selectFlights() {
    this.step = this.MODIFY;
  }

  modifyFlights() {
    this.closeWindow.emit();
  }

}
