import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError, interval, map, Observable, of } from 'rxjs';
import { IResponse } from '../shared/models/IResponse.model';
import { IChatMessageModel } from '../shared/models/chat-message.model';
import { IMyChatGroups } from '../shared/models/my-chat-groups.interface';
import { IGenericContainerObject } from '../shared/models/genericContainerObject.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  unreadCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  messageGroups: IGenericContainerObject<IMyChatGroups> = {};
  myMessageGroups: IGenericContainerObject<IMyChatGroups> = {};
  uid: string;

  constructor(private httpClient: HttpClient, private auth: Auth, private authService: AuthService) {
    this.uid = auth.currentUser?.uid;
    this.authService.loggedOutEvent.subscribe(() => {
      this.clearData();
    });
    this.storeUnreadMessageCount();
    interval(10000).subscribe(() => {
      this.storeUnreadMessageCount();
    });

    interval(10000).subscribe(() => {
      if (this.auth.currentUser) {
        this.getMyChatGroups().subscribe((result) => {
          this.refreshChatGroups(result);
        });
      }
    });

    this.getMyChatGroups().subscribe((result) => {
      this.refreshChatGroups(result);
    });
  }

  clearData() {
    this.messageGroups = {};
    this.myMessageGroups = {};
    this.unreadCount.next(0);
  }

  refreshChatGroups(groups: IMyChatGroups[]): void {
    // if (Object.keys(this.messageGroups).length) {
    //   return;
    // }
    for (const res of groups.filter((group) => group.userId !== this.authService.userSubject.value?.id)) {
      //this.messageGroups[res.chatGroupId] = res;
      if(this.messageGroups[res.chatGroupId]) {
        Object.assign(this.messageGroups[res.chatGroupId] ?? {}, res);
        continue;
      }
      this.messageGroups[res.chatGroupId] = res;
    }
    for (const res of groups.filter((group) => group.userId === this.authService.userSubject.value?.id)) {
      //this.myMessageGroups[res.chatGroupId] = res;
      if(this.myMessageGroups[res.chatGroupId]) {
        Object.assign(this.myMessageGroups[res.chatGroupId] ?? {}, res);
      }
      this.myMessageGroups[res.chatGroupId] = res;
    }
  }

  async storeUnreadMessageCount() {
    this.getUnreadCount().subscribe((result) => {
      this.unreadCount.next(result);
    });
  }

  acknowledgeMessage(lastMessageId: number): Observable<number> {
    return this.httpClient.post(`${environment.api_base_url}chat/acknowledge`, { lastMessageIdToAcknowledge: lastMessageId }, {}).pipe(
      map((item: IResponse<number>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || 0;
      }),
      catchError((error) => {
        console.log(error);
        return of(0);
      })
    );
  }

  getUnreadCount(): Observable<number> {
    return this.httpClient.get(`${environment.api_base_url}chat/get-unread-count`, {}).pipe(
      map((item: IResponse<number>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || 0;
      }),
      catchError((error) => {
        console.log(error);
        return of(0);
      })
    );
  }

  getChatGroup(memberIds: number[]): Observable<number> {
    return this.httpClient.post(`${environment.api_base_url}chat/find-group`, { memberIds }, {}).pipe(
      map((item: IResponse<number>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || 0;
      }),
      catchError((error) => {
        console.log(error);
        return of(0);
      })
    );
  }

  getChatHistory(chatGroupId: number, lastMessageId: number): Observable<IChatMessageModel[]> {
    return this.httpClient.post(`${environment.api_base_url}chat/get-history`, { chatGroupId, lastMessageId }, {}).pipe(
      map((item: IResponse<IChatMessageModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  sendMessage(chatGroupId: number, content: string): Observable<IChatMessageModel> {
    return this.httpClient.post(`${environment.api_base_url}chat/send-message`, { chatGroupId, content }, {}).pipe(
      map((item: IResponse<IChatMessageModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        console.log(error);
        return of({});
      })
    );
  }

  createChatGroup(memberIds: number[]): Observable<number> {
    return this.httpClient.post(`${environment.api_base_url}chat/create-group`, { memberIds }, {}).pipe(
      map((item: IResponse<number>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || 0;
      }),
      catchError((error) => {
        console.log(error);
        return of(0);
      })
    );
  }

  deleteMessage(messageId: number): Observable<boolean> {
    return this.httpClient.delete(`${environment.api_base_url}chat/${messageId}`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }

  editMessage(message: IChatMessageModel): Observable<IChatMessageModel> {
    return this.httpClient.post(`${environment.api_base_url}chat/${message?.id}`, message, {}).pipe(
      map((item: IResponse<IChatMessageModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        console.log(error);
        return of({});
      })
    );
  }

  getMyChatGroups(): Observable<IMyChatGroups[]> {
    return this.httpClient.get(`${environment.api_base_url}chat/find-my-groups`, {}).pipe(
      map((item: IResponse<IMyChatGroups[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }
}
