import { Component, Input, OnInit } from '@angular/core';
import { ISimpleData } from '../../../../../shared/models/simpleData.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { ToastService } from '../../../../../services/toast.service';
import { take } from 'rxjs/operators';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';
import { AddEditFlightServiceTypesDialogComponent } from '../add-edit-flight-services-types-dialog/add-edit-flight-service-types-dialog.component';

@Component({
  selector: 'app-manage-flight-service-types-dialog',
  templateUrl: './manage-flight-service-types-dialog.component.html',
  styleUrls: ['./manage-flight-service-types-dialog.component.scss']
})
export class ManageFlightServiceTypesDialogComponent implements OnInit {

  @Input() title?: string;
  @Input() flightServiceTypes?: { [p: number]: ISimpleData };
  @Input() readonly = true;
  selected?: string | any;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private generalSettingsService: GeneralSettingsService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.fetchFlightServiceTypes();
  }

  doAdd() {
    const modalRef = this.modalService.open(AddEditFlightServiceTypesDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Add Flight Service Type';
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchFlightServiceTypes();
    })
  }

  doEdit(item: string | any) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(AddEditFlightServiceTypesDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Edit Flight Service Type';
    modalRef.componentInstance.flightServiceType = this.flightServiceTypes[item];
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchFlightServiceTypes();
    })
  }

  doDelete(item: string | any) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Flight Service Type ' + this.flightServiceTypes[item].code;

    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.generalSettingsService.deleteFlightServiceType(item).subscribe((result) => {
        if(result) {
          this.toastService.showSuccess("The flight service type has been deleted.");
          this.fetchFlightServiceTypes();
        }
      });
    });

  }

  fetchFlightServiceTypes() {
    this.generalSettingsService.getFlightServiceTypes().subscribe((result) => {
      this.flightServiceTypes = {};
      for (const flightServiceType of result) {
        this.flightServiceTypes[flightServiceType.id] = flightServiceType;
      }
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

}
