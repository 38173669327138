<div class="modal-header">
  <span class="modal-title">{{ title }}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal('Cross click')">
  </button>
</div>
<div class="modal-body">
  {{ text }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" ngbAutofocus (click)="closeModal('Close click')">{{ buttonText }}</button>
</div>
