import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { IGlobalCountry } from '../shared/models/global-country.model';
import { IMasterCountriesModel } from '../shared/models/master-countries.model';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  get countries(): BehaviorSubject<IGlobalCountry[]> {
    return this._countries;
  }

  private _countries: BehaviorSubject<IGlobalCountry[]> = new BehaviorSubject([]);
  constructor(private httpClient: HttpClient, private toastService: ToastService) {
    this.fetchCountries().subscribe(() => {});
  }

  fetchCountries(filter: IMasterCountriesModel = { isActive: true }): Observable<IGlobalCountry[]> {
    return this.httpClient.post(`${environment.api_base_url}master-countries/search`, filter, {}).pipe(
      map((item: IResponse<IMasterCountriesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IGlobalCountry[]) => {
        if (!filter.isActive || Object.keys(filter).length > 1) {
          return;
        }
        this._countries.next(value);
      })
    );
  }

}
