import {Component, Input, OnInit} from '@angular/core';
import {TurnaroundType} from '../../../constants/turnaround-type.constants';

@Component({
  selector: 'app-settings-view-tolerance-threshold-bar',
  templateUrl: './settings-view-tolerance-threshold-bar.component.html',
  styleUrls: ['./settings-view-tolerance-threshold-bar.component.scss']
})
export class SettingsViewToleranceThresholdBarComponent implements OnInit {

  @Input() topLabel1?: string;
  @Input() topLabel2?: string;
  @Input() bottomLabel2?: string;
  @Input() isDuration?: boolean;
  @Input() turnaroundType?: TurnaroundType;

  standardTurnaroundType = TurnaroundType.STANDARD;

  constructor() { }

  ngOnInit() {
  }

}
