import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-flights-cancel-flights-view',
  templateUrl: './flights-cancel-flights-view.component.html',
  styleUrls: ['./flights-cancel-flights-view.component.scss']
})
export class FlightsCancelFlightsViewComponent implements OnInit {

  SEARCH = 'Search';
  SELECT_AND_CANCEL = 'Select & Cancel';

  @Output() closeWindow = new EventEmitter();

  step = this.SEARCH;
  steps = [this.SEARCH, this.SELECT_AND_CANCEL];

  constructor() {
  }

  ngOnInit() {
  }

  searchFlights() {
    this.step = this.SELECT_AND_CANCEL;
  }

  selectAndCancelFlights() {
    this.closeWindow.emit();
  }

}
