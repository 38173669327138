import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPairOverview } from '../../models/pair-overview.model';
import { IPairDetail } from '../../models/pair-detail.model';
import { isPairOverview } from '../../utils/utils';

@Component({
  selector: 'app-leg-information-window',
  templateUrl: './leg-information-window.component.html',
  styleUrls: ['./leg-information-window.component.scss']
})
export class LegInformationWindowComponent implements OnInit {

  pair: IPairOverview | IPairDetail;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  public readonly isPairOverview = isPairOverview;
}
