export class FlightDetails {
  static DEPARTING_FLIGHT_INFORMATION = 'Departing Flight Information';
  static ARRIVING_FLIGHT_INFORMATION = 'Arriving Flight Information';
  static TURNAROUND_PROCESSES_AND_GSES = 'Turnaround Processes & GSEs';
  static TURNAROUND_PROCESSES = 'Turnaround Processes';
  static TURNAROUND_FACILITIES = 'Turnaround GSE';

  static LIST = [
    FlightDetails.DEPARTING_FLIGHT_INFORMATION,
    FlightDetails.ARRIVING_FLIGHT_INFORMATION,
    FlightDetails.TURNAROUND_PROCESSES,
    FlightDetails.TURNAROUND_PROCESSES_AND_GSES,
    FlightDetails.TURNAROUND_FACILITIES
  ];
}
