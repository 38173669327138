<div class="modal-header">
  <span class="modal-title">View Further Information</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body children-fs16">
  <div class="flex flex-column gap-3 pb-3">

    <div class="flex flex-column ">
      <div class="col-12 form-field-header colorBlack title">
        New information
      </div>
      <div class="flex">
        <div class="col-6 form-field-header">
          Next information (NI) at:
        </div>
        <div class="col-3 form-field-subheader">
          {{!isPairOverview(pair) ? pair?.departureLeg?.ni ? (pair.departureLeg.ni | date:'HH:mm': '+0000') : '-' : 'Loading...' }}
        </div>
      </div>
    </div>
    <div class="border-bottom"></div>
    <div class="flex flex-column ">
      <div class="col-12 form-field-header colorBlack title">
        Passengers
      </div>
      <div class="col-6 sub-title mt-2">
        Connecting Passengers:
      </div>
      <div class="flex">
        <div class="col-6 form-field-header">EW0656/14 ATH Y12</div>
        <div class="dark-blue">01:45</div>
      </div>
      <div class="flex">
        <div class="col-6 form-field-header">EW0858/14 BER C1 Y22</div>
        <div class="dark-blue">00:39</div>
      </div>
    </div>
    <div class="border-bottom"></div>
    <div class="flex flex-column gap-2">
      <div class=" col-12 form-field-header colorBlack title">
        A-CDM Info
      </div>
      <div class="flex gap-4 ">
        <div class="flex-column col-4 flex gap-2">
          <div class="sub-title">
            Arriving Flight
          </div>
          <div class="flex">
            <div class="form-field-header col-5">SIBT</div>
            <div class="form-field-content dark-blue">18:50*</div>
          </div>
          <div class="flex">
            <div class="form-field-header col-5">IBT</div>
            <div class="form-field-content dark-blue">18:50*</div>
          </div>
          <div class="flex">
            <div class="form-field-header col-5">Rwy</div>
            <div class="form-field-content dark-blue">03R*</div>
          </div>
          <div class="flex">
            <div class="form-field-header col-5">STATA</div>
            <div class="form-field-content dark-blue">AIRB*</div>
          </div>
        </div>
        <div class="right-border"></div>
        <div class="flex-column col-5  flex gap-2">
          <div class="sub-title">
            Departing Flight
          </div>
          <div class="flex">
            <div class="form-field-header col-5">TOBT</div>
            <div class="form-field-content dark-blue">19:35*</div>
          </div>
          <div class="flex">
            <div class="form-field-header col-5">OBT</div>
            <div class="form-field-content dark-blue">19:35*</div>
          </div>
          <div class="flex">
            <div class="form-field-header col-5">SOBT</div>
            <div class="form-field-content dark-blue">19:35*</div>
          </div>
          <div class="flex">
            <div class="form-field-header col-5">TOT</div>
            <div class="form-field-content dark-blue">-*</div>
          </div>
          <div class="flex">
            <div class="form-field-header col-5">TSAT</div>
            <div class="form-field-content dark-blue">19:20*</div>
          </div>
          <div class="flex">
            <div class="form-field-header col-5">TTOT</div>
            <div class="form-field-content dark-blue">19:50*</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
