import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tabs-navigation',
  templateUrl: './tabs-navigation.component.html',
  styleUrls: ['./tabs-navigation.component.scss']
})
export class TabsNavigationComponent implements OnInit {

  @Input() tabs: string[];
  @Output() tabChanged = new EventEmitter<string>();
  activeTabId: number = 0;
  constructor() { }

  ngOnInit(): void {
  }

  changeTab(index: number) {
    this.activeTabId = index;
    this.tabChanged.emit(this.tabs[index]);
  }
}
