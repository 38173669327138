<form class="flex gap-2 p-2" [formGroup]="formGroup">
  <input type="text" class="form-control typing-input" placeholder="Type a message" formControlName="text" (keyup)="submitKeyboard($event)"/>
  <div class="send-button-container rounded-circle align-self-center" (click)="submit(formGroup.get('text')?.value?.trim().length ? formGroup.get('text')?.value?.trim() : '👍')">
<!--    <i class="demo-icon icon-icons8-paper-plane size-30 send-button" *ngIf="formGroup.get('text')?.value?.trim()?.length"></i>-->
<!--    <fa-icon [icon]="sendIcon" [classes]="['text-white']" *ngIf="formGroup.get('text')?.value?.trim().length"></fa-icon>-->
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  class="send" viewBox="0 0 16 16" *ngIf="formGroup.get('text')?.value?.trim().length">
      <path fill="#3964FF" d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
    </svg>
    <fa-icon [icon]="likeIcon" [styles]="{ width: '20px', height: '20px' }" [classes]="['avitium-color']" *ngIf="!formGroup.get('text')?.value?.trim().length"></fa-icon>
  </div>
</form>
