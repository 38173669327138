import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {map, take} from 'rxjs/operators';
import {UserRoles} from '../shared/constants/user-roles.constants';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    console.log('no auth guard');

    if (this.authService.isInitialized) {
      return this.checkAuth();
    }

    return this.authService.initializeSubject.asObservable()
      .pipe(take(1), map(() => {
        return this.checkAuth();
      }));
  }

  private checkAuth() {
    if (!this.authService.user || !this.authService.user.role || this.authService.user.role === UserRoles.RAMP_AGENT) {
      return true;
    }
    this.router.navigate(['flights']);
    return false;
  }
}
