import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { ISSIMModel } from '../shared/models/ssim.model';

@Injectable({
  providedIn: 'root'
})
export class SsimService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  uploadSSIM(ssimData: ISSIMModel): Observable<boolean> {
    return this.httpClient.post(`${environment.api_base_url}ssim/upload`, ssimData, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error uploading SSIM file");
        return of(null);
      })
    );
  }
}
