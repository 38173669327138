import { Injectable } from '@angular/core';
import { IUser } from '../shared/models/user.model';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { IPairLegTimesLogModel } from '../shared/models/pair-leg-times-log.model';
import { Arrayable } from '../shared/utils/arrayable.type';

@Injectable({
  providedIn: 'root'
})
export class PairLegTimesLogService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getPairLegTimeLogs(filter:  Arrayable<IPairLegTimesLogModel> = { isActive: true }): Observable<any[]> {
    return this.httpClient.post(`${environment.api_base_url}pair-leg-times-log/search`, filter, {}).pipe(
      map((item: IResponse<IPairLegTimesLogModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getPairLegTimeLogById(id: number): Observable<IPairLegTimesLogModel> {
    return this.httpClient.get(`${environment.api_base_url}pair-leg-times-log/${id}`, {}).pipe(
      map((item: IResponse<IPairLegTimesLogModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        console.log(error);
        return of({});
      })
    );
  }

  deletePairLegTimeLog(userId: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}pair-leg-times-log/${userId}`, {}).pipe(
      map((item: IResponse<IUser>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError(() => {
        this.toastService.showError("Error deleting pair-leg time log");
        return of({});
      })
    );
  }

  savePairLegTimeLog(user: IPairLegTimesLogModel): Observable<IUser> {
    let obs;
    if (user.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}pair-leg-times-log/${user.id}`, user, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}pair-leg-times-log/create`, user, {})
    }
    return obs.pipe(
      map((item: IResponse<IPairLegTimesLogModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError(() => {
        this.toastService.showError("Error saving pair-leg time log");
        return of({});
      })
    );
  }
}
