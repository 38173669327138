export class TurnaroundStatus {
  static UPCOMING = 'UPCOMING';
  static IN_PROGRESS = 'IN_PROGRESS';
  static PAUSED = 'PAUSED';
  static RESUMED = 'RESUMED';
  static FINISHED = 'FINISHED';

  static LIST = [
    TurnaroundStatus.UPCOMING,
    TurnaroundStatus.IN_PROGRESS,
    TurnaroundStatus.PAUSED,
    TurnaroundStatus.RESUMED,
    TurnaroundStatus.FINISHED
  ];

  static IDS = {
    'UPCOMING': 2,
    'IN_PROGRESS': 3,
    'PAUSED': 4,
    'RESUMED': 5,
    'FINISHED': 6,
  };
}
