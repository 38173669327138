<div class="modal-header">
  <span class="modal-title">Edit message</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body" [formGroup]="formGroup">
  <div class="flex gap-2 justify-content-center">
    <input class="form-control" formControlName="content">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn button-blue settings-modal-button" (click)="editMessage()">Edit message</button>
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Close
  </button>
</div>
