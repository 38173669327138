import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-settings-header',
  templateUrl: './settings-header.component.html',
  styleUrls: ['./settings-header.component.scss']
})
export class SettingsHeaderComponent implements OnInit {
  @Input() text: string;
  @Input() showSaveButton: boolean = false;
  @Input() textSize: number = 20;
  @Input() saveButtonDisabled: boolean = false;
  @Input() spinner: boolean = false;
  @Input() backButton: boolean = false;
  @Input() backButtonUrl: string = '..';
  @Output() onSaveClicked: EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

}
