import {Component, Input, OnInit} from '@angular/core';
import { IAirportTerminals } from '../../../../../../shared/models/airport-terminals.model';

@Component({
  selector: 'app-manage-airport-terminal-dialog-list-item',
  templateUrl: './manage-airport-terminal-dialog-list-item.component.html',
  styleUrls: ['./manage-airport-terminal-dialog-list-item.component.scss']
})
export class ManageAirportTerminalDialogListItemComponent implements OnInit {

  @Input() item?: IAirportTerminals;
  @Input() opened?: boolean;
  @Input() shallNotOpen?: boolean;

  constructor() { }

  ngOnInit() {
  }

}
