import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import { AsmService } from '../../../services/asm.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-asm-upload',
  templateUrl: './asm-upload.component.html',
  styleUrls: ['./asm-upload.component.scss']
})
export class AsmUploadComponent implements OnInit {

  file: File;
  fileContent: any;
  fileName: string;
  isBusy = false;


  constructor(private modalService: NgbModal, private fns: AngularFireFunctions, private asmService: AsmService, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    console.log(files.item(0));

    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      this.fileContent = fileReader.result;
      this.file = files.item(0);
    };

    if (files.item(0) != null && files.item(0).size > 0) {
      console.log('start read file');
      this.fileName = files[0].name;
      fileReader.readAsText(files.item(0));
    } else {
      console.log('file is empty');
    }
  }
/*
  openValidationView() {
    const modalRef = this.modalService.open(SettingsFlightScheduleMaintenanceValidateSsimFileComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'Validate SSIM File and prepare upload';
    modalRef.componentInstance.fileContent = this.fileContent;
    modalRef.componentInstance.periodFrom = (this.fromDate && this.delimitFlights) ? dayjs(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day + ' +0000', 'YYYY-M-D Z').utc().format() : null;
    modalRef.componentInstance.periodTo = (this.toDate && this.delimitFlights) ? dayjs(this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day + ' +0000', 'YYYY-M-D Z').utc().format() : null;
  } */

callFunction() {
  // const params = {
  //   message: this.fileContent
  // };
  // const callable = this.fns.httpsCallable('webIncomingAsmMessage');
  // const data$ = callable(params);
  //
  // data$.subscribe(item => console.log(item));

  this.isBusy = true;
  this.asmService.uploadASM({ fileContent: this.fileContent, fileName: this.fileName }).subscribe((res) => {
    this.isBusy = false;
    if (res) {
     this.toastService.showSuccess('ASM file has been processed successfully!');
    }
  });
}

}
