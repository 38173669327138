import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {IFlightsFilter} from '../shared/models/filters/flights-filter.model';

@Injectable({
  providedIn: 'root'
})
export class FlightsFilterService {

  filterValuesSubject = new BehaviorSubject<IFlightsFilter>({
    station: null,
    alerts: null,
    sortBy: null,
    status: null,
    timespan: null,
    showUnpaired: null,
    acRegistration: null,
  } as IFlightsFilter);

  constructor() {}

  resetFilters(): void {
    const filter = this.filterValuesSubject.getValue();
    filter.timespan = null;
    filter.status = null;
    filter.station = null;
    filter.showUnpaired = false;
    filter.sortBy = null;
    filter.date = null;
    filter.alerts = null;
    filter.acRegistration = null;
    filter.flightNumber = null;
  }

  getFilterValues(): Observable<IFlightsFilter> {
    return this.filterValuesSubject.asObservable();
  }

  setFilterValues(newFilters: IFlightsFilter) {
    this.filterValuesSubject.next(newFilters);
  }
}
