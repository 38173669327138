import { Component, OnInit } from '@angular/core';
import { FlightDetails } from '../../../constants/flight-details.constants';
import { ISimpleData } from '../../../models/simpleData.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSettingsService } from '../../../../services/general-settings.service';
import { IGenericContainerObject } from 'src/app/shared/models/genericContainerObject.model';
import { IPairDetail } from '../../../models/pair-detail.model';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-change-flight-details-dialog',
  templateUrl: './change-flight-details-dialog.component.html',
  styleUrls: ['./change-flight-details-dialog.component.scss']
})
export class ChangeFlightDetailsDialogComponent implements OnInit {
  title?: string;
  pair?: IPairDetail;
  timezone?: string;
  activeTab: string = '';
  dataComplete = false;
  tabs: string[];

  flightDetailsConstants = FlightDetails;

  //delayCodes?: IGenericContainerObject<ISimpleData>;
  //passengerClasses?: IGenericContainerObject<ISimpleData>;
  delayCodes?: ISimpleData[];
  passengerClasses?: ISimpleData[];

  constructor(private settingsService: GeneralSettingsService, private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.tabs = [];
    if (this.pair.arrivalLeg?.std) {
      this.tabs.push(FlightDetails.ARRIVING_FLIGHT_INFORMATION);
    }
    if (this.pair.departureLeg?.std) {
      this.tabs.push(FlightDetails.DEPARTING_FLIGHT_INFORMATION);
    }
    this.tabs.push(FlightDetails.TURNAROUND_PROCESSES_AND_GSES);
    this.activeTab = this.tabs[0];
    forkJoin([this.settingsService.getDelayCodes(), this.settingsService.getPassengerClasses()]).subscribe((results) => {
      this.delayCodes = results[0];
      this.passengerClasses = results[1];
      this.dataComplete = true;
    })
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
