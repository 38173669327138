import {Component, Input, OnInit} from '@angular/core';
import {IUser} from '../../../../shared/models/user.model';
import {UserRoles} from '../../../../shared/constants/user-roles.constants';

@Component({
  selector: 'app-settings-user-management-list-item',
  templateUrl: './settings-user-management-list-item.component.html',
  styleUrls: ['./settings-user-management-list-item.component.scss']
})
export class SettingsUserManagementListItemComponent implements OnInit {

  @Input() user: IUser;
  @Input() airportTitle: string;
  @Input() opened: boolean;

  userRole = UserRoles;

  constructor() {
  }

  ngOnInit() {
  }

}
