<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup">
      <div class="container">
        <div class="row">
          <div class="col-6 form-field-header">
            A/C Type Name*
          </div>
          <div class="col-6">
            <div class="mb-3">
              <span *ngIf="!isNew" class="form-field-content">{{ acTypeId === 0 ? 'Default A/C Types' : acTypeList[acTypeId]?.alias}}</span>

              <select *ngIf="isNew" class="form-control form-select" id="arrival-flight-departure-delay-code-1" formControlName="acTypeName">
                <option *ngFor="let acType of acTypeList | keyvalue" [value]="acType.value?.id" selected>{{acType.value?.alias}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 form-field-header">
            Process applicable for {{acTypeId ? acTypeId === 0 ? 'Default A/C Types' : acTypeList[acTypeId].alias : 'new A/C Type'}}
          </div>
        </div>
        <div class="row mt-1 padding-15">
          <div class="col-12 p-0 list-container">

            <div class="type-list">
              <div *ngFor="let airport of airportsWithException | keyvalue" class="list-item-row border-bottom-darkgray">
                <div class="flex-basis-50 text-block align-self-center">
                  {{airport?.key === '0' ? 'Default Airports' : airport?.value?.iata}}
                </div>
                <div class="flex-basis-50 text-block text-end icon-container colorBlack">
                  <i class="demo-icon icon-icons8-edit-file fs-30" [ngClass]="{'clickable': !isNew, 'notClickable': isNew}" (click)="editAirport(airport?.value?.id)"></i>
                  <i class="ms-3 demo-icon icon-icons8-delete fs-30" [ngClass]="{'clickable': !isNew && 0 !== airport?.value?.id, 'colorWhite': isNew || 0 === airport?.value?.id}" (click)="deleteItem(airport?.value?.id)"></i>
                </div>

              </div>
            </div>
            <app-add-row buttonText="Add Exceptions for Airports" [isDisabled]="isNew" (addClicked)="addAirport()"></app-add-row>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">{{ isNew ? 'Cancel' : 'Close' }}
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="formGroup?.disabled" *ngIf="isNew">Save
  </button>
</div>
