import { Component, OnDestroy, OnInit } from '@angular/core';
import { Chart, ChartConfiguration, } from 'chart.js';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { IUser } from '../../shared/models/user.model';
import { Subject, firstValueFrom } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AirportsService } from '../../services/airports.service';
import { IAirport } from '../../shared/models/airport.model';
import { IAcType } from '../../shared/models/ac-type.model';
import { AcTypesService } from '../../services/ac-types.service';
import { ILegsModel } from '../../shared/models/legs.model';
import { LegsService } from '../../services/legs.service';
import * as dayjs from 'dayjs';
import { PairsService } from '../../services/pairs.service';
import { IPairsModel } from '../../shared/models/pairs.model';
import { extractSeatingConfigurations, ngbDateToDayjs } from '../../shared/utils/utils';
import { SeatingConfigurationService } from '../../services/seating-configuration.service';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { LegDelaysLogService } from '../../services/leg-delays-log.service';
import { PairLegTimesLogService } from '../../services/pair-leg-times-log.service';
import { GseService } from '../../services/gse.service';
import { PairsProcessesService } from '../../services/pairs-processes.service';
import { ProcessesService } from '../../services/processes.service';
import * as XLSX from 'xlsx/xlsx.mjs';



@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  operationsChartLabel: string;
  airports: IAirport[];
  acTypes: IAcType[];
  legs: ILegsModel[];
  pairs: IPairsModel[];
  filterDayFrom;
  filterDayTo;
  filterStation: string;
  filterAcType: string;
  punctualityGraphData = [];
  totalDelayMinutes: number;
  totalFlights: number;
  startFilterDay;
  endFilterDay;
  fileName:string;
  sheetName:string;
  checkIcon: IconDefinition = faCheck;
  dotsIcon: IconDefinition = faEllipsisVertical;
  financeFiltersChanged = false;
  punctualityLabels = [];
  punctualityData: ChartConfiguration['data'] = {datasets: [], labels: []};
  punctualityConfig: ChartConfiguration = {
    type: 'bar',
    data: null,
  };
  punctualityOptions: ChartConfiguration['options'] = {};
  passengerLabels = [];
  passengerData: ChartConfiguration['data'] = {datasets: [], labels: []};
  passengerConfig: ChartConfiguration = {
    type: 'bar',
    data: null,
  };
  passengerOptions: ChartConfiguration['options'] = {};
  operationLabels = [];
  operationData: ChartConfiguration['data'] = {datasets: [], labels: []};
  operationConfig: ChartConfiguration = {
    type: 'bar',
    data: null,
  };
  operationOptions: ChartConfiguration['options'] = {};
  financialLabels = [];
  financialData: ChartConfiguration['data'] = {datasets: [], labels: []};
  financialConfig: ChartConfiguration = {
    type: 'bar',
    data: null,
  };
  financialOptions: ChartConfiguration['options'] = {};

  scaleTextFont: ChartConfiguration['options']['font'] = {
    size: 16,
  };
  user: IUser;

  unsubscribe = new Subject<void>();

  constructor(
    private authService: AuthService, 
    private airportsService: AirportsService, 
    private acTypesService: AcTypesService, 
    private legsService: LegsService, 
    private pairsService: PairsService,
    private seatConfigService: SeatingConfigurationService,
    private generalSettingsService: GeneralSettingsService,
    private legDelayLogsService: LegDelaysLogService,
    private pairLegTimesLogService: PairLegTimesLogService,
    private gseService: GseService,
    private pairProcessesService: PairsProcessesService,
    private processService : ProcessesService,) {
    this.user = authService.user;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {

    this.airportsService.airports.subscribe((result) => {
      this.airports = result
    });
    this.acTypesService.acTypes.subscribe((result) => {
      this.acTypes = result
    });

    this.formGroup = new FormGroup({
      operations: new FormGroup({
        type: new FormControl('1'),
        date: new FormControl(null),
        airport: new FormControl('all'),
        acType: new FormControl('all')
      }),
      punctuality: new FormGroup({
        type: new FormControl('1'),
        date: new FormControl(null),
        airport: new FormControl('all')
      }),
      passengers: new FormGroup({
        type: new FormControl('1'),
        date: new FormControl(null),
        airport: new FormControl('all'),
        acType: new FormControl('all')
      }),
      finance: new FormGroup({
        type: new FormControl('1'),
        date: new FormControl(null),
        airport: new FormControl(this.user.location ?? 'all')
      }),
    });


    this.formGroup.get('operations').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      setTimeout(() => {
        this.buildOperationsGraph();
      }, 1)

    });
    this.formGroup.get('punctuality').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      setTimeout(() => {
        this.buildPunctualityGraph();
      }, 1)
    });
    this.formGroup.get('passengers').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.buildPassengerGraph();
    });
    this.formGroup.get('finance').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.buildFinancialsGraph();
    });
    this.buildOperationsGraph();
    this.buildPunctualityGraph();
    this.buildPassengerGraph();
    this.buildFinancialsGraph();



    Chart.register(ChartDataLabels);
    Chart.defaults.set('plugins.datalabels', {
      display: false,
      color: '#fff',
      font: {
        size: '20rem'
      }
    });
  }

  buildPunctualityGraph() {
    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    let graphData = [];
    let onTimeCount = 0;
    let delayedCount = 0;
    switch (this.formGroup?.get('punctuality').value.type) {
      case "1": // On Time Performance
      if (this.formGroup.get('punctuality').get('date').value === null) {
        //default dates
        this.filterDayFrom = defaultStartDate;
        this.filterDayTo = defaultEndDate;
      } else {
        this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
        this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
      }

      this.filterStation = this.formGroup?.get('punctuality').value.airport === 'all' ? null : this.formGroup?.get('punctuality').value.airport;

      this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, timeType: 'atd'}).subscribe((result) => {
          this.legs = result;
          for (const leg of this.legs) {
            if (dayjs(leg.atd).isAfter(dayjs(leg.std).add(5, 'minute'))) {
              delayedCount = delayedCount + 1;
            } else {
              onTimeCount = onTimeCount + 1;

            }
          }

          graphData.push(onTimeCount);
          graphData.push(delayedCount);

        this.punctualityLabels = ['On Time', 'Delayed'];
        this.punctualityData = {
          labels: this.punctualityLabels,
          datasets: [{
            label: 'On Time Performance',
            backgroundColor: ['#5b9bd5', '#ff00ff'],
            hoverBackgroundColor: ['#5b9bd5', '#ff00ff'],
            borderColor: ['#5b9bd5', '#ff00ff'],
            hoverBorderWidth: 0,
            data: graphData
          }],
        };
        this.punctualityConfig = {
          type: 'doughnut',
          data: this.punctualityData,
          plugins: [ChartDataLabels],
        };
        this.punctualityOptions = {
          aspectRatio: 2,
          plugins: {
            datalabels: {
              display: true,
            }
          }
        };
      });
        break;
      case "2": // Arrival Punctiality
      if (this.formGroup.get('punctuality').get('date').value === null) {
        //default dates
        this.filterDayFrom = defaultStartDate;
        this.filterDayTo = defaultEndDate;
      } else {
        this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
        this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
      }

      this.filterStation = this.formGroup?.get('punctuality').value.airport === 'all' ? null : this.formGroup?.get('punctuality').value.airport;

      this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, timeType: 'ata'}).subscribe((result) => {
          this.legs = result;
          for (const leg of this.legs) {
            if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
              delayedCount = delayedCount + 1;
            } else {
              onTimeCount = onTimeCount + 1;
            }
          }
      
          graphData.push(onTimeCount);
          graphData.push(delayedCount);

          console.log('GRAPH DATA ARRAY :',graphData)
        this.punctualityLabels = ['On Time', 'Arrival Delayed'];
        this.punctualityData = {
          labels: this.punctualityLabels,
          datasets: [{
            label: 'Arrival Punctuality',
            backgroundColor: ['#5b9bd5', '#ed7d31'],
            hoverBackgroundColor: ['#5b9bd5', '#ed7d31'],
            borderColor: ['#5b9bd5', '#ed7d31'],
            hoverBorderWidth: 0,
            data: graphData
          }],
        };
        this.punctualityConfig = {
          type: 'doughnut',
          data: this.punctualityData,
          options: {
            aspectRatio: 2,
            plugins: {
              datalabels: {
                display: true,
              }
            }
          },
        };
        this.punctualityOptions = {
          aspectRatio: 2,
          plugins: {
            datalabels: {
              display: true,
            }
          }
        };
      });
        break;
      case "3": // Delayed per Delay Code
      const displayMinutesArray = [];
      const displayCodesArray = [];
      let delayMinutesPerCode: {[code:string]:number} = {};

      if (this.formGroup.get('punctuality').get('date').value === null) {
        //default dates
        this.filterDayFrom = defaultStartDate;
        this.filterDayTo = defaultEndDate;
      } else {
        this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
        this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
      }

      this.filterStation = this.formGroup?.get('punctuality').value.airport === 'all' ? null : this.formGroup?.get('punctuality').value.airport;

      this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, timeType: 'atd'}).subscribe(async (result) => {
        this.legs = result;
        const legIdsInLegList = this.legs.map((leg) => leg.id);

        const legDelayLogs = await firstValueFrom(this.legDelayLogsService.getLegDelayLogs({ isActive: [true], legId: legIdsInLegList }));
        const delayCodes = await firstValueFrom(this.generalSettingsService.getDelayCodes());
        const codesInDelayCodesList = delayCodes.map((delayCode) => delayCode.id);

        for (const delayLog of legDelayLogs) {
          const delayCodesWeNeed = delayCodes.filter((delaycode) => delayLog.delayCodeId === delaycode.id);
          delayMinutesPerCode[delayCodesWeNeed[0].code] = (delayMinutesPerCode[delayCodesWeNeed[0].code] || 0) + (Number(delayLog.minutes) || 0 );
        }
        for (const [key, value] of Object.entries(delayMinutesPerCode)) {
            displayCodesArray.push(key);
            displayMinutesArray.push(value)
        };
 
        this.punctualityData = {
          labels: displayCodesArray,
          datasets: [{
            label: 'Delayed per Delay Code',
            backgroundColor: ['#5b9bd5', '#ed7d31'],
            hoverBackgroundColor: ['#5b9bd5', '#ed7d31'],
            borderColor: ['#5b9bd5', '#ed7d31'],
            hoverBorderWidth: 0,
            data: displayMinutesArray,
          }],
        };
        this.punctualityConfig = {
          type: 'bar',
          data: this.punctualityData,
        };
        this.punctualityOptions = {
          scales: {
            x: {
              title: {
                display: true,
                text: 'Delay Code',
                font: this.scaleTextFont,
              }
            },
            y: {
              title: {
                display: true,
                text: 'Minutes',
                font: this.scaleTextFont,
              }
            }
          }
        }
      });
        break;
      case "4": // Total Departure Delay
      
        if (this.formGroup.get('punctuality').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
          this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
        }
  
        this.filterStation = this.formGroup?.get('punctuality').value.airport === 'all' ? null : this.formGroup?.get('punctuality').value.airport;

        this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, timeType: 'atd'}).subscribe(async (result) => {
          this.legs = result.filter((leg) => dayjs(leg.atd).isAfter(dayjs(leg.std).add(5, 'minute')));
          const legIdsInLegList = this.legs.map((leg) => leg.id);
          const pairLegDelayLogs = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({ isActive: true, legId: legIdsInLegList, timeTypeId: 6 }));
          this.totalDelayMinutes = 0;
          pairLegDelayLogs.sort((a,b) => {
            return b.id - a.id
          })
          for (const pairLegDelay of pairLegDelayLogs) {
            if(legIdsInLegList.includes(pairLegDelay.legId)) {
              const index = legIdsInLegList.indexOf(pairLegDelay.legId);
              legIdsInLegList.splice(index, 1);

              this.totalDelayMinutes = this.totalDelayMinutes + pairLegDelay.totalDelayInMinutes
              
            }
          }
          this.totalFlights = this.legs.length;
          this.punctualityData = {
            datasets: [{
              label: 'Total Departure Delay',
              data: graphData
            }],
          };
        });
          break;
      case "5": // Total Arrival Delay
    
        if (this.formGroup.get('punctuality').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
          this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('punctuality').value.airport === 'all' ? null : this.formGroup?.get('punctuality').value.airport;

        this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, timeType: 'ata'}).subscribe(async (result) => {
          this.legs = result.filter((leg) => dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute')));
          const legIdsInLegList = this.legs.map((leg) => leg.id);
          const pairLegDelayLogs = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({ isActive: true, legId: legIdsInLegList, timeTypeId: 7 }));
          this.totalDelayMinutes = 0;
          pairLegDelayLogs.sort((a,b) => {
            return b.id - a.id
          })
          for (const pairLegDelay of pairLegDelayLogs) {
            if(legIdsInLegList.includes(pairLegDelay.legId)) {
              const index = legIdsInLegList.indexOf(pairLegDelay.legId);
              legIdsInLegList.splice(index, 1);

              this.totalDelayMinutes = this.totalDelayMinutes + pairLegDelay.totalDelayInMinutes
              
            }
          }
          this.totalFlights = this.legs.length;
          this.punctualityData = {
            datasets: [{
              label: 'Total Arrival Delay',
              data: graphData
            }],
          };

        });
        break;
      }
  }

  buildPassengerGraph() {
    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    let graphData = [];

    switch (this.formGroup?.get('passengers').value.type) {
      case "1": // Passenger Load Factor
      if (this.formGroup.get('passengers').get('date').value === null) {
        //default dates
        this.filterDayFrom = defaultStartDate;
        this.filterDayTo = defaultEndDate;
      } else {
        this.filterDayFrom = this.formGroup.get('passengers').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
        this.filterDayTo = this.formGroup.get('passengers').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
      }

      this.filterStation = this.formGroup?.get('passengers').value.airport === 'all' ? null : this.formGroup?.get('passengers').value.airport;
      this.filterAcType = this.formGroup?.get('passengers').value.acType === 'all' ? null : this.formGroup?.get('passengers').value.acType;

      this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, acType: this.filterAcType ,station: this.filterStation, timeType: 'atd'}).subscribe(async (result) => {
          this.legs = result;
          const acRegIdsArray = new Set<number>();
          const paxPerCode: {[code:string]:number} = {};
          const passClassLabels = [];
          const passClassCodes = [];
          const displayArray = [];
          const acRegCount = {};

          let totalPax = 0;
          const passengerClasses =  await firstValueFrom(this.generalSettingsService.getPassengerClasses());
          for (const passClass of passengerClasses) {
            passClassLabels.push(passClass.description);
            passClassCodes.push(passClass.code);
          }
          for (const leg of this.legs) {
            if (!acRegCount[leg.acRegistrationId]) {
              acRegCount[leg.acRegistrationId] = 1;
            } else {
              acRegCount[leg.acRegistrationId]++;
            }
            acRegIdsArray.add(leg.acRegistrationId);
      
            if(leg.pax !== null) {
              extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                const code = item.slice(0, 1);
                const amount = item.slice(1);

                for (const passClassCode of passClassCodes) {
                  if(code === passClassCode) {
                    paxPerCode[code] = (paxPerCode[code] || 0) + (Number(amount) || 0 );
                  }
                }

                totalPax += +amount;
              });
            }

          }
          const seatConfigs = await firstValueFrom(this.seatConfigService.getSeatingConfigurationsByAcRegistrationId(Array.from(acRegIdsArray)));
          let totalScheduledSeats = 0;
          const seatPerCode: {[code:string]:number} = {};
          console.log('Ac Registrations Array', acRegIdsArray, 'Count of ac reg: ',acRegCount)
          for (const seat of seatConfigs) {
            totalScheduledSeats = (+seat.description) * acRegCount[seat.acRegistrationId] + totalScheduledSeats;
            seatPerCode[seat.code] = (seatPerCode[seat.code] || 0) + (Number(seat.description) || 0 )*acRegCount[seat.acRegistrationId];
          }
          
          for (const [key, value] of Object.entries(paxPerCode)) {
            console.log('Key is: ', key, 'pass class codes are: ',passClassCodes);
            for (const passClassCode of passClassCodes) {
              if(key === passClassCode) {
                //console.log('Value to push',value/totalScheduledSeats);
                graphData.push((value/seatPerCode[key])*100)
                displayArray.push(key);
              }
            }
          };
          displayArray.push('Total')
          graphData.push((totalPax/totalScheduledSeats)*100)
          console.log('PAX per Code:',paxPerCode,'Seat per Code:',seatPerCode, 'Total Pax:', totalPax, 'Total Seats:',totalScheduledSeats);
          console.log('OMG: ', graphData)


        this.passengerData = {
          labels: displayArray,
          datasets: [{
            label: 'Passenger Load (%)',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: graphData,
          }],
        };
        this.passengerConfig = {
          type: 'bar',
          data: this.passengerData,
          options: this.passengerOptions,
        };
        this.passengerOptions = {
          scales: {
            y: {
              ticks: {
                callback: function (tickValue) {
                  return tickValue + '%';
                },
                stepSize: 2,
              },
              suggestedMin: 0,
              suggestedMax: 100,
              title: {
                display: true,
                text: 'Passenger Load',
                font: this.scaleTextFont,
              },
              beginAtZero: false,
            },
            x: {
              title: {
                display: true,
                text: 'Classes',
                font: this.scaleTextFont,
              }
            },
          }
        };
      });
        break;
      case "2": // # Delayed Passengers
      if (this.formGroup.get('passengers').get('date').value === null) {
        //default dates
        this.filterDayFrom = defaultStartDate;
        this.filterDayTo = defaultEndDate;
      } else {
        this.filterDayFrom = this.formGroup.get('passengers').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
        this.filterDayTo = this.formGroup.get('passengers').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
      }

      this.filterStation = this.formGroup?.get('passengers').value.airport === 'all' ? null : this.formGroup?.get('passengers').value.airport;
      this.filterAcType = this.formGroup?.get('passengers').value.acType === 'all' ? null : this.formGroup?.get('passengers').value.acType;


      this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, acType: this.filterAcType ,timeType: 'atd'}).subscribe(async (result) => {
          this.legs = result;
          const acRegIdsArray = new Set<number>();
          const paxPerCode: {[code:string]:number} = {};
          const passClassLabels = [];
          const passClassCodes = [];
          const displayArray = [];
          const acRegCount = {};

          let totalPax = 0;
          const passengerClasses =  await firstValueFrom(this.generalSettingsService.getPassengerClasses());
          for (const passClass of passengerClasses) {
            passClassLabels.push(passClass.description);
            passClassCodes.push(passClass.code);
          }
          for (const leg of this.legs) {
            if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
              if (!acRegCount[leg.acRegistrationId]) {
                acRegCount[leg.acRegistrationId] = 1;
             } else {
              acRegCount[leg.acRegistrationId]++;
  
             }
              acRegIdsArray.add(leg.acRegistrationId);
        
              if(leg.pax !== null) {
                extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                  const code = item.slice(0, 1);
                  const amount = item.slice(1);
  
                  for (const passClassCode of passClassCodes) {
                    if(code === passClassCode) {
                      paxPerCode[code] = (paxPerCode[code] || 0) + (Number(amount) || 0 );
                    }
                  }
  
                  totalPax += +amount;
                });
              }
            } 
         

          }
        
          for (const [key, value] of Object.entries(paxPerCode)) {
            for (const passClassCode of passClassCodes) {
              if(key === passClassCode) {
                graphData.push(value)
                displayArray.push(key);
              }
            }
          };
          displayArray.push('Total')
          graphData.push(totalPax)
          console.log('PAX per Code:',paxPerCode, 'Total Pax:', totalPax,);


        this.passengerData = {
          labels: displayArray,
          datasets: [{
            label: '# Delayed Passengers',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: graphData
          }],
        };
        this.passengerConfig = {
          type: 'bar',
          data: this.passengerData
        };
        this.passengerOptions = {
          scales: {
            y: {
              max: Math.max(...graphData) + Math.round(0.1 *Math.max(...graphData)), 
              // ticks: {
              //   stepSize: 500,
              // },
              // suggestedMin: 200,
              // suggestedMax: 2700,
              title: {
                display: true,
                text: 'Delayed Passengers',
                font: this.scaleTextFont,
              },
              beginAtZero: false,
            },
            x: {
              title: {
                display: true,
                text: 'Classes',
                font: this.scaleTextFont,
              }
            },
          }
        };
      });

        break;
      case "3": // % Delayed Passengers
      if (this.formGroup.get('passengers').get('date').value === null) {
        //default dates
        this.filterDayFrom = defaultStartDate;
        this.filterDayTo = defaultEndDate;
      } else {
        this.filterDayFrom = this.formGroup.get('passengers').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
        this.filterDayTo = this.formGroup.get('passengers').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
      }

      this.filterStation = this.formGroup?.get('passengers').value.airport === 'all' ? null : this.formGroup?.get('passengers').value.airport;

      this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, timeType: 'atd'}).subscribe(async (result) => {
          this.legs = result;
          const acRegIdsArray = new Set<number>();
          const delayedPaxPerCode: {[code:string]:number} = {};
          const totalPaxPerCode: {[code:string]:number} = {};
          const passClassLabels = [];
          const passClassCodes = [];
          const displayArray = [];
          const acRegCount = {};

          let totalDelayedPax = 0;
          let totalPax = 0;
          const passengerClasses =  await firstValueFrom(this.generalSettingsService.getPassengerClasses());
          for (const passClass of passengerClasses) {
            passClassLabels.push(passClass.description);
            passClassCodes.push(passClass.code);
          }
          for (const leg of this.legs) {
            if (!acRegCount[leg.acRegistrationId]) {
              acRegCount[leg.acRegistrationId] = 1;
            } else {
              acRegCount[leg.acRegistrationId]++;
            }
            acRegIdsArray.add(leg.acRegistrationId);

            if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
              if(leg.pax !== null) {
                extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                  const code = item.slice(0, 1);
                  const amount = item.slice(1);

                  for (const passClassCode of passClassCodes) {
                    if(code === passClassCode) {
                      delayedPaxPerCode[code] = (delayedPaxPerCode[code] || 0) + (Number(amount) || 0 );
                    }
                  }

                  totalDelayedPax += +amount;
                });
              }
           } 
          if(leg.pax !== null) {
            extractSeatingConfigurations(leg.pax).forEach((item: string) => {
              const code = item.slice(0, 1);
              const amount = item.slice(1);

              for (const passClassCode of passClassCodes) {
                if(code === passClassCode) {
                  totalPaxPerCode[code] = (totalPaxPerCode[code] || 0) + (Number(amount) || 0 );
                }
              }
              totalPax += +amount;
             });
            }
           
          }
      
          for (const [key, value] of Object.entries(delayedPaxPerCode)) {
            for (const passClassCode of passClassCodes) {
              if(key === passClassCode) {
                graphData.push((value/totalPaxPerCode[key])*100)
                displayArray.push(key);
              }
            }
          };
          displayArray.push('Total')
          graphData.push((totalDelayedPax/totalPax)*100)
          console.log('Delayed PAX per Code:',delayedPaxPerCode,'Total PAX per Code:',totalPaxPerCode,'Total Delyaed Pax:',totalDelayedPax, 'Total Pax:', totalPax,);



        this.passengerData = {
          labels: displayArray,
          datasets: [{
            label: '% Delayed Passengers',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: graphData
          }],
        };
        this.passengerConfig = {
          type: 'bar',
          data: this.passengerData
        };
        this.passengerOptions = {
          scales: {
            y: {
              ticks: {
                callback: function (tickValue) {
                  return tickValue + '%';
                },
                stepSize: 2,
              },
              suggestedMax: 100,
              title: {
                display: true,
                text: 'Delayed Passengers',
                font: this.scaleTextFont,
              },
            },
            x: {
              title: {
                display: true,
                text: 'Classes',
                font: this.scaleTextFont,
              }
            },
          }
        };
      });
        break;
      case "4": // Passenger Connections
        this.passengerLabels = ['ATH', 'VIE', 'LGW', 'HER', 'MAN'];
        this.passengerData = {
          labels: this.passengerLabels,
          datasets: [{
            label: 'Economy',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: [15, 2, 3, 5, 5]
          },
            {
              label: 'Business',
              backgroundColor: ['#ed7d31'],
              hoverBackgroundColor: ['#ed7d31'],
              borderColor: ['#ed7d31'],
              hoverBorderWidth: 0,
              data: [4, 1, 2, 1, 0]
            },
            {
              label: 'Total',
              backgroundColor: ['#a5a5a5'],
              hoverBackgroundColor: ['#a5a5a5'],
              borderColor: ['#a5a5a5'],
              hoverBorderWidth: 0,
              data: [19, 3, 5, 6, 5]
            }],
        };
        this.passengerConfig = {
          type: 'bar',
          data: this.passengerData
        };
        this.passengerOptions = {
          plugins: {
            title: {
              display: true,
              text: 'Connecting airport: ATH',
              font: {
                size: 20,
              }
            }
          },
          scales: {
            y: {
              ticks: {
                stepSize: 5,
              },
              suggestedMin: 0,
              suggestedMax: 20,
              title: {
                display: true,
                text: 'Connections',
                font: this.scaleTextFont,
              },
            },
            x: {
              title: {
                display: true,
                text: 'Destination Airports',
                font: this.scaleTextFont,
              },
            }
          },

        };
        break;
      case "5": // Passenger Misconnections
        this.passengerLabels = ['VIE', 'ATH', 'LIS', 'CDG', 'LHR'];
        this.passengerData = {
          labels: this.passengerLabels,
          datasets: [{
            label: 'Economy',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: [97, 34, 23, 12, 28]
          },
            {
              label: 'Business',
              backgroundColor: ['#9900ff'],
              hoverBackgroundColor: ['#9900ff'],
              borderColor: ['#9900ff'],
              hoverBorderWidth: 0,
              data: [35, 11, 0, 7, 17]
            },
            {
              label: 'Total',
              backgroundColor: ['#ff00ff'],
              hoverBackgroundColor: ['#ff00ff'],
              borderColor: ['#ff00ff'],
              hoverBorderWidth: 0,
              data: [132, 33, 28, 53, 18]
            }],
        };
        this.passengerConfig = {
          type: 'bar',
          data: this.passengerData
        };
        this.passengerOptions = {
          plugins: {
            title: {
              display: true,
              text: 'Connecting airport: VIE',
              font: {
                size: 20,
              }
            }
          },
          scales: {
            y: {
              ticks: {
                stepSize: 1,
              },
              suggestedMin: 0,
              suggestedMax: 5,
              title: {
                display: true,
                text: 'Misconnections',
                font: this.scaleTextFont,
              },
            },
          }
        };
        break;
    }
  }

  buildOperationsGraph() {

    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    let graphData = [];
    this.operationLabels = [];
    let operationLabels = [];
    let actualBlockTime = 0;
    let periodSelectedInDays: number;

    switch (this.formGroup?.get('operations').value.type) {
      
      case "1": // Turnaround performance
      this.operationsChartLabel = 'Turnaround Performance';
      if (this.formGroup.get('operations').get('date').value === null) {
        //default dates
        this.filterDayFrom = defaultStartDate;
        this.filterDayTo = defaultEndDate;
      } else {
        this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
        this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
      }

      this.filterStation = this.formGroup?.get('operations').value.airport === 'all' ? null : this.formGroup?.get('operations').value.airport;
      this.filterAcType = this.formGroup?.get('operations').value.acType === 'all' ? null : this.formGroup?.get('operations').value.acType;
      const onTimeArray = [];
      const goodArray = [];
      const midArray = [];
      const poorArray = [];


      this.pairsService.getPairsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, acType: this.filterAcType}).subscribe((result) => {
          this.pairs = result;
          for (const pair of this.pairs) {
            let agt = dayjs(pair.__departureLegModel__.atd).diff(dayjs(pair.__arrivalLegModel__.ata));
            let sgt = dayjs(pair.__departureLegModel__.std).diff(dayjs(pair.__arrivalLegModel__.sta));
            console.log('AGT AND SGT FOR PAIR :',pair.id, 'are :',agt, 'and', sgt);
            let diff = Math.floor(agt / 60000) - Math.floor(sgt / 60000);
            if(agt && sgt) {
              if (agt <= sgt) {
                onTimeArray.push(pair);
              } else if (diff > 0 && diff <= 5 ) {
                goodArray.push(pair);
              } else if (diff > 5 && diff < 15) {
                midArray.push(pair);
              } else {
                poorArray.push(pair)
              }
            }
          }
          graphData.push((onTimeArray.length/this.pairs.length)*100);
          graphData.push((goodArray.length/this.pairs.length)*100);
          graphData.push((midArray.length/this.pairs.length)*100);
          graphData.push((poorArray.length/this.pairs.length)*100);
          console.log('GRAPH DATA ARRAY :',graphData)

          this.operationLabels = ['On Time', 'Good', 'Mid', 'Poor'];

          this.operationData = {
            labels: this.operationLabels,
            datasets: [{
              label: 'Turnaround Performance',
              backgroundColor: ['#5b9bd5', '#00e700', '#ffd966', '#e70000'],
              hoverBackgroundColor: ['#5b9bd5', '#00e700', '#ffd966', '#e70000'],
              borderColor: ['#5b9bd5', '#00e700', '#ffd966', '#e70000'],
              hoverBorderWidth: 0,
              data: graphData,
            }],
          };
          this.operationConfig = {
            type: 'bar',
            data: this.operationData,
            options: this.operationOptions,
          };
          this.operationOptions = {
            scales: {
              y: {
                ticks: {
                  callback: function (tickValue) {
                    return tickValue + '%';
                  },
                  stepSize: 2,
                },
                max: 100,
                title: {
                  display: true,
                  text: 'Percentage of Turnarounds',
                  font: this.scaleTextFont,
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Turnaround Performance',
                  font: this.scaleTextFont,
                }
              },
            }
          };

        });


        break;
      case "2": // Completed Non SLA Processes
      this.operationsChartLabel = 'Completed Non SLA Processes';
      const processCount: {[title:string]:number} = {};
  
      if (this.formGroup.get('operations').get('date').value === null) {
        //default dates
        this.filterDayFrom = defaultStartDate;
        this.filterDayTo = defaultEndDate;
      } else {
        this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
        this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
      }

      this.filterStation = this.formGroup?.get('operations').value.airport === 'all' ? null : this.formGroup?.get('operations').value.airport;
      this.filterAcType = this.formGroup?.get('operations').value.acType === 'all' ? null : this.formGroup?.get('operations').value.acType;

      const filterAcTypeId = this.formGroup?.get('operations').value.acType === 'all' ? null : this.acTypes.filter((actype) => actype.iata === this.filterAcType)[0].id;
      const filterAirportId = this.formGroup?.get('operations').value.airport === 'all' ? null : this.airports.filter((airport) => airport.iata === this.filterStation)[0].id;

      this.pairsService.getPairsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, acType: this.filterAcType}).subscribe(async (result) => {
        this.pairs = result;
        console.log('Pairs are', result);
        const pairIds: number[] = [];
        for (const pair of this.pairs) {
          pairIds.push(pair.id)
        };
        const pairProcesses = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));
        console.log('Pair Processes are', pairProcesses);

        const gseIds: number[] = [];
        for (const pairProcess of pairProcesses) {
          gseIds.push(pairProcess.gseId);
        }
        console.log(gseIds)
        let filters;
        if (filterAcTypeId === null && filterAirportId !== null){
          filters = {isActive: true, isSla: false, airportId:filterAirportId, id: gseIds}
        } else if (filterAcTypeId !== null && filterAirportId === null) {
          filters = {isActive: true, isSla: false, acTypeId:filterAcTypeId, id: gseIds}
        } else if (filterAcTypeId === null && filterAirportId === null) {
          filters = {isActive: true, isSla: false, id: gseIds}
        } else {
          filters = {isActive: true, acTypeId:filterAcTypeId, isSla: false, airportId:filterAirportId, id: gseIds}
        }
        const gses = await firstValueFrom(this.gseService.fetchGses(filters));

        for (const pairProcess of pairProcesses) {
          const gseNonSLA = gses.find((gse) => 
            gse.id === pairProcess.gseId
          );
          if (gseNonSLA) {
            if (!processCount[gseNonSLA.title]) {
              processCount[gseNonSLA.title] = 1;
           } else {
            processCount[gseNonSLA.title]++;
           }
          }
        }

        let graphData = [];
        let operationLabels = [];

        //const allProcesses = await firstValueFrom(this.processService.fetchProcesses());
        console.log('process Counter', processCount)
        for (const [key, value] of Object.entries(processCount)) {
              graphData.push(value)
              operationLabels.push(key);
        };

        this.operationLabels = operationLabels;
        this.operationData = {
          labels: this.operationLabels,
          datasets: [{
            label: 'Completed Non SLA Processes',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: graphData,
          }],
        };
        this.operationConfig = {
          type: 'bar',
          data: this.operationData,
          options: this.operationOptions,
        };
        this.operationOptions = {
          scales: {
            y: {
              ticks: {
                stepSize: 5,
              },
              suggestedMax: 50,
              title: {
                display: true,
                text: 'Times used',
                font: this.scaleTextFont,
              },
            },
            x: {
              title: {
                display: true,
                text: 'Processes',
                font: this.scaleTextFont,
              }
            },
          }
        };
      });
        break;
      case "3": // AC Reg Utilization
      this.operationsChartLabel = 'AC Reg Utilization';

      if (this.formGroup.get('operations').get('date').value === null) {
        //default dates
        this.filterDayFrom = defaultStartDate;
        this.filterDayTo = defaultEndDate;
      } else {
        this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
        this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
      }
      // let actualBlockTime = 0;
      const blockTimePerAcReg: {[acReg:string]:number} = {};


      this.filterStation = this.formGroup?.get('operations').value.airport === 'all' ? null : this.formGroup?.get('operations').value.airport;
      this.filterAcType = this.formGroup?.get('operations').value.acType === 'all' ? null : this.formGroup?.get('operations').value.acType;



      this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, acType: this.filterAcType, timeType: 'atd'}).subscribe((result) => {
          this.legs = result;
          periodSelectedInDays = Math.round((dayjs(this.filterDayTo).diff(dayjs(this.filterDayFrom)))/86400000);
          if (!periodSelectedInDays) {
            periodSelectedInDays = 1
          }
          for (const leg of this.legs) {
            if (leg.atd !== null && leg.ata !== null) {
              blockTimePerAcReg[leg.acRegistration] = ((dayjs(leg.ata).diff(dayjs(leg.atd)))/3600000)/periodSelectedInDays + (blockTimePerAcReg[leg.acRegistration] || 0);
            };
          };

          for (const [key, value] of Object.entries(blockTimePerAcReg)) {
                graphData.push(value)
                operationLabels.push(key);             
          };
          console.log('Time per ac reg', blockTimePerAcReg)

        
        this.operationData = {
          labels: operationLabels,
          datasets: [{
            label: 'AC Reg Utilization',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: graphData
          }],
        };
        this.operationConfig = {
          type: 'bar',
          data: this.operationData
        };
        this.operationOptions = {
          scales: {
            y: {
              suggestedMin: 0,
              title: {
                display: true,
                text: 'AC Reg Utilization (Hours)',
                font: this.scaleTextFont,
              },
              beginAtZero: false,
            },
            x: {
              title: {
                display: true,
                text: 'AC Registrations',
                font: this.scaleTextFont,
              }
            },
          },
        };
      });
        break;
      case "4": // AC Type Utilization
        this.operationsChartLabel = 'AC Type Utilization';

        if (this.formGroup.get('operations').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour',0).set('minute',0).set('second',0).toDate() : null;
          this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour',23).set('minute',59).set('second',59).toDate() : null;
        }
        const blockTimePerAcType: {[acType:string]:number} = {};
  
  
        this.filterStation = this.formGroup?.get('operations').value.airport === 'all' ? null : this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType === 'all' ? null : this.formGroup?.get('operations').value.acType;
  
  
  
        this.legsService.getLegsForReports({startDate: this.filterDayFrom, endDate: this.filterDayTo, station: this.filterStation, acType: this.filterAcType, timeType: 'atd'}).subscribe((result) => {
            this.legs = result;
            periodSelectedInDays = Math.round((dayjs(this.filterDayTo).diff(dayjs(this.filterDayFrom)))/86400000);
            if (!periodSelectedInDays) {
              periodSelectedInDays = 1
            }
  
            for (const leg of this.legs) {
              if (leg.atd !== null && leg.ata !== null) {
                actualBlockTime = actualBlockTime + (dayjs(leg.ata).diff(dayjs(leg.atd)))/3600000;
                blockTimePerAcType[leg.acType] = actualBlockTime/periodSelectedInDays;
              };
            };
            for (const [key, value] of Object.entries(blockTimePerAcType)) {
              graphData.push(value)
              operationLabels.push(key);             
              };
                     
          
          this.operationData = {
            labels: operationLabels,
            datasets: [{
              label: 'AC Type Utilization',
              backgroundColor: ['#5b9bd5'],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: ['#5b9bd5'],
              hoverBorderWidth: 0,
              data: graphData
            }],
          };
          this.operationConfig = {
            type: 'bar',
            data: this.operationData
          };
          this.operationOptions = {
            scales: {
              y: {
                suggestedMin: 0,
                title: {
                  display: true,
                  text: 'AC Type Utilization (Hours)',
                  font: this.scaleTextFont,
                },
                beginAtZero: false,
              },
              x: {
                title: {
                  display: true,
                  text: 'AC Types',
                  font: this.scaleTextFont,
                }
              },
            },
          };
        });
          break;
      
      }
  }

  build

  private buildFinancialsGraph() {
    switch (this.formGroup?.get('finance').value.type) {
      case "1": // Landing fees - Per Flight
        this.financialLabels = ['A1 2378', 'A1 5140', 'A1 3987', 'A1 4437', 'A1 9856', 'A1 2954', 'A1 295P'];
        this.financialData = {
          labels: this.financialLabels,
          datasets: [{
            label: 'Landing fee',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: [438, 438, 438, 1335, 438, 1335, 292],
          }],
        };
        this.financialConfig = {
          type: 'bar',
          data: this.financialData,
          options: this.financialOptions,
        };
        this.financialOptions = {
          scales: {
            y: {
              ticks: {
                callback: function (tickValue) {
                  return tickValue + '€';
                },
                stepSize: 1000,
              },
              suggestedMax: 3000,
              title: {
                display: true,
                text: 'Landing fee',
                font: this.scaleTextFont,
              },
            },
            x: {
              title: {
                display: true,
                text: 'Flights',
                font: this.scaleTextFont,
              }
            },
          }
        };
        break;
      case "2": // Landing Fee - all airports
        this.financialLabels = ['ATH', 'VIE', 'LHR', 'CDG', 'LIS', 'FCO'];
        this.financialData = {
          labels: this.financialLabels,
          datasets: [{
            label: 'Landing fee',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: [2846, 4712, 3890, 3145, 1249, 895],
          }],
        };
        this.financialConfig = {
          type: 'bar',
          data: this.financialData,
          options: this.financialOptions,
        };
        this.financialOptions = {
          scales: {
            y: {
              ticks: {
                callback: function (tickValue) {
                  return tickValue + '€';
                },
                stepSize: 1000,
              },
              suggestedMax: 5000,
              title: {
                display: true,
                text: 'Total Cost',
                font: this.scaleTextFont,
              },
            },
            x: {
              title: {
                display: true,
                text: 'Airports',
                font: this.scaleTextFont,
              }
            },
          }
        };
        break;
      case "3": // Parking Fees - Per flight
        this.financialLabels = ['A-123', 'A-124', 'A-125'];
        this.financialData = {
          labels: this.financialLabels,
          datasets: [{
            label: 'Parking fees',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: [1200, 1600, 2700],
          }],
        };
        this.financialConfig = {
          type: 'bar',
          data: this.financialData
        };
        this.financialOptions = {
          scales: {
            y: {
              ticks: {
                callback: function (tickValue) {
                  return tickValue + '€';
                },
                stepSize: 1000,
              },
              suggestedMax: 3000,
              title: {
                display: true,
                text: 'Parking fee',
                font: this.scaleTextFont,
              },
            },
            x: {
              title: {
                display: true,
                text: 'Flights',
                font: this.scaleTextFont,
              }
            },
          },
        };
        break;
      case "4":
        this.financialLabels = ['ATH', 'VIE', 'LHR'];
        this.financialData = {
          labels: this.financialLabels,
          datasets: [{
            label: 'Parking fee',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: [21000, 19000, 6000],
          }],
        };
        this.financialConfig = {
          type: 'bar',
          data: this.financialData,
          options: this.financialOptions,
        };
        this.financialOptions = {
          scales: {
            y: {
              ticks: {
                callback: function (tickValue) {
                  return tickValue + '€';
                },
                stepSize: 5000,
              },
              suggestedMax: 25000,
              title: {
                display: true,
                text: 'Parking fee',
                font: this.scaleTextFont,
              },
            },
            x: {
              title: {
                display: true,
                text: 'Airports',
                font: this.scaleTextFont,
              }
            },
          }
        };
        break;
      case "5": // GSE Costs
        this.financialLabels = ['A-123', 'A-124', 'A-125', 'A-126', 'A-342'];
        this.financialData = {
          labels: this.financialLabels,
          datasets: [{
            label: 'GSE cost',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: [1200, 1400, 800, 600, 700],
          }],
        };
        this.financialConfig = {
          type: 'bar',
          data: this.financialData,
          options: this.financialOptions,
        };
        this.financialOptions = {
          scales: {
            y: {
              ticks: {
                callback: function (tickValue) {
                  return tickValue + '€';
                },
                stepSize: 500,
              },
              suggestedMax: 1500,
              title: {
                display: true,
                text: 'GSE Costs',
                font: this.scaleTextFont,
              },
            },
            x: {
              title: {
                display: true,
                text: 'Flights',
                font: this.scaleTextFont,
              }
            },
          }
        };
        break;
      case "6": // PAX Handling Fees
        this.financialLabels = ['A1 2378', 'A1 5140', 'A1 3987', 'A1 4437', 'A1 9856', 'A1 2954', 'A1 295P'];
        this.financialData = {
          labels: this.financialLabels,
          datasets: [{
            label: 'PAX Handling fee',
            backgroundColor: ['#5b9bd5'],
            hoverBackgroundColor: ['#5b9bd5'],
            borderColor: ['#5b9bd5'],
            hoverBorderWidth: 0,
            data: [3186, 3080, 2336, 3983, 1947, 3752, 0],
          }],
        };
        this.financialConfig = {
          type: 'bar',
          data: this.financialData,
          options: this.financialOptions,
        };
        this.financialOptions = {
          scales: {
            y: {
              ticks: {
                callback: function (tickValue) {
                  return tickValue + '€';
                },
                stepSize: 200,
              },
              suggestedMax: 600,
              title: {
                display: true,
                text: 'PAX Handling Fees',
                font: this.scaleTextFont,
              },
            },
            x: {
              title: {
                display: true,
                text: 'Flights',
                font: this.scaleTextFont,
              }
            },
          },
        };
        break;
    }
  }

  download(canvasId:string,graph,category: string) {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    this.fillCanvasBackgroundWithColor(canvas, 'white');
    const downloadLink = document.createElement('a');
    downloadLink.href = canvas.toDataURL('image/png');
    this.createPNGFileName(graph, category)
    downloadLink.download = this.fileName;
    downloadLink.click();
  }

  fillCanvasBackgroundWithColor(canvas: HTMLCanvasElement, color: string) {
    const context = canvas.getContext('2d');
    context.save();
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();
  }

  async createOperationsExcelFile(category,pairs,legs) {

    let data = [];
    let periodSelectedInDays;
    switch (category) {
      case 'Turnaround Performance':

        data.push(['Arriving Sector','Departing Sector','Turnaround Station','Actual Ground Time (Min.)','Standard Ground Time (Min.)','Turnaround Performance']);

        for (const pair of pairs) {
          let agt = dayjs(pair.__departureLegModel__.atd).diff(dayjs(pair.__arrivalLegModel__.ata));
          let sgt = dayjs(pair.__departureLegModel__.std).diff(dayjs(pair.__arrivalLegModel__.sta));
          let diff = Math.floor(agt / 60000) - Math.floor(sgt / 60000);
          if(agt && sgt) {
            if (agt <= sgt) {
              data.push([pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber, pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber, pair.__arrivalLegModel__.arrivalStation ,Math.floor(agt / 60000).toString(), Math.floor(sgt / 60000).toString(), 'On Time' ]);
            } else if (diff > 0 && diff <= 5 ) {
              data.push([pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber, pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber, pair.__arrivalLegModel__.arrivalStation ,Math.floor(agt / 60000).toString(), Math.floor(sgt / 60000).toString(), 'Good' ]);
            } else if (diff > 5 && diff < 15) {
              data.push([pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber, pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber, pair.__arrivalLegModel__.arrivalStation ,Math.floor(agt / 60000).toString(), Math.floor(sgt / 60000).toString(), 'Mid' ]);
            } else {
              data.push([pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber, pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber, pair.__arrivalLegModel__.arrivalStation ,Math.floor(agt / 60000).toString(), Math.floor(sgt / 60000).toString(), 'Poor' ]);
            }
          }
        }
        data.push([],[],['Info for Calculations'],['On Time', 'AGT <= SGT'],['Good','AGT - SGT <= 5min.'],['Mid','15min. > AGT - SGT > 5min.'],['Poor', 'AGT - SGT > 15min.'])
        this.createFileName('Operations', 'Turnaround Performance');
        this.sheetName = 'Turnaround Performance'
        break;
      
      case 'Completed Non SLA Processes':
      const pairIds = [];
      const processCount: {[title:string]:number} = {};  
      this.filterStation = this.formGroup?.get('operations').value.airport === 'all' ? null : this.formGroup?.get('operations').value.airport;
      this.filterAcType = this.formGroup?.get('operations').value.acType === 'all' ? null : this.formGroup?.get('operations').value.acType;

      const filterAcTypeId = this.formGroup?.get('operations').value.acType === 'all' ? null : this.acTypes.filter((actype) => actype.iata === this.filterAcType)[0].id;
      const filterAirportId = this.formGroup?.get('operations').value.airport === 'all' ? null : this.airports.filter((airport) => airport.iata === this.filterStation)[0].id;

      data.push(['Date','Arriving Sector','Departing Sector','Turnaround Station','Process']);

      for (const pair of this.pairs) {
        pairIds.push(pair.id)
      };
      const pairProcesses = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));

      const gseIds: number[] = [];
      for (const pairProcess of pairProcesses) {
        gseIds.push(pairProcess.gseId);
      }
      let filters;
      if (filterAcTypeId === null && filterAirportId !== null){
        filters = {isActive: true, isSla: false, airportId:filterAirportId, id: gseIds}
      } else if (filterAcTypeId !== null && filterAirportId === null) {
        filters = {isActive: true, isSla: false, acTypeId:filterAcTypeId, id: gseIds}
      } else if (filterAcTypeId === null && filterAirportId === null) {
        filters = {isActive: true, isSla: false, id: gseIds}
      } else {
        filters = {isActive: true, acTypeId:filterAcTypeId, isSla: false, airportId:filterAirportId, id: gseIds}
      }
      const gses = await firstValueFrom(this.gseService.fetchGses(filters));
      for (const pair of this.pairs) {
        const pairProcessesOfPair = pairProcesses.filter((pairProccess) => pairProccess.pairId === pair.id);
        for (const a of pairProcessesOfPair) {
          const gseNonSLA = gses.find((gse) => 
            gse.id === a.gseId
          );
          if (gseNonSLA) {
            data.push([
              dayjs(pair.__departureLegModel__.atd).format('DD-MM-YYYY'),
              pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber,
              pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber,
              pair.__departureLegModel__.departureStation,
              gseNonSLA.title
            ])
          }
        }

      }


      this.createFileName('Operations', 'Completed Non SLA Processes');
      this.sheetName = 'Completed Non SLA Processes'
      break;
      
      case 'AC Reg Utilization':

        data.push(['Date','Ac Registration','Actual Block Time (Hours)','Scheduled Block Time (Hours)']);  

        periodSelectedInDays = Math.round((dayjs(this.filterDayTo).diff(dayjs(this.filterDayFrom)))/86400000);
        if (!periodSelectedInDays) {
          periodSelectedInDays = 1
        };

        for (const leg of legs) {
          if (leg.atd !== null && leg.ata !== null) {
            data.push([dayjs(leg.atd).format('DD-MM-YYYY'),leg.acRegistration, (dayjs(leg.ata).diff(dayjs(leg.atd)))/3600000, (dayjs(leg.sta).diff(dayjs(leg.std)))/3600000]);

          };
        };
          data.push([],[],['Info for Calculations'],['Actual Block Time (per tail) hours/Number of days selected in the period']);
          this.createFileName('Operations', 'Ac Reg Utilization');
          this.sheetName = 'Ac Reg Utilization'
          break;
      
      case 'AC Type Utilization':

          data.push(['Date','Ac Type','Actual Block Time (Hours)',]);  
          const blockTimePerAcType: {[acType:string]:number} = {};

          periodSelectedInDays = Math.round((dayjs(this.filterDayTo).diff(dayjs(this.filterDayFrom)))/86400000);
          if (!periodSelectedInDays) {
            periodSelectedInDays = 1
          }
  
            for (const leg of legs) {
              if (leg.atd !== null && leg.ata !== null) {
                data.push([dayjs(leg.atd).format('DD-MM-YYYY'),leg.acType,(dayjs(leg.ata).diff(dayjs(leg.atd)))/3600000,]);
                blockTimePerAcType[leg.acType] = ((dayjs(leg.ata).diff(dayjs(leg.atd)))/3600000)/periodSelectedInDays + (blockTimePerAcType[leg.acType] || 0);
              };
            };
            data.push([]);
            for (const [key, value] of Object.entries(blockTimePerAcType)) {
                  data.push(['Total A/C Type Utilization ' + key,value])
            };
            this.createFileName('Operations', 'AC Type Utilization');
            this.sheetName = 'AC Type Utilization'
            break;
      default:
        break;
    }
    this.createExcelFile(data,this.fileName,this.sheetName)
  }

  async createPunctualityExcelFile(category, legs) {

    let data = [];
    let periodSelectedInDays;
    let leginfo;
    let countOnTime = 0;
    let countDelayed = 0;
    let totalCountMinutes = 0;

    switch (category) {
      case 'On Time Performance':

        data.push(['Date','Sector','Departing Station','STD','ATD','Total Dep. Delay (Min)','Performance']);
        for (const leg of legs) {
          if (dayjs(leg.atd).isAfter(dayjs(leg.std).add(5, 'minute'))) {
            data.push([
              dayjs(leg.atd).format('DD-MM-YYYY'),
              leg.airlineDesignator+leg.flightNumber,
              leg.departureStation,
              dayjs(leg.std).format('HH:mm'),
              dayjs(leg.atd).format('HH:mm'),
              dayjs(leg.atd).diff(dayjs(leg.std),'minutes'),
              'Delayed'
            ]);
            countDelayed++;
          } else {
              data.push([              
              dayjs(leg.atd).format('DD-MM-YYYY'),
              leg.airlineDesignator+leg.flightNumber,
              leg.departureStation,
              dayjs(leg.std).format('HH:mm'),
              dayjs(leg.atd).format('HH:mm'),
              dayjs(leg.atd).diff(dayjs(leg.std),'minutes'),
              'On Time'
            ]);
            countOnTime++;
          }
        };
        data.push([]);
        data.push(['Total On Time', countOnTime]);
        data.push(['Total Delayed', countDelayed]);
        this.createFileName('Punctuality', 'On Time Performance');
        this.sheetName = 'On Time Performance'

      
      break;

      case 'Arrival Punctuality':
        countDelayed = 0;
        countOnTime = 0;
        data.push(['Date','Sector','Arrival Station','STD','ATD','Total Arr. Delay (Min)','Performance']);
        for (const leg of legs) {
          if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
            data.push([
              dayjs(leg.ata).format('DD-MM-YYYY'),
              leg.airlineDesignator+leg.flightNumber,
              leg.arrivalStation,
              dayjs(leg.sta).format('HH:mm'),
              dayjs(leg.ata).format('HH:mm'),
              dayjs(leg.ata).diff(dayjs(leg.sta),'minutes'),
              'Delayed'
            ]);
            countDelayed++;
          } else {
            data.push([              
              dayjs(leg.ata).format('DD-MM-YYYY'),
              leg.airlineDesignator+leg.flightNumber,
              leg.arrivalStation,
              dayjs(leg.sta).format('HH:mm'),
              dayjs(leg.ata).format('HH:mm'),
              dayjs(leg.ata).diff(dayjs(leg.sta),'minutes'),
              'On Time'
            ]);
            countOnTime++;
          }
        };
        data.push([]);
        data.push(['Total On Time', countOnTime]);
        data.push(['Total Delayed', countDelayed]);
        this.createFileName('Punctuality', 'Arrival Punctuality');
        this.sheetName = 'Arrival Punctuality'  
      break;

      case 'Delayed per Delay Code':
        data.push(['Date','Sector','Delay Code','Delay Minutes']);
        const legIdsInLegList = legs.map((leg) => leg.id);

        const legDelayLogs = await firstValueFrom(this.legDelayLogsService.getLegDelayLogs({ isActive: [true], legId: legIdsInLegList }));
        const delayCodes = await firstValueFrom(this.generalSettingsService.getDelayCodes());

        for (const delayLog of legDelayLogs) {
          const delayCodesWeNeed = delayCodes.filter((delaycode) => delayLog.delayCodeId === delaycode.id);
          leginfo = legs.find((leg) => leg.id === delayLog.legId);
          data.push([
            dayjs(leginfo.atd).format('DD-MM-YYYY'),
            leginfo.airlineDesignator+leginfo.flightNumber,
            delayCodesWeNeed[0].code,
            delayLog.minutes]);
        }
        data.push([]);
        this.createFileName('Punctuality', 'Delayed per Delay Code');
        this.sheetName = 'Delayed per Delay Code'  
      break;

      case 'Total Departure Delay':
        legs.filter((leg) => dayjs(leg.atd).isAfter(dayjs(leg.std).add(5, 'minute')));
        totalCountMinutes = 0;
        data.push(['Date','Sector','Departure Station','Delay Minutes']);
        const legIdsInLegListD = legs.map((leg) => leg.id);

        const pairLegDelayLogsD = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({ isActive: true, legId: legIdsInLegListD, timeTypeId: 6 }));
        pairLegDelayLogsD.sort((a,b) => {
          return b.id - a.id
        })
        for (const pairLegDelay of pairLegDelayLogsD) {
          if(legIdsInLegListD.includes(pairLegDelay.legId)) {
            const index = legIdsInLegListD.indexOf(pairLegDelay.legId);
            legIdsInLegListD.splice(index, 1);
            leginfo = legs.find((leg) => leg.id === pairLegDelay.legId);
            data.push([
              dayjs(leginfo.atd).format('DD-MM-YYYY'),
              leginfo.airlineDesignator+leginfo.flightNumber, 
              leginfo.departureStation, 
              pairLegDelay.totalDelayInMinutes
            ]);
            totalCountMinutes = totalCountMinutes + pairLegDelay.totalDelayInMinutes;
          }
        };
        data.push([]);
        data.push(['Total Delay (Min)',totalCountMinutes]);
        data.push(['Total Sectors',legs.length])
        this.createFileName('Punctuality', 'Total Departure Delay');
        this.sheetName = 'Total Departure Delay'  
      break;

      case 'Total Arrival Delay':
        legs.filter((leg) => dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute')));

        data.push(['Date','Sector','Arrival Station','Delay Minutes']);
        const legIdsInLegListA = legs.map((leg) => leg.id);

        const pairLegDelayLogsA = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({ isActive: true, legId: legIdsInLegListA, timeTypeId: 7 }));
        pairLegDelayLogsA.sort((a,b) => {
          return b.id - a.id
        })
        for (const pairLegDelay of pairLegDelayLogsA) {
          if(legIdsInLegListA.includes(pairLegDelay.legId)) {
            const index = legIdsInLegListA.indexOf(pairLegDelay.legId);
            legIdsInLegListA.splice(index, 1);
            leginfo = legs.find((leg) => leg.id === pairLegDelay.legId);
            data.push([
              dayjs(leginfo.atd).format('DD-MM-YYYY'),
              leginfo.airlineDesignator+leginfo.flightNumber, 
              leginfo.arrivalStation, 
              pairLegDelay.totalDelayInMinutes
            ]);
            totalCountMinutes = totalCountMinutes + pairLegDelay.totalDelayInMinutes;

          }
        }
        data.push([]);
        data.push(['Total Delay (Min)',totalCountMinutes]);
        data.push(['Total Sectors',legs.length])
        this.createFileName('Punctuality', 'Total Arrival Delay');
        this.sheetName = 'Total Arrival Delay'  
      break;
      default:
        break;
    }

    this.createExcelFile(data,this.fileName,this.sheetName);

  }

  async createPassengersExcelFile(category, legs) {

    let data = [];
    let periodSelectedInDays;
    let leginfo;
    const acRegCount = {};

    let totalLoadFactorPerCode: {[code:string]:number} = {};
    let totalPaxPerCode: {[code:string]:number} = {};
    let totalSeatPerCode: {[code:string]:number} = {};

    let paxPerCode: {[code:string]:number} = {};
    const passClassCodes = [];
    
    const acRegIdsArray = new Set<number>();
    const seatPerCode: {[code:string]:number} = {};

    for (const leg of legs) {
      if (!acRegCount[leg.acRegistrationId]) {
        acRegCount[leg.acRegistrationId] = 1;
      } else {
        acRegCount[leg.acRegistrationId]++;
      }
      acRegIdsArray.add(leg.acRegistrationId);
    }
    const seatConfigs = await firstValueFrom(this.seatConfigService.getSeatingConfigurationsByAcRegistrationId(Array.from(acRegIdsArray)));
    const passengerClasses =  await firstValueFrom(this.generalSettingsService.getPassengerClasses());

    switch (category) {
      case 'Passenger Load (%)':
        data.push(['Date','Sector','Ac Registration']);
        for (const passClass of passengerClasses) {
          passClassCodes.push(passClass.code);
          data[0].push('PAX '+passClass.code);
        }
        for (const passClass of passengerClasses) {
          data[0].push('Offered Seats '+passClass.code);
        }
        for (const leg of legs) {
          data.push([
            dayjs(leg.atd).format('DD-MM-YYYY'),
            leg.airlineDesignator+leg.flightNumber,
            leg.acRegistration
          ]);
          const index = data.length - 1;
          if(leg.pax !== null) {
            paxPerCode = {}
            console.log('The leg with flight number ',leg.flightNumber,' has pax ',leg.pax);
            extractSeatingConfigurations(leg.pax).forEach((item: string) => {
              const code = item.slice(0, 1);
              const amount = item.slice(1);

              for (const passClassCode of passClassCodes) {
                if(code === passClassCode) {
                  paxPerCode[code] = Number(amount) || 0 ;
                  totalPaxPerCode[code] = (totalPaxPerCode[code] || 0) + (Number(amount) || 0 );
                }
              }
            });
          } else {
            paxPerCode = {};
          }
          const seatsForLeg = seatConfigs.filter((seating) => seating.acRegistrationId === leg.acRegistrationId)
          for (const seat of seatsForLeg) {
            seatPerCode[seat.code] = Number(seat.description) || 0;
            totalSeatPerCode[seat.code] = (totalSeatPerCode[seat.code] || 0) + (Number(seat.description) || 0);
 
          }
          console.log(seatsForLeg)
          for (const pax of passClassCodes) {
            data[index].push(paxPerCode[pax] || 0);
          };
          for (const pax of passClassCodes) {
            data[index].push(seatPerCode[pax] || 0);
          };   
        };
        data.push([],[]);
        console.log('the totals are :',totalPaxPerCode,totalSeatPerCode,acRegCount);

        for (const passClass of passengerClasses) {
          data.push(['Load Factor '+passClass.code,(totalPaxPerCode[passClass.code]/totalSeatPerCode[passClass.code] || 0)*100]);
        }
        
        this.createFileName('Passengers', 'Passenger Load Factor');
        this.sheetName = 'Passenger Load Factor'
      break;

      case '# Delayed Passengers':
        let totalDelayedPassengers = 0;
        data.push(['Date','Sector','Ac Registration','STD','ATD',]);
        for (const passClass of passengerClasses) {
          passClassCodes.push(passClass.code);
          data[0].push('PAX '+passClass.code);
        };
        data[0].push('Total Delayed Passengers')
        for (const leg of legs) {
          if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
            let totalPax = 0;
            const index = data.length;
            if(leg.pax !== null) {
              extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                const code = item.slice(0, 1);
                const amount = item.slice(1);
                totalPax += +amount;
                totalDelayedPassengers += +amount;

                for (const passClassCode of passClassCodes) {
                  if(code === passClassCode) {
                    paxPerCode[code] = Number(amount) || 0 ;
                    totalPaxPerCode[code] = (totalPaxPerCode[code] || 0) + (Number(amount) || 0 );
                  }
                }
              });
            } else {
              paxPerCode = {};    
            }          
            data.push([
              dayjs(leg.atd).format('DD-MM-YYYY'),
              leg.airlineDesignator+leg.flightNumber,
              leg.acRegistration,
              dayjs(leg.std).format('HH:mm'),
              dayjs(leg.atd).format('HH:mm'),
            ]);
            for (const paxCode of passClassCodes) {
              data[index].push(paxPerCode[paxCode] || 0);
            };
            data[index].push(totalPax)
          }
        };
        data.push([],[]);
        for (const passClass of passengerClasses) {
          data.push(['Total Delayed Passengers '+passClass.code,(totalPaxPerCode[passClass.code] || 0)]);
        }
        data.push(['Total Delayed Passengers', totalDelayedPassengers]);

        this.createFileName('Passengers', '# Delayed Passengers');
        this.sheetName = '# Delayed Passengers'
      break;

      case '% Delayed Passengers':
        data.push(['Date','Sector','Ac Registration']);
        let totalDelayedPaxPerCode: {[code:string]:number} = {};
        let allPaxPerCode: {[code:string]:number} = {};


        for (const passClass of passengerClasses) {
          passClassCodes.push(passClass.code);
          data[0].push('PAX '+passClass.code);
        }
        for (const passClass of passengerClasses) {
          data[0].push('Offered Seats '+passClass.code);
        }
        for (const leg of legs) {
          if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
          data.push([
            dayjs(leg.atd).format('DD-MM-YYYY'),
            leg.airlineDesignator+leg.flightNumber,
            leg.acRegistration
          ]);
          const index = data.length - 1;
          if(leg.pax !== null) {
            paxPerCode = {};
            extractSeatingConfigurations(leg.pax).forEach((item: string) => {
              const code = item.slice(0, 1);
              const amount = item.slice(1);

              for (const passClassCode of passClassCodes) {
                if(code === passClassCode) {
                  paxPerCode[code] = Number(amount) || 0 ;
                  totalDelayedPaxPerCode[code] = (totalDelayedPaxPerCode[code] || 0) + (Number(amount) || 0 );
                }
              }
            });
          } else {
            paxPerCode = {};
          }
          const seatsForLeg = seatConfigs.filter((seating) => seating.acRegistrationId === leg.acRegistrationId)
          for (const seat of seatsForLeg) {
            seatPerCode[seat.code] = Number(seat.description) || 0;
            totalSeatPerCode[seat.code] = (totalSeatPerCode[seat.code] || 0) + (Number(seat.description) || 0);
 
          }
          for (const pax of passClassCodes) {
            data[index].push(paxPerCode[pax] || 0);
          };
          for (const pax of passClassCodes) {
            data[index].push(seatPerCode[pax] || 0);
           };
          }
          if(leg.pax !== null) {
            extractSeatingConfigurations(leg.pax).forEach((item: string) => {
              const code = item.slice(0, 1);
              const amount = item.slice(1);

              for (const passClassCode of passClassCodes) {
                if(code === passClassCode) {
                  allPaxPerCode[code] = (allPaxPerCode[code] || 0) + (Number(amount) || 0 );
                }
              }
            });
          }   
        };
        data.push([],[]);

        for (const passClass of passengerClasses) {
          data.push(['Percentage of Delayed Passengers '+passClass.code,(totalDelayedPaxPerCode[passClass.code]/allPaxPerCode[passClass.code] || 0)*100]);
        }
        this.createFileName('Passengers', '% Delayed Passengers');
        this.sheetName = '% Delayed Passengers'
      break;

      default:
        break;
    }
    this.createExcelFile(data,this.fileName,this.sheetName);
  }

  createExcelFile(data, fileName, sheetName) {
    console.log('starboy')
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;
    XLSX.writeFile(workbook, fileName);
  }

  createFileName(category: string, graph: string) {
    this.fileName = category + ' - ' + graph + ' for ';
    this.fileName += (this.filterStation === null ? 'All Airports ' : this.filterStation);
    this.fileName += this.formGroup.get(category.toLowerCase()).get('date').value === null ? dayjs.utc().format('DD-MM-YYYY') : ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.from).format('DD-MM-YYYY')+' '+ (this.formGroup.get(category.toLowerCase()).get('date').value.to ? ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.to).format('DD-MM-YYYY') : ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.from).format('DD-MM-YYYY'));
    this.fileName +='.xlsx';
  }

  createPNGFileName(category: string, graph: string) {
    this.fileName = category + ' - ' + graph + ' for ';
    this.fileName += (this.filterStation === null ? 'All Airports ' : this.filterStation);
    this.fileName += this.formGroup.get(category.toLowerCase()).get('date').value === null ? dayjs.utc().format('DD-MM-YYYY') : ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.from).format('DD-MM-YYYY')+' '+ (this.formGroup.get(category.toLowerCase()).get('date').value.to ? ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.to).format('DD-MM-YYYY') : ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.from).format('DD-MM-YYYY'));
    this.fileName +='.png';
  }
}
