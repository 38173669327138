import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser } from '../../../shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAirport } from '../../../shared/models/airport.model';
import { take, takeUntil } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AddEditUserDialogComponent } from './dialogs/add-edit-user-dialog/add-edit-user-dialog.component';
import { IGlobalCountry } from '../../../shared/models/global-country.model';
import { UserService } from '../../../services/user.service';
import { ToastService } from '../../../services/toast.service';
import { IAirlineDesignator } from '../../../shared/models/airline-designator.model';
import { SettingsComponent } from '../settings.component';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { forkJoin, Subject } from 'rxjs';
import { AirportsService } from '../../../services/airports.service';
import { CountriesService } from '../../../services/countries.service';

@Component({
  selector: 'app-settings-user-management',
  templateUrl: './settings-user-management.component.html',
  styleUrls: ['./settings-user-management.component.scss']
})
export class SettingsUserManagementComponent implements OnInit, OnDestroy {

  users: IUser[];
  airportContainer: IAirport[];
  countryList: IGlobalCountry[];
  airlineDesignators: IAirlineDesignator[];
  filteredUsers: IUser[] = [];
  airportNamesList: {[key: string]: string} = {};
  selected = '';
  unsubscribe$ = new Subject();

  searchText: string = '';

  constructor(private modalService: NgbModal, private countriesService: CountriesService, private airportService: AirportsService, private userService: UserService, private toastService: ToastService, private generalSettingsService: GeneralSettingsService) {
    forkJoin([this.userService.fetchUsers(),this.airportService.fetchAirports(), this.countriesService.fetchCountries(), this.generalSettingsService.getAirlineDesignators()]).subscribe((results) => {
      this.users = results[0];
      this.airportContainer = results[1];
      this.countryList = results[2];
      this.airlineDesignators = results[3];
      this.filteredUsers = [...this.users];
      this.filterUsers();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.userService.fetchUsers().subscribe(() => {});
    if (this.airportContainer) {
      Object.entries(this.airportContainer).forEach(([key, val]: [string, IAirport]) => this.airportNamesList[key] = val.title);
    }
  }

  doAdd() {
    const modalRef = this.modalService.open(AddEditUserDialogComponent, { size: 'xl' as 'lg'});
    const modal = modalRef.componentInstance as AddEditUserDialogComponent;
    modal.title = 'Add User';
    modal.airportContainer = this.airportContainer;
    modal.countryList = this.countryList;
    modal.airlineDesignators = this.airlineDesignators;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.userService.fetchUsers().subscribe(() => {});
    });
    //
    // const saveClickedSubscription = modalRef.componentInstance.saveClicked.subscribe((formValue: IUser) => {
    //   const callable = this.fns.httpsCallable('createUser');
    //   const data$ = callable(formValue);
    //
    //   data$.pipe(take(1)).subscribe((response: {success: boolean}) => {
    //     if (response && response.success) {
    //       this.auth.sendPasswordResetEmail(formValue.email).then((passResetMailResponse: any) => {
    //         console.log('reset password mail is sent');
    //         console.log(passResetMailResponse);
    //       }).catch(error => {
    //         console.log('reset password mail is not sent');
    //         console.log(error);
    //       });
    //     }
    //   });
    // });
    //
    // modalRef.result.then().catch().finally(() => saveClickedSubscription.unsubscribe());
  }

  doEdit(item: string | any) {
    const modalRef = this.modalService.open(AddEditUserDialogComponent, { size: 'xl' as 'lg'});
    const modal = modalRef.componentInstance as AddEditUserDialogComponent;
    modal.title = 'Edit User';
    modal.airportContainer = this.airportContainer;
    modal.countryList = this.countryList;
    modal.user = this.users.find((user) => user.id === item);
    modal.airlineDesignators = this.airlineDesignators;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.userService.fetchUsers().subscribe(() => {});
    });
  }

  doDelete(item: string | any) {
    if (!item || !this.users || !this.users.find((user) => user.id === item)) {
      return;
    }
    const user = this.users.find((user) => user.id === item);
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'User ' + user.username;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.userService.deleteUser(user.id).subscribe((result) => {
        if(result) {
          this.toastService.showSuccess("User has been deleted");
          this.userService.fetchUsers().subscribe(() => {});
        }
      });
    });
  }

  filterUsers() {
    this.filteredUsers = this.users.filter((user) => {
      return (user.firstname.toLowerCase().concat(user.lastname.toLowerCase())).includes(this.searchText.toLowerCase()) || user.location.toLowerCase().includes(this.searchText.toLowerCase()) || user.role.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }
}
