import { Component, Input, OnInit } from '@angular/core';
import { IAirportContact } from '../../../../../shared/models/airport-contact.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';
import { AirportsService } from '../../../../../services/airports.service';
import { AddEditAirportContactComponent } from '../add-edit-airport-contact-dialog/add-edit-airport-contact-dialog.component';
import { ToastService } from '../../../../../services/toast.service';

@Component({
  selector: 'app-manage-airport-contact-dialog',
  templateUrl: './manage-airport-contact-dialog.component.html',
  styleUrls: ['./manage-airport-contact-dialog.component.scss']
})
export class ManageAirportContactDialogComponent implements OnInit {

  @Input() title?: string;
  @Input() data?: { [p: number]: IAirportContact };
  @Input() readonly?: boolean;
  @Input() airportId?: number;
  selected?: string | any;
  empty:boolean;

  constructor(private modalService: NgbModal, private toastService: ToastService, private airportService: AirportsService, private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.fetchContacts();
  }

  fetchContacts() {
    this.airportService.fetchAirportContactCompanies(this.airportId).subscribe((result) => {
      if(!result.length)
      {
        this.empty = true;
        return;
      }
      const data = {};
      for (const item of result) {
        data[item.id] = item;
      }
      this.data = data;
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  doAdd() {
    const modalRef = this.modalService.open(AddEditAirportContactComponent, { size: 'xl' as 'lg'});
    modalRef.componentInstance.title = 'Add Airport Contact Person';
    modalRef.componentInstance.airportId = this.airportId;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchContacts();
    })
  }

  doEdit(item: string | any) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(AddEditAirportContactComponent, { size: 'xl' as 'lg'});
    modalRef.componentInstance.title = 'Edit Airport Contact Person';
    modalRef.componentInstance.contactData = this.data[item];
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchContacts();
    });
  }

  doDelete(item: string | any) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Airport Contact Person ' + this.data[item].firstname + ' ' + this.data[item].lastname;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.airportService.deleteAirportContactCompany(item).subscribe((result) => {
        if(result) {
          this.toastService.showSuccess("The contact company has been deleted.");
          this.fetchContacts();
        }
      });
    });
  }

}
