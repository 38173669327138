import {Component, Input, OnInit} from '@angular/core';
import { IAirportCurfewTime } from '../../../../../../shared/models/airport-curfew-time.model';


@Component({
  selector: 'app-manage-airport-curfew-time-dialog-list-item',
  templateUrl: './manage-airport-curfew-time-dialog-list-item.component.html',
  styleUrls: ['./manage-airport-curfew-time-dialog-list-item.component.scss']
})
export class ManageAirportCurfewTimeDialogListItemComponent implements OnInit {

  @Input() item?: IAirportCurfewTime;
  @Input() opened?: boolean;
  @Input() shallNotOpen?: boolean;

  constructor() { }

  ngOnInit() {
  }

}
