import { EventEmitter, Injectable } from '@angular/core';
import { IUser } from '../shared/models/user.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { UserRoles } from '../shared/constants/user-roles.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isInitialized: boolean;
  initializeSubject = new Subject();
  user: IUser;
  userSubject = new BehaviorSubject(null);
  loggedOutEvent = new EventEmitter<void>();

  constructor(private fireAuth: AngularFireAuth, private fireDb: AngularFirestore) {
    this.checkAuthState();
  }

  loginWithCredentials(username: string, password: string): Promise<boolean> {
    if (!username || !password) {
      return Promise.reject(false);
    }

    return this.fireAuth.signInWithEmailAndPassword(username, password).then((res: any) => {
      if (!res.user || !res.user.uid) {
        return Promise.reject(false);
      }
      return this.getInternalUser(res.user.uid)
        .then(() => Promise.resolve(true))
        .catch(() => Promise.reject(false));
    })
      .catch((error: any) => {
        console.log('error: ', error);
        return Promise.reject(false);
      });
  }

  logout(): Promise<void> {
    if (!this.user) {
      return Promise.reject();
    }
    return this.fireAuth.signOut()
      .then(() => {
        this.loggedOutEvent.emit();
        this.setUser(null);
        return Promise.resolve();
      });
  }

  private getInternalUser(userId: string) {
    return firstValueFrom(this.fireDb.collection('users').doc(`${userId}`).get()).then(res => {
        if (res && res.data()) {
          console.log(res.data());
          this.setUser(res.data() as IUser);

          if (this.user.role === UserRoles.RAMP_AGENT) {
            this.fireAuth.signOut();
            this.setUser(null);
            return Promise.reject();
          }

          return Promise.resolve();
        } else {

          return Promise.reject();
        }
      })
      .catch((error: any) => {
        console.log(error);
        return Promise.reject();
      });
  }

  private checkAuthState(): void {
    this.fireAuth.authState
      .pipe(take(1))
      .subscribe(user => {
        if (!user || !user.uid) {
          this.initializingFinished();
          return;
        }
        this.getInternalUser(user.uid)
          .then(() => {
            this.initializingFinished();
          })
          .catch(error => {
            console.log(error);
            this.setUser(null);
            this.fireAuth.signOut();
            this.initializingFinished();
          });
        console.log('authstate2: ', user);
      });
  }

  private initializingFinished() {
    this.isInitialized = true;
    console.log('sending subject push');
    this.initializeSubject.next(null);
  }

  private setUser(user: IUser) {
    this.user = user;
    this.userSubject.next(user);
  }
}
