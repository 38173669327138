import { IMemoModel } from './memo.model';
import { ISeatingConfigurationModel } from './seating-configuration.model';
import { ILegsFirestoreModel } from './legs.model';
import { ITurnaround } from './turnaround.model';
import { IAlertsModel } from './alerts.model';

// delays
// seating configuration
// UI filters - Airport ID + code - hasAlerts(boolean) - hasMessages(boolean) - Status - STD+ETA+ETD + Date of operation + AC Registration
export interface IPairDetail {
  isActive?: boolean;
  id?: number;
  status?: string;
  arrivalLeg?: ILegsFirestoreModel;
  departureLeg?: ILegsFirestoreModel;
  dateOfOperation?: Date;
  acRegistration?: string;
  airportGate?: string;
  aircraftStand?: string;

  ctot?: Date;

  //messages?: any[];
  //errors?: any[];
  arrivalMemos?: IMemoModel[];
  departureMemos?: IMemoModel[];
  arrivalAlerts?: IAlertsModel[];
  departureAlerts?: IAlertsModel[];
  groundTime?: number;

  locked?: any;

  processes?: any[];
  turnaround?: ITurnaround;

  seatingConfiguration?: ISeatingConfigurationModel[];
  arrivalDelays?: { delayCode: string, minutes: number }[];
  departureDelays?: { delayCode: string, minutes: number }[];

  airportCode?: string;
  hasAlerts?: boolean;
  hasMessages?: boolean;
  hasMemo?: boolean;
  std?: Date;
  sta?: Date;
  etd?: Date;
  eta?: Date;
  atd?: Date;
  ata?: Date;
  tot?: Date;
  landingTime?: Date;
  turnaroundStatus?: string;

  sortingDatetime?: Date;
}

export function isPairDetail(pair: any): pair is IPairDetail {
  return 'arrivalLeg' in pair || 'departureLeg' in pair;
}
