<div class="bg-white flex flex-row chatHeader">
  <div id="participantsImages" class="participantsImages">
    <app-images [images]="participantsImages"></app-images>
  </div>
  <div id="participantsNames" class="participantsNames">
    <span>{{names}}</span>
  </div>
  <div id="actions">
    <i class="demo-icon icon-icons8-delete avitium-color size-30 close-icon" (click)="close()"></i>
  </div>
</div>
