import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IAirport} from '../../../models/airport.model';
export class SettingsAirportMainFormBuilder {

  static constructForm(
    fb: FormBuilder): FormGroup {

    return fb.group({
      title: ['', Validators.required],
      iata: ['', Validators.required],
      icao: ['', Validators.required],
      country: ['', Validators.required],
      icaoCountry: [''],
      latitude: [''],
      longitude: [''],
      slotCoordinationLevel: [''],
      fireCategory: [''],
    });
  }

  static patchForm(
    fb: FormBuilder,
    form: FormGroup,
    airport: IAirport): FormGroup {

    if (!fb || !form || !airport) {
      return new FormGroup({});
    }
  }
}
