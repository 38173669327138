import { Component, Input, OnInit } from '@angular/core';
import { IUser } from '../../../shared/models/user.model';
import { UserRoles } from '../../../shared/constants/user-roles.constants';

@Component({
  selector: 'app-simple-chat-contact',
  templateUrl: './simple-chat-contact.component.html',
  styleUrls: ['./simple-chat-contact.component.scss']
})
export class SimpleChatContactComponent implements OnInit {
  @Input() user: IUser;
  userRoleObject = UserRoles;
  constructor() { }

  ngOnInit(): void {
  }

}
