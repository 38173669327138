<div class="flex dark-blue">
  <div class="flex flex-column gap-2 w-100" *ngIf="showContent">
    <strong class="fs-5 margin-fix">Issue ETD for Flight {{ pairDetail.departureLeg?.airlineDesignator + pairDetail.departureLeg?.flightNumber }}</strong>
    <strong class="fs-6 margin-fix">{{ pairDetail.departureLeg?.departureStation}} {{pairDetail.departureLeg?.std | date:'HH:mm':'+00:00'}}z - {{ pairDetail.departureLeg?.arrivalStation}} {{pairDetail.departureLeg?.sta | date:'HH:mm':'+00:00'}}z ({{pairDetail.departureLeg?.acType}})</strong>
    <form [formGroup]="form" class="mt-4">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="container">
              <div class="row">
                <div class="col-5 form-field-header">
                  New ETD*
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <div class="input-group">
                      <input class="form-control" [placeholder]="dateFormat"
                             name="dp" formControlName="etdDate" ngbDatepicker #d="ngbDatepicker">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar" (click)="d.toggle()" type="button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <ngb-timepicker name="newEtdTime" formControlName="newEtdTime" [spinners]="false"></ngb-timepicker>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row mt-6">
                <div class="col-5 form-field-header">
                  Delay Code*
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <div class="mb-3">
                      <ng-select id="etd-delay-code" formControlName="delayCode">
                        <ng-option *ngFor="let delayCode of delayCodes" [value]="delayCode.id">{{delayCode.code}} {{ delayCode.description}}</ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="mb-3">
                    <input type="text" class="form-control" id="arrival-flight-passenger-gate" placeholder="" [value]="delayInMinutes | timeFromMinutes" disabled>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Close</button>
  <button type="button" class="btn button-blue settings-modal-button" (click)="save()" [disabled]="!form || form?.invalid || isBusy">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
