import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tooltip-content-gate',
  templateUrl: './tooltip-content-gate.component.html',
  styleUrls: ['./tooltip-content-gate.component.scss']
})
export class TooltipContentGateComponent implements OnInit {

  @Input() stand: string;
  @Input() gate: string;

  constructor() {
  }

  ngOnInit() {
  }

}
