import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlertsNewComponent } from './content/alerts/alerts.component';
import { DummiesComponent } from './content/dummies/dummies.component';
import { FlightsComponent } from './content/flights/flights.component';
import { LoginComponent } from './content/global/login/login.component';
import { ReportListComponent } from './content/reports/report-list/report-list.component';
import { ReportsComponent } from './content/reports/reports.component';
import { SettingsComponent } from './content/settings/settings.component';
import { AuthGuardService } from './services/auth-guard.service';
import { NoAuthGuardService } from './services/no-auth-guard.service';
import { ChatComponent } from './content/chat/chat.component';
import { ExitScreenComponent } from './content/exit-screen/exit-screen.component';
import { ExitScreenMvtComponent } from './content/exit-screen/exit-screen-mvt/exit-screen-mvt.component';
import { ExitScreenListComponent } from './content/exit-screen/exit-screen-list/exit-screen-list.component';
import { SettingsUserManagementComponent } from './content/settings/settings-user-management/settings-user-management.component';
import { SettingsFlightScheduleMaintenanceComponent } from './content/settings/settings-flight-schedule-maintenance/settings-flight-schedule-maintenance.component';
import { SettingsAcTypeManagementComponent } from './content/settings/settings-ac-type-management/settings-ac-type-management.component';
import { SettingsAcRegistrationMgmtComponent } from './content/settings/settings-ac-registration-management/settings-ac-registration-mgmt.component';
import { SettingsAirportManagementComponent } from './content/settings/settings-airport-management/settings-airport-management.component';
import { SettingsSlaGseManagementComponent } from './content/settings/settings-sla-gse-management/settings-sla-gse-management.component';
import { SettingsInterfaceManagementComponent } from './content/settings/settings-interface-management/settings-interface-management.component';
import { SettingsGeneralSettingsNewComponent } from './content/settings/settings-general-settings-new/settings-general-settings-new.component';
import { AddEditAcTypeDialogComponent } from './content/settings/settings-ac-type-management/dialogs/add-edit-ac-type-dialog/add-edit-ac-type-dialog.component';
import { AddEditAcRegistrationDialogComponent } from './content/settings/settings-ac-registration-management/dialogs/add-edit-ac-registration-dialog/add-edit-ac-registration-dialog.component';
import { AddEditAirportDialogComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-dialog/add-edit-airport-dialog.component';
import { AddEditAirportTurnaroundTimesDialogComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-turnaround-times-dialog/add-edit-airport-turnaround-times-dialog.component';
import { SettingsSlaGseEditFirstLevelComponent } from './content/settings/settings-sla-gse-management/settings-sla-gse-edit-first-level/settings-sla-gse-edit-first-level.component';
import { DummyPageGuardService } from './services/dummy-page-guard.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuardService]
  },
  {
    path: 'flights',
    component: FlightsComponent,
    canActivate: [AuthGuardService],
    children: [{
      path: 'all',
      component: FlightsComponent,
    }],
  },
  {
    path: 'alerts',
    component: AlertsNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'reports',
    canActivate: [AuthGuardService],
    children: [{
      path: '',
      component: ReportsComponent,
    },
    {
      path: 'list',
      component: ReportListComponent,
    }],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'general'
      },
      {
        path: 'general',
        component: SettingsGeneralSettingsNewComponent,
      },
      {
        path: 'users',
        component: SettingsUserManagementComponent,
      },
      {
        path: 'ssim',
        component: SettingsFlightScheduleMaintenanceComponent,
      },
      {
        path: 'ac-types/:id',
        component: AddEditAcTypeDialogComponent,
      },
      {
        path: 'ac-types',
        component: SettingsAcTypeManagementComponent,
      },
      {
        path: 'ac-registrations/:id',
        component: AddEditAcRegistrationDialogComponent,
      },
      {
        path: 'ac-registrations',
        component: SettingsAcRegistrationMgmtComponent
      },
      {
        path: 'airports/:id/turnaround/:acid',
        component: AddEditAirportTurnaroundTimesDialogComponent,
      },
      {
        path: 'airports/:id',
        component: AddEditAirportDialogComponent,
      },
      {
        path: 'airports',
        component: SettingsAirportManagementComponent,
      },
      {
        path: 'sla-gse/:gseId',
        component: SettingsSlaGseEditFirstLevelComponent
      },
      {
        path: 'sla-gse',
        component: SettingsSlaGseManagementComponent
      },
      {
        path: 'interfaces',
        component: SettingsInterfaceManagementComponent,
      }
    ]
  },
  {
    path: 'dummies',
    component: DummiesComponent,
    canActivate: [AuthGuardService, DummyPageGuardService]
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'create-message',
    canActivate: [AuthGuardService],
    children: [{
      path: '',
      component: ExitScreenComponent,
    },
    {
      path: 'mvt',
      component: ExitScreenMvtComponent,
    },
    {
      path: 'list',
      component: ExitScreenListComponent,
    }],
  },
  {
    path: '',
    redirectTo: '/flights',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: LoginComponent,
    canActivate: [NoAuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
