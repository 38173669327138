import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { IAcType } from '../shared/models/ac-type.model';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AcTypesService {

  private _acTypes: BehaviorSubject<IAcType[]> = new BehaviorSubject([]);
  get acTypes(): BehaviorSubject<IAcType[]> {
    return this._acTypes;
  }

  constructor(private httpClient: HttpClient, private toastService: ToastService) {
    this.fetchAcTypes().subscribe(() => {});
  }

  fetchAcTypes(filter: Partial<IAcType> = { isActive: true }): Observable<IAcType[]> {
    return this.httpClient.post(`${environment.api_base_url}ac-types/search`, filter, {}).pipe(
      map((item: IResponse<IAcType[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IAcType[]) => {
        if (!filter.isActive || Object.keys(filter).length > 1) {
          return;
        }
        this._acTypes.next(value);
      })
    );
  }

  saveAcType(acType: IAcType): Observable<IAcType> {
    let obs;
    if (acType.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}ac-types/${acType.id}`, acType, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}ac-types/create`, acType, {})
    }
    return obs.pipe(
      map((item: IResponse<IAcType>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving AC Type");
        return of({});
      })
    );
  }

  deleteAcType(acTypeId: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}ac-types/${acTypeId}`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting curfew time");
        return of({});
      })
    );
  }
}
