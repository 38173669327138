import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { ViewModalComponent } from '../../shared/components/view-modal/view-modal.component';
import { ViewTypes } from '../../shared/constants/viewTypes.constants';
import { TimezoneService } from '../../services/timezone.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimeService } from '../../services/time.service';
import { AuthService } from '../../services/auth.service';
import { IUser } from '../../shared/models/user.model';
import { UserRoles } from '../../shared/constants/user-roles.constants';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FlightsFilterService } from '../../services/flights-filter.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() user: IUser;
  logoutIcon: IconDefinition = faRightFromBracket;
  userRoleObject = UserRoles;
  usersInitials: string;
  userCardOpen = false;

  isNavbarCollapsed: boolean;
  languages: any[];
  modalRef: NgbModalRef;
  version: string;
  timezone = 'zulu';
  time: Date;

  routePath = '/flights';

  unsubscribe = new Subject();
  unreadMessageCount = 0;

  constructor(private router: Router, private modalService: NgbModal,
              private tzService: TimezoneService, private timeService: TimeService,
              private authService: AuthService, private filterService: FlightsFilterService,
              private chatService: ChatService) {
  }

  ngOnInit() {
    this.routePath = this.router.routerState.snapshot.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routePath = event.urlAfterRedirects;
      }
    });

    this.timeService.getTimeObservable().pipe(takeUntil(this.unsubscribe)).subscribe((time: Date) => {
      this.time = time;
      console.log(this.time);
    });

    this.usersInitials = this.getUsersInitials(this.user);

    this.chatService.unreadCount.subscribe((result) => {
      this.unreadMessageCount = result;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }


  getUsersInitials(user: IUser) {
    let initials = '';

    if (!user) {
      return initials;
    }

    if (user.firstname && user.firstname.length > 0) {
      initials = user.firstname.slice(0, 1);
    }

    if (user.lastname && user.lastname.length > 0) {
      initials += user.lastname.slice(0, 1);
    }

    return initials.toUpperCase();
  }

  collapseNavbar() {
    //this.isNavbarCollapsed = true;
  }

  login() {
    //   this.modalRef = this.loginModalService.open();
  }

  logout() {
    console.log('start logout');
    this.authService.logout()
      .then(() => {
        this.filterService.resetFilters();
        console.log('navigating');
        console.log('user: ', this.authService.user);
        this.router.navigate(['login']);
      })
      .catch(error => {
        console.log('no navigation');
        console.log(error);
      });
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  changeToZulu() {
    this.timezone = 'zulu';
    this.tzService.setTimezone('+0000');
  }

  changeToLocal() {
    this.timezone = 'local';
    this.tzService.setTimezone(null);
  }

  openAddFlight() {
    const activeModal = this.modalService.open(ViewModalComponent, {size: 'xl' as 'lg'});
    activeModal.componentInstance.title = 'Add Flights';
    activeModal.componentInstance.viewType = ViewTypes.FLIGHTS_ADD_FLIGHTS;
  }

  openModifyFlight() {
    const activeModal = this.modalService.open(ViewModalComponent, {size: 'xl' as 'lg'});
    activeModal.componentInstance.title = 'Modify Flights';
    activeModal.componentInstance.viewType = ViewTypes.FLIGHTS_MODIFY_FLIGHTS;
  }

  openCancelFlight() {
    const activeModal = this.modalService.open(ViewModalComponent, {size: 'xl' as 'lg'});
    activeModal.componentInstance.title = 'Cancel Flights';
    activeModal.componentInstance.viewType = ViewTypes.FLIGHTS_CANCEL_FLIGHTS;
  }

  switchToAllFlights() {
    this.router.navigateByUrl('/flights/all').then();
  }

  switchToDayOfOperation() {
    this.router.navigateByUrl('/flights').then();
  }
}
