<form [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="container h-54">
          <div class="row">
            <div class="col-6 form-field-header">
              Code
            </div>
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airline-code-code" placeholder="" formControlName="code">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 border-start">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Description
            </div>
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airline-code-description" placeholder="" formControlName="description">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
