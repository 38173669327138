import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flights-modify-flights',
  templateUrl: './flights-modify-flights.component.html',
  styleUrls: ['./flights-modify-flights.component.scss']
})
export class FlightsModifyFlightsComponent implements OnInit {

  changeDate?: boolean;
  changeACType?: boolean;
  changeACRegistration?: boolean;
  changeFlightNumber?: boolean;
  changeDepartingStation?: boolean;
  changeSTD?: boolean;
  changeArrivingStation?: boolean;
  changeSTA?: boolean;
  changeFlightServiceType?: boolean;

  etdDate: any;
  stdTime: any;
  staTime: any;

  constructor() { }

  ngOnInit() {
  }

}
