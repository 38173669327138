import { Component, OnDestroy, OnInit } from '@angular/core';
import { IMemoModel } from '../../models/memo.model';
import { IUser } from '../../models/user.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from '../../../services/auth.service';
import { take, takeUntil } from 'rxjs/operators';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { DeleteFormTypes } from '../../constants/deleteFormTypes.constants';
import { MemosService } from '../../../services/memos.service';
import { ToastService } from '../../../services/toast.service';
import { UserService } from '../../../services/user.service';
import { AddEditMemosDialogComponent } from '../add-edit-memos-dialog/add-edit-memos-dialog.component';
import { IPairDetail } from '../../models/pair-detail.model';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { TimezoneService } from '../../../services/timezone.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-view-memos-dialog',
  templateUrl: './view-memos-dialog.component.html',
  styleUrls: ['./view-memos-dialog.component.scss']
})
export class ViewMemosDialogComponent implements OnInit, OnDestroy {

  arrivalMemoUser?: IUser;
  departureMemoUser?: IUser;
  arrivalMemo?: IMemoModel;
  departureMemo?: IMemoModel;
  title?: string;
  pair?: IPairDetail;
  userRole?: string;
  dateFormat = 'DD/MM/YYYY';
  timezone = 'UTC';
  unsubscribe$ = new Subject();

  constructor(
    private modalService: NgbModal,
    private fns: AngularFireFunctions,
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private memoService: MemosService,
    private toastService: ToastService,
    private userService: UserService,
    private generalSettingsService: GeneralSettingsService,
    private tzService: TimezoneService) {
  }

  ngOnInit() {
    this.tzService.getTimezone().pipe(take(1)).subscribe((result => {
      this.timezone = result;
    }));
    this.generalSettingsService.generalSettings.pipe(takeUntil(this.unsubscribe$)).subscribe((settings) => {
      this.dateFormat = settings?.dateFormat || 'DD/MM/YYYY';
    });

    this.fetchMemos();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  fetchMemos()
  {
    this.memoService.getMemos({ isActive: true, pairId: this.pair?.id }).subscribe((result) => {
      for (const memo of result) {
        if(memo.legId === this.pair.arrivalLeg?.id) {
          this.arrivalMemo = memo;
          this.userService.getUserById(memo.lastChangedBy).subscribe((result) => {
            if(result) {
              this.arrivalMemoUser = result;
            }
          });
        }
        else {
          this.departureMemo = memo;
          this.userService.getUserById(memo.lastChangedBy).subscribe((result) => {
            if(result) {
              this.departureMemoUser = result;
            }
          });
        }
      }
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() { }

  addMemo() {
    const modalRef = this.modalService.open(AddEditMemosDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditMemosDialogComponent;
    modal.title = 'Add Memo';
    modal.pair = this.pair;
    modal.userRole = this.userRole;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchMemos();
    });

  }

  editMemo() {
    const modalRef = this.modalService.open(AddEditMemosDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditMemosDialogComponent;
    modal.title = 'Edit Memo';
    modal.pair = this.pair;
    modal.userRole = this.userRole;
    modal.arrivalMemo = this.arrivalMemo;
    modal.departureMemo = this.departureMemo;
    modal.arrivalMemoUser = this.arrivalMemoUser;
    modal.departureMemoUser = this.departureMemoUser;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchMemos();
    });
  }

  deleteMemo() {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Memo';
    modalRef.componentInstance.formType = DeleteFormTypes.DELETE_MEMO_FORM;
    modalRef.componentInstance.data = {
      arrivalLegId: this.arrivalMemo?.legId,
      departureLegId: this.departureMemo?.legId
    };
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe((flightPart: string) => {
      let idToDelete;
      if(flightPart === 'arrival') {
        idToDelete = this.arrivalMemo?.id;
      }
      else {
        idToDelete = this.departureMemo?.id;
      }
      if(idToDelete) {
        this.memoService.deleteMemo(idToDelete).subscribe((result) => {
          if(result) {
            this.toastService.showSuccess('Memo has been deleted!');
            if(this.arrivalMemo?.id === idToDelete) {
              this.arrivalMemo = null;
            }
            else
            {
              this.departureMemo = null;
            }
          }
        });
      }
    });

  }
}
