import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAlertsModel } from '../../../shared/models/alerts.model';
import { AlertService } from '../../../services/alert.service';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { IGenericContainerObject } from '../../../shared/models/genericContainerObject.model';
import { IDelayModel } from '../../../shared/models/delays.model';
import { TimezoneService } from '../../../services/timezone.service';
import { AlertTypesEnum } from '../../../shared/constants/alert-types.enum';

@Component({
  selector: 'app-edit-pair-dialog',
  templateUrl: './edit-pair-dialog.component.html',
  styleUrls: ['./edit-pair-dialog.component.scss']
})
export class EditPairDialogComponent implements OnInit {

  title = 'Edit pair';
  alert: IAlertsModel;
  alertTypes = AlertTypesEnum;

  dateFormat = 'DD/MM/YYYY';
  delayCodes: IDelayModel[];
  timezone: string;
  constructor(private activeModal: NgbActiveModal,
              public alertService: AlertService,
              private generalSettingsService: GeneralSettingsService,
              public timezoneService: TimezoneService) { }


  ngOnInit() {
    this.generalSettingsService.getDelayCodes().subscribe((result) => {
      this.delayCodes = result;
    });
    this.dateFormat = this.generalSettingsService.generalSettings.value.dateFormat;
  }
  closeModal() {
    this.activeModal.dismiss();
  }
}
