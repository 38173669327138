<div class="modal-header">
    <span class="modal-title">Remind Me</span>
    <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
    </button>
  </div>
  <div class="modal-body">
    <div class="flex gap-2 justify-content-center">
        <div class="align-self-center">Remind me in</div>
        <div><input type="number" min="0" max="23" value="0" maxlength="3" size="3" style="width: 40px" #remindTimeHours (keydown)="onKeyPress($event, remindTimeHours.value, remindTimeMinutes.value)"> hour(s)</div>
        <div><input type="number" min="0" max="59" value="0" maxlength="3" size="3" style="width: 40px" #remindTimeMinutes (keydown)="onKeyPress($event, remindTimeHours.value, remindTimeMinutes.value)"> minute(s)</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn button-blue settings-modal-button" (click)="remindMe(remindTimeHours.value, remindTimeMinutes.value)">Remind me</button>
    <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Close
    </button>
  </div>
