<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal('Cross click')">
    <!--<span aria-hidden="true" class="colorWhite">&times;</span> -->
  </button>
</div>
<div class="modal-body">
  <ng-container [ngSwitch]="viewType">

    <ng-container *ngSwitchCase="viewTypes.FLIGHTS_VIEW_FURTHER_INFORMATION">
      <app-flights-view-further-information [data]="data"></app-flights-view-further-information>
    </ng-container>

  </ng-container>
</div>
