export class TurnaroundType {
  public static MINIMUM = 'MINIMUM';
  public static STANDARD = 'STANDARD';

  static LIST = [
    TurnaroundType.MINIMUM,
    TurnaroundType.STANDARD
  ];
}


export enum TurnaroundTypes {
  MINIMUM = 'MINIMUM',
  STANDARD = 'STANDARD'
}
