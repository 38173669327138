import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { IUser } from '../shared/models/user.model';
import { ToastService } from './toast.service';
import { IGlobalCountry } from '../shared/models/global-country.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  get users(): BehaviorSubject<IUser[]> {
    return this._users;
  }


  private _users: BehaviorSubject<IUser[]> = new BehaviorSubject([]);
  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getUserById(userId: number): Observable<IUser> {
    return this.httpClient.get(`${environment.api_base_url}users/${userId}`, {}).pipe(
      map((item: IResponse<IUser>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        console.log(error);
        return of({});
      })
    );
  }

  fetchUsers(filter: IUser = { isActive: true }): Observable<IUser[]> {
    return this.httpClient.post(`${environment.api_base_url}users/search`, filter, {}).pipe(
      map((item: IResponse<IUser[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IUser[]) => {
        if (!filter.isActive || Object.keys(filter).length > 1) {
          return;
        }
        this._users.next(value);
      })
    );
  }

  deleteUser(userId: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}users/${userId}`, {}).pipe(
      map((item: IResponse<IUser>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting user");
        return of({});
      })
    );
  }

  saveUser(user: IUser): Observable<IUser> {
    let obs;
    if (user.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}users/${user.id}`, user, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}users/create`, user, {})
    }
    return obs.pipe(
      map((item: IResponse<IUser>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving user");
        return of({});
      })
    );
  }
}
