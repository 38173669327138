<div class="steps-container">
  <div class="steps">
    <div class="step" *ngFor="let item of menuItems; let i = index">
      <div class="step-circle border-right-design"
           [ngClass]="{
           'border-left-design': i === (menuItems.length-1),
           'border-both-design': i !== 0 && i !== (menuItems.length-1),
           'border-right-design': i === 0,
           'active': selectedItem === item || (i === 0 && selectedItem === undefined),
           'hide-text': noCircleContent
           }"
           (click)="selectItem(item)"
      >
        {{i + 1}}
      </div>
      <span class="text-center" [ngClass]="{
      'form-field-subheader': selectedItem !== item,
      'form-field-header': selectedItem === item || (i === 0 && selectedItem === undefined),
      'text-smaller': smaller
      }">
        {{item}}</span>
    </div>
  </div>
</div>
