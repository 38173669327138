import {Component, Input, OnInit} from '@angular/core';
import { Subject } from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SSIMParseValidator} from '../../../../shared/utils/ssim-parse.validator';
import {IAcType} from '../../../../shared/models/ac-type.model';
import {ISimpleData} from '../../../../shared/models/simpleData.model';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import { GeneralSettingsService } from '../../../../services/general-settings.service';
import { IGenericContainerObject } from '../../../../shared/models/genericContainerObject.model';
import { IAirlineDesignator } from '../../../../shared/models/airline-designator.model';
import { IMasterAirportsModel } from '../../../../shared/models/global-airport.model';
import { AirportsService } from '../../../../services/airports.service';
import { AcTypesService } from '../../../../services/ac-types.service';
import { SsimService } from '../../../../services/ssim.service';
import * as dayjs from 'dayjs';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'app-settings-flight-schedule-maint-validate-ssim-file',
  templateUrl: './settings-flight-schedule-maint-validate-ssim-file.component.html',
  styleUrls: ['./settings-flight-schedule-maint-validate-ssim-file.component.scss']
})
export class SettingsFlightScheduleMaintValidateSsimFileComponent implements OnInit {

  @Input() title?: string;
  @Input() fileName?: string;
  @Input() ssimFileContent: any;
  @Input() periodFrom?: dayjs.Dayjs;
  @Input() periodTo?: dayjs.Dayjs;

  isLoading?: boolean;
  isBusy = false;

  warnings: string[] = [];
  errors: string[] = [];
  highRisk: string[] = [];
  fileIsValid?: boolean;

  acTypes: any = {};
  airports: string[] = [];
  globalAirports: string[] = [];
  designators: string[] = [];
  serviceTypes: string[] = [];

  designatorsKV: IGenericContainerObject<IAirlineDesignator> = {};
  acTypesKV: IGenericContainerObject<IAcType> = {};
  globalAirportsKV: IGenericContainerObject<IMasterAirportsModel> = {};
  airportsKV: IGenericContainerObject<IMasterAirportsModel> = {};
  serviceTypesKV: IGenericContainerObject<ISimpleData> = {};

  v1?: boolean;
  v2?: boolean;
  v3 = true;
  v4?: boolean;
  v5?: boolean;

  eventsSubject: Subject<void> = new Subject<void>();

  constructor(private activeModal: NgbActiveModal, private fns: AngularFireFunctions, private db: AngularFireDatabase, private generalSettingsService: GeneralSettingsService,
              private airportsService: AirportsService, private acTypesService: AcTypesService, private ssimService: SsimService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.fetchData();
    console.log(JSON.stringify(this.ssimFileContent));

    /* if (this.ssimFileContent) {
      this.startSSIMValidation((this.ssimFileContent));
    } */
  }

  private fetchData() {
    this.generalSettingsService.getAirlineDesignators().subscribe((results) => {
      if(results?.length) {
        this.designators = results.map((designator) => {
          this.designatorsKV[designator.code] = designator;
          return designator.code;
        });
        this.v1 = true;
        this.startValidation();
      }
    });
    this.generalSettingsService.getFlightServiceTypes().subscribe((results) => {
      if(results?.length) {
        this.serviceTypes = results.map((serviceType) => {
          this.serviceTypesKV[serviceType.code] = serviceType;
          return serviceType.code;
        });
        this.v2 = true;
        this.startValidation();
      }
    });
    // this.airportsService.getAirports().subscribe((results) => {
    //   if(results?.length) {
    //     this.airports = results.map((airport) => {
    //       this.airportsKV[airport.iata] = airport;
    //       return airport.iata;
    //     });
    //     this.startValidation();
    //   }
    //   this.v3 = true;
    // });
    this.acTypesService.fetchAcTypes().subscribe((results) => {
      if(results?.length) {
        this.acTypes = results.map((acType) => {
          this.acTypesKV[acType.iata] = acType;
          return acType;
        });
        this.v4 = true;
        this.startValidation();
      }
    });

    this.airportsService.fetchMasterAirports().subscribe((results) => {
      if(results?.length) {
        this.globalAirports = results.map((airport) => {
          this.globalAirportsKV[airport.iata] = airport;
          return airport.iata;
        });
        this.v5 = true;
        this.startValidation();
      }
    });
  }

  private startValidation() {
    if (this.v1 && this.v2 && this.v3 && this.v4 && this.v5 && this.ssimFileContent) {
      this.startSSIMValidation(this.ssimFileContent);
    }
  }

  async startSSIMValidation(ssimContent: string) {
    console.log('starting validation');
    console.log('period is', this.periodFrom, this.periodTo);
    const validator = new SSIMParseValidator();
    /* const res = validator.parseAndValidateFlightSchedules(true, ssimContent,
      ['NRA'], PARAM_CORRECT_AIRPORTS, PARAM_CORRECT_GLOBAL_AIRPORTS, PARAM_CORRECT_AC_TYPES,
      PARAM_CORRECT_SERVICE_TYPES); */
    const res = await validator.parseAndValidateFlightSchedules(true, ssimContent,
      this.designators, this.airports, this.globalAirports, this.acTypesKV,
      this.serviceTypes, this.periodFrom, this.periodTo);
    console.log(res);
    this.isLoading = false;
    this.fileIsValid = res.valid;
    this.errors = res.errors;
    this.warnings = res.warnings;
    this.highRisk = res.highRisk;
  }

  closeModal(reason?: string) {
    this.activeModal.dismiss(reason);
  }

  onUploadClick() {
    this.isBusy = true;
    this.ssimService.uploadSSIM({
      fileContent: this.ssimFileContent,
      fileName: this.fileName,
      periodFrom: this.periodFrom ? this.periodFrom.toDate() : undefined,
      periodTo: this.periodTo ? this.periodTo.toDate() : undefined,
    }).subscribe((result) => {
      this.isBusy = false;
      if(result) {
        this.toastService.showSuccess('The SSIM File has been processed successfully');
        this.closeModal();
      }
      else {
        this.toastService.showError('An error has occurred');
      }
    });
  }
}
