import { Component, OnInit } from '@angular/core';
import {Router, NavigationError, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './app-main.component.html'
})
export class AppMainComponent {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationError && event.error.status === 404) {
        this.router.navigate(['/404']);
      }
    });

    this.route.fragment.subscribe(item => console.log(item));
  }
}
