<div class="item">
  <div class="itemHeaderContainer rounded-circle" [ngClass]="getBgCol()">
    <app-indicator-dot *ngIf="indicator && !indicatorType"></app-indicator-dot>
    <app-indicator-text *ngIf="indicator && indicatorType === 'text'" [text]="indicatorText"></app-indicator-text>
    <i *ngIf="textHeader" class="demo-icon rounded-circle item-header" [ngClass]="getCol()">{{textHeader}}</i>
    <!-- <i *ngIf="iconHeader" class="demo-icon rounded-circle item-header {{iconHeader}}"></i> -->
    <i *ngIf="iconHeader" class="demo-icon circle-icon {{iconHeader}} rounded-circle item-icon-header" [ngClass]="rotate"></i>
  </div>
  <p class="item-text">{{text ? text : '-'}}</p>
</div>
