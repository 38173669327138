<form [formGroup]="formGroup" class="container-fluid mt-4">
    <div class="row mt-3">
        <div class="col-6">
          <div class="light-blue-border card" formGroupName="operations">
            <div class="card-header text-center title">
              <div class="report-title">Operations
                <div ngbDropdown class="d-inline-block float-end cursor-pointer">
                  <fa-icon [icon]="dotsIcon" ngbDropdownToggle></fa-icon>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem>Download as PDF</button>
                    <button (click)="download('operationsChart','Operations',operationsChartLabel)"  ngbDropdownItem>Download as PNG</button>
                    <button (click)="createOperationsExcelFile(operationsChartLabel, pairs, legs)" ngbDropdownItem>Download as XLSX</button>
                  </div>
                </div>
              </div>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <select class="form-select light-blue-border input-bg-color" aria-label="Operations Type" formControlName="type">
                    <option value="1" selected>Turnaround Performance</option>
                    <option value="2">Completed Non SLA Processes</option>
                    <option value="3">AC Reg Utilization</option>
                    <option value="4">AC Type Utilization</option>
                  </select>
                </div>
                <div class="col-6">
                  <div class="flex justify-content-center">
                    <app-date-picker-range-popup class="w-100" formControlName="date"></app-date-picker-range-popup>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <select class="form-select light-blue-border input-bg-color" aria-label="Airport selector" formControlName="airport">
                    <option value="all" selected>All Airports</option>
                    <option *ngFor="let airport of airports | keyvalue" [value]="airport.value.iata">{{airport.value.iata}}</option>
                  </select>
                </div>
                <div *ngIf="formGroup.get('operations').value.type !== '3'" class="col-6">
                  <select class="form-select light-blue-border input-bg-color" aria-label="AC Type selector" formControlName="acType">
                    <option value="all" selected>Ac Types</option>
                    <option *ngFor="let acType of acTypes | keyvalue" [value]="acType.value.iata">{{acType.value.description}}</option>
                  </select>
                </div>
                <div class="col-2 text-center" *ngIf="financeFiltersChanged">
                  <button type="button" class="btn btn-outline-success">Apply filters</button>
                </div>
              </div>
              <div class="row flex justify-content-center">
                <div>
                  <canvas id="operationsChart" baseChart
                          [data]="operationData"
                          [options]="operationOptions"
                          [type]="operationConfig.type">
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="light-blue-border card" formGroupName="punctuality">
            <div class="card-header text-center title">
              <div class="report-title">Punctuality
                <div ngbDropdown class="d-inline-block float-end cursor-pointer">
                  <fa-icon [icon]="dotsIcon" ngbDropdownToggle></fa-icon>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem>Download as PDF</button>
                    <button (click)="download('punctualityChart','Punctuality',punctualityData.datasets[0].label)" ngbDropdownItem>Download as PNG</button>
                    <button (click)="createPunctualityExcelFile(punctualityData.datasets[0].label, legs)" ngbDropdownItem>Download as XLSX</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="flex justify-content-center">
                    <select class="form-select light-blue-border input-bg-color" aria-label="Type selector" formControlName="type" #punctualitySelector>
                      <option value="1" selected>On Time Performance</option>
                      <option value="2">Arrival Punctuality</option>
                      <option value="3">Delayed per DC</option>
                      <option value="4">Total Departure Delay</option>
                      <option value="5">Total Arrival Delay</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 flex justify-content-center">
                  <app-date-picker-range-popup class="w-100" formControlName="date"></app-date-picker-range-popup>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <select class="form-select light-blue-border input-bg-color" aria-label="Airport selector" formControlName="airport">
                    <option value="all" selected>All Airports</option>
                    <option *ngFor="let airport of airports | keyvalue" [value]="airport.value.iata">{{airport.value.iata}}</option>
                  </select>
                </div>
                <div class="col-2 text-center" *ngIf="financeFiltersChanged">
                  <button type="button" class="btn btn-outline-success">Apply filters</button>
                </div>
              </div>
              <div class="row flex justify-content-center">
<!--                <div [ngStyle]="{ 'max-width': punctualitySelector.value !== '3' ? '50%': ''}">-->
                <div >
                  <canvas id="punctualityChart" baseChart *ngIf="+punctualitySelector.value <= 3"
                     [data]="punctualityData"
                     [options]="punctualityOptions"
                     [type]="punctualityConfig.type">
                  </canvas>
                  <div  class="flex justify-content-center flex-column" *ngIf="+punctualitySelector.value === 4" [ngStyle]="{ 'height': financialChart.clientHeight + 'px'}">
                    <div class="text-center fw-bold total-delay">
                      {{totalDelayMinutes}} Minutes / {{totalFlights}} Flights
                    </div>
                  </div>
                  <div class="flex justify-content-center flex-column" *ngIf="+punctualitySelector.value === 5" [ngStyle]="{ 'height': financialChart.clientHeight + 'px'}">
                    <div class="text-center fw-bold total-delay">
                      {{totalDelayMinutes}} Minutes / {{totalFlights}} Flights
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-6">
          <div class="light-blue-border card" formGroupName="passengers">
            <div class="card-header text-center title">
              <div class="report-title">Passengers
                <div ngbDropdown class="d-inline-block float-end cursor-pointer">
                  <fa-icon [icon]="dotsIcon" ngbDropdownToggle></fa-icon>
                  <div ngbDropdownMenu aria-labelledby="downloadedDropdown">
                    <button ngbDropdownItem>Download as PDF</button>
                    <button (click)="download('passengersChart','Passengers',passengerData.datasets[0].label)" ngbDropdownItem>Download as PNG</button>
                    <button (click)="createPassengersExcelFile(passengerData.datasets[0].label, legs)" ngbDropdownItem>Download as XLSX</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="flex justify-content-center">
                    <select class="form-select light-blue-border input-bg-color" aria-label="Type selector" formControlName="type">
                      <option value="1" selected>Passenger Load Factor</option>
                      <option value="2"># Delayed Passengers</option>
                      <option value="3">% Delayed Passengers</option>
                      <option value="4">Passenger Connections</option>
                      <option value="5">Passenger Misconnections</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 flex justify-content-center">
                  <app-date-picker-range-popup class="w-100" formControlName="date"></app-date-picker-range-popup>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <select class="form-select light-blue-border input-bg-color" aria-label="Airport selector" formControlName="airport">
                    <option value="all" selected>All Airports</option>
                    <option *ngFor="let airport of airports | keyvalue" [value]="airport.value.iata">{{airport.value.iata}}</option>
                  </select>
                </div>
                <div class="col-6">
                  <select class="form-select light-blue-border input-bg-color" aria-label="AC Type selector" formControlName="acType">
                    <option value="all" selected>Ac Types</option>
                    <option *ngFor="let acType of acTypes | keyvalue" [value]="acType.value.iata">{{acType.value.description}}</option>
                  </select>
                </div>
                <div class="col-2 text-center" *ngIf="financeFiltersChanged">
                  <button type="button" class="btn btn-outline-success">Apply filters</button>
                </div>
              </div>
              <div class="row flex justify-content-center">
                <div>
                  <canvas id="passengersChart" baseChart
                          [data]="passengerData"
                          [options]="passengerOptions"
                          [type]="passengerConfig.type">
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="light-blue-border card" formGroupName="finance">
            <div class="card-header text-center title">
              <div class="report-title">Finance
                <div ngbDropdown class="d-inline-block float-end cursor-pointer">
                  <fa-icon [icon]="dotsIcon" ngbDropdownToggle></fa-icon>
                  <div ngbDropdownMenu aria-labelledby="downloadedDropdown">
                    <button ngbDropdownItem>Download as PDF</button>
                    <button ngbDropdownItem>Download as PNG</button>
                    <button ngbDropdownItem>Download as XLSX</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="flex justify-content-center">
                    <select class="form-select light-blue-border input-bg-color" aria-label="Type Selector" formControlName="type">
                      <option value="1" selected>Landing fees - Per Flight</option>
                      <option value="2">Landing fees - All Airports</option>
                      <option value="3">Parking fees - Per Flight</option>
                      <option value="4">Parking fees - All Airports</option>
                      <option value="5">GSE Costs</option>
                      <option value="6">PAX Handling Fees</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 flex justify-content-center">
                  <app-date-picker-range-popup class="w-100"></app-date-picker-range-popup>
                </div>
              </div>
              <div class="row mt-3">

                <div class="col-6">
                  <select class="form-select light-blue-border input-bg-color" aria-label="Airport selector" formControlName="airport">
                    <option value="all" selected>All Airports</option>
                    <option>ATH</option>
                    <option>VIE</option>
                    <option>LHR</option>
                    <option>CDG</option>
                    <option>LIS</option>
                    <option>FCO</option>
                  </select>
                </div>
                <div class="col-6"></div>
                <div class="col-2 text-center" *ngIf="financeFiltersChanged">
                  <button type="button" class="btn btn-outline-success">Apply filters</button>
                </div>
              </div>
              <div class="row flex justify-content-center">
                <div>
                  <canvas baseChart
                          [data]="financialData"
                          [options]="financialOptions"
                          [type]="financialConfig.type"
                          #financialChart>
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</form>

