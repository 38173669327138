<form class="px-3 form-up">
  <div class="container pb-3">
    <div class="row mb-3 px-3">
      <div class="col-12 form-field-subheader">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="selectAllCheckbox" name="selectAllCheckbox" (click)="changeAllSelections()"> &nbsp; &nbsp;
          <label class="form-check-label" for="selectAllCheckbox">Select all</label>
        </div>
      </div>
    </div>
    <div class="row flight-list-container mb-3 px-3">
      <div class="col-12 flex" *ngFor="let item of flights; let i = index">
        <div class="form-check form-check-inline form-field-header">
          <input class="form-check-input" type="checkbox" id="selectFlight{{i}}" name="selectFlight{{i}}" [(ngModel)]="selectFlight[i]"> &nbsp; &nbsp;
        </div>
        <div class="flex-grow-1">
          <app-flight-list-item></app-flight-list-item>
        </div>
      </div>
    </div>
  </div>
</form>
