<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="bar-container">
        <div class="spacing-area">&nbsp;</div>
        <div class="text-area form-field-header text-center">{{topLabel1 || 'Minimum Turnaround Time'}}</div>
        <div class="text-area form-field-header text-center">{{topLabel2 || 'Standard Turnaround Time'}}</div>
      </div>
    </div>
    <div class="col-12">
      <div class="bar-container">
        <div class="red-area bg-white" [ngClass]="{'right-border': true}">&nbsp;</div>
        <div class="yellow-area bg-white">&nbsp;</div>
        <div class="green-area bg-white" [ngClass]="{'left-border': turnaroundType === standardTurnaroundType}">&nbsp;</div>
      </div>
    </div>
    <div class="col-12">
      <div class="bar-container">
        <div class="red-area">&nbsp;</div>
        <div *ngIf="turnaroundType === standardTurnaroundType" class="yellow-area">&nbsp;</div>
        <div class="green-area">&nbsp;</div>
      </div>
    </div>
    <div class="col-12">
      <div class="bar-container">
        <div class="red-area bg-white">&nbsp;</div>
        <div *ngIf="turnaroundType === standardTurnaroundType" class="yellow-area bg-white right-border">&nbsp;</div>
        <div class="green-area bg-white">&nbsp;</div>
      </div>
    </div>
    <div class="col-12">
      <div class="bar-container">
        <div class="spacing-area"></div>
        <div class="red-area bg-white form-field-header text-center"></div>
        <div class="text-area form-field-header text-center">{{bottomLabel2}}</div>
        <div class="green-area bg-white flex-grow-1">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
