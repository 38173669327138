<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="form"><form [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="col-6 form-field-header">
          Process Name*
        </div>
        <div class="col-6">
          <div class="mb-3">
            <span *ngIf="isSaved" class="form-field-content">{{process?.title}}</span>
            <input *ngIf="!isSaved" type="text" class="form-control" id="settings-ac-reg-mob-nr" placeholder="" formControlName="processName">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 form-field-header">
          Process applicable for A/C Types
        </div>
      </div>
      <div class="row mt-1 padding-15">
        <div class="col-12 p-0 list-container">
          <!-- insert list here -->

          <div class="type-list">
            <div *ngFor="let gse of filteredGseList | keyvalue" class="list-item-row border-bottom-darkgray">
              <div class="flex-basis-50 text-block align-self-center">
                {{gse?.key === '0' ? 'Default A/C Types' : acTypeList[gse.key].alias}}
              </div>
              <div class="flex-basis-50 text-block text-end icon-container colorBlack">
                <i class="demo-icon icon-icons8-edit-file fs-30" [ngClass]="{'clickable': isSaved, 'disabled': !isSaved}" (click)="editAcType(gse?.key)"></i>
                <i class="ms-3 demo-icon icon-icons8-delete fs-30" [ngClass]="{'clickable': !isSaved && '0' !== gse?.key, 'colorWhite': !isSaved || gse?.key === '0' }" (click)="deleteItem(gse?.key)"></i>
              </div>

            </div>
          </div>
          <app-add-row buttonText="Add Exceptions for A/C Types" [isDisabled]="!isSaved" (addClicked)="addAcType()"></app-add-row>
        </div>
      </div>
    </div>
  </form>
  </ng-container>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">{{ this.isSaved ? 'Close' : 'Cancel' }}
  </button>
  <button *ngIf="!this.isSaved" type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="saveButtonClicked()"
          [disabled]="form?.disabled">Save
  </button>
</div>
