<!-- Version 1 - removed xx.07.19
<div class="process-container">
  <div class="checkmark-icon-container rounded-circle" [ngClass]="{'hidden' : !done}">
    <i class="demo-icon icon-icons8-checkmark"></i>
  </div>
  <span class="name-span">{{name}}</span>
  <span class="time-span">{{starttime}}</span>
  <div class="forward-icon-container rounded-circle">
    <i class="demo-icon icon-icons8-forward"></i>
  </div>
  <span class="time-span">{{endtime}}</span>
</div> -->

<!-- Version 2 - removed 21.08.19
<div class="process-container2 me-3">
  <div class="a0 rounded-circle" [ngClass]="{'hidden' : !done}">
    <i class="demo-icon icon-icons8-checkmark"></i>
  </div>
  <span class="a1">{{name}}</span>
  <span class="a2">{{starttime}}</span>
  <div class="a3 rounded-circle">
    <i class="demo-icon icon-icons8-forward"></i>
  </div>
  <span class="a4">{{endtime}}</span>
</div> -->

<!-- Version 3 - added 21.08.19 -->
<!-- <div class="process-container2 me-3">
</div> -->
<div class="a-new-container">
  <div class="a-new">
    <span *ngIf="isSla" class="sla-indicator sla">SLA</span>
    <span *ngIf="!isSla" class="sla-indicator non-sla">Non-SLA</span>
    <span class="name">&nbsp; &nbsp;{{name}}</span>
  </div>
</div>
