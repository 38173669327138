<form [formGroup]="formGroup">

<div class="container dark-blue mt-4">
    <!-- <div class="row mt-3 flex item light-blue-border" style="height:65px">
            <div class="col-5 align-self-center">All Data</div>
            <div class="col-4 flex justify-content-center align-self-center">
                <app-date-picker-range-popup></app-date-picker-range-popup>
            </div>
            <div class="col-1 cursor-pointer align-self-center" style="color: grey !important;"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
            <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="csvIcon"></fa-icon></div>
            <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div> -->
    <div class="row mt-3 flex item light-blue-border" style="height:65px">
        <div class="col-5 align-self-center">Flight Details</div>
        <div class="col-4 flex justify-content-center align-self-center">
            <app-date-picker-range-popup  formControlName="flightDetails"></app-date-picker-range-popup>
        </div>
        <div class="col-1 align-self-center"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
        <div class="col-1 cursor-pointer align-self-center" > <fa-icon size="2x" (click)="getFLightDetailsXLS()" [icon]="csvIcon"></fa-icon></div>
        <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div>
    <div class="row mt-3 flex item light-blue-border" style="height:65px">
        <div class="col-5 align-self-center">Flight Pairings</div>
        <div class="col-4 flex justify-content-center align-self-center">
            <app-date-picker-range-popup formControlName="flightPairings"></app-date-picker-range-popup>
        </div>
        <div class="col-1 align-self-center"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
        <div class="col-1 cursor-pointer align-self-center" > <fa-icon size="2x" (click)="getFLightPairingsXLS()" [icon]="csvIcon"></fa-icon></div>
        <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div>
    <div class="row mt-3 flex item light-blue-border" style="height:65px">
        <div class="col-5 align-self-center">Users Report</div>
        <div class="col-4 flex justify-content-center align-self-center">
            <app-date-picker-range-popup></app-date-picker-range-popup>
        </div>
        <div class="col-1  align-self-center"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
        <div class="col-1 cursor-pointer align-self-center" > <fa-icon size="2x" (click)="getUsersReportXLS()" [icon]="csvIcon"></fa-icon></div>
        <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div>
    <div class="row mt-3 flex item light-blue-border" style="height:65px">
        <div class="col-5 align-self-center">GSE Utilization per Airport</div>
        <div class="col-4 flex justify-content-center align-self-center">
            <app-date-picker-range-popup formControlName="gseUtilization"></app-date-picker-range-popup>
        </div>
        <div class="col-1 align-self-center"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
        <div class="col-1 cursor-pointer align-self-center" > <fa-icon size="2x" (click)="getGseUtilizationXLS()" [icon]="csvIcon"></fa-icon></div>
        <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div>
    <div class="row mt-3 flex item light-blue-border" style="height:65px">
        <div class="col-5 align-self-center">List of SLA/Non-SLA Processes</div>
        <div class="col-4 flex justify-content-center align-self-center">
            <app-date-picker-range-popup formControlName="processes"></app-date-picker-range-popup>
        </div>
        <div class="col-1 align-self-center"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
        <div class="col-1 cursor-pointer align-self-center" > <fa-icon size="2x" (click)="getProcessesXLS()" [icon]="csvIcon"></fa-icon></div>
        <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div>
    <!-- <div class="row mt-3 flex item light-blue-border" style="height:65px">
        <div class="col-5 align-self-center">EU 261 Eligible Flights</div>
        <div class="col-4 flex justify-content-center align-self-center">
            <app-date-picker-range-popup></app-date-picker-range-popup>
        </div>
        <div class="col-1 align-self-center"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
        <div class="col-1 cursor-pointer align-self-center" > <fa-icon size="2x" [icon]="csvIcon"></fa-icon></div>
        <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div> -->
    <div class="row mt-3 flex item light-blue-border" style="height:65px">
        <div class="col-5 align-self-center">Flights Memos</div>
        <div class="col-4 flex justify-content-center align-self-center">
            <app-date-picker-range-popup formControlName="memos"></app-date-picker-range-popup>
        </div>
        <div class="col-1 align-self-center"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
        <div class="col-1 cursor-pointer align-self-center" > <fa-icon size="2x" (click)="getMemosXLS()" [icon]="csvIcon"></fa-icon></div>
        <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div>
</div>
</form>


