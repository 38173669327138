import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISimpleData } from '../shared/models/simpleData.model';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { ToastService } from './toast.service';

import { IAirportTerminals } from '../shared/models/airport-terminals.model';
import { ISeatingConfigurationModel } from '../shared/models/seating-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class SeatingConfigurationService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getSeatingConfigurationsByAcTypeId(acTypeId: number): Observable<ISeatingConfigurationModel[]>  {
    return this.httpClient.post(`${environment.api_base_url}seating-configuration/search`, { isActive: true, acTypeId: acTypeId }, {}).pipe(
      map((item: IResponse<ISeatingConfigurationModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getSeatingConfigurationsByAirportId(airportId: number): Observable<ISeatingConfigurationModel[]>  {
    return this.httpClient.post(`${environment.api_base_url}seating-configuration/search`, { isActive: true, airportId }, {}).pipe(
      map((item: IResponse<ISeatingConfigurationModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getSeatingConfigurationsByAcRegistrationId(acRegistrationId: number[]): Observable<ISeatingConfigurationModel[]>  {
    return this.httpClient.post(`${environment.api_base_url}seating-configuration/search`, { isActive: true, acRegistrationId: acRegistrationId }, {}).pipe(
      map((item: IResponse<ISeatingConfigurationModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  saveSeatingConfiguration(seatingConfiguration: ISeatingConfigurationModel): Observable<IAirportTerminals> {
    let obs;
    if (seatingConfiguration.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}seating-configuration/${seatingConfiguration.id}`, seatingConfiguration, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}seating-configuration/create`, seatingConfiguration, {})
    }
    return obs.pipe(
      map((item: IResponse<ISeatingConfigurationModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving seating configuration");
        return of({});
      })
    );
  }

  getSeatingConfiguration(): Observable<ISimpleData[]> {
    return this.httpClient.post(`${environment.api_base_url}seating-configuration/search`, { isActive: true }, {}).pipe(
      map((item: IResponse<ISimpleData[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        this.toastService.showError("Error Loading Seating Configurations");
        return of([]);
      })
    );
  }

  deleteSeatingConfiguration(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}seating-configuration/${id}`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting seating configuration");
        return of({});
      })
    );
  }
}
