import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ISimpleData } from '../../../models/simpleData.model';
import { seatingConfigurationValidator } from '../../../validators/seating-configuration.validator';

export class SettingsAcRegistrationMainFormBuilder {

  static constructForm(
    fb: FormBuilder,
    passengerClassesArray: ISimpleData[]): FormGroup {

    const seatConfigFormGroup = new FormGroup({}, {validators: seatingConfigurationValidator});

    passengerClassesArray.forEach((item: ISimpleData) => {
      seatConfigFormGroup.addControl(item.code, new FormGroup({
        code: new FormControl(item.code),
        description: new FormControl({ value: null, disabled: true })
      }));
    });

    return fb.group({
      acTypeId: ['', Validators.required],
      registration: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      calibrationId: ['', Validators.required],
      mobNr: ['', Validators.required],
      deliveryDate: [null],
      mtow: ['', Validators.required],
      selcal: ['', Validators.required],
      seatingConfig: seatConfigFormGroup,
      noseCode: ['', Validators.required]
    });
  }
}
