import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  private tz: BehaviorSubject<string>;

  constructor() {
    this.tz = new BehaviorSubject<string>('+0000');
  }

  getTimezone(): Observable<string> {
    return this.tz.asObservable();
  }

  setTimezone(tz: string): void {
    this.tz.next(tz);
  }
}
