<div class="flight-info-headline-container padding-bottom-15">
  <span *ngIf="isArrivalLeg">Arriving Flight</span>
  <span *ngIf="!isArrivalLeg">Departing Flight</span>

  <span *ngIf="leg" class="margin-left-30">
          {{leg?.departureStation ? (leg?.departureStation + ' ' + (leg?.std | date:'HH:mm':timezone) + ' - ' + leg?.arrivalStation + ' ' + (leg?.sta | date:'HH:mm':timezone)) : '-'}}
        </span>
  <span *ngIf="!leg" class="margin-left-30">-</span>
</div>

<div class="flight-info-data-container">

  <ng-container *ngIf="isArrivalLeg">
    <app-icon-text-item-small textHeader="ETD"
                              [text]="leg?.etd | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.etd, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.etd, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="ATD"
                              [text]="leg?.atd | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.atd, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.atd, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="TAKE OFF" [text]="leg?.takeOffTime | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.takeOffTime, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.takeOffTime, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="LAND"
                              [text]="leg?.landingTime | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.sta, leg?.landingTime, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.sta, leg?.landingTime, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="ATA"
                              [text]="leg?.ata | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.ata, leg?.sta, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.sta, leg?.ata, timezone)"
    ></app-icon-text-item-small>
  </ng-container>

  <ng-container *ngIf="!isArrivalLeg">
    <app-icon-text-item-small textHeader="ATD"
                              [text]="leg?.atd | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.atd, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.atd, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="TAKE OFF"
                              [text]="leg?.takeOffTime | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.takeOffTime, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.takeOffTime, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="ETA"
                              [text]="leg?.eta | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.eta, leg?.sta, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.sta, leg?.eta, timezone)"
    ></app-icon-text-item-small>
  </ng-container>

  <app-icon-text-item-small textHeader="{{isArrivalLeg ? ' IN ' : 'OUT'}} PAX" [text]="paxTotal" [ngbTooltip]="pax"></app-icon-text-item-small>
  <app-icon-text-item-small textHeader="GATE" [text]="isArrivalLeg ? leg?.arrivalAcStand : leg?.departureAcStand" [ngbTooltip]="gate"></app-icon-text-item-small>
  <app-icon-text-item-small textHeader="DL" [text]="isArrivalLeg ? arrivalDelayInMinutes > 0 ? (arrivalDelayInMinutes | timeFromMinutes) : '-' : departureDelayInMinutes > 0 ? (departureDelayInMinutes | timeFromMinutes) : '-'" [ngbTooltip]="dl" [indicator]="(departureNumberOfDelays+arrivalNumberOfDelays) > 0" indicatorType="text"
                            [indicatorText]="(departureNumberOfDelays+arrivalNumberOfDelays).toString()"></app-icon-text-item-small>

  <ng-template #pax>
    <app-tooltip-content-pax [pax]="paxList"></app-tooltip-content-pax>
  </ng-template>
  <ng-template #gate>
    <app-tooltip-content-gate *ngIf="isArrivalLeg" [stand]="leg?.arrivalAcStand" [gate]="leg?.arrivalGate"></app-tooltip-content-gate>
    <app-tooltip-content-gate *ngIf="!isArrivalLeg" [stand]="leg?.departureAcStand" [gate]="leg?.departureGate"></app-tooltip-content-gate>
  </ng-template>
  <ng-template #dl>
    <app-tooltip-content-delays [arrivalDelays]="leg?.arrivalDelays" [departureDelays]="leg?.departureDelays"></app-tooltip-content-delays>
  </ng-template>
</div>
