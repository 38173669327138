import { Component, Input, OnInit } from '@angular/core';
import { IGeneralSettingsModel } from '../../../../../shared/models/general-settings.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-turnaround-threshold-dialog',
  templateUrl: './view-turnaround-threshold-dialog.component.html',
  styleUrls: ['./view-turnaround-threshold-dialog.component.scss']
})
export class ViewTurnaroundThresholdDialogComponent implements OnInit {

  @Input() settings?: IGeneralSettingsModel;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.dismiss();
  }

}
