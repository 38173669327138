export class UserRoles {
  static ADMIN = 'ADMIN';
  static OPS_CONTROLLER = 'OPS_CONTROLLER';
  static DUTY_MANAGER = 'DUTY_MANAGER';
  static RAMP_AGENT = 'RAMP_AGENT';
  static OBSERVER = 'OBSERVER';

  static LIST = [
    UserRoles.ADMIN,
    UserRoles.OPS_CONTROLLER,
    UserRoles.DUTY_MANAGER,
    UserRoles.RAMP_AGENT,
    UserRoles.OBSERVER
  ];

  static DISPLAY_TITLE_MAP = {
    ADMIN: 'Administrator',
    OPS_CONTROLLER: 'Ops Controller',
    DUTY_MANAGER: 'Duty Manager',
    RAMP_AGENT: 'Ramp Agent',
    OBSERVER: 'Observer'
  };
}
