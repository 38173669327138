import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAirportTerminals } from '../../../../../shared/models/airport-terminals.model';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AirportsService } from '../../../../../services/airports.service';
import { ToastService } from '../../../../../services/toast.service';
import { AddEditAirportTerminalDialogComponent } from '../../../settings-airport-management/dialogs/add-edit-airport-terminal-dialog/add-edit-airport-terminal-dialog.component';
import { take } from 'rxjs/operators';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';
import { IAirlineDesignator } from '../../../../../shared/models/airline-designator.model';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { AddEditAirlineDesignatorsDialogComponent } from '../add-edit-airline-designators-dialog/add-edit-airline-designators-dialog.component';

@Component({
  selector: 'app-manage-airline-codes-dialog',
  templateUrl: './manage-airline-codes-dialog.component.html',
  styleUrls: ['./manage-airline-codes-dialog.component.scss']
})
export class ManageAirlineCodesDialogComponent implements OnInit {

  title = "Manage Airline Codes";
  @Input() airlineDesignators?: { [p: number]: IAirlineDesignator };
  @Input() readonly = true;
  selected?: string | any;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private generalSettingsService: GeneralSettingsService, private toastService: ToastService) { }

  ngOnInit() {
    this.fetchAirlineDesignators();
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  fetchAirlineDesignators() {
    this.generalSettingsService.getAirlineDesignators().subscribe((result) => {
      const data = {};
      for (const item of result) {
        data[item.id] = item;
      }
      this.airlineDesignators = data;
    });
  }

  doAdd() {
    const modalRef = this.modalService.open(AddEditAirlineDesignatorsDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Add Airline Code';
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchAirlineDesignators();
    })
  }

  doEdit(item: number) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(AddEditAirlineDesignatorsDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Edit Airline code';
    modalRef.componentInstance.airlineDesignator = this.airlineDesignators[item];
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchAirlineDesignators();
    })
  }

  doDelete(item: number) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Airline Code';

    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.generalSettingsService.deleteAirlineDesignator(item).subscribe((result) => {
        if(result) {
          this.toastService.showSuccess("The airline code has been deleted.");
          this.fetchAirlineDesignators();
        }
      });
    });
  }

}
