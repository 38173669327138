<app-step-menu [menuItems]="steps" [(selectedItem)]="step"></app-step-menu>

<app-flights-search-flights *ngIf="step === SEARCH"></app-flights-search-flights>
<app-flights-select-flights *ngIf="step === SELECT"></app-flights-select-flights>
<app-flights-modify-flights *ngIf="step === MODIFY"></app-flights-modify-flights>

<div class="modal-footer">
  <button class="btn btn-outline-secondary settings-modal-button" type="button" (click)="closeWindow.emit()">Cancel</button>
  <button *ngIf="step === SEARCH" class="btn button-blue settings-modal-button" type="button" (click)="searchFlights()">Search</button>
  <button *ngIf="step === SELECT" class="btn button-blue settings-modal-button" type="button" (click)="selectFlights()">Select</button>
  <button *ngIf="step === MODIFY" class="btn button-blue settings-modal-button" type="button" (click)="modifyFlights()">Save</button>
</div>
