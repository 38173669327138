<form class="children-fs16" [formGroup]="form">
  <div class="row mb-4">
    <div class="col-6 align-self-center fw-bold dark-blue">
      {{leg?.airlineDesignator}}{{leg?.flightNumber}} | {{leg?.std | date:'d MMM' | uppercase }} | {{leg?.departureStation}} {{leg?.std | date:'HH:mm':'+00:00'}} - {{leg?.arrivalStation}} {{leg?.sta | date:'HH:mm':'+00:00'}} | {{leg?.acType}}
    </div>
    <div class="col-6 flex justify-content-end">
      <button class="btn btn-danger divert" [disabled]="!pair.arrivalLeg.takeOffTime" (click)="divertFlight(leg)">Divert Flight</button>
    </div>
  </div>
  <div class="row pb-3">
    <div class="col-6 left flex flex-column gap-3">
      <div class="row">
        <div class="col-7 form-field-header">
          Estimated Time of Departure (ETD)
        </div>
        <div class="col-5">
          <div class="input-group">
            <input class="form-control light-blue-border" [placeholder]="dateFormat"
                   name="dp" formControlName="etdDate" ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar light-blue-border"
                      (click)="d.toggle()" type="button" [disabled]="form.get('etdDate').disabled"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7"></div>
        <div class="col-5">
          <ngb-timepicker formControlName="etdTime" [spinners]="false" [disabled]="true" name="etdTime"></ngb-timepicker>
        </div>
      </div>
      <div class="h-divider"></div>
      <div class="row">
        <div class="col-7 form-field-header">
          Actual Time of Departure (ATD)
        </div>
        <div class="col-5">
          <div class="input-group">
            <input [disabled]="disabled" class="form-control light-blue-border" [placeholder]="dateFormat"
                   name="dp" formControlName="atdDate" ngbDatepicker #d2="ngbDatepicker" (ngModelChange)="localCalculateETA()">
            <div class="input-group-append">
              <button [disabled]="disabled" class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar light-blue-border"
                      (click)="d2.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7"></div>
        <div class="col-5">
          <ngb-timepicker formControlName="atdTime" [readonlyInputs]="disabled" [spinners]="false" name="atdTime" (ngModelChange)="localCalculateETA()"></ngb-timepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-7 form-field-header">
          Take Off Time
        </div>
        <div class="col-5">
          <ngb-timepicker formControlName="totTime" [readonlyInputs]="disabled" [spinners]="false" name="takeoffTime" (ngModelChange)="localCalculateETA()"></ngb-timepicker>
        </div>
      </div>
      <div class="row ">
        <div class="col-7 form-field-header">
          Estimated Time of Arrival (ETA)
        </div>
        <div class="col-5">
          <ngb-timepicker formControlName="etaTime" [readonlyInputs]="disabled" [spinners]="false" name="etaTime"></ngb-timepicker>
        </div>
      </div>
      <div class="row ">
        <div class="col-7 form-field-header">
          Landing Time
        </div>
        <div class="col-5">
          <ngb-timepicker formControlName="lndTime" [spinners]="false" name="landTime"></ngb-timepicker>
        </div>
      </div>
      <div class="row ">
        <div class="col-7 form-field-header">
          Actual Time of Arrival (ATA)
        </div>
        <div class="col-5">
          <ngb-timepicker formControlName="ataTime" [spinners]="false" name="ataTime"></ngb-timepicker>
        </div>
      </div>
      <div class="h-divider"></div>
      <div class="row">
        <div class="col-7 form-field-header">
          Passenger Gate
        </div>
        <div class="col-5">
          <div class="">
            <input type="text" class="form-control light-blue-border" id="arrival-flight-passenger-gate" placeholder=""
                   formControlName="gate">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7 form-field-header">
          Aircraft Stand / Gate
        </div>
        <div class="col-5">
          <div class="">
            <input type="text" class="form-control light-blue-border" id="arrival-flight-aircraft-stand" placeholder=""
                   formControlName="stand">
          </div>
        </div>
      </div>
    </div>
    <!-- Left side end -->
    <div class="col-6 right flex flex-column gap-3">
      <div class="row">
        <div class="col-7 form-field-header">
          Calculated Take Off Time (CTOT)
        </div>
        <div class="col-5 flex justify-content-end">
          <ngb-timepicker formControlName="ctotTime" [spinners]="false" name="ctotTime" [readonlyInputs]="true"></ngb-timepicker>
        </div>
      </div>
      <div class="h-divider"></div>
      <div class="row">
        <div class="col-7 form-field-header align-self-baseline">
          Passenger Information
        </div>
        <div class="col-5">
          <div class="form-field-subheader pe-0 c-flex" formGroupName="seatConfig">
            <div class="row flex"  [formGroupName]="passengerClass.code" *ngFor="let passengerClass of passengerClasses">
              <div class="flex-grow-1 w-auto seat-text">
                {{passengerClass.code}} ({{seatingConfigurations[passengerClass.code] ?? '0'}})
              </div>
              <div class="seat-box position-relative">
                  <app-indicator-text *ngIf="!leg.atd" text="E" width="20px" height="20px" marginLeft="-10px" marginTop="-10px" position="top-left" fontSize="14px" fontWeight="500"></app-indicator-text>
                  <input [readOnly]="disabled" type="number" class="form-control light-blue-border" id="arrival-flight-seating-config-{{passengerClass.key}}" placeholder="" formControlName="description">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-divider"></div>
      <div class="row">
        <div class="col-6 flex flex-column gap-3">
          <div class="row">
            <div class="form-field-header">
              Departure Delays
            </div>
          </div>
          <form class="row" [formGroup]="delay.value"
                *ngFor="let delay of departureDelayListNew | keyvalue">
            <div class="col-7">
                <ng-select formControlName="delayCode">
                 <ng-option *ngFor="let code of delayCodes" [value]="code.id">{{code.code}} {{ code.description }}</ng-option>
                </ng-select>
            </div>
            <div class="col-5">
                <app-time-input formControlName="time" (focusLost)="getRemainingDelay()"></app-time-input>
            </div>
          </form>
        </div>
        <div class="col-6 flex flex-column gap-3">
          <div class="row">
            <div class="form-field-header">
              Arrival Delays
            </div>
          </div>
          <form class="row" [formGroup]="delay.value"
                *ngFor="let delay of arrivalDelayListNew | keyvalue">
            <div class="col-7">
              <ng-select formControlName="delayCode">
                <ng-option *ngFor="let code of delayCodes" [value]="code.id">{{code.code}} {{ code.description }}</ng-option>
              </ng-select>
            </div>
            <div class="col-5">
              <app-time-input formControlName="time" (focusLost)="getRemainingDelay()"></app-time-input>
            </div>
          </form>
          <div class="row">
            <div class="delay-info-box">
              <ng-container *ngIf="maxArrivalDelayMinutes > 0 && currentArrivalDelayMinutes !== maxArrivalDelayMinutes">
                <div class="row">
                  <span class="delay-error">The delay minutes you entered ({{currentArrivalDelayMinutes}}) do not match the current flight delay time ({{ maxArrivalDelayMinutes }}) </span>
                </div>
              </ng-container>
              <ng-container *ngIf="maxDepartureDelayMinutes > 0 && currentDepartureDelayMinutes !== maxDepartureDelayMinutes">
                <div class="row">
                  <span class="delay-error">The delay minutes you entered ({{currentDepartureDelayMinutes}}) do not match the current flight delay time ({{ maxDepartureDelayMinutes }}) </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="h-divider"></div>
      <div class="row">
        <div class="col-7 form-field-header">
          Next Information
        </div>
        <div class="col-5 flex justify-content-end">
          <ngb-timepicker formControlName="niTime" [readonlyInputs]="true" [spinners]="false" name="ni" (ngModelChange)="localCalculateETA()"></ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="h-divider w-100"></div>
  <div class="row">
     <div class="col-12">
       <app-restore-cancel-row [showSpinner]="isBusy" (restoreClicked)="restoreForm()" (saveClicked)="saveForm()" [isSaveDisabled]="form.invalid || maxDepartureDelayMinutes > 0 && currentDepartureDelayMinutes !== maxDepartureDelayMinutes || maxArrivalDelayMinutes > 0 && currentArrivalDelayMinutes !== maxArrivalDelayMinutes"></app-restore-cancel-row>
     </div>
   </div>
</form>
