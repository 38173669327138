import { Component, OnInit } from '@angular/core';

type InterfaceData = {
  name: string;
  status: boolean;
  canEdit: boolean;
}

@Component({
  selector: 'app-settings-interface-management',
  templateUrl: './settings-interface-management.component.html',
  styleUrls: ['./settings-interface-management.component.scss']
})
export class SettingsInterfaceManagementComponent implements OnInit {
  tableData: InterfaceData[] = [];
  constructor() {
    this.tableData.push({
      name: 'Flight Schedule Management (SSIM, ASM, SSM)',
      status: true,
      canEdit: true,
    }, {
      name: 'Actual Information Messages (MVT, ACARS)',
      status: true,
      canEdit: true,
    },
      {
        name: 'Passenger Information (PTM, RESERVATION SYSTEM)',
        status: true,
        canEdit: true,
      },
      {
        name: 'Stands & Gates',
        status: false,
        canEdit: false,
      },
      {
        name: 'A-CDM',
        status: false,
        canEdit: false,
      },
      {
        name: 'Third Party Interfaces',
        status: false,
        canEdit: false,
      });
  }


  ngOnInit() {
  }

}
