import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-deprecated-ta-process',
  templateUrl: './deprecated-ta-process.component.html',
  styleUrls: ['./deprecated-ta-process.component.scss']
})
export class DeprecatedTaProcessComponent implements OnInit {

  @Input() done: boolean;
  @Input() name: string;
  @Input() starttime: string;
  @Input() endtime: string;

  @Input() isSla: boolean;

  constructor() { }

  ngOnInit() {
  }

}
