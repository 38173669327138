import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FlightsFilterService } from '../../../services/flights-filter.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FlightsFilter, IFlightsFilter } from '../../models/filters/flights-filter.model';
import { Subject } from 'rxjs';
import { IUser } from '../../models/user.model';
import { UserRoles } from '../../constants/user-roles.constants';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { AirportsService } from '../../../services/airports.service';

@Component({
  selector: 'app-execution-filter',
  templateUrl: './execution-filter.component.html',
  styleUrls: ['./execution-filter.component.scss']
})
export class ExecutionFilterComponent implements OnInit, OnDestroy {

  @Input() user: IUser;
  @Input() dateFilter: boolean;
  datePlaceholder = 'DD/MM/YYYY'

  filterForm: FormGroup;
  unsubscribe$ = new Subject();
  stations?: string[] = [];

  constructor(private fb: FormBuilder, private flightsFilterService: FlightsFilterService, private generalSettingsService: GeneralSettingsService, private airportService: AirportsService) {
  }

  ngOnInit() {
    this.filterForm = this.createForm();
    this.onChanges(this.filterForm);
    this.airportService.fetchAirports().subscribe((airports) => {
      this.stations.push(...(airports.map((val) => val.iata)));
      if (this.user && this.user.role === UserRoles.DUTY_MANAGER) {
        this.filterForm.patchValue({
          station: this.user.location
        });

        this.filterForm.get('station').disable();
      }
    });

    this.generalSettingsService.getGeneralSettings().pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      this.datePlaceholder = result?.[0]?.dateFormat || 'DD/MM/YYYY';
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    this.flightsFilterService.resetFilters();
  }

  createForm(): FormGroup {
    return this.fb.group({
      timespan: [null],
      date: [null],
      station: [this.user?.role === 'DUTY_MANAGER' ? { value: null, disabled: true } : null],
      status: [null],
      alerts: [null],
      sortBy: [null],
      showUnpaired: [false],
      acRegistration: [''],
      flightNumber: [''],
    });
  }

  onChanges(form: FormGroup) {
    form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((val: IFlightsFilter) => {
      const values = new FlightsFilter(val);
      this.flightsFilterService.setFilterValues(values);
    });
  }

}
