import * as dayjs from 'dayjs'

export function ourTimezone(_, c) {
  c.prototype.customTimezone = function (timezone: string | null) {
    // Local timezone
    if (timezone === null) {
      return this;
    }
    return this.utc();
  }
}

// add custom plugin to dayjs' types
declare module 'dayjs' {
  interface Dayjs {
    customTimezone(timezone: string | string): dayjs.Dayjs;
  }
}
