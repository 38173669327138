import { Component, Input, OnInit } from '@angular/core';
import { ISimpleData } from '../../../../../shared/models/simpleData.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { AddEditDelayCodeDialogComponent } from '../add-edit-delay-code-dialog/add-edit-delay-code-dialog.component';
import { ToastService } from '../../../../../services/toast.service';

@Component({
  selector: 'app-manage-delay-codes-dialog',
  templateUrl: './manage-delay-code-dialog.component.html',
  styleUrls: ['./manage-delay-code-dialog.component.scss']
})
export class ManageDelayCodeDialogComponent implements OnInit {

  @Input() title?: string;
  @Input() delayCodes?: { [p: number]: ISimpleData };
  @Input() readonly = true;
  selected?: string | any;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private generalSettingsService: GeneralSettingsService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.fetchDelayCodes();
  }

  doAdd() {
    const modalRef = this.modalService.open(AddEditDelayCodeDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Add Delay Codes';
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchDelayCodes();
    })
  }

  doEdit(item: string | any) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(AddEditDelayCodeDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Edit Delay Codes';
    modalRef.componentInstance.delayCode = this.delayCodes[item];
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchDelayCodes();
    })
  }

  doDelete(item: string | any) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Delay Code ' + this.delayCodes[item].code;

    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.generalSettingsService.deleteDelayCode(item).subscribe((result) => {
        if(result) {
          this.toastService.showSuccess("The delay code has been deleted.");
          this.fetchDelayCodes();
        }
      });
    });

  }

  fetchDelayCodes() {
    this.generalSettingsService.getDelayCodes().subscribe((result) => {
      this.delayCodes = {};
      for (const delayCode of result) {
        this.delayCodes[delayCode.id] = delayCode;
      }
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

}
