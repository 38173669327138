<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Firstname*
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-firstname" placeholder="" formControlName="firstname">
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Lastname*
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-lastname" placeholder="" formControlName="lastname">
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Phone
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-phone" placeholder="" formControlName="phone">
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Mobile
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-mobile" placeholder="" formControlName="mobile">
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Remarks
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-remarks" placeholder="" formControlName="remarks">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 border-start">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Company*
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-company" placeholder="" formControlName="company">
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Department
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-department" placeholder="" formControlName="department">
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Email
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="email" class="form-control" id="settings-airport-contact-email" placeholder="" formControlName="email">
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              VHF Frequency
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-vhf" placeholder="" formControlName="vhf">
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              SITA Address 1
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-sita-address-1" placeholder="" formControlName="sita1">
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              SITA Address 2
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-airport-contact-sita-address-2" placeholder="" formControlName="sita2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="form?.disabled || form?.invalid || isBusy">{{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
