import * as dayjs from 'dayjs';
import {IFilterListItem} from '../filter-list-item.model';

export interface IFlightsFilter {
  timespan?: string;
  station?: string;
  status?: string;
  alerts?: string;
  sortBy?: string;
  date?: Date;
  showUnpaired?: boolean;
  acRegistration?: string;
  flightNumber?: string;

  getListOfNonEmptyProperties(): IFilterListItem[];
}

export class FlightsFilter implements IFlightsFilter {
  timespan?: string;
  station?: string;
  status?: string;
  alerts?: string;
  sortBy?: string;
  date?: Date;
  showUnpaired?: boolean;
  acRegistration?: string;
  flightNumber?: string;


  constructor(val: IFlightsFilter) {
    this.timespan = val.timespan;
    this.station = val.station;
    this.status = val.status;
    this.alerts = val.alerts;
    this.sortBy = val.sortBy;
    this.date = val.date;
    this.showUnpaired = val.showUnpaired;
    this.acRegistration = val.acRegistration;
    this.flightNumber = val.flightNumber;
  }

  getListOfNonEmptyProperties(): IFilterListItem[] {
    let list: { name: string, operator: string, value: any }[] = [];

    if (this.timespan && this.timespan !== 'null') {
      const from: dayjs.Dayjs = dayjs();
      let until: dayjs.Dayjs;

      switch (this.timespan) {
        case 'next8Hours':
          until = dayjs(from).add(8, 'hours');
          break;
        case 'next12Hours':
          until = dayjs(from).add(12, 'hours');
          break;
        case 'next24Hours':
          until = dayjs(from).add(24, 'hours');
          break;
      }

      list = [
        {name: 'dateOfOperation', operator: '>=', value: new Date(from.unix() * 1000)},
        {name: 'dateOfOperation', operator: '<=', value: new Date(until.unix() * 1000)}
      ];
    }

    if (this.acRegistration?.length) {
      list.push({ name: 'acRegistration', operator: '==', value: this.acRegistration });
    }

    if (this.flightNumber?.length) {
      list.push({ name: 'flightNumber', operator: '==', value: this.flightNumber });
    }

    if (!this.showUnpaired) {
      list.push({name: 'groundTime', operator: '!=', value: 0});
    }

    if (this.station && this.station !== 'null') {
      list.push({name: 'airportCode', operator: '==', value: this.station});
    }

    if (this.status && this.status !== 'null') {
      list.push({name: 'status', operator: '==', value: this.status});
    }

    if (this.alerts && this.alerts !== 'null') {
      list.push({name: 'hasAlerts', operator: '==', value: this.alerts === 'true'});
    }
    /*
    if (this.sortBy && this.sortBy !== 'null') {
      list = [...list, {name: 'sortBy', operator: '==', value: this.sortBy}];
    } */

    return list;
  }

}
