import { Component, Input, OnInit } from '@angular/core';
import { IAirport } from '../../../../shared/models/airport.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewTypes } from '../../../../shared/constants/viewTypes.constants';
import { TitleCasePipe } from '@angular/common';
import { IGenericContainerObject } from '../../../../shared/models/genericContainerObject.model';
import { ISimpleData } from '../../../../shared/models/simpleData.model';
import { AirportsService } from '../../../../services/airports.service';
import { SettingsService } from '../../../../services/settings.service';
import { ManageAirportTerminalDialogComponent } from '../dialogs/manage-airport-terminal-dialog/manage-airport-terminal-dialog.component';
import { ViewAirportTimezoneDialogComponent } from '../dialogs/view-airport-timezone-dialog/view-airport-timezone-dialog.component';
import { ManageAirportContactDialogComponent } from '../dialogs/manage-airport-contact-dialog/manage-airport-contact-dialog.component';
import { ViewAirportSlotTimeToleranceDialogComponent } from '../dialogs/view-airport-slot-time-tolerance/view-airport-slot-time-tolerance-dialog.component';
import { ManageAirportCurfewTimeDialogComponent } from '../dialogs/manage-airport-curfew-time-dialog/manage-airport-curfew-time-dialog.component';

import { ViewAirportGlobalTurnaroundTimeDialogComponent } from '../dialogs/view-airport-global-turnaround-time-dialog/view-airport-global-turnaround-time-dialog.component';
import { IGeneralSettingsModel } from '../../../../shared/models/general-settings.model';

@Component({
  selector: 'app-settings-airport-management-list-item',
  templateUrl: './settings-airport-management-list-item.component.html',
  styleUrls: ['./settings-airport-management-list-item.component.scss']
})
export class SettingsAirportManagementListItemComponent implements OnInit {

  @Input() item: IAirport;
  @Input() flightServiceTypeContainer: IGenericContainerObject<ISimpleData>;
  @Input() opened: boolean;
  @Input() generalSettings?: IGeneralSettingsModel;

  constructor(private modalService: NgbModal, private titleCasePipe: TitleCasePipe) { }

  ngOnInit() {
  }

  openViewAirportTerminals() {
    const modalRef = this.modalService.open(ManageAirportTerminalDialogComponent, { size: 'lg'});
    const modal: ManageAirportTerminalDialogComponent = modalRef.componentInstance;
    modalRef.componentInstance.title = 'View Airport Terminals';
    modalRef.componentInstance.readonly = true;
    modal.airportId = this.item.id;
  }

  openViewAirportTimezone() {
    const modalRef = this.modalService.open(ViewAirportTimezoneDialogComponent, { size: 'xl' as 'lg'});
    modalRef.componentInstance.airportId = this.item?.id;
    modalRef.componentInstance.title = 'View Airport Time Zone';
  }

  openViewAirportContacts() {
    const modalRef = this.modalService.open(ManageAirportContactDialogComponent, { size: 'xl' as 'lg'});
    modalRef.componentInstance.title = 'View Airport Contacts';
    modalRef.componentInstance.airportId = this.item?.id;
    modalRef.componentInstance.readonly = true;
  }

  openViewAirportMinimalTurnaroundTimes() {
    const modalRef = this.modalService.open(ViewAirportGlobalTurnaroundTimeDialogComponent, { size: 'xxl' as 'lg'});
    modalRef.componentInstance.title = 'View Airport ' + this.titleCasePipe.transform(`${this.generalSettings?.turnaroundTimeType}`) + ' Turnaround Times';
    modalRef.componentInstance.viewType = ViewTypes.SETTINGS_VIEW_AIRPORT_MINIMAL_TURNAROUND_TIMES;

    modalRef.componentInstance.data = {
      flightServiceTypes: this.flightServiceTypeContainer,
    };
  }

  openViewAirportCurfewTimes() {
    const modalRef = this.modalService.open(ManageAirportCurfewTimeDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'View Airport Curfew Times';
    modalRef.componentInstance.viewType = ViewTypes.SETTINGS_VIEW_AIRPORT_CURFEW_TIMES;
  }

  openViewSlotTimeTolerance() {
    const modalRef = this.modalService.open(ViewAirportSlotTimeToleranceDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'View Slot Time Tolerance';
    modalRef.componentInstance.airportId = this.item?.id;
  }

}
