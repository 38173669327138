<div class="modal-header">
  <span class="modal-title">View Turnaround Threshold</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-settings-view-tolerance-threshold-bar topLabel1="Minimum Turnaround Time" topLabel2="Standard Turnaround Time" bottomLabel2="Turnaround Threshold at {{ this.settings.turnaroundThresholdInMinutes | timeFromMinutes }}" [turnaroundType]="settings.turnaroundTimeType"></app-settings-view-tolerance-threshold-bar>
      </div>
    </div>
  </div>
</div>
