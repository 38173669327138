import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-simple-data-add-edit-form',
  templateUrl: './simple-data-add-edit-form.component.html',
  styleUrls: ['./simple-data-add-edit-form.component.scss']
})
export class SimpleDataAddEditFormComponent implements OnInit {

  @Input() form?: FormGroup;
  @Input() outerSaveClicked?: EventEmitter<any>;
  @Output() saveClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.outerSaveClicked?.pipe(take(1)).subscribe(() => this.saveClicked.emit(this.form));
  }
}
