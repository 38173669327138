import { Component, Input, OnInit } from '@angular/core';
import { DefinedGses } from '../../constants/defined-gses';

@Component({
  selector: 'app-equipment-icon',
  templateUrl: './equipment-icon.component.html',
  styleUrls: ['./equipment-icon.component.scss']
})
export class EquipmentIconComponent implements OnInit {

  @Input() equipment: string;
  @Input() amount: number;
  class?: string;

  constructor() { }

  ngOnInit() {
    this.class = DefinedGses[this.equipment]?.class;
  }

}
