import {Injectable} from '@angular/core';
import {NgbTimeAdapter, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NumericTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    let hour = 0;
    let minute = 0;
    const second = 0;

    if (value !== '0' && value.length > 2) {
      if (value.length === 4) {
        hour = parseInt(value.slice(0, 2), 10);
      } else if (value.length === 3) {
        hour = parseInt(value.slice(0, 1), 10);
      }
      minute = parseInt(value.slice(-2), 10);
    }

    return {
      hour,
      minute,
      second
    };
  }

  toModel(time: NgbTimeStruct): string | null {
    if (!time) {
      return null;
    }
    // return `${this.pad(time.hour)}:${this.pad(time.minute)}:${this.pad(time.second)}`;
    return `${time.hour}${this.pad(time.minute)}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}
