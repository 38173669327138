import { Component, Input, OnInit } from '@angular/core';
import { ITimezone } from '../../../../../shared/models/timezone.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AirportsService } from '../../../../../services/airports.service';

@Component({
  selector: 'app-view-airport-timezone-dialog',
  templateUrl: './view-airport-timezone-dialog.component.html',
  styleUrls: ['./view-airport-timezone-dialog.component.scss']
})
export class ViewAirportTimezoneDialogComponent implements OnInit {

  @Input() title?: string;
  @Input() airportId?: number;
  timezoneData?: ITimezone;
  constructor(private activeModal: NgbActiveModal, private airportsService: AirportsService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  fetchData() {
    this.airportsService.fetchAirportTimezones(this.airportId).subscribe((result) => {
      this.timezoneData =  result[0] || {};
    });
  }
}
