/* tslint:disable:no-string-literal */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {Equipment} from '../../../constants/equipment.constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IFlightPair} from '../../../models/flight-pair.model';
import * as dayjs from 'dayjs';
import {interval, Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ISimpleData} from '../../../models/simpleData.model';
import {IPairProcessAndEquipment} from '../../../models/pairProcessAndEquipment.model';
import {TurnaroundStatus} from '../../../constants/turnaround-status.constants';
import {RemainingTimeCalculator} from '../../../utils/remaining-time.calculator';
import {of} from 'rxjs/internal/observable/of';
import {AuthService} from '../../../../services/auth.service';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {IUser} from '../../../models/user.model';
import {UserRoles} from '../../../constants/user-roles.constants';
import { ChangeFlightDetailsDialogComponent } from '../../flight-details/change-flight-details-dialog/change-flight-details-dialog.component';
import { PairsProcessesService } from 'src/app/services/pairs-processes.service';
import { GseService } from 'src/app/services/gse.service';
import { ProcessesService } from 'src/app/services/processes.service';
import { ViewMemosDialogComponent } from '../../view-memos-dialog/view-memos-dialog.component';
import { IssueNewInfoDialogComponent } from '../../issue-new-info-dialog/issue-new-info-dialog.component';
import { IssueEtdDialogComponent } from '../../issue-etd-dialog/issue-etd-dialog.component';
import { PairsService } from '../../../../services/pairs.service';
import { IPairDetail } from '../../../models/pair-detail.model';
import { IPairOverview } from '../../../models/pair-overview.model';
import { ConfirmResumeTurnaroundDialogComponent } from './confirm-resume-turnaround-dialog/confirm-resume-turnaround-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leg-detail',
  templateUrl: './leg-detail.component.html',
  styleUrls: ['./leg-detail.component.scss']
})
export class LegDetailComponent implements OnInit, OnDestroy, OnChanges {

  _flightPair: IFlightPair;
  user: IUser;
  @Input() pairOverview: IPairOverview;
  @Input() pairDetail: IPairDetail;
  @Input() refreshSql: EventEmitter<any>;


  @Input() isActive: boolean;
  @Input() equipmentList: Equipment[];
  @Input() timezone: string;
  @Input() passengerClasses: ISimpleData[];
  @Input() pairId: number;

  @Input()
  set flightPair(fp: IFlightPair) {
    this._flightPair = fp;
    this.cleanedGses = this.getCleanedGses(fp);
  }

  get flightPair(): IFlightPair {
    return this._flightPair;
  }
  @Output() pairDetailChange = new EventEmitter();


  unsubscribe$ = new Subject();

  cleanedGses: IPairProcessAndEquipment[];

  turnaroundStates = TurnaroundStatus;
  groundTime?: number;

  remainingTimeInMinutes: number;
  totalTimeInMinutes: number;
  turnaroundProgressPercentage: number;
  turnaroundProgressBarColor: string;

  constructor(private modalService: NgbModal, private authService: AuthService, private fns: AngularFireFunctions, private gseService: GseService, private processService: ProcessesService, private pairProcessService: PairsProcessesService, private pairsService: PairsService, private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.pairDetail) {
      this.calculateGroundTime();
    }
  }

  ngOnInit(): void {
    if(!this.pairDetail)
    {
      this.fetchData();
    }
    else if(this.pairDetail.departureLeg?.landingTime) {
      this.calculateGroundTime();
    }
    this.subscribeToCurrentUser();
    this.updateRemainingTime();

    interval(10000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateRemainingTime();
    });

  }

  calculateGroundTime()
  {
    if(!this.pairDetail?.departureLeg?.atd || !this.pairDetail?.arrivalLeg?.ata) {
      this.groundTime = undefined;
      return;
    }
    this.groundTime = dayjs(this.pairDetail?.departureLeg?.atd).diff(dayjs(this.pairDetail?.arrivalLeg?.ata), 'minutes');
  }

  fetchData() {
    this.pairsService.getPairDetail(this.pairId).subscribe((result) => {
      this.pairDetail = result;
      this.pairDetailChange.emit(this.pairDetail);
      this.updateRemainingTime();
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  subscribeToCurrentUser() {
    this.authService.userSubject.asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => this.user = user);
  }

  updateRemainingTime() {
    console.log('updateRemainingTime got called');

    if (!this.pairDetail || !this.pairDetail.departureLeg || !this.pairDetail.turnaround) {
      console.log('A1');
      this.remainingTimeInMinutes = 0;
      this.totalTimeInMinutes = 0;
      this.turnaroundProgressPercentage = 0;
      this.turnaroundProgressBarColor = 'primary';
      return;
    }

    this.remainingTimeInMinutes = RemainingTimeCalculator.calcRemainingTime(this.pairDetail?.departureLeg?.tod?.toString());
    this.totalTimeInMinutes = RemainingTimeCalculator.calcTotalTime(this.pairDetail?.turnaround, this.pairDetail?.departureLeg?.tod?.toString());

    console.log('Remaining: ', this.remainingTimeInMinutes);
    console.log('Total: ', this.totalTimeInMinutes);

    if (this.pairDetail.turnaround.currentState === this.turnaroundStates.FINISHED) {
      this.turnaroundProgressPercentage = 100;
    } else if (this.totalTimeInMinutes === 0) {
      this.turnaroundProgressPercentage = 0;
    } else {
      this.turnaroundProgressPercentage = ((this.totalTimeInMinutes - this.remainingTimeInMinutes) / this.totalTimeInMinutes) * 100;
    }


    if (!this.pairDetail || !Object.keys(this.pairDetail.turnaround).length || !this.pairDetail.turnaround.currentState
      || this.pairDetail.turnaround.currentState === this.turnaroundStates.UPCOMING
      || this.pairDetail.turnaround.currentState === this.turnaroundStates.IN_PROGRESS
      || this.pairDetail.turnaround.currentState === this.turnaroundStates.RESUMED) {
      this.turnaroundProgressBarColor = 'primary';
    } else if (this.pairDetail.turnaround.currentState === this.turnaroundStates.PAUSED) {
      this.turnaroundProgressBarColor = 'warning';
    } else if (this.pairDetail.turnaround.currentState === this.turnaroundStates.FINISHED) {
      this.turnaroundProgressBarColor = 'success';
    }
  }

  calcTurnaroundRemainingTimeText(arrivalAta: string, departureStd: string, departureEtd: string, departureAtd: string): string {
    if (!arrivalAta || !dayjs(arrivalAta).isValid()) {
      return 'No arrival leg ATA given yet';
    }

    if (departureAtd && dayjs(departureAtd).isValid()) {
      const departureAtdDatetime = dayjs(departureAtd);
      const remainingSeconds = departureAtdDatetime.diff(dayjs(), 'seconds');

      if (0 >= remainingSeconds) {
        return 'finished';
      }

      return Math.floor(remainingSeconds / 60) + ':' + ('0' + (remainingSeconds % 60)).slice(-2) + ' remaining to ATD / TOBT';


    } else if (departureEtd && dayjs(departureEtd).isValid()) {
      const departureEtdDatetime = dayjs(departureEtd);
      const remainingSeconds = departureEtdDatetime.diff(dayjs(), 'seconds');

      if (0 >= remainingSeconds) {
        return 'finished although no ATD is given yet';
      }

      return Math.floor(remainingSeconds / 60) + ':' + ('0' + (remainingSeconds % 60)).slice(-2) + ' remaining to ETD';

    } else if (departureStd && dayjs(departureStd).isValid()) {
      const departureStdDatetime = dayjs(departureStd);
      const remainingSeconds = departureStdDatetime.diff(dayjs(), 'seconds');

      if (0 >= remainingSeconds) {
        return 'finished although no ATD is given yet';
      }

      return Math.floor(remainingSeconds / 60) + ':' + ('0' + (remainingSeconds % 60)).slice(-2) + ' remaining to STD';

    } else {
      return 'No time of departure given yet';
    }
  }

  getCleanedGses(fp: IFlightPair): IPairProcessAndEquipment[] {
    if (fp && fp.processes && Object.values(fp.processes) && Object.values(fp.processes).length > 0) {
      return Object.values(fp.processes).filter((item: IPairProcessAndEquipment) => item && item.hasGse);
    }
    return null;
  }

  calcTurnaroundProgress(arrivalAta: string, departureStd: string, departureEtd: string, departureAtd: string):
    { text: string, total: string, percentage: number } {
    let text = '-';
    let total = '-';
    let percentage = 0;

    if (!arrivalAta || !dayjs(arrivalAta).isValid()) {
      return {text: 'No arrival leg ATA given yet', total, percentage};
    }

    const arrivalAtaDatetime = dayjs(arrivalAta);

    if (departureAtd && dayjs(departureAtd).isValid()) {
      const departureAtdDatetime = dayjs(departureAtd);
      const remainingSeconds = departureAtdDatetime.diff(dayjs(), 'seconds');
      const totalSeconds = departureAtdDatetime.diff(arrivalAtaDatetime, 'seconds');

      total = this.formatSecondsToTime(totalSeconds);

      if (0 >= remainingSeconds) {
        text = 'finished';
        percentage = 100;
      } else {
        text = this.formatSecondsToTime(remainingSeconds) + ' remaining to ATD / TOBT';
        percentage = (1 - remainingSeconds / totalSeconds) * 100;
      }
    } else if (departureEtd && dayjs(departureEtd).isValid()) {
      const departureEtdDatetime = dayjs(departureEtd);
      const remainingSeconds = departureEtdDatetime.diff(dayjs(), 'seconds');
      const totalSeconds = departureEtdDatetime.diff(arrivalAtaDatetime, 'seconds');

      total = this.formatSecondsToTime(totalSeconds);

      if (0 >= remainingSeconds) {
        text = 'finished although no ATD is given yet';
        percentage = 100;
      } else {
        text = this.formatSecondsToTime(remainingSeconds) + ' remaining to ETD';
        percentage = (1 - remainingSeconds / totalSeconds) * 100;
      }
    } else if (departureStd && dayjs(departureStd).isValid()) {
      const departureStdDatetime = dayjs(departureStd);
      const remainingSeconds = departureStdDatetime.diff(dayjs(), 'seconds');
      const totalSeconds = departureStdDatetime.diff(arrivalAtaDatetime, 'seconds');

      total = this.formatSecondsToTime(totalSeconds);

      if (0 >= remainingSeconds) {
        text = 'finished although no ATD is given yet';
        percentage = 100;
      } else {
        text = this.formatSecondsToTime(remainingSeconds) + ' remaining to STD';
        percentage = (1 - remainingSeconds / totalSeconds) * 100;
      }

    } else {
      text = 'No time of departure given yet';
    }

    return {text, total, percentage};
  }

  formatSecondsToTime(seconds: number): string {
    if (seconds <= 0) {
      return null;
    }
    return Math.floor(seconds / 60) + ':' + ('0' + (seconds % 60)).slice(-2);
  }

  openMemo() {

    if (!this.user || !(this.user.role === UserRoles.OPS_CONTROLLER || this.user.role === UserRoles.DUTY_MANAGER || this.user.role === UserRoles.ADMIN)) {
      console.log('stopped opening view memo modal because no permission was granted to the users role!');
      return;
    }

    const modalRef = this.modalService.open(ViewMemosDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as ViewMemosDialogComponent;
    modal.title = 'View Memo';
    modal.userRole = this.user.role;
    modal.pair = this.pairDetail;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.pairsService.getPairDetail(this.pairDetail.id).subscribe((result) => {
        if (result?.id) {
          Object.assign(this.pairDetail, result);
        }
        this.pairOverview.hasMemo = result.hasMemo;
      });
    });
  }

  openResumeTurnaroundConfirmationModal() {
    const modalRef = this.modalService.open(ConfirmResumeTurnaroundDialogComponent);
    const modal: ConfirmResumeTurnaroundDialogComponent = modalRef.componentInstance;
    modal.title = 'Confirm Resume Turnaround';
    modal.pairId = this.pairId;
  }

  openChangeFlightDetails() {
    const modalRef = this.modalService.open(ChangeFlightDetailsDialogComponent, {size: 'xl', modalDialogClass: 'min-w-1100' });
    const modal = (modalRef.componentInstance as ChangeFlightDetailsDialogComponent);
    modal.title = 'Change Flight Details';
    modal.pair = this.pairDetail ?? (this.pairOverview as any as IPairDetail);
    modal.timezone = this.timezone;
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (this.router.url.includes("flights/all")) {
        if (reason && reason === 'repair') {
          this.refreshSql.emit();
          return;
        }
        this.fetchData();
      }
    });
  }

  openIssueETD() {
    const modalRef = this.modalService.open(IssueEtdDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as IssueEtdDialogComponent;
    modalRef.componentInstance.title = 'Issue ETD';
    modal.pair = this.pairDetail;
    modal.leg = this.pairDetail.departureLeg;
    modal.pairOverview = this.pairOverview;
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (this.router.url.includes("flights/all")) {
        if (reason && reason === 'repair') {
          this.refreshSql.emit();
          return;
        }
        this.fetchData();
      }
    })
  }

  openIssueNI() {
    const modalRef = this.modalService.open(IssueNewInfoDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as IssueNewInfoDialogComponent;

    modal.title = 'Issue new information';
    modal.pair = this.pairDetail;

    //modalRef.componentInstance.formType = FormTypes.FLIGHTS_ISSUE_NEW_INFORMATION;
    //modalRef.componentInstance.data = this.flightPair;
  }

  private resumeTurnaround(user: string, pairKey: string): Observable<any> {
    if (!user || !pairKey) {
      return of(false);
    }

    const params = {
      newState: TurnaroundStatus.RESUMED,
      user,
      pairKey,
      datetime: dayjs.utc().format()
    };
    const callable = this.fns.httpsCallable('rampChangeTurnaroundState');
    return callable(params);
  }

}
