<!--<div class="settings-container flex flex-column">-->
<!--  <div class="item-list" *ngFor="let item of processes | keyvalue">-->
<!--    <app-settings-list-->
<!--      [opened]="selected === item?.key"-->
<!--      [noOpenedContent]="true"-->
<!--      (editClicked)="doEdit(item?.key)"-->
<!--      (deleteClicked)="doDelete(item?.key)">-->

<!--      <app-settings-sla-gse-management-list-item-->
<!--        [opened]="selected === item?.key"-->
<!--        [item]="item?.value"-->
<!--        (click)="selected = item?.key"-->
<!--        [icons]="processIcons[item.value.id]">-->
<!--      </app-settings-sla-gse-management-list-item>-->

<!--    </app-settings-list>-->
<!--  </div>-->
<!--  <app-add-row (addClicked)="doAdd()"></app-add-row>-->
<!--</div>-->
<ng-container *ngIf="tableData">
  <app-settings-header text="SLA and GSE Management"></app-settings-header>
<app-settings-card sectionTitle="Turnaround Process" addText="Add Process" (onAddClicked)="addClicked()">
  <app-settings-data-table [data]="tableData" [columns]="['Name', 'GSE Required', 'GSE Icon', 'SLA / Non-SLA', 'Max. Process Time']" [variableNames]="['name', 'gseRequired', 'gseIcon', 'sla', 'maxTime']" (onEditClicked)="editClicked($event)" emitProperty="gseId" (onDeleteClicked)="doDelete($event)">
    <ng-template propName="gseIcon" let-icon>
      <i [class]="'demo-icon vertical-middle align-self-center ' + getIconClass(icon) + ' fs-30'" [style]="{ color: '#aaa' }"></i>
    </ng-template>
  </app-settings-data-table>
</app-settings-card>
</ng-container>
