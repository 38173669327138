import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-indicator-dot',
  templateUrl: './indicator-dot.component.html',
  styleUrls: ['./indicator-dot.component.scss']
})
export class IndicatorDotComponent implements OnInit {

  @Input() position: string;
  @Input() size: string;

  constructor() { }

  ngOnInit() {
  }

}
