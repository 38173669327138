<div class="row" *ngIf="!pax || pax.length === 0">
  <div class="col-12">none</div>
</div>
<div *ngIf="pax" class="custom-container">
  <div class="flex flex-column text-start">
    <ng-container *ngFor="let paxItem of pax">
      <span class="text-nowrap">{{paxItem?.code}}: {{paxItem?.amount}}</span>
    </ng-container>
  </div>
</div>
