import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { LegsService } from '../../../services/legs.service';
import { ToastService } from '../../../services/toast.service';
import { FlightsReturnToRampFormBuilder } from '../../forms/formBuilders/flights/flights-return-to-ramp-form-builder';
import { ILegsModel } from '../../models/legs.model';
import * as dayjs from 'dayjs';
import { dayjsToNgbDate, ngbDateToDayjs } from '../../utils/utils';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-return-to-ramp-airborne-dialog',
  templateUrl: './return-to-ramp-airborne-dialog.component.html',
  styleUrls: ['./return-to-ramp-airborne-dialog.component.scss']
})
export class ReturnToRampAirborneDialogComponent implements OnInit, OnDestroy {
  title?: string;
  leg?: ILegsModel;
  type?: string;
  form?: FormGroup;
  isBusy = false;
  dateFormat = 'dd/mm/yyyy';
  unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private activeModal: NgbActiveModal,
    private generalSettingsService: GeneralSettingsService,
    private legService: LegsService,
    ) { }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.generalSettingsService.generalSettings.pipe(takeUntil(this.unsubscribe$)).subscribe((settings) => {
      this.dateFormat = settings.dateFormat;
    });

    this.generateForm();
    this.patchForm();
    this.form?.markAsDirty();

  }

  closeModal(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  generateForm() {
    this.form = FlightsReturnToRampFormBuilder.constructForm(this.fb, this.dateFormat);
  }

  patchForm() {
    let predefinedEta = null;
    if (this.leg && this.leg.tod && dayjs(this.leg.tod).isValid()) {
      predefinedEta = dayjs(this.leg.tod).utc();
    }
    this.form?.patchValue({
      etaDate: predefinedEta ? dayjsToNgbDate(predefinedEta) : null,
      ataDate: predefinedEta ? dayjsToNgbDate(predefinedEta) : null,
      std: this.leg && this.leg.std ? this.leg.std : null
    });
  }


  async onSaveClick() {
    //IMPORTANT : The flight MUST have atd in order to assign the new eta or ata
    if (!this.form || this.form.invalid || !this.leg) {
      return;
    }
    this.isBusy = true;
    const formValue = this.form.value;
    let etaRRorFR;
    let ataRRorFR;
    let flightNumberRRorFR;
    if (formValue.etaTime) {
      const etaTimeSplit = formValue.etaTime.split(':');
      etaRRorFR = ngbDateToDayjs(formValue.etaDate)?.utc(true).hour(etaTimeSplit[0]).minute(etaTimeSplit[1]).toDate() ?? null;
    }
    if (formValue.ataTime) {
      const ataTimeSplit = formValue.ataTime.split(':');
      ataRRorFR = ngbDateToDayjs(formValue.ataDate)?.utc(true).hour(ataTimeSplit[0]).minute(ataTimeSplit[1]).toDate() ?? null;

    }
    if(this.type === 'RR') {
      if (this.leg.flightNumber.length === 4 && this.leg.flightNumber[3] !== 'R') {
        flightNumberRRorFR = this.leg.flightNumber.slice(0, -1) + 'R';
      } else  {
        flightNumberRRorFR = this.leg.flightNumber.concat('R');
      }
    }
    if(this.type === 'FR') {
      if (this.leg.flightNumber.length === 4 && this.leg.flightNumber[3] !== 'A') {
        flightNumberRRorFR = this.leg.flightNumber.slice(0, -1) + 'A';
      } else  {
        flightNumberRRorFR = this.leg.flightNumber.concat('A');
      }
    }

    const legRRorFR = {
      ...this.leg,
      id: undefined,
      arrivalDelays: undefined,
      departureDelays: undefined,
      arrivalStation: this.leg.departureStation,
      arrivalStationId: this.leg.departureStationId,
      arrivalGate: this.leg.departureGate,
      arrivalAcStand: this.leg.arrivalAcStand,
      flightNumber: flightNumberRRorFR,
      std: this.leg.std,
      sta: etaRRorFR ?? ataRRorFR,
      tod: this.leg.tod,
      toa: etaRRorFR ?? ataRRorFR,
      eta: etaRRorFR ?? null,
      etd: this.leg.etd ?? null,
      ata: ataRRorFR ?? null,
      atd: this.leg.atd ?? null,
      lastChangedAt: new Date(),
    };
    this.leg.lastChangedAt = new Date();
    await firstValueFrom(this.legService.returnToRampAirborne({leg: legRRorFR , si: formValue.si, oldLeg: this.leg}));

    this.toastService.showSuccess('Leg has been saved');

    this.closeModal('repair');
    this.isBusy = false;
  }
}
