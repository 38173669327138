import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { PairsService } from '../../../services/pairs.service';
import { LegsService } from '../../../services/legs.service';

@Component({
  selector: 'app-database-actions',
  templateUrl: './database-actions.component.html',
  styleUrls: ['./database-actions.component.scss']
})
export class DatabaseActionsComponent implements OnInit {

  isDeletingJobs: boolean;
  isDeletingLegs: boolean;
  isDeletingPairs: boolean;
  isDeletingLegsAndPairs: boolean;
  isDeletingSchedules: boolean;

  isAlteringPairs: boolean;

  deleteJobsSuccess: boolean;
  deleteLegsSuccess: boolean;
  deletePairsSuccess: boolean;
  deleteSchedulesSuccess: boolean;

  constructor(private fireDb: AngularFireDatabase, private firestore: AngularFirestore, private fns: AngularFireFunctions, private pairsService: PairsService, private legsService: LegsService) {
  }

  ngOnInit() {
  }

  // alterAllPairs(shouldAddThem: boolean) {
  //
  //   // testAlteringAllPairsGses
  //   const callable = this.fns.httpsCallable('testAlteringAllPairsGses');
  //   const params = shouldAddThem ? 'yes' : 'no';
  //   const data$ = callable(params);
  //
  //   this.isAlteringPairs = true;
  //
  //   data$.subscribe(item => {
  //     this.isAlteringPairs = false;
  //     console.log(item);
  //   });
  // }

  // deleteSchedules() {
  //   this.isDeletingSchedules = true;
  //   this.fireDb.object('/planning/schedules').remove()
  //     .then(() => {
  //       console.log('schedules removed successfully');
  //       this.isDeletingSchedules = false;
  //       this.deleteSchedulesSuccess = true;
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       this.isDeletingSchedules = false;
  //       this.deleteSchedulesSuccess = false;
  //     });
  // }
  //
  // deleteJobs() {
  //   this.isDeletingJobs = true;
  //   this.fireDb.object('/planning/bulkJob').remove()
  //     .then(() => {
  //       console.log('bulkJobs removed successfully');
  //       this.isDeletingJobs = false;
  //       this.deleteJobsSuccess = true;
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       this.isDeletingJobs = false;
  //       this.deleteJobsSuccess = false;
  //     });
  // }

  deletePairs() {
    this.isDeletingPairs = true;
    this.pairsService.deleteAll().subscribe((result) => {
      this.isDeletingPairs = false;
      this.deletePairsSuccess = result;
    });

    // const unsubscribePairs$ = new Subject();
    //
    // const unsubscribe1$ = new Subject();
    // const unsubscribe2$ = new Subject();
    //
    // let isDeletingRtdb = true;
    // let isDeletingFsdb = true;
    //
    // let heatbeat = true;
    //
    // unsubscribePairs$.pipe(take(1)).subscribe(() => {
    //   console.log('pairs exit push sent');
    //   unsubscribe1$.next(null);
    //   unsubscribe1$.complete();
    //   unsubscribe2$.next(null);
    //   unsubscribe2$.complete();
    //   this.isDeletingPairs = false;
    // });
    //
    // this.unsubscribe$.pipe(take(1)).subscribe(() => {
    //   console.log('global exit push sent');
    //   unsubscribePairs$.next(null);
    //   unsubscribePairs$.complete();
    // });
    //
    // interval(10000).pipe(takeUntil(unsubscribePairs$)).subscribe(() => {
    //   if (!heatbeat) {
    //     unsubscribePairs$.next(null);
    //     unsubscribePairs$.complete();
    //   } else {
    //     heatbeat = false;
    //   }
    // });
    //
    // this.isDeletingPairs = true;
    //
    // const firestoreList$ = this.firestore.collection<IFlatPair>('plannedPairs', ref => ref).valueChanges();
    //
    // const list$ = this.fireDb.list('/planning/pairs', ref => ref.limitToFirst(1)).valueChanges();
    //
    //
    // list$.pipe(takeUntil(unsubscribe1$)).subscribe(item => {
    //
    //   console.log('B item ', item);
    //
    //   if (item) {
    //     console.log('A itemlength ', item.length);
    //   }
    //
    //   if (item && item.length > 0) {
    //
    //     isDeletingRtdb = true;
    //
    //     item.forEach((item2: IFlightPair) => {
    //       const arrivalLegKey = (item2.arrivalLegKey) ? item2.arrivalLegKey : 'null';
    //       const departureLegKey = (item2.departureLegKey) ? item2.departureLegKey : 'null';
    //       const pairKey = '' + arrivalLegKey + '-' + departureLegKey;
    //
    //       this.fireDb.object('/planning/pairs/' + pairKey).set(null)
    //         .then(() => {
    //           console.log('pair removed with key: ' + pairKey);
    //           heatbeat = true;
    //         })
    //         .catch(err => {
    //           console.log('error: ', err);
    //           isDeletingRtdb = false;
    //           this.isDeletingPairs = false;
    //           this.deletePairsSuccess = false;
    //           unsubscribe1$.next(null);
    //         });
    //     });
    //   } else {
    //     console.log('rtdb deletion has finished');
    //     isDeletingRtdb = false;
    //
    //     if (!isDeletingFsdb) {
    //       unsubscribePairs$.next(null);
    //       unsubscribePairs$.complete();
    //       this.isDeletingPairs = false;
    //       this.deletePairsSuccess = true;
    //     }
    //   }
    // });
    //
    // firestoreList$.pipe(takeUntil(unsubscribe2$))
    //   .subscribe(item => {
    //     console.log('firestore length - ', item.length);
    //
    //     if (item && item.length > 0 && item[0].id) {
    //
    //       /*
    //       if (!isDeletingFsdb) {
    //         isDeletingFsdb = true;
    //       } */
    //
    //       this.firestore.doc(`plannedPairs/${item[0].id}`).delete().then(() => {
    //         console.log(`firestore - plannedPairs/${item[0].id} got deleted`);
    //         heatbeat = true;
    //       })
    //         .catch(err => {
    //           console.log('error: ', err);
    //           isDeletingFsdb = false;
    //           this.deletePairsSuccess = false;
    //           unsubscribe2$.next(null);
    //           unsubscribe2$.complete();
    //         });
    //     } else if (item && item.length === 0) {
    //       console.log('fsdb deletion has finished');
    //       isDeletingFsdb = false;
    //
    //       if (!isDeletingRtdb) {
    //         unsubscribePairs$.next(null);
    //         unsubscribePairs$.complete();
    //         this.isDeletingPairs = false;
    //         this.deletePairsSuccess = true;
    //       }
    //     }
    //   });
  }

  deleteLegs() {
    // this.isDeletingLegs = true;
    // this.fireDb.object('/planning/legs').remove()
    //   .then(() => {
    //     console.log('legs removes successfully');
    //     this.isDeletingLegs = false;
    //     this.deleteLegsSuccess = true;
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     this.isDeletingLegs = false;
    //     this.deleteLegsSuccess = false;
    //   });

    this.isDeletingLegs = true;
    this.legsService.deleteAll().subscribe((result) => {
      this.isDeletingLegs = false;
      this.deleteLegsSuccess = result;
    });
  }

  // abortDeletion() {
  //   this.unsubscribe$.next(null);
  //   this.isDeletingPairs = false;
  // }

  deleteLegsPairsFirestore() {
    this.isDeletingLegsAndPairs = true;
    this.legsService.deleteAllAndFirestore().subscribe((result) => {
      this.isDeletingLegsAndPairs = false;
      this.deleteLegsSuccess = result;
    });
  }
}
