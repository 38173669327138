<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup">
      <div class="container">
        <div class="row">
          <div class="col-6 form-field-header">
            Airport Name*
          </div>
          <div class="col-6">
            <div class="mb-3">
              <span *ngIf="!isNew" class="form-field-content">{{this.airportId === 0 ? 'Default Airports' : this.airportList[this.airportId]?.title}}</span>

              <select *ngIf="isNew" class="form-control form-select" id="arrival-flight-departure-delay-code-1" formControlName="airportName">
                <option *ngFor="let airport of airportList | keyvalue" [value]="airport.value.id" selected>{{airport?.value?.title}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 form-field-header">
            Process applicable for {{acTypeId ? acTypeId === 0 ? 'Default A/C Types' : this.acTypeList[this.acTypeId].alias : 'new A/C Type'}} in {{this.airportList[this.airportId]?.title ? this.airportList[this.airportId]?.title === 'Default' ? 'Default Airports' : this.airportList[this.airportId]?.title : 'new Airport'}}
          </div>
        </div>
        <div class="row mt-1 padding-15">
          <div class="col-12 p-0 list-container">

            <div class="type-list">
              <ng-container *ngFor="let gse of filteredGseList | keyvalue; let i = index">
                <ng-container *ngIf="!gse?.value.isNone || gse?.value.isNone && (filteredGseList | keyvalue).length === 1">
                  <div  class="list-item-row border-bottom-darkgray">
                      <div class="flex-basis-70 text-block align-self-center d-flex justify-content-between">
                        <span>{{gse?.value?.title}}</span>
                        <span class="demo-icon {{gseKVPairs[gse.key]}}"></span>
                        <span class="sla-indicator" [ngClass]="{'sla': gse?.value?.isSla, 'non-sla': !gse?.value?.isSla}">{{gse?.value?.isSla? 'SLA' : 'Non-SLA'}}</span>
                      </div>
                      <div class="flex-basis-30 text-block text-end icon-container colorBlack">
                        <i class="demo-icon icon-icons8-edit-file fs-30" [ngClass]="{'clickable': !isNew, 'disabled': isNew}" (click)="editGse(gse?.value?.id)"></i>
                        <i class="ms-3 demo-icon icon-icons8-delete fs-30" [ngClass]="{'clickable': !isNew && 'none' !== gse?.value?.title, 'colorWhite': isNew || 'none' === gse?.value?.title}" (click)="deleteItem(gse?.value?.id)"></i>
                      </div>
                  </div>
                </ng-container>
              </ng-container>

            </div>
            <app-add-row buttonText="Add GSE" [isDisabled]="this.isNew" (addClicked)="addGse()"></app-add-row>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">{{ isNew ? 'Cancel' : 'Close' }}
  </button>
  <button *ngIf="isNew" type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="formGroup?.disabled">Save
  </button>
</div>
