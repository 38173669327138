<app-step-menu [menuItems]="steps" [(selectedItem)]="step"></app-step-menu>

<app-flights-search-flights *ngIf="step === SEARCH"></app-flights-search-flights>
<app-flights-select-flights *ngIf="step === SELECT_AND_CANCEL"></app-flights-select-flights>

<div class="modal-footer">
  <button class="btn btn-outline-secondary settings-modal-button" type="button" (click)="closeWindow.emit()">Cancel</button>
  <button *ngIf="step === SEARCH" class="btn button-blue settings-modal-button" type="button" (click)="searchFlights()">Search</button>
  <button *ngIf="step === SELECT_AND_CANCEL" class="btn btn-outline-danger settings-modal-button" type="button" (click)="selectAndCancelFlights()">Cancel Flights</button>
</div>
