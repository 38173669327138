import {Component, Input, OnInit} from '@angular/core';
import {ISlaGseLevelProcess} from '../../../../shared/models/sla-gse-level.model';
import { DefinedGses } from '../../../../shared/constants/defined-gses';

@Component({
  selector: 'app-settings-sla-gse-management-list-item',
  templateUrl: './settings-sla-gse-management-list-item.component.html',
  styleUrls: ['./settings-sla-gse-management-list-item.component.scss']
})
export class SettingsSlaGseManagementListItemComponent implements OnInit {

  @Input() item: ISlaGseLevelProcess;
  @Input() opened: boolean;
  @Input() icons: string[];

  constructor() { }

  ngOnInit() {
  }

  getIconClass(gseTypeName: string): string {
    const gseContainer = DefinedGses.IGENERIC_CONTAINER_OBJECT;
    let gseType: any;
    if (gseContainer && gseContainer[gseTypeName]) {
      gseType = gseContainer[gseTypeName];
    }
    return gseType?.class || '';
  }
}
