/* tslint:disable:max-line-length */
import { Component, Input, OnInit } from '@angular/core';
import { IPairProcessAndEquipment } from '../../../models/pairProcessAndEquipment.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IGenericContainerObject } from '../../../models/genericContainerObject.model';
import { ISlaGseLevelGse } from '../../../models/sla-gse-level.model';
import { DefinedGses } from '../../../constants/defined-gses';
import { IProcessJoinedModel } from '../../../models/processes.model';
import { IGsesModel } from '../../../models/gses.model';
import { ProcessesService } from '../../../../services/processes.service';
import { GseService } from '../../../../services/gse.service';
import { firstValueFrom, forkJoin, Observable } from 'rxjs';
import { PairsProcessesService } from 'src/app/services/pairs-processes.service';
import { IPairsProcessesModel } from 'src/app/shared/models/pairs-processes.model';
import { ToastService } from 'src/app/services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const PAIR_PROCESS_MOCK = [
  {
    title: 'De-icing',
    hasGse: true,
    gseType: 'De-icing Service',
    amount: 11,
    inUse: true,
    isSla: true
  }
];

interface innerProcessModel extends  IProcessJoinedModel {
  selectedGse?: IGsesModel;
}

@Component({
  selector: 'app-change-flights-turnaround-processes',
  templateUrl: './change-flights-turnaround-processes.component.html',
  styleUrls: ['./change-flights-turnaround-processes.component.scss']
})

export class ChangeFlightsTurnaroundProcessesComponent implements OnInit {

  @Input() pairProcesses?: IGenericContainerObject<IPairsProcessesModel> = {};
  @Input() airportId?: number;
  @Input() acTypeId?: number;
  @Input() pairId?: number;
  processList: IGenericContainerObject<innerProcessModel>;
  gseList: IGenericContainerObject<IGsesModel>;


  form?: FormGroup;
isBusy = false;
  processes: IGenericContainerObject<{
    title?: string,
    gses?: IGenericContainerObject<ISlaGseLevelGse>
    selectedGse?: ISlaGseLevelGse
  }> = {};

  formRestoreValue: any;

  activateProcessesWithGsesInSla = true;
  definedGses = DefinedGses;

  constructor(private fb: FormBuilder, private toastService: ToastService, private processService: ProcessesService, private gseService: GseService, private pairProcessService: PairsProcessesService, private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    // if (!this.pairProcesses || !Object.keys(this.pairProcesses)?.length) {
    //   this.activateProcessesWithGsesInSla = false;
    // }

    this.fetchData().then();

    // this.getAllProcesses();
    // this.settingsService.getAllProcessesByAcTypeAndAirport(this.acTypeIata, this.airportIata);
  }

  createForm() {
    if (!this.processList || !Object.keys(this.processList).length) {
      console.log('processKeys is empty or null! ', this.processList);
      return;
    }

    // this.processKeys = processKeys;

    this.form = this.fb.group({});

    Object.keys(this.processList).map(Number).forEach((processKey: number) => {
      this.form?.addControl(processKey.toString(), this.fb.group({
        title: [this.processList[processKey].title, Validators.required],
        gseType: ['None', Validators.required],
        hasGse: [false],
        isSla: [true],
        amount: [1, Validators.compose([Validators.required, Validators.pattern('^[1-9]([0-9])*')])],
        inUse: [false],
        selectedGse: [null],
        id: 0
      }));

      console.log('form: ', this.form);
      // this.processes[processKey] = {
      //   title: processKey
      // };

      this.getProcessByAcTypeAndAirportOrDefault(processKey, this.acTypeId, this.airportId)
        .then((res: IGsesModel[]) => {
          if (res) {
            const gses = {};
            console.log('GSES fetched:', res);
            for (const gse of res) {
              gses[gse.id] = gse;
            }
            this.processList[processKey] = {
              ...this.processList[processKey],
              gses: gses
            };

            // const realGses = (Object.values(gses) as IGsesModel[])
            //       .filter((gse: IGsesModel) => !gse.isNone);

            // for (const gseLocal of realGses) {
            //   this.form?.patchValue({
            //     [processKey]: {
            //       selectedGse: gseLocal,
            //       gseType: gseLocal.id,
            //       inUse: !gseLocal.isNone
            //     }
            //   });
            // }

            if (this.activateProcessesWithGsesInSla) {

              const predefinedSlaGses: IGsesModel[] = (Object.values(gses) as IGsesModel[])
                .filter((gse: IGsesModel) => gse?.isSla);

              let firstSlaGse: IGsesModel | undefined;

              console.log('predefinedSlaGses: ', predefinedSlaGses);

              if (predefinedSlaGses?.length > 1) {
                firstSlaGse = (Object.values(gses) as IGsesModel[])
                  .find((gse: IGsesModel) => !gse.isNone);
              } else {
                  firstSlaGse = predefinedSlaGses[0];
              }

              console.log('firstSlaGse: ', firstSlaGse);

              if (firstSlaGse) {
                this.form?.patchValue({
                  [processKey]: {
                    selectedGse: firstSlaGse,
                    gseType: firstSlaGse.id,
                    inUse: firstSlaGse.isSla
                  }
                });
                this.formRestoreValue = this.form?.value;
              }
            } else {
              if (this.pairProcesses && this.pairProcesses[processKey]) {
                // this.form?.patchValue({
                //   [processKey]: this.pairProcesses[processKey]
                // });
                this.form?.patchValue({
                  [processKey]: {
                    selectedGse: this.gseList[this.pairProcesses[processKey].gseId],
                    gseType: this.gseList[this.pairProcesses[processKey].gseId].id,
                    inUse: true,
                    amount: this.pairProcesses[processKey].amount,
                    id: this.pairProcesses[processKey].id
                  }
                });
                this.formRestoreValue = this.form?.value;
              }
            }
          }

          console.log(this.processes);
        });

    });
  }

  restoreForm() {
    this.form?.patchValue(this.formRestoreValue);
    for (const process of Object.values(this.processList)) {
      if(process.selectedGse && !this.form.get(String(process.id)).value.selectedGse)
      {
        console.log('need to reset selected gse inside process');
      }
    }
  }

  getOnlyInUseProcessesValue(allValues: IGenericContainerObject<IPairProcessAndEquipment>): IGenericContainerObject<IPairProcessAndEquipment> {
    if (!allValues) {
      return {};
    }

    const result = {};

    Object.entries(allValues).forEach(([key, item]) => {
      if (item?.inUse) {
        result[key] = item;
      }
    });

    return result;
  }

  saveForm() {
    this.isBusy = true;
    const val = this.form?.value;
    console.log('save processes form');
    console.log(val);
    const finalVal = this.getOnlyInUseProcessesValue(val);
    const observables: Observable<any>[] = [];
    for (const [key, pairProcess] of Object.entries(finalVal)) {
      observables.push(this.pairProcessService.savePairProcess({
        pairId: this.pairId,
        gseId: pairProcess.gseType,
        processId: Number(key),
        amount: pairProcess.amount,
        id: pairProcess.id > 0 ? pairProcess.id : undefined
      }));
    }
    for (const pairProcess of Object.values(this.pairProcesses)) {
      if(!finalVal[pairProcess.processId])
      {
        observables.push(this.pairProcessService.deletePairsProcess(pairProcess.id))
      }
    }
    forkJoin(observables).subscribe((res) => {
      this.isBusy = false;
      if(!res.includes(null)) {
        this.toastService.showSuccess('Pair processes have been saved!');
        this.activeModal.dismiss('repair');
      }
    });

    // this.settingsService.setPairProcesses(this.pairKey, finalVal).subscribe(item => {
    //   console.log(item);
    //   if (!dontEmitSaveClicked) {
    //     //this.saveClicked.emit();
    //   }
    // });
  }

  getIconClass(gseTypeName: string): string {
    return DefinedGses[gseTypeName]?.class || '';
  }

  gseTypeSelectionChanged(process: number, gseType: number) {
    console.log('test changed: ', process, gseType);

    if (!Object.keys(this.processList)?.length || !this.processList[process] || !this.processList[process]?.gses) {
      return;
    }
    const gses = this.processList[process]?.gses;
    if (!gses) {
      return;
    }
    const selectedGse = this.gseList[gseType] as IGsesModel;
    const hasGse = !this.gseList[gseType]?.isNone;
    const isSla = selectedGse ? selectedGse.isSla : true;

    this.form?.patchValue({
      [process]: {
        selectedGse,
        hasGse,
        isSla,
        amount: 1
      }
    });

    this.processList[process] = {
      ...this.processList[process],
      selectedGse
    };

    console.log('changed form: ', this.form?.value);

    console.log('process: ', this.processes[process]);
  }

  async fetchData() {
    const pairProcessesResult = await firstValueFrom(this.pairProcessService.getPairsProccessesByPairId(this.pairId));
    if(pairProcessesResult?.length > 0)
    {
      this.activateProcessesWithGsesInSla = false;
      for (const pairProcess of pairProcessesResult) {
        this.pairProcesses[pairProcess.processId] = pairProcess;
      }
    }
    this.gseService.fetchGses({ isActive: true }).subscribe((gseResult) => {
      if(gseResult) {
        this.gseList = {};
        for (const gse of gseResult) {
          this.gseList[gse.id] = gse;
        }
      }
      if(this.processList) {
        this.createForm();
      }
    });
    this.processService.fetchProcesses({ isActive: true }).subscribe((result) => {
      if(result) {
        this.processList = {};
        for (const process of result) {
          this.processList[process.id] = process;
        }
      }
      if(this.gseList) {
        this.createForm();
      }
    });
  }

  getProcessByAcTypeAndAirportOrDefault(processId: number, acTypeId?: number, airportId?: number): Promise<any> {
    return new Promise<any>(((resolve) => {
      if (!processId) {
        console.log('Either process, acType or airport is empty!');
        resolve(null);
      }
      return this.getProcessByParams(processId, acTypeId, airportId)
        .catch(() => this.getProcessByParams(processId, acTypeId)
          .catch(() => this.getProcessByParams(processId)
            .catch(() => resolve(null))
            .then((res) => resolve(res)))
          .then((res) => resolve(res)))
        .then((res) => resolve(res));
    }));
  }

  getProcessByParams(processId: number, acTypeId?: number, airportId?: number): Promise<any> {
    return new Promise<any>((async (resolve, reject): Promise<void> | undefined => {
      if (!processId) {
        console.log('Process id undefined!');
        reject();
      }
      const result = await firstValueFrom(this.gseService.fetchGses({ processId, airportId: airportId ?? null, acTypeId: acTypeId ?? null, isActive: true}));
      if(!result.length) {
        reject();
      }
      resolve(result);
      return undefined;
    }));
  }
}
