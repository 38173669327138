import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[propName]',
  exportAs: 'propName'
})
export class PropNameDirective {
  constructor (public tmpl: TemplateRef<any>) { }
  @Input('propName') propName: string;
}
