<div class="container pb-2 px-2">
  <div class="row">
    <div class="col-10 headline">
      <span class="badge bg-light colorRed"><i class="demo-icon icon-icons8-error fw-bold"></i> {{alerts.length > 0 ? alerts.length : 0}}</span>
    </div>
    <div class="col-2 text-end">
      <div *ngIf="isMaximized" class="icon-button-container icon-minus-container-bg-color pe-2" (click)="toggleIsMaximized()">
        <span class="iconized size-30">-</span>
      </div>

      <div *ngIf="!isMaximized" class="icon-button-container icon-plus-container-bg-color size-30 pe-2" (click)="toggleIsMaximized()">
        <span class="iconized">+</span>
      </div>
    </div>
  </div>
  <div *ngIf="alerts && alerts.length > 0 && isMaximized" class="row">
    <div class="col-12" *ngFor="let alert of alerts">
      <app-alert-list-item
        [isActive]="alert?.reference && activePairIndex === alert?.reference"
        [pairKey]="alert?.reference"
        [title]="alert?.title"
        [text]="alert?.description"
        (click)="alertClicked(alert?.reference)"></app-alert-list-item>
    </div>
  </div>
</div>
