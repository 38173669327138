import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IUser } from '../../models/user.model';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-small-chat-contacts-container',
  templateUrl: './small-chat-contacts-container.component.html',
  styleUrls: ['./small-chat-contacts-container.component.scss']
})
export class SmallChatContactsContainerComponent implements OnInit, OnChanges {

  @Input() flightStations: string[];
  @Input() chatUser: IUser;
  @Input() defaultText = 'Select a pair to see available contacts';
  @Input() showContacts = false;

  searchText: string = '';

  stationsChangedEvent = new EventEmitter<void>();
  chatOpened: boolean;
  selectedStations: string[];

  constructor(private router: Router) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flightStations && changes.flightStations.currentValue) {
      this.selectedStations = [...this.flightStations];
      this.stationsChangedEvent.emit();
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectedStations = [];
      }
    });
  }

  changeChatState(state: boolean, data: IUser) {
    this.chatUser = data;
    this.chatOpened = state;
  }

  stationSelected(station: string) {
    const index = this.selectedStations.findIndex((item) => item === station);
    if (index !== -1) {
      this.selectedStations.splice(index, 1);
      this.stationsChangedEvent.emit();
      return;
    }
    this.selectedStations.push(station);
    this.stationsChangedEvent.emit();
  }
}
