import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'timeFromMinutes'
})
export class TimeFromMinutesPipe extends DecimalPipe implements PipeTransform {

  override transform(minutes: any, ...args: any[]): any {
    if (!minutes || minutes === 0) {
      return '00:00';
    }
    const m = minutes % 60;
    const h = (minutes - m) / 60;
    const isMinus = m < 0 || h < 0;
    return (isMinus ? '-' : '') + super.transform(Math.abs(h), '2.') + ':' + super.transform(Math.abs(m), '2.');
  }
}
