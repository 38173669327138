import { Component, OnInit } from '@angular/core';
import { IMemoModel } from '../../models/memo.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MemosService } from '../../../services/memos.service';
import { ToastService } from '../../../services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from '../../models/user.model';
import { forkJoin, Observable } from 'rxjs';
import { IPairDetail } from '../../models/pair-detail.model';

@Component({
  selector: 'app-add-edit-memos-dialog',
  templateUrl: './add-edit-memos-dialog.component.html',
  styleUrls: ['./add-edit-memos-dialog.component.scss']
})
export class AddEditMemosDialogComponent implements OnInit {
  isBusy = false;
  pair?: IPairDetail;
  arrivalMemo?: IMemoModel;
  departureMemo?: IMemoModel;
  arrivalMemoUser?: IUser;
  departureMemoUser?: IUser;
  form?: FormGroup;
  title?: string;
  flightTypeSelection?: string;
  userRole?: string;



  constructor(private fb: FormBuilder, private memoService: MemosService, private toastService: ToastService, private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.generateForm(this.fb);
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    if(!this.form.value.arrivalText.length && !this.form.value.departureText.length) {
      return;
    }
    console.log('ROLE : ', this.userRole);
    const observables: Observable<any>[] = [];
    if(this.form.value.arrivalText.length) {
      observables.push(this.memoService.saveMemo({
        id: this.arrivalMemo?.id ?? undefined,
        legId: this.pair.arrivalLeg?.id,
        content: this.form.value.arrivalText,
        pairId: this.pair.id,
        userGroupId: 1,
        role: this.userRole,
      }));
      // this.memoService.saveMemo({
      //   id: this.arrivalMemo?.id ?? undefined,
      //   legId: this.pair.arrivalLegId,
      //   content: this.form.value.arrivalText,
      //   pairId: this.pair.id,
      //   userGroupId: 1,
      //   role: this.userRole,
      // }).subscribe((result) => {
      //   if(result) {
      //     this.toastService.showSuccess('Arrival Memo has been saved')
      //     if(this.arrivalMemo) {
      //       this.arrivalMemo.content = result.content;
      //     }
      //     this.closeModal();
      //   }
      // });
    }

    if(this.form.value.departureText.length) {
      observables.push(this.memoService.saveMemo({
        id: this.departureMemo?.id ?? undefined,
        legId: this.pair.departureLeg?.id,
        content: this.form.value.departureText,
        pairId: this.pair.id,
        userGroupId: 1,
        role: this.userRole,
      }));
    }

    this.isBusy = true;
    forkJoin(observables).subscribe((results) => {
      if(!results.includes(null)) {
        this.toastService.showSuccess('Memos has been saved');
        for (const result of results) {
          if(this.pair.departureLeg?.id === result.legId && this.departureMemo) {
            this.departureMemo.content = result.content;
          }
          if(this.pair.arrivalLeg?.id === result.legId && this.arrivalMemo) {
            this.arrivalMemo.content = result.content;
          }
        }
        this.closeModal();
      }
      this.isBusy = false;
    });
  }

  swapValues(){
    if(this.form.controls['flightType'].value == 'arrival'){
      console.log('Change to Deprture')

      this.form.controls['flightType'].setValue('departure');
      this.flightTypeSelection = 'departure'
    }

    else  {
      console.log('Change to Arrival ')
      this.form.controls['flightType'].setValue('arrival');
      this.flightTypeSelection = 'arrival'
    }
  }

  generateForm(fb: FormBuilder) {
    this.form = fb.group({
      arrivalText: [''],
      departureText: [''],
      flightType: ['arrival', Validators.required],
    });

    this.flightTypeSelection = 'arrival';

    if (this.arrivalMemo) {
      this.form.patchValue({
        arrivalText: this.arrivalMemo.content
      });
    }

    if (this.departureMemo) {
      this.form.patchValue({
        departureText: this.departureMemo.content
      });
    }

    if (!this.pair.arrivalLeg?.id) {
      this.form.patchValue({
        flightType: 'departure'
      });
      this.flightTypeSelection = 'departure';
      this.form.get('flightType')?.disable();
    }

    if (!this.pair.departureLeg?.id) {
      this.form.patchValue({
        flightType: 'arrival'
      });
      this.flightTypeSelection = 'arrival';
      this.form.get('flightType')?.disable();
    }
  }
}
