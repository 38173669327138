import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-airport-contact-dialog-list-item',
  templateUrl: './manage-airport-contact-dialog-list-item.component.html',
  styleUrls: ['./manage-airport-contact-dialog-list-item.component.scss']
})
export class ManageAirportContactDialogListItemComponent implements OnInit {

  @Input() item?: any;
  @Input() opened?: boolean;
  @Input() shallNotOpen?: boolean;

  constructor() { }

  ngOnInit() {
    console.log(this.item)
  }

}
