import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flight-list-item',
  templateUrl: './flight-list-item.component.html',
  styleUrls: ['./flight-list-item.component.scss']
})
export class FlightListItemComponent implements OnInit {

  registrationNrState: any;
  departingStationState: any;
  stdState: any;
  arrivalStationState: any;
  staState: any;
  flightServiceTypeState: any;
  acTypeState: any;

  constructor() { }

  ngOnInit() {
  }

}
