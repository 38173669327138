<div class="list-item-column">

  <div class="list-item-row-header" [ngClass]="{'opened' : opened}">
    <div class="flex-basis-25 text-block fs-16">{{item?.iata}}</div>
    <div class="flex-basis-50 text-block text-center fs-16">{{item?.title}}</div>
    <div class="flex-basis-25 text-block text-center fs-16">{{item?.icao}}</div>
  </div>

  <ng-container *ngIf="opened">
    <div class="list-item-row">
      <div class="flex-basis-25 text-block fs-16">Terminals</div>
      <div class="flex-basis-25 text-block h-30">
        <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewAirportTerminals()"></i>
      </div>

      <div class="flex-basis-35 text-block fs-16">Airport Slot Time Tolerance</div>
      <div class="flex-basis-15 text-block h-30">
        <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewSlotTimeTolerance()"></i>
      </div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-25 text-block fs-16">Country</div>
      <div class="flex-basis-25 text-block fs-16">{{item?.country | valueOrDash}}</div>

      <div class="flex-basis-35 text-block fs-16">Slot Coordination Level </div>
      <div class="flex-basis-15 text-block fs-16">{{item?.slotCoordinationLevel | valueOrDash}}</div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-25 text-block fs-16">Coordinates</div>
      <div class="flex-basis-25 text-block fs-16">{{item?.latitude | valueOrDash}} <br> {{item?.longitude | valueOrDash}} </div>

      <div class="flex-basis-35 text-block fs-16">Fire Category</div>
      <div class="flex-basis-15 text-block fs-16">{{item?.fireCategory | valueOrDash}}</div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-25 text-block fs-16">Time Zone</div>

      <div class="flex-basis-25 text-block h-30">
        <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewAirportTimezone()"></i>
      </div>

      <div class="flex-basis-35 text-block fs-16">{{'' + this.generalSettings?.turnaroundTimeType | titlecase}}  Turnaround Time</div>
      <div class="flex-basis-15 text-block h-30">
        <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewAirportMinimalTurnaroundTimes()"></i>
      </div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-25 text-block fs-16">Airport Contacts</div>
      <div class="flex-basis-25 text-block h-30">
        <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewAirportContacts()"></i>
      </div>

      <div class="flex-basis-35 text-block fs-16">Curfew Times</div>
      <div class="flex-basis-15 text-block h-30">
        <i class="demo-icon icon-icons8-eye fs-30 clickable" (click)="openViewAirportCurfewTimes()"></i>
      </div>
    </div>

  </ng-container>
</div>
