import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Inject, INJECTOR, Injector, Input, OnInit, Output, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControlName, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, ValidationErrors, Validator, Validators } from '@angular/forms';
import dayjs from 'dayjs';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimeInputComponent),
    }
  ]
})
export class TimeInputComponent implements OnInit, ControlValueAccessor  {
  @Output() focusLost = new EventEmitter<void>();
  _control: NgControl;
  constructor(@Inject(INJECTOR) private injector: Injector, private  cd: ChangeDetectorRef) {}

  ngOnInit() {
    this._control = this.injector.get(NgControl);
  }

  value: any;
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};
  disabled: boolean;

  writeValue(value: any): void {
    if (typeof value === 'number') {
      const d = dayjs.duration(value, 'minutes');
      this.value = Math.trunc(d.as('hours')).toString().padStart(2, '0') + ':' + d.format('mm').replace('-', '');
      setTimeout(() => this._control?.control?.patchValue(this.value), 1);
      return;
    }
    this.value = value;
    this.cd.detectChanges();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
