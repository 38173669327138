<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="container">
            <div class="row">
              <div class="col-12 form-field-header colorBlack mb-3">
                Departure Slot Tolerance
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header">
                Early
              </div>
              <div class="col-6 form-field-content">
                {{slotTimeToleranceData?.departureEarlyInMinutes | timeFromMinutes}}
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header">
                Late
              </div>
              <div class="col-6 form-field-content">
                {{slotTimeToleranceData?.departureLateInMinutes | timeFromMinutes}}
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 border-start">
          <div class="container">
            <div class="row">
              <div class="col-12 form-field-header colorBlack mb-3">
                Arrival Slot Tolerance
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header">
                Early
              </div>
              <div class="col-6 form-field-content">
                {{slotTimeToleranceData?.arrivalEarlyInMinutes | timeFromMinutes}}
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header">
                Late
              </div>
              <div class="col-6 form-field-content">
                {{slotTimeToleranceData?.arrivalLateInMinutes | timeFromMinutes}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
