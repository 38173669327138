import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as dayjs from 'dayjs';
import { ISimpleData } from '../../../../models/simpleData.model';
import { ILegsFirestoreModel } from '../../../../models/legs.model';
import { IPairDetail } from '../../../../models/pair-detail.model';
import { extractSeatingConfigurations } from '../../../../utils/utils';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-pair-detail-leg',
  templateUrl: './pair-detail-leg.component.html',
  styleUrls: ['./pair-detail-leg.component.scss']
})
export class PairDetailLegComponent implements OnInit, OnChanges {

  _leg: ILegsFirestoreModel;

  @Input() pairDetail: IPairDetail;
  arrivalDelayInMinutes = 0;
  arrivalNumberOfDelays = 0;

  departureDelayInMinutes = 0;
  departureNumberOfDelays = 0;

  get leg() {
    return this._leg;
  }

  @Input('leg')
  set leg(value: ILegsFirestoreModel) {
    this._leg = value;
    if (value) {
      this.calcPaxData(this._leg.pax);
    }
  }

  _passengerClasses: ISimpleData[];

  get passengerClasses() {
    return this._passengerClasses;
  }

  @Input('passengerClasses')
  set passengerClasses(value: ISimpleData[]) {
    this._passengerClasses = value;
    if (this.leg) {
      this.calcPaxData(this.leg.pax);
    }
  }

  @Input() isArrivalLeg: boolean;
  @Input() timezone: string;

  paxTotal = '-';
  paxList: { code: string, amount: string }[];

  constructor(private generalSettingsService: GeneralSettingsService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.pairDetail && this.pairDetail?.id) {
        firstValueFrom(this.generalSettingsService.getDelayCodes()).then((delayCodes) => {
          this.arrivalNumberOfDelays = (Object.values(this.leg?.arrivalDelays ?? {})).filter((delay) => delayCodes.find((d) => d.id === delay.delayCodeId).code !== '-')?.length || 0;
          if(this.arrivalNumberOfDelays)
          {
            this.arrivalDelayInMinutes = 0;
            for (const delay of Object.values(this.leg?.arrivalDelays)) {
              this.arrivalDelayInMinutes += delay.minutes;
            }
          }
          this.departureNumberOfDelays =(Object.values(this.leg?.departureDelays ?? {})).filter((delay) => delayCodes.find((d) => d.id === delay.delayCodeId).code !== '-')?.length || 0;
          if(this.departureNumberOfDelays)
          {
            this.departureDelayInMinutes = 0;
            for (const delay of Object.values(this.leg?.departureDelays)) {
              this.departureDelayInMinutes += delay.minutes;
            }
          }
        });
    }
  }

  ngOnInit() {
  }

  calcDateDifferenceInDays(date1: Date, date2: Date, tz: string): number {
    if (!date1 || !date2) {
      return 0;
    }

    let d1 = dayjs(date1);
    let d2 = dayjs(date2);
    if (d1 && d2 && d1.isValid() && d2.isValid()) {
      if (tz && tz.length === 5) {
        const hours = Number(tz.slice(1, 3));
        const minutes = Number(tz.slice(3, 5)) + hours * 60;
        d1 = d1.utcOffset(minutes);
        d2 = d2.utcOffset(minutes);
      }
      d1 = d1.startOf('day');
      d2 = d2.startOf('day');
      return Math.abs(d2.diff(d1, 'd'));
    }
    return 0;
  }

  calcDateDifferenceString(date1: Date, date2: Date, tz: string): string {
    const res = this.calcDateDifferenceInDays(date1, date2, tz);
    if (res === 0) {
      return '';
    }
    const sign = dayjs.utc(date1).isAfter(date2) ? '-' : '+';
    return sign + res;
  }

  calcPaxData(paxData: string) {
    if(/^\d/.test(paxData)) {
      this.paxTotal = paxData;
      this.paxList = null;
      return;
    }

    if (!paxData) {
      this.paxTotal = '-';
      this.paxList = null;
      return;
    }

    const passClasses = this.passengerClasses ? this.passengerClasses : [];

    let paxCounter = 0;

    //const seatConfigSplitBySlash = seatConfig.split('/');
    const seatingConfigurations = extractSeatingConfigurations(paxData);
    const paxList = seatingConfigurations.map((item: string) => {
      const code = item.slice(0, 1);
      const amount = item.slice(1);

      const title = passClasses.find((passClassItem: ISimpleData) => passClassItem && passClassItem.code === code);

      paxCounter += +amount;
      return {
        code: title ? title.description : code,
        amount
      };
    });

    this.paxTotal = '' + paxCounter;
    this.paxList = paxList;
  }

}
