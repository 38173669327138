import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.scss']
})
export class SettingsListComponent implements OnInit {

  @Input() opened: boolean;
  @Input() noOpenedContent: boolean;
  @Input() noEditDeleteBar: boolean;

  @Output() editClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
