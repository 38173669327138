import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { ExitScreenCreateAsmFormBuilder } from '../../shared/forms/formBuilders/exit-screen/create-asm-form-builder';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ISimpleData } from '../../shared/models/simpleData.model';
import { AcTypesService } from '../../services/ac-types.service';
import { IAcType } from '../../shared/models/ac-type.model';
import { AcRegistrationsService } from '../../services/ac-registrations.service';
import { IAcRegistration } from '../../shared/models/ac-registration.model';
import { AirportsService } from '../../services/airports.service';
import { IAirport } from '../../shared/models/airport.model';
import { ngbDateToFormat } from '../../shared/utils/utils';
import { ASM_CNL_EXIT_TYPE, ASM_CON_EXIT_TYPE, ASM_EQT_EXIT_TYPE, ASM_NEW_EXIT_TYPE, ASM_RRT_EXIT_TYPE, ASM_TIM_EXIT_TYPE, ExitScreenMessage } from '../../shared/constants/exit-screen-asm-msg.constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AsmService } from '../../services/asm.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { GeneralDateFormatter } from '../../shared/formatters/GeneralDateFormatter';
import { ToastService } from '../../services/toast.service';


@Component({
  selector: 'app-exit-screen',
  templateUrl: './exit-screen.component.html',
  styleUrls: ['./exit-screen.component.scss'],
  providers: [{provide: NgbDateParserFormatter, useClass: GeneralDateFormatter}]
})
export class ExitScreenComponent implements OnInit, OnDestroy {

  dateFormat = 'dd/mm/yyyy';
  form?: FormGroup;
  flightServiceTypes: ISimpleData[];
  acTypes: IAcType[];
  acRegistrations: IAcRegistration[];
  airports: IAirport[];
  airlineDesignator: any;
  exitScreenSetup: ExitScreenMessage;
  isBusy = false;
  fileContent: string;
  forOthers?: boolean = false;
  forAvitium?: boolean = true;
  availableAcRegistrations: IAcRegistration[] = [];
  availableAcTypes: IAcType[] = [];

  unsubscribe$ = new Subject();
  ngOnDestroy() {
      this.unsubscribe$.next(undefined);
      this.unsubscribe$.complete();
    }


  constructor(
    private fb: FormBuilder,
    private generalSettingsService: GeneralSettingsService,
    private acTypesService: AcTypesService,
    private acRegistrationsService: AcRegistrationsService,
    private airportsService: AirportsService,
    private asmService: AsmService,
    private toastService: ToastService
    ) { }

  ngOnInit(): void {
    this.dateFormat = this.generalSettingsService.generalSettings.value.dateFormat;
    this.generalSettingsService.getFlightServiceTypes().subscribe((result) => {
      this.flightServiceTypes = result;
    });
    this.acTypesService.acTypes.subscribe((result) => {
      this.acTypes = result;
      this.availableAcTypes = this.form?.value?.acRegistration ? this.acTypes?.filter((acType) => this.form.value.acRegistration.acTypeId === acType.id) : this.acTypes ? [...this.acTypes] : [];
      this.availableAcRegistrations = this.form?.value?.acType ? this.acRegistrations?.filter((registration) => this.form.value.acType?.id === registration.acTypeId) : this.acRegistrations ? [...this.acRegistrations] : [];
    });
    this.acRegistrationsService.getAcRegistrations().subscribe((result) => {
      this.acRegistrations = result;
      this.availableAcTypes = this.form?.value?.acRegistration ? this.acTypes?.filter((acType) => this.form.value.acRegistration.acTypeId === acType.id) : this.acTypes ? [...this.acTypes] : [];
      this.availableAcRegistrations = this.form?.value?.acType ? this.acRegistrations?.filter((registration) => this.form.value.acType?.id === registration.acTypeId) : this.acRegistrations ? [...this.acRegistrations] : [];
    });
    this.airportsService.airports.subscribe((result) => {
      this.airports = result
    });
    this.generalSettingsService.getAirlineDesignators().subscribe((result) => {
      this.airlineDesignator = result
    });
    this.generateForm();
  }



  generateForm() {
    this.form = ExitScreenCreateAsmFormBuilder.constructForm(this.fb);
    //this.form.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(item => this.updateDelayTimespan(item));
    this.form.get('messageType').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      switch (result) {
        case 'CNL':
          this.exitScreenSetup = ASM_CNL_EXIT_TYPE;
          break;
        case 'EQT':
          this.exitScreenSetup = ASM_EQT_EXIT_TYPE;
          break;
        case 'CON':
          this.exitScreenSetup = ASM_CON_EXIT_TYPE;
          break;
        case 'NEW':
          this.exitScreenSetup = ASM_NEW_EXIT_TYPE;
          break;
        case 'RRT':
          this.exitScreenSetup = ASM_RRT_EXIT_TYPE;
          break;
        case 'TIM':
          this.exitScreenSetup = ASM_TIM_EXIT_TYPE;
          break;
        default:
          break;
      }
      for (const [key, value] of Object.entries(this.exitScreenSetup)) {
          if (value) {
            this.form.get(key).enable({onlySelf: true, emitEvent: false});
            continue;
          }
          this.form.get(key).disable({onlySelf: true, emitEvent: false});
      }
    });

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateFileContent('<br>');
    });
    this.form.get('acRegistration').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((acReg) => {
        this.availableAcTypes = acReg ? this.acTypes.filter((acType) => acReg.acTypeId === acType.id) : [...this.acTypes];
    });
    this.form.get('acType').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((type) => {
      this.availableAcRegistrations = type ? this.acRegistrations.filter((registration) => type.id === registration.acTypeId) : [...this.acRegistrations];
    });
  }

  restoreForm() {
    this.form.reset();
    this.form = ExitScreenCreateAsmFormBuilder.constructForm(this.fb);
  }

  downloadFile() {
    if (this.form.invalid) {
      return;
    }
    const dlink: HTMLAnchorElement = document.createElement('a');
    dlink.download = this.getFileName();
    const content = this.updateFileContent('\n', true);
    dlink.href = 'data:text/plain;charset=utf-16,' + content;
    dlink.click(); // this will trigger the dialog window
    dlink.remove();
  }

  updateFileContent(lineSeparator = '\n', returnOnly = false) {
    const type = this.form.value?.messageType;
    if (!type) {
      return;
    }
    let content = `ASM${lineSeparator}UTC${lineSeparator}${type}${lineSeparator}${this.form.value?.airlineDesignator+this.form.value?.flightNumber}/${ngbDateToFormat(this.form.value?.dateOfDeparture, 'DDMMMYY')}`;
    switch (type) {
      case 'CNL':
        content += ` ${this.form.value?.depAirport}/${this.form.value?.arrAirport}${this.form.value?.si ? lineSeparator + 'SI '+this.form.value?.si :''}`
        break;
      case 'CON':
        content += ` ${this.form.value?.depAirport}/${this.form.value?.arrAirport}${lineSeparator}${this.form.value?.flightServiceType} ${this.form.value?.acType?.iata || ''} ${this.form.value?.acConfig}.${this.form.value?.acConfig} ${this.form.value?.acRegistration?.registration || ''}${this.form.value?.si ? lineSeparator + 'SI '+this.form.value?.si :''}`
        break;
      case 'EQT':
        content+= `${lineSeparator}${this.form.value?.flightServiceType} ${this.form.value?.acType?.iata || ''} ${this.form.value?.acConfig}.${this.form.value?.acConfig} ${this.form.value?.acRegistration?.registration || ''}${this.form.value?.si ? lineSeparator + 'SI '+this.form.value?.si :''}`
        break;
      case 'NEW':
      case 'RRT':
        content+= `${lineSeparator}${this.form.value?.flightServiceType} ${this.form.value?.acType?.iata || ''} ${this.form.value?.acConfig}.${this.form.value?.acConfig} ${this.form.value?.acRegistration?.registration || ''}${lineSeparator}${this.form.value?.depAirport}${this.form.value?.std ? this.form.value.std.replace(':', '') : ''} ${this.form.value?.arrAirport}${this.form.value?.sta ? this.form.value.sta.replace(':', '') : '' }${this.form.value?.si ? lineSeparator + 'SI '+this.form.value?.si :''}`
        break;
      case 'TIM':
        content+= `${lineSeparator}${this.form.value?.depAirport}${this.form.value?.std ? this.form.value.std.replace(':', '') : ''} ${this.form.value?.arrAirport}${this.form.value?.sta ? this.form.value.sta.replace(':', '') : ''}${this.form.value?.si ? lineSeparator + 'SI '+this.form.value?.si :''}`
        break;
      default:
        break;
    }
    if (returnOnly) {
      return content.toUpperCase();
    }
    this.fileContent = content.toUpperCase();
  }

  clearForm() {
    this.form.reset();
    this.fileContent = '';
  }

  submit() {
    this.isBusy = true;
    this.asmService.uploadASM({
      fileName: this.getFileName(),
      fileContent: this.updateFileContent('\n', true),
    }).subscribe((result) => {
      console.log('Result:', result);
      this.isBusy = false;
      if (result) {
        this.toastService.showSuccess('Message submitted successfully');
      }
    });
  }

  getFileName(): string {
    return `${this.form.value.messageType+ngbDateToFormat(this.form.value.dateOfDeparture, 'DDMMMYY').toUpperCase()}.txt`;
  }
}
