import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeleteFormTypes} from '../../constants/deleteFormTypes.constants';
import {Subject} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() text: string;
  @Input() term: string;
  @Input() cancelButtonText = "No, cancel";
  @Input() confirmButtonText = "Yes, do it!";
  @Output() startConfirmedTask = new EventEmitter();

  isConfirmationButtonDisabled: boolean;
  interruptConfirmation: boolean;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  confirm() {
    if (!this.interruptConfirmation) {
      this.startConfirmedTask.emit();
    }
    this.closeModal('confirmation done');
  }

  closeModal(reason: string) {
    this.activeModal.dismiss(reason);
  }

  nonClosingConfirmationAction(data: any) {
    this.startConfirmedTask.emit(data);
  }

  formStatusChanged(status: any) {
    if ('INVALID' === status && !this.isConfirmationButtonDisabled) {
      this.isConfirmationButtonDisabled = true;
    } else if ('VALID' === status && this.isConfirmationButtonDisabled) {
      this.isConfirmationButtonDisabled = false;
    }
  }

}
