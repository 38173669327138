<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
<app-step-menu [menuItems]="steps" [(selectedItem)]="step" [smaller]="true" [noCircleContent]="true" (selectedItemChange)="onTypeChange($event)"></app-step-menu>

      <app-view-airport-minimal-turnaround-time-dialog
        *ngIf="this.flightServiceTypes && this.turnaroundTimes"
        [flightServiceTypes]="flightServiceTypes"
        [turnaroundTimes]="turnaroundTimes"
      ></app-view-airport-minimal-turnaround-time-dialog>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Close
  </button>
</div>
