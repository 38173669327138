import {Pipe, PipeTransform} from '@angular/core';
import {IGenericContainerObject} from '../models/genericContainerObject.model';
import {ISimpleData} from '../models/simpleData.model';

@Pipe({
  name: 'seatObjectToString'
})
export class SeatObjectToStringPipe implements PipeTransform {

  transform(value?: IGenericContainerObject<ISimpleData>, args?: any): string {
    if (!value || Object.keys(value).length === 0) {
      return '';
    }
    const resArray = Object.values(value).map((item: ISimpleData | null) => {
      if (item?.code && item?.description) {
        return item.code + item.description;
      }
      return '';
    }).filter(item => item);
    return resArray.join('/');
  }

}
