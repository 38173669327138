import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IWeekdaysModel } from '../shared/models/weekdays.model';
import { catchError, map, Observable, of } from 'rxjs';
import { IResponse } from '../shared/models/IResponse.model';

@Injectable({
    providedIn: 'root'
  })
  export class WeekdaysService {

    constructor(private httpClient: HttpClient, private toastService: ToastService) { }

    getWeekdaysById(weekdayId: number): Observable<IWeekdaysModel>  {
      return this.httpClient.get(`${environment.api_base_url}weekdays-boolean/${weekdayId}`,{}).pipe(
        map((item: IResponse<IWeekdaysModel>) => {
          if(item?.isError){
            console.log(item.message);
          }
          return item?.data || {};
        }),
        catchError((error) => {
          console.log(error);
          return of({});
        })
      );
    }

    saveWeekdays(weekday: IWeekdaysModel): Observable<IWeekdaysModel> {
        let obs;
        if (weekday.id) {
          obs = this.httpClient.patch(`${environment.api_base_url}weekdays-boolean/${weekday.id}`, weekday, {})
        } else {
          obs = this.httpClient.post(`${environment.api_base_url}weekdays-boolean/create`, weekday, {})
        }
        return obs.pipe(
          map((item: IResponse<IWeekdaysModel>) => {
            if(item?.isError){
              console.log(item.message);
            }
            return item?.data || {};
          }),
          catchError((error) => {
            this.toastService.showError("Error saving weekday");
            return of({});
          })
        );
      }

  deleteWeekday(weekdaysBooleanId?: number) {
    return this.httpClient.delete(`${environment.api_base_url}weekdays-boolean/${weekdaysBooleanId}`, {}).pipe(
      catchError(() => {
        return of({});
      })
    );
  }
}
