import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';
import { ExcelUploadService } from '../../../services/excel-upload.service';
import * as XLSX from 'xlsx/xlsx.mjs';


@Component({
  selector: 'app-excel-upload',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.scss']
})
export class ExcelUploadComponent implements OnInit {

  file: File;
  fileContent: any;
  fileName: string;
  isBusy = false;
  excelData : Partial<{
    generalSettings: any,
    passengerClasses: any,
    flightServiceTypes: any,
    dlc: any
    delayCodes: any,
    users: any,
    acTypes: any,
    acRegistrations: any,
    airportSlotTimeTolerance: any,
    airportContactCompanies: any,
    designators: any,
  }> = {};

  constructor(private modalService: NgbModal, private fns: AngularFireFunctions, private excelUploadService: ExcelUploadService, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    console.log(files)

    const reader = new FileReader();

    reader.onloadend = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: 'binary'
      });
      this.file = files.item(0);
      let XL_row_object;

      workbook.SheetNames.forEach((sheetName,index) => {
        // Here is your object
        switch (index) {
          case 0:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.generalSettings = XL_row_object

            break;
          case 1:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.passengerClasses = XL_row_object

          break;
          case 2:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.flightServiceTypes = XL_row_object

          break;
          case 3:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.delayCodes = XL_row_object

            break;
          case 4:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.users = XL_row_object

          break;
          case 5:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.acTypes = XL_row_object

          break;
          case 6:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.acRegistrations = XL_row_object

          break;
          case 7:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.airportSlotTimeTolerance = XL_row_object

          break;
          case 8:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.airportContactCompanies = XL_row_object
          break;
          case 9:
            XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            //var json_object = JSON.stringify(XL_row_object);
            this.excelData.designators = XL_row_object
            break;
          default:
            break;
        }
      })

    };

    reader.onerror = function(ex) {
      console.log(ex);
    };

    reader.readAsBinaryString(files.item(0));
  }

callFunction() {
  this.isBusy = true;
  console.log('Excel Data for Alois :', this.excelData);
  this.excelUploadService.uploadExcel({ fileContent: JSON.stringify(this.excelData), fileName: this.fileName }).subscribe((res) => {
    this.isBusy = false;
    if (res) {
     this.toastService.showSuccess('Excel file has been processed successfully!');
    }
  });
  console.log('File Content',this.fileContent);
}
}
