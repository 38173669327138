<div *ngIf="isActive" class="bg-white mx-3 rounded-lg legDetailOuter">
  <div class="flight-info-container border-bottom-gray padding-bottom-15">
    <div class="from-flight-container border-right-gray">
      <div class="flight-info-headline-container padding-bottom-15">
        <span>Arriving Flight</span>
        <span *ngIf="!flightPair" class="margin-left-30">ZRH 10:20 - VIE 11:40</span>
        <span *ngIf="flightPair?.arrivalLeg" class="margin-left-30">
          {{flightPair.arrivalLeg.departureStation + ' ' + (flightPair.arrivalLeg.std | date:'HH:mm':timezone) + ' - ' + flightPair.arrivalLeg.arrivalStation + ' ' + (flightPair.arrivalLeg.sta | date:'HH:mm':timezone)}}
        </span>
        <span *ngIf="flightPair && !flightPair.arrivalLeg" class="margin-left-30">-</span>
      </div>
      <div *ngIf="!flightPair" class="flight-info-data-container">
        <app-icon-text-item-small textHeader="ETD" text="10:30" [indicator]="true" indicatorType="text" indicatorText="+1"></app-icon-text-item-small>
        <app-icon-text-item-small textHeader="ATD" text="10:40" [indicator]="true" indicatorType="text" indicatorText="+1"></app-icon-text-item-small>
        <app-icon-text-item-small textHeader="TAKE OFF" text="10:45" [indicator]="true" indicatorType="text" indicatorText="+1"></app-icon-text-item-small>
        <app-icon-text-item-small textHeader="LAND" text="11:59" [indicator]="true" indicatorType="text" indicatorText="+1"></app-icon-text-item-small>
        <app-icon-text-item-small textHeader="ATA" text="12:05" [indicator]="true" indicatorType="text" indicatorText="+1"></app-icon-text-item-small>
        <app-icon-text-item-small textHeader="IN PAX" text="164" [ngbTooltip]="inPax"></app-icon-text-item-small>
        <app-icon-text-item-small textHeader="GATE" text="F13" [ngbTooltip]="inGate"></app-icon-text-item-small>
        <app-icon-text-item-small textHeader="DL" text="41" [ngbTooltip]="inDl" [indicator]="true" indicatorType="text"
                                  indicatorText="4"></app-icon-text-item-small>

        <ng-template #inPax>
          <div class="container">
            <div class="row">
              <div class="col-8">First:</div>
              <div class="col-4">2</div>
              <div class="col-8">Business:</div>
              <div class="col-4">23</div>
              <div class="col-8">Economy:</div>
              <div class="col-4">139</div>
            </div>
          </div>
        </ng-template>
        <ng-template #inGate>
          <div class="container">
            <div class="row">
              <div class="col-8">Aircraft stand:</div>
              <div class="col-4">F13</div>
              <div class="col-8 text-nowrap">Passenger gate:</div>
              <div class="col-4">F13</div>
            </div>
          </div>
        </ng-template>
        <ng-template #inDl>

          <!--
          <div class="container">
          <div class="row">
             <div class="col-12">Departure Delay:</div>
             <div class="col-6">32:</div>
             <div class="col-6">32:00</div>
             <div class="col-6">12:</div>
             <div class="col-6">16:30</div>
             <div class="col-6">22:</div>
             <div class="col-6">13:00</div>
             <div class="col-6">48:</div>
             <div class="col-6">05:00</div>
           </div>
           <div class="row mt-2">
             <div class="col-12">Arrival Delay:</div>
             <div class="col-6">93:</div>
             <div class="col-6">32:00</div>
           </div>
         </div>
          </div> -->
          <div class="container m-0 p-0">
            <div class="row">
              <div class="col-12">Delays:</div>
              <div class="col-3">32:</div>
              <div class="col-6">32:00</div>
              <div class="col-3">(A)</div>
              <div class="col-3">12:</div>
              <div class="col-6">16:30</div>
              <div class="col-3">(D)</div>
              <div class="col-3">22:</div>
              <div class="col-6">13:00</div>
              <div class="col-3">(D)</div>
              <div class="col-3">48:</div>
              <div class="col-6">05:00</div>
              <div class="col-3">(A)</div>
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngIf="flightPair" class="flight-info-data-container">

        <app-icon-text-item-small textHeader="ETD"
                                  [text]="flightPair.arrivalLeg?.etd | date:'HH:mm':timezone"
                                  [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.etd, flightPair?.arrivalLeg?.std, timezone)"
                                  indicatorType="text"
                                  [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.etd, flightPair?.arrivalLeg?.std, timezone)"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="ATD"
                                  [text]="flightPair.arrivalLeg?.atd | date:'HH:mm':timezone"
                                  [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.atd, flightPair?.arrivalLeg?.std, timezone)"
                                  indicatorType="text"
                                  [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.atd, flightPair?.arrivalLeg?.std, timezone)"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="TAKE OFF" [text]="flightPair.arrivalLeg?.takeOffTime | date:'HH:mm':timezone"
                                  [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.takeOffTime, flightPair?.arrivalLeg?.std, timezone)"
                                  indicatorType="text"
                                  [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.takeOffTime, flightPair?.arrivalLeg?.std, timezone)"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="LAND"
                                  [text]="flightPair.arrivalLeg?.landingTime | date:'HH:mm':timezone"
                                  [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.landingTime, flightPair?.arrivalLeg?.std, timezone)"
                                  indicatorType="text"
                                  [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.landingTime, flightPair?.arrivalLeg?.std, timezone)"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="ATA"
                                  [text]="flightPair.arrivalLeg?.ata | date:'HH:mm':timezone"
                                  [indicator]="0 !== calcDateDifferenceInDays(flightPair?.arrivalLeg?.ata, flightPair?.arrivalLeg?.std, timezone)"
                                  indicatorType="text"
                                  [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.arrivalLeg?.ata, flightPair?.arrivalLeg?.std, timezone)"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="IN PAX" [text]="flightPair.arrivalLeg?.inPax" [ngbTooltip]="inPax"></app-icon-text-item-small>
        <app-icon-text-item-small textHeader="GATE" [text]="flightPair.arrivalLeg?.arrivalGate" [ngbTooltip]="inGate"></app-icon-text-item-small>
        <app-icon-text-item-small textHeader="DL" [text]="flightPair.arrivalLeg?.totalDelay" [ngbTooltip]="inDl" [indicator]="!!flightPair.arrivalLeg?.totalDelay" indicatorType="text"
                                  [indicatorText]="(flightPair.arrivalLeg?.delays) ? flightPair.arrivalLeg.delays.length.toString() : ''"></app-icon-text-item-small>

        <ng-template #inPax>
          <div *ngIf="!flightPair?.arrivalLeg?.pax" class="container">
            <div class="row">
              <div class="col-8">First:</div>
              <div class="col-4">2</div>
              <div class="col-8">Business:</div>
              <div class="col-4">23</div>
              <div class="col-8">Economy:</div>
              <div class="col-4">139</div>
            </div>
          </div>
          <div class="row" *ngIf="!flightPair?.arrivalLeg?.pax">
            <div class="col-12">none</div>
          </div>
          <div *ngIf="flightPair?.arrivalLeg?.pax" class="container">
            <div class="row">
              <ng-container *ngFor="let paxItem of flightPair.arrivalLeg.pax | keyvalue">
                <div class="col-7 text-nowrap">{{paxItem?.value?.description}}:</div>
                <div class="col-5">{{paxItem?.value?.amount}}</div>
              </ng-container>
            </div>
          </div>
        </ng-template>
        <ng-template #inGate>
          <div class="container" *ngIf="!flightPair">
            <div class="row">
              <div class="col-8">Aircraft stand:</div>
              <div class="col-4">F13</div>
              <div class="col-8 text-nowrap">Passenger gate:</div>
              <div class="col-4">F13</div>
            </div>
          </div>
          <div class="container" *ngIf="!flightPair?.arrivalLeg">
            <div class="row">
              <div class="col-8">Aircraft stand:</div>
              <div class="col-4">none</div>
              <div class="col-8 text-nowrap">Passenger gate:</div>
              <div class="col-4">none</div>
            </div>
          </div>
          <div class="container" *ngIf="flightPair?.arrivalLeg">
            <div class="row">
              <div class="col-8">Aircraft stand:</div>
              <div class="col-4">{{flightPair.arrivalLeg?.arrivalStand}}</div>
              <div class="col-8 text-nowrap">Passenger gate:</div>
              <div class="col-4">{{flightPair.arrivalLeg?.arrivalGate}}</div>
            </div>
          </div>
        </ng-template>
        <ng-template #inDl>
          <div class="container">
            <div class="row">
              <div class="col-12">Departure Delay:</div>
              <div *ngIf="!flightPair?.arrivalLeg?.departureDelays" class="col-12">None</div>
              <ng-container *ngIf="flightPair?.arrivalLeg?.departureDelays">
                <ng-container *ngFor="let delay of flightPair.arrivalLeg.departureDelays | keyvalue:orderObjectKeyValues">
                  <div class="col-6">{{delay.key}}</div>
                  <div class="col-6">{{delay.value | timeFromMinutes}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!flightPair">
                <div class="col-6">32:</div>
                <div class="col-6">32:00</div>
                <div class="col-6">12:</div>
                <div class="col-6">16:30</div>
                <div class="col-6">22:</div>
                <div class="col-6">13:00</div>
                <div class="col-6">48:</div>
                <div class="col-6">05:00</div>
              </ng-container>
              <div class="col-12">Arrival Delay:</div>
              <div *ngIf="!flightPair?.arrivalLeg?.arrivalDelays" class="col-12">None</div>
              <ng-container *ngIf="flightPair?.arrivalLeg?.arrivalDelays">
                <ng-container *ngFor="let delay of flightPair.arrivalLeg.arrivalDelays | keyvalue:orderObjectKeyValues">
                  <div class="col-6">{{delay.key}}</div>
                  <div class="col-6">{{delay.value | timeFromMinutes}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!flightPair">
                <div class="col-6">32:</div>
                <div class="col-6">32:00</div>
                <div class="col-6">12:</div>
                <div class="col-6">16:30</div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="to-flight-container">
      <div class="flight-info-headline-container padding-bottom-15">
        <span>Departing Flight</span>
        <span *ngIf="!flightPair" class="margin-left-30">VIE 13:15 - CDG 14:45</span>
        <span *ngIf="flightPair?.departureLeg" class="margin-left-30">
          {{flightPair.departureLeg.departureStation + ' ' + (flightPair.departureLeg.std | date:'HH:mm':timezone) + ' - ' + flightPair.departureLeg.arrivalStation + ' ' + (flightPair.departureLeg.sta | date:'HH:mm':timezone)}}
        </span>
        <span *ngIf="flightPair && !flightPair.departureLeg" class="margin-left-30">-</span>
      </div>

      <div *ngIf="!flightPair" class="flight-info-data-container">

        <app-icon-text-item-small textHeader="ATD"
                                  text=""
                                  [indicator]="true"
                                  indicatorType="text"
                                  indicatorText="+1"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="TAKE OFF"
                                  text=""
                                  [indicator]="true"
                                  indicatorType="text"
                                  indicatorText="+1"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="ETA"
                                  text=""
                                  [indicator]="true"
                                  indicatorType="text"
                                  indicatorText="+1"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="OUT PAX"
                                  text="149"
                                  [ngbTooltip]="outPax"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="GATE"
                                  text="F5"
                                  [ngbTooltip]="outGate"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="DL"
                                  text="-"
                                  [ngbTooltip]="outDl"
                                  [indicator]="false"
        ></app-icon-text-item-small>
        <!-- <app-icon-text-item-small textHeader="DL CODE" text=""></app-icon-text-item-small> -->

        <ng-template #outPax>
          <div class="container">
            <div class="row" *ngIf="!flightPair">
              <div class="col-8">First:</div>
              <div class="col-4">0</div>
              <div class="col-8">Business:</div>
              <div class="col-4">10</div>
              <div class="col-8">Economy:</div>
              <div class="col-4">139</div>
            </div>
            <div class="row" *ngIf="!flightPair?.departureLeg?.pax">
              <div class="col-12">none</div>
            </div>
            <div *ngIf="flightPair?.departureLeg?.pax" class="container">
              <div class="row">
                <ng-container *ngFor="let paxItem of flightPair.departureLeg.pax | keyvalue">
                  <div class="col-7 text-nowrap">{{paxItem?.value?.description}}:</div>
                  <div class="col-5">{{paxItem?.value?.amount}}</div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #outGate>
          <div class="container">
            <div class="row">
              <div class="col-8">Aircraft stand:</div>
              <div class="col-4">32F</div>
              <div class="col-8 text-nowrap">Passenger gate:</div>
              <div class="col-4">5F</div>
            </div>
          </div>
        </ng-template>
        <ng-template #outDl>
          <div class="container">
            <div class="row">
              <div class="col-12">Departure Delay:</div>
              <div *ngIf="!flightPair?.departureLeg?.departureDelays" class="col-12">None</div>
              <ng-container *ngIf="flightPair?.departureLeg?.departureDelays">
                <ng-container *ngFor="let delay of flightPair.departureLeg.departureDelays | keyvalue">
                  <div class="col-6">{{delay.key}}</div>
                  <div class="col-6">{{delay.value}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!flightPair">
                <div class="col-6">32:</div>
                <div class="col-6">32:00</div>
                <div class="col-6">12:</div>
                <div class="col-6">16:30</div>
                <div class="col-6">22:</div>
                <div class="col-6">13:00</div>
                <div class="col-6">48:</div>
                <div class="col-6">05:00</div>
              </ng-container>
              <div class="col-12">Arrival Delay:</div>
              <div *ngIf="!flightPair?.departureLeg?.arrivalDelays" class="col-12">None</div>
              <ng-container *ngIf="flightPair?.departureLeg?.arrivalDelays">
                <ng-container *ngFor="let delay of flightPair.departureLeg.arrivalDelays | keyvalue">
                  <div class="col-6">{{delay.key}}</div>
                  <div class="col-6">{{delay.value}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!flightPair">
                <div class="col-6">32:</div>
                <div class="col-6">32:00</div>
                <div class="col-6">12:</div>
                <div class="col-6">16:30</div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </div>

      <div *ngIf="flightPair" class="flight-info-data-container">

        <app-icon-text-item-small textHeader="ATD"
                                  [text]="flightPair.departureLeg?.atd | date:'HH:mm':timezone"
                                  [indicator]="0 !== calcDateDifferenceInDays(flightPair?.departureLeg?.atd, flightPair?.arrivalLeg?.std, timezone)"
                                  indicatorType="text"
                                  [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.departureLeg?.atd, flightPair?.arrivalLeg?.std, timezone)"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="TAKE OFF"
                                  [text]="flightPair.departureLeg?.takeOffTime | date:'HH:mm':timezone"
                                  [indicator]="0 !== calcDateDifferenceInDays(flightPair?.departureLeg?.takeOffTime, flightPair?.arrivalLeg?.std, timezone)"
                                  indicatorType="text"
                                  [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.departureLeg?.takeOffTime, flightPair?.arrivalLeg?.std, timezone)"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="ETA"
                                  [text]="flightPair.departureLeg?.eta | date:'HH:mm':timezone"
                                  [indicator]="0 !== calcDateDifferenceInDays(flightPair?.departureLeg?.eta, flightPair?.arrivalLeg?.std, timezone)"
                                  indicatorType="text"
                                  [indicatorText]="'+' + calcDateDifferenceInDays(flightPair?.departureLeg?.eta, flightPair?.arrivalLeg?.std, timezone)"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="OUT PAX"
                                  text="149"
                                  [ngbTooltip]="outPax"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="GATE"
                                  text="F5"
                                  [ngbTooltip]="outGate"
        ></app-icon-text-item-small>

        <app-icon-text-item-small textHeader="DL"
                                  text="-"
                                  [ngbTooltip]="outDl"
                                  [indicator]="false"
        ></app-icon-text-item-small>
        <!-- <app-icon-text-item-small textHeader="DL CODE" text=""></app-icon-text-item-small> -->

        <ng-template #outPax>
          <div class="container">
            <div class="row">
              <div class="col-8">First:</div>
              <div class="col-4">0</div>
              <div class="col-8">Business:</div>
              <div class="col-4">10</div>
              <div class="col-8">Economy:</div>
              <div class="col-4">139</div>
            </div>
          </div>
        </ng-template>
        <ng-template #outGate>
          <div class="container">
            <div class="row">
              <div class="col-8">Aircraft stand:</div>
              <div class="col-4">32F</div>
              <div class="col-8 text-nowrap">Passenger gate:</div>
              <div class="col-4">5F</div>
            </div>
          </div>
        </ng-template>
        <ng-template #outDl>
          <div class="container">
            <div class="row">
              <div class="col-12">
                Delay:
              </div>
              <div class="col-12">
                None
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="processes-container border-bottom-gray margin-top-15 padding-bottom-15">
    <div class="container-fluid ps-0">
      <div class="row mb-2">
        <div class="col-12 font-20">Turnaround Processes</div>
      </div>
      <div class="row ps-3 mb-3">
        <div class="col-6 ps-3 font-14">
          <span *ngIf="true" class="ps-3">17:59 remaining to TOBT/ETD/STD</span>
          <span *ngIf="false" class="ps-3">{{turnaroundProgress?.text}}</span>
        </div>
        <div class="col-6 pe-3 text-end font-14">
          <span class="pe-3" *ngIf="false">{{turnaroundProgress?.total}} in total</span>
          <span class="pe-3" *ngIf="true">48:00 in total</span>
        </div>
        <div class="col-12 mt-1">
          <ngb-progressbar type="success" [value]="(turnaroundProgress && 0 !== turnaroundProgress.percentage) ? turnaroundProgress.percentage : 62" [striped]="true"></ngb-progressbar>
        </div>
      </div>
      <div *ngIf="flightPair?.vie?.processes" class="row ps-3">
        <div class="col-4" *ngFor="let process of flightPair?.vie?.processes | keyvalue">
          <app-deprecated-ta-process [done]="process.value?.finished" [name]="process.value?.name | titlecase" [isSla]="process.value?.isSla" [starttime]="process.value?.starttime | date:'HH:mm'" [endtime]="process?.endtime | date:'HH:mm'"></app-deprecated-ta-process>
        </div>
      </div>
      <div *ngIf="!flightPair || !flightPair?.vie" class="row ps-3">
        <div class="col-4">
          <app-ta-process [done]="true" name="De-boarding" [isSla]="true" starttime="12:07" endtime="12:17"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="Refueling" [isSla]="true" starttime="12:10" endtime="12:25"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="De-icing" starttime="12:10" endtime="12:30"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="Catering" [isSla]="true" starttime="12:10" endtime="12:40"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="Airconditioning" starttime="12:10" endtime="12:40"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="Towing" [isSla]="true" starttime="12:10" endtime="12:40"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Cleaning" [isSla]="true" starttime="12:17" endtime="12:45"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Air Starting" starttime="12:30" endtime="13:00"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Lavatory Service" [isSla]="true" starttime="12:30" endtime="13:10"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Powering" [isSla]="true" starttime="12:30" endtime="13:10"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Engine Checking" [isSla]="true" starttime="12:45" endtime="12:40"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Guiding" [isSla]="true" starttime="12:45" endtime="12:40"></app-ta-process>
        </div>
      </div>
    </div>
  </div>
  <div class="equipments-container border-bottom-gray margin-top-15 padding-bottom-15">
    <div class="equipments-headline">Turnaround Equipments</div>
    <div *ngIf="flightPair?.vie?.processes" class="equipments-list-container px-3">
      <ng-container *ngFor="let process of flightPair?.vie?.processes | keyvalue">
        <app-equipment-icon [equipment]="process?.value?.iconId | uppercase"></app-equipment-icon>
      </ng-container>
    </div>
    <div *ngIf="!flightPair || !flightPair?.vie?.processes" class="equipments-list-container px-3">
      <ng-container *ngFor="let equipment of equipmentList">
        <app-equipment-icon [equipment]="equipment"></app-equipment-icon>
      </ng-container>
    </div>
  </div>
  <div class="equipments-container margin-top-15 padding-bottom-15">
    <div class="action-button-container">
      <button class="btn button-blue mx-1 action-button text-white" (click)="openMemo()">Memo</button>
      <button class="btn button-blue mx-1 action-button text-white" (click)="openChangeFlightDetails()">Change Flight Details</button>
<!--      <button class="btn button-blue mx-1 action-button text-white" (click)="openIssueETD()">Issue ETD</button>-->
<!--      <button class="btn button-blue mx-1 action-button text-white" (click)="openIssueNI()">Issue New Information</button>-->
    </div>
  </div>
</div>
