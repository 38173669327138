import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-add-row',
  templateUrl: './add-row.component.html',
  styleUrls: ['./add-row.component.scss']
})
export class AddRowComponent implements OnInit {

  @Input() isDisabled: boolean;
  @Input() buttonText = 'Add';
  @Output() addClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
