import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../services/toast.service';
import { UserRoles } from '../../../../../shared/constants/user-roles.constants';
import { IUser } from '../../../../../shared/models/user.model';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { IGlobalCountry } from '../../../../../shared/models/global-country.model';
import { UserService } from '../../../../../services/user.service';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, Subject } from 'rxjs';
import { IAirport } from '../../../../../shared/models/airport.model';
import { IAirlineDesignator } from '../../../../../shared/models/airline-designator.model';
import { CountriesService } from '../../../../../services/countries.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-user-dialog',
  templateUrl: './add-edit-user-dialog.component.html',
  styleUrls: ['./add-edit-user-dialog.component.scss']
})
export class AddEditUserDialogComponent implements OnInit, OnDestroy {

  form?: FormGroup;
  isBusy = false;
  title?: string;
  user?: IUser;
  countryList?: IGlobalCountry[];
   airportContainer?: IAirport[];
  airlineDesignators: IAirlineDesignator[];
  // Country with name as key
  countriesKV?: IGenericContainerObject<IGlobalCountry> = {};
  userRoleObject = UserRoles;
  locationListObject: {[key: string]: string} = {};
  constructor(private generalSettingsService: GeneralSettingsService, private activeModal: NgbActiveModal, private toastService: ToastService, private userService: UserService, private countriesService: CountriesService) {
    this.countriesService.countries.pipe(takeUntil(this.unsubscribe$)).subscribe((countries) => {
      this.countryList = countries;
      for (const country of countries) {
        this.countriesKV[country.title] = country;
      }
    });
  }

  unsubscribe$ = new Subject();
  ngOnDestroy() {
      this.unsubscribe$.next(undefined);
      this.unsubscribe$.complete();
    }

  ngOnInit(): void {
    this.form = new FormGroup({
      firstname: new FormControl(this.user?.firstname || '', Validators.required),
      lastname: new FormControl(this.user?.lastname || '', Validators.required),
      email: new FormControl(this.user?.email || '', [Validators.required, Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
      username: new FormControl(this.user?.username || ''),
      role: new FormControl(this.user?.role || '', Validators.required),
      location: new FormControl(this.user?.location || '', Validators.required),
      phone: new FormControl(this.user?.phone || '', Validators.pattern(/^\+[1-9]\d{1,14}$/)),
      airline: new FormControl(this.user?.airline || 'Default Airline', Validators.required),
      country: new FormControl(this.countryList.find((country) => country.id === this.user?.countryId)?.title || ''),
      airlineDesignator: new FormControl(this.user?.airlineDesignatorId || Object.values(this.airlineDesignators)[0].id || null),
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    const data = { ...this.form.value, id: this.user?.id, countryId: this.countriesKV[this.form.value.country]?.id || 0 };
    this.isBusy = true;
    this.userService.saveUser(data).subscribe((result) => {
      if(result?.id) {
        this.toastService.showSuccess("User has been saved");
        this.closeModal();
      }
      this.isBusy = false;
    });

  }

  searchCountry: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.countryList.map((country) => country.title).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  onCountryBlur() {
    this.form.get('country').setValue(this.form.value.country.toUpperCase());
    if (!this.countriesKV[this.form.value.country]?.id) {
      this.form.get('country').setErrors({
        error: true,
      });
    }
  }
}
