import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-indicator-text',
  templateUrl: './indicator-text.component.html',
  styleUrls: ['./indicator-text.component.scss']
})
export class IndicatorTextComponent implements OnInit {

  @Input() position: string;
  @Input() text: string;
  @Input() size40: boolean;
  @Input() marginTop?: number;
  @Input() marginLeft?: number;
  @Input() width?: number;
  @Input() height?: number;
  @Input() fontSize?: string = '12px';
  @Input() fontWeight?: number = 400;

  constructor() { }

  ngOnInit() {}

}
