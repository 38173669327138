import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { firstValueFrom, map, Observable, Subject, Subscription } from 'rxjs';
import { IFlightPair } from '../shared/models/flight-pair.model';
import * as dayjs from 'dayjs';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { takeUntil } from 'rxjs/operators';
import { FlightsFilterService } from './flights-filter.service';
import { IFlightsFilter } from '../shared/models/filters/flights-filter.model';
import { IPairDetail } from '../shared/models/pair-detail.model';
import firebase from 'firebase/compat';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import { convertTimestamps } from '../shared/utils/converter/timestamp-converter';

@Injectable({
  providedIn: 'root'
})
export class FlightPairService implements OnDestroy {
  firestoreFlatPairSubject = new Subject();
  firestoreFlatPairSubscription: Subscription;
  firestoreLastSnapshot: DocumentSnapshot<IPairDetail>;
  firestoreFirstSnapshot: DocumentSnapshot<IPairDetail>;
  hasNextPage = true;
  pageSize = 20;
  pageNumber = 1;


  flightsFilterValues: IFlightsFilter;

  unsubscribe$ = new Subject();

  constructor(private fireDb: AngularFireDatabase, private fireStore: AngularFirestore, private flightsFilterService: FlightsFilterService) {
   // this.subscribeFlightsFilter();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getPairs(previous: boolean, dateKey: string): Observable<IFlightPair[]> {
    console.log('dateKey = ' + dateKey);
    if (!dateKey) {
      // const now = dayjs('010819', 'DDMMYY').format();
      const now = dayjs().format();
      console.log('now = ' + now);
      console.log('FLIGHTPAIRSERVICE: getPairs called');
      return this.fireDb.list<IFlightPair>('planning/pairs', ref => ref.orderByChild('dateOfOperation').startAt(now).limitToFirst(20)).valueChanges();
    } else {
      if (previous) {
        return this.fireDb.list<IFlightPair>('planning/pairs',
          ref => ref.orderByChild('dateOfOperation').endAt(dateKey).limitToLast(20))
          .valueChanges();
      } else {
        return this.fireDb.list<IFlightPair>('planning/pairs',
          ref => ref.orderByChild('dateOfOperation').startAt(dateKey).limitToFirst(20))
          .valueChanges();
      }
    }
  }

  getPairsFirestore(previous: boolean, flatPair: IPairDetail): Observable<any> {
    this.fetchFirestoreFlatPairs(previous, flatPair);
    return this.firestoreFlatPairSubject.asObservable();
  }

  private subscribeFlightsFilter() {
    this.flightsFilterService.getFilterValues().pipe(takeUntil(this.unsubscribe$)).subscribe((item: IFlightsFilter) => {
      this.flightsFilterValues = item;
      this.fetchFirestoreFlatPairs();
    });
  }

  private fetchFirestoreFlatPairs(previous?: boolean, flatPair?: IPairDetail) {
    if (this.firestoreFlatPairSubscription) {
      this.firestoreFlatPairSubscription.unsubscribe();
    }

    let firestoreObservable;

    firestoreObservable = this.fireStore.collection<IPairDetail>('pairs',
      (ref) => this.generateFirestoreQuery(ref, previous, flatPair)
    ).valueChanges().pipe(map(list => {
      console.table(list, ['id']);
      if(!list?.length)
      {
        return [];
      }
      //this.hasNextPage = list.length > this.pageSize;
      let newList = list;
      // if(newList.length >= this.pageSize) {
      //     newList = list.slice(0, this.pageSize);
      // }
      firstValueFrom(this.fireStore.collection<IPairDetail>('pairs').doc(newList[newList.length-1].id.toString()).get()).then((result) => {
        this.firestoreLastSnapshot = result;
      });
      firstValueFrom(this.fireStore.collection<IPairDetail>('pairs').doc(newList[0].id.toString()).get()).then((result) => {
        this.firestoreFirstSnapshot = result;
      });
      return newList.map((val) => {
        convertTimestamps(val);
        return val;
      })
    }));

    /*
    if (!this.flightsFilterValues) {
      firestoreObservable = this.fireStore.collection<IFlightPair>('plannedPairs',
        ref => ref.orderBy('dateOfOperation').limit(40)
      ).valueChanges();
      console.log('bla blubb');
    } else {
      console.log('list:', this.flightsFilterValues.getListOfNonEmptyProperties());

      const list: { name: string, operator: string, value: any }[] = this.flightsFilterValues.getListOfNonEmptyProperties();

      if (!list || list.length === 0) {
        firestoreObservable = this.fireStore.collection<IFlightPair>('plannedPairs',
          ref => ref.limit(40)
        ).valueChanges();

      } else if (list.length === 1) {
        firestoreObservable = this.fireStore.collection<IFlightPair>('plannedPairs',
          ref => ref.where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
        ).valueChanges();

      } else if (list.length === 2) {
        firestoreObservable = this.fireStore.collection<IFlightPair>('plannedPairs',
          ref => ref
            .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
            .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value)
        ).valueChanges();

      } else if (list.length === 3) {
        firestoreObservable = this.fireStore.collection<IFlightPair>('plannedPairs',
          ref => ref
            .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
            .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value)
            .where(list[2].name, list[2].operator as WhereFilterOp, list[2].value)
        ).valueChanges();

      } else if (list.length === 4) {
        firestoreObservable = this.fireStore.collection<IFlightPair>('plannedPairs',
          ref => ref
            .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
            .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value)
            .where(list[2].name, list[2].operator as WhereFilterOp, list[2].value)
            .where(list[3].name, list[3].operator as WhereFilterOp, list[3].value)
        ).valueChanges();

      } else if (list.length === 5) {
        firestoreObservable = this.fireStore.collection<IFlightPair>('plannedPairs',
          ref => ref
            .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
            .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value)
            .where(list[2].name, list[2].operator as WhereFilterOp, list[2].value)
            .where(list[3].name, list[3].operator as WhereFilterOp, list[3].value)
            .where(list[4].name, list[4].operator as WhereFilterOp, list[4].value)
        ).valueChanges();

      } else if (list.length === 6) {
        firestoreObservable = this.fireStore.collection<IFlightPair>('plannedPairs',
          ref => ref
            .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
            .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value)
            .where(list[2].name, list[2].operator as WhereFilterOp, list[2].value)
            .where(list[3].name, list[3].operator as WhereFilterOp, list[3].value)
            .where(list[4].name, list[4].operator as WhereFilterOp, list[4].value)
            .where(list[5].name, list[5].operator as WhereFilterOp, list[5].value)
        ).valueChanges();

      } else {
        firestoreObservable = this.fireStore.collection<IFlightPair>('plannedPairs',
          ref => ref.limit(40)
        ).valueChanges();
      }
    } */

    this.firestoreFlatPairSubscription = firestoreObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(item => {
      console.log('item:', item);
      this.firestoreFlatPairSubject.next(item);
    });
  }

  private generateFirestoreQuery(ref: CollectionReference, previous?: boolean, flatPair?: IPairDetail): Query {
    if (this.firestoreFlatPairSubscription) {
      this.firestoreFlatPairSubscription.unsubscribe();
    }

    let firestoreQuery: Query = null;

    if (!this.flightsFilterValues) {
      // firestoreQuery = ref; // .orderBy('dateOfOperation').limit(40);
      console.log('no filter values');

    } else {
      // console.log('list:', this.flightsFilterValues.getListOfNonEmptyProperties());
      //
      // const list: IFilterListItem[] = this.flightsFilterValues.getListOfNonEmptyProperties();
      //
      // if (!list || list.length === 0) {
      // //  firestoreQuery = ref; // .limit(40);
      //
      // } else {
      //   list.forEach((item: IFilterListItem) => {
      //     if (!firestoreQuery) {
      //       firestoreQuery = ref.where(item.name, item.operator as WhereFilterOp, item.value);
      //     } else {
      //       firestoreQuery = firestoreQuery.where(item.name, item.operator as WhereFilterOp, item.value);
      //     }
      //   });
      // }

      /* else if (list.length === 1) {
        firestoreQuery = ref.where(list[0].name, list[0].operator as WhereFilterOp, list[0].value);

      } else if (list.length === 2) {
        firestoreQuery = ref
          .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
          .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value);

      } else if (list.length === 3) {
        firestoreQuery = ref
          .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
          .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value)
          .where(list[2].name, list[2].operator as WhereFilterOp, list[2].value);

      } else if (list.length === 4) {
        firestoreQuery = ref
          .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
          .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value)
          .where(list[2].name, list[2].operator as WhereFilterOp, list[2].value)
          .where(list[3].name, list[3].operator as WhereFilterOp, list[3].value);

      } else if (list.length === 5) {
        firestoreQuery = ref
          .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
          .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value)
          .where(list[2].name, list[2].operator as WhereFilterOp, list[2].value)
          .where(list[3].name, list[3].operator as WhereFilterOp, list[3].value)
          .where(list[4].name, list[4].operator as WhereFilterOp, list[4].value);

      } else if (list.length === 6) {
        firestoreQuery = ref
          .where(list[0].name, list[0].operator as WhereFilterOp, list[0].value)
          .where(list[1].name, list[1].operator as WhereFilterOp, list[1].value)
          .where(list[2].name, list[2].operator as WhereFilterOp, list[2].value)
          .where(list[3].name, list[3].operator as WhereFilterOp, list[3].value)
          .where(list[4].name, list[4].operator as WhereFilterOp, list[4].value)
          .where(list[5].name, list[5].operator as WhereFilterOp, list[5].value);

      } else {
        firestoreQuery = ref.limit(40);
      } */
    }

    if (!this.flightsFilterValues?.showUnpaired) {
      firestoreQuery = ref.orderBy('groundTime');
    }

    let orderBy = 'dateOfOperation';
    if(this.flightsFilterValues?.sortBy && this.flightsFilterValues?.sortBy !== "null") {
      switch(this.flightsFilterValues.sortBy) {
        case "REG":
          orderBy = 'acRegistration';
          break;
        case "ETA":
          orderBy = "eta";
          break;
        case "STD":
          orderBy = "std";
          break;
        case "ETD":
          orderBy = "etd";
          break;
      }
    }

    if (firestoreQuery) {
      firestoreQuery = firestoreQuery.orderBy('dateOfOperation');
    } else {
      firestoreQuery = ref.orderBy('dateOfOperation');
    }
    // firestoreQuery = firestoreQuery.orderBy('acRegistration');



    if (flatPair) {
      console.log('has flatpair: ', flatPair);
      console.log('previous: ', previous);

      // if(this.firestoreLastSnapshot) {
      //   if (!previous)
      //   {
      //     firestoreQuery = firestoreQuery.startAfter(this.firestoreLastSnapshot);
      //   }
      //   else {
      //     firestoreQuery = firestoreQuery.endAt(this.firestoreFirstSnapshot);
      //   }
      //
      // }

    }

    // Adding +1 for pagination purposes
    // if(!previous)
    // {
    //   firestoreQuery = firestoreQuery.limit(this.pageSize + 1);
    // }
    // else {
    //   firestoreQuery = firestoreQuery.limitToLast(this.pageSize + 1);
    // }


    console.log('firestore query: ', firestoreQuery);

    return firestoreQuery;
  }

}
