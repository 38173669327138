import { Component, EventEmitter, OnInit } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertSeverity } from '../../shared/constants/alert-severity.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { IUser } from '../../shared/models/user.model';
import { IAlertsModel } from '../../shared/models/alerts.model';
import { stationsFromAlert } from '../../shared/utils/utils';

@Component({
  selector: 'app-alerts-new',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsNewComponent implements OnInit {
  localAlertSeverity = AlertSeverity;
  severityFilters: AlertSeverity[] = [AlertSeverity.CRITICAL, AlertSeverity.INTERMEDIATE, AlertSeverity.LOW];
  exclamationMarkIcon = faExclamationCircle;
  currentUser: IUser;
  unsubscribe$ = new Subject();
  activeAlert: IAlertsModel;
  stations: string[] = [];

  activeAlertChanged(alert: IAlertsModel) {
    this.stations = stationsFromAlert(alert);
    this.activeAlert = alert;
  }

  formGroup = new FormGroup({
    searchText: new FormControl(''),
  });
  severityChanged = new EventEmitter<void>();
  constructor( private authService: AuthService ) { }

  ngOnInit(): void {
    this.subscribeToCurrentUser();
  }

  setSeverityFilter(filters: AlertSeverity[]): void {
    if (this.severityFilters === filters) {
      this.severityFilters = null;
      return;
    }
    this.severityFilters = filters;
  }

  subscribeToCurrentUser() {
    this.authService.userSubject.asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => this.currentUser = user);
  }

  severitySwitched(severity: AlertSeverity) {
    const index = this.severityFilters.findIndex((item) => item === severity);
    if(index !== -1) {
      this.severityFilters.splice(index, 1);
      this.severityChanged.emit();
      return;
    }
    this.severityFilters.push(severity);
    this.severityChanged.emit();
  }
}
