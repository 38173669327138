export class ViewTypes {
  static FLIGHTS_VIEW_FURTHER_INFORMATION = 'FLIGHTS_VIEW_FURTHER_INFORMATION';
  static FLIGHTS_CHANGE_FLIGHT_DETAILS = 'FLIGHTS_CHANGE_FLIGHT_DETAILS';
  static FLIGHTS_VIEW_MEMO = 'FLIGHTS_VIEW_MEMO';

  static FLIGHTS_ADD_FLIGHTS = 'FLIGHTS_ADD_FLIGHTS';
  static FLIGHTS_MODIFY_FLIGHTS = 'FLIGHTS_MODIFY_FLIGHTS';
  static FLIGHTS_CANCEL_FLIGHTS = 'FLIGHTS_CANCEL_FLIGHTS';

  static SETTINGS_MANAGE_PASSENGER_CLASSES = 'SETTINGS_MANAGE_PASSENGER_CLASSES';
  static SETTINGS_MANAGE_FLIGHT_SERVICE_TYPES = 'SETTINGS_MANAGE_FLIGHT_SERVICE_TYPES';
  static SETTINGS_MANAGE_DELAY_CODES = 'SETTINGS_MANAGE_DELAY_CODES';
  static SETTINGS_MANAGE_AIRLINE_CODES = 'SETTINGS_MANAGE_AIRLINE_CODES';
  static SETTINGS_MANAGE_AIRPORT_CONTACT_PEOPLE = 'SETTINGS_MANAGE_AIRPORT_CONTACT_PEOPLE';
  static SETTINGS_MANAGE_AIRPORT_CURFEW_TIMES = 'SETTINGS_MANAGE_AIRPORT_CURFEW_TIMES';
  static SETTINGS_MANAGE_AIRPORT_TERMINALS = 'SETTINGS_MANAGE_AIRPORT_TERMINALS';
  static SETTINGS_MANAGE_MEASUREMENT_UNITS = 'SETTINGS_MANAGE_MEASUREMENT_UNITS';

  static SETTINGS_VIEW_PASSENGER_CLASSES = 'SETTINGS_VIEW_PASSENGER_CLASSES';
  static SETTINGS_VIEW_FLIGHT_SERVICE_TYPES = 'SETTINGS_VIEW_FLIGHT_SERVICE_TYPES';
  static SETTINGS_VIEW_DELAY_CODES = 'SETTINGS_VIEW_DELAY_CODES';
  static SETTINGS_VIEW_MEASUREMENT_UNITS = 'SETTINGS_VIEW_MEASUREMENT_UNITS';
  static SETTINGS_VIEW_AIRLINE_CODES = 'SETTINGS_VIEW_AIRLINE_CODES';
  static SETTINGS_VIEW_TURNAROUND_THRESHOLD = 'SETTINGS_VIEW_TURNAROUND_THRESHOLD';
  static SETTINGS_VIEW_SLOT_TIME_TOLERANCE = 'SETTINGS_VIEW_SLOT_TIME_TOLERANCE';
  static SETTINGS_VIEW_AIRPORT_CONTACT_PEOPLE = 'SETTINGS_VIEW_AIRPORT_CONTACT_PEOPLE';
  static SETTINGS_VIEW_AIRPORT_CURFEW_TIMES = 'SETTINGS_VIEW_AIRPORT_CURFEW_TIMES';
  static SETTINGS_VIEW_AIRPORT_MINIMAL_TURNAROUND_TIMES = 'SETTINGS_VIEW_AIRPORT_MINIMAL_TURNAROUND_TIMES';
  static SETTINGS_VIEW_AIRPORT_TERMINALS = 'SETTINGS_VIEW_AIRPORT_TERMINALS';
  static SETTINGS_VIEW_AIRPORT_TIMEZONE = 'SETTINGS_VIEW_AIRPORT_TIMEZONE';
}
