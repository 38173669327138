<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
<div class="settings-container flex flex-column">
  <ng-container *ngIf="flightServiceTypes">
    <div class="item-list" *ngFor="let item of flightServiceTypes | keyvalue">
      <app-settings-list
        [opened]="selected === item?.key"
        [noOpenedContent]="true"
        [noEditDeleteBar]="readonly"
        (editClicked)="doEdit(item?.key)"
        (deleteClicked)="doDelete(item?.key)">

        <app-manage-flight-service-types-dialog-list-item
          [opened]="selected === item?.key"
          [item]="item?.value"
          [shallNotOpen]="readonly"
          (click)="selected = item?.key">
        </app-manage-flight-service-types-dialog-list-item>

      </app-settings-list>
    </div>
  </ng-container>
  <app-add-row *ngIf="!readonly" (addClicked)="doAdd()"></app-add-row>
</div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Close</button>
</div>
