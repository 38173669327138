import {FormGroup, AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ISimpleData} from '../models/simpleData.model';

/** At least one seating configuration has to be filled */

export const seatingConfigurationValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formVal = control ? control.value : null;

  if (!formVal) {
    return {seatConf: true};
  }

  let isValid = false;

  Object.values(formVal).forEach((item: any) => {
    if (item?.description) {
      isValid = true;
    }
  });

  return isValid ? null : {seatConfig: true};
};
