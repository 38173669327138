<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <div class="settings-container flex flex-column">
    <ng-container *ngIf="airlineDesignators">
      <div class="item-list" *ngFor="let item of airlineDesignators | keyvalue">
        <app-settings-list
          [opened]="selected === item?.key"
          [noOpenedContent]="true"
          [noEditDeleteBar]="readonly"
          (editClicked)="doEdit(item?.key)"
          (deleteClicked)="doDelete(item?.key)">

          <app-manage-airline-codes-dialog-list-item
            [opened]="selected === item?.key"
            [item]="item?.value"
            [shallNotOpen]="readonly"
            (click)="selected = item?.key">
          </app-manage-airline-codes-dialog-list-item>

        </app-settings-list>
      </div>
    </ng-container>
    <app-add-row *ngIf="!readonly" (addClicked)="doAdd()"></app-add-row>
  </div>
</div>
