import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AirportsService } from '../../../../../services/airports.service';
import { ToastService } from '../../../../../services/toast.service';
import { take, takeUntil } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';
import { IAirportCurfewTime } from '../../../../../shared/models/airport-curfew-time.model';
import { AddEditAirportCurfewTimeDialogComponent } from '../add-edit-airport-curfew-time-dialog/add-edit-airport-curfew-time-dialog.component';
import { WeekdaysService } from '../../../../../services/weekdays-service';

@Component({
  selector: 'app-manage-airport-curfew-time-dialog',
  templateUrl: './manage-airport-curfew-time-dialog.component.html',
  styleUrls: ['./manage-airport-curfew-time-dialog.component.scss']
})
export class ManageAirportCurfewTimeDialogComponent implements OnInit {

  title = "Manage Airport Curfew Times"
  @Input() data?: { [p: number]: IAirportCurfewTime };
  @Input() dataForm?: FormGroup;
  @Input() airportId?: number;
  @Input() readonly = true;
  @Output() nonClosingSaveAction = new EventEmitter();
  @Output() closeWindow = new EventEmitter();
  selected?: string | any;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private airportsService: AirportsService, private toastService: ToastService, private weekdaysService: WeekdaysService) { }

  ngOnInit() {
    this.fetchCurfewTimes();
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  fetchCurfewTimes() {
    this.airportsService.fetchAirportCurfewTimes(this.airportId).subscribe((result) => {
      const data = {};
      for (const item of result) {
        if(item?.weekdaysBooleanId) {
          this.weekdaysService.getWeekdaysById(item.weekdaysBooleanId).subscribe((weekdays) => {
            if(weekdays) {
              item.weekday = weekdays;
            }
          });
        }
        if(item.validFrom){
          item.validFrom = new Date(item?.validFrom);
        }
        if(item.validTo){
          item.validTo = new Date(item?.validTo);
        }
        data[item.id] = item;
      }
      this.data = data;
    });
  }

  doAdd() {
    const modalRef = this.modalService.open(AddEditAirportCurfewTimeDialogComponent, { size: 'xl'});
    modalRef.componentInstance.title = 'Add Airport Curfew Time';
    modalRef.componentInstance.curfewTimeData = {
      airportId: this.airportId,
    };
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchCurfewTimes();
    })
  }

  doEdit(item: number) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(AddEditAirportCurfewTimeDialogComponent, { size: 'xl'});
    modalRef.componentInstance.title = 'Edit Airport Curfew Time';
    modalRef.componentInstance.curfewTimeData = this.data[item];
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchCurfewTimes();
    })
  }

  doDelete(item: number) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Airport Curfew Time ' + this.data[item]?.openingTime + '-' + this.data[item]?.closingTime;

    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.airportsService.deleteAirportCurfewTime(item).subscribe((result) => {
        if(result) {
          this.weekdaysService.deleteWeekday(this.data[item]?.weekdaysBooleanId).subscribe((result) => {
            console.log('delete weekday result:', result);
          });
          this.toastService.showSuccess("The curfew time has been deleted.");
          this.fetchCurfewTimes();
        }
      });
    });
  }
}
