import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-flight-service-types-dialog-list-item',
  templateUrl: './manage-flight-service-types-dialog-list-item.component.html',
  styleUrls: ['./manage-flight-service-types-dialog-list-item.component.scss']
})
export class ManageFlightServiceTypesDialogListItemComponent implements OnInit {

  @Input() item: any;
  @Input() opened = false;
  @Input() shallNotOpen = false;

  constructor() { }

  ngOnInit(): void {
  }

}
