import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AirportsService } from '../../../../../services/airports.service';
import { ToastService } from '../../../../../services/toast.service';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { NgbDate, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { IAirport } from '../../../../../shared/models/airport.model';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { TurnaroundType } from '../../../../../shared/constants/turnaround-type.constants';
import { BehaviorSubject, debounceTime, distinctUntilChanged, firstValueFrom, forkJoin, map, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { SettingsAirportMainFormBuilder } from '../../../../../shared/forms/formBuilders/settings/settings-airport-main-form-builder';
import { AddEditAirportTimezoneDialogComponent } from '../add-edit-airport-timezone-dialog/add-edit-airport-timezone-dialog.component';
import { AddEditAirportSlotTimeToleranceDialogComponent } from '../add-edit-airport-slot-time-tolerance-dialog/add-edit-airport-slot-time-tolerance-dialog.component';
import { ManageAirportCurfewTimeDialogComponent } from '../manage-airport-curfew-time-dialog/manage-airport-curfew-time-dialog.component';
import { AddEditAirportTurnaroundTimesDialogComponent } from '../add-edit-airport-turnaround-times-dialog/add-edit-airport-turnaround-times-dialog.component';
import { IGeneralSettingsModel } from '../../../../../shared/models/general-settings.model';
import { IMasterAirportsModel } from '../../../../../shared/models/global-airport.model';
import { IMasterCountriesModel } from '../../../../../shared/models/master-countries.model';
import { CountriesService } from '../../../../../services/countries.service';
import { SettingsComponent } from '../../../settings.component';
import { ActivatedRoute, Router } from '@angular/router';
import { IAirportTerminals } from '../../../../../shared/models/airport-terminals.model';
import { IAirportContact } from '../../../../../shared/models/airport-contact.model';
import { ISlotTimeTolerance } from '../../../../../shared/models/slotTimeTolerance.model';
import { AddEditAirportTerminalDialogComponent } from '../add-edit-airport-terminal-dialog/add-edit-airport-terminal-dialog.component';
import { AddEditAirportContactComponent } from '../add-edit-airport-contact-dialog/add-edit-airport-contact-dialog.component';
import { ITimezone } from '../../../../../shared/models/timezone.model';
import { MinutesFromTimePipe } from '../../../../../shared/pipes/minutes-from-time.pipe';
import { TimeFromMinutesPipe } from '../../../../../shared/pipes/time-from-minutes.pipe';
import { ISimpleData } from '../../../../../shared/models/simpleData.model';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { IAirportCurfewTime } from '../../../../../shared/models/airport-curfew-time.model';
import { dateToNgbDate, ngbDateToDayjs } from 'src/app/shared/utils/utils';
import { WeekdaysService } from '../../../../../services/weekdays-service';
import { IAcType } from '../../../../../shared/models/ac-type.model';
import { FlightType } from '../../../../../shared/constants/flight-types.constants';
import { AcTypesService } from '../../../../../services/ac-types.service';
import { ITurnaroundTimes } from '../../../../../shared/models/turnaround-times.model';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';
import { IGlobalCountry } from '../../../../../shared/models/global-country.model';

@Component({
  selector: 'app-add-edit-airport-dialog',
  templateUrl: './add-edit-airport-dialog.component.html',
  styleUrls: ['./add-edit-airport-dialog.component.scss']
})
export class AddEditAirportDialogComponent implements OnInit {

  title = "Edit Airport";
  @Input() generalSettings?: IGeneralSettingsModel;
  @Input() outerSaveClicked?: Observable<any>;
  @Output() statusChanged = new EventEmitter();
  @Output() saveClicked = new EventEmitter();
  airport: IAirport;
  isBusy = false;
  loaded = true;

  masterAirportsKV?: IGenericContainerObject<IMasterAirportsModel>;
  masterCountriesKV?: IGenericContainerObject<IMasterCountriesModel>;
  flightServiceTypes: ISimpleData[];
  destroySubject = new Subject();
  turnaroundType?: TurnaroundType;
  formGroup?: FormGroup;
  timezoneFormGroup?: FormGroup;
  slotTimeToleranceFormGroup?: FormGroup;
  curfewTimesFormGroup?: FormGroup;
  airportId: number;
  airportTerminals: IAirportTerminals[];
  airportContacts: IAirportContact[];
  airportSlotTolerance: ISlotTimeTolerance;
  curfewTimeData: IAirportCurfewTime;
  timezoneData?: ITimezone;
  mon = false;
  tue = false;
  wed = false;
  thu = false;
  fri = false;
  sat = false;
  sun = false;
  from: any;
  to: any;
  hoveredDate: NgbDate;
  drp = false;

  fromDate: NgbDate;
  toDate: NgbDate;

  acTypes: IAcType[];
  activeAcTypeId: number = 0;
  // AC Type ID -> Flight Service Type From -> Flight Service Type To -> Time in minutes
  DOMDOM: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};
  DOMINT: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};
  INTINT: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};
  INTDOM: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};

  selectedAcType: IAcType;
  turnaroundTypes = TurnaroundType;
  turnaroundTimes: ITurnaroundTimes[];
  countryList: IGlobalCountry[] = [];

  constructor(private acTypeService: AcTypesService, private generalSettingsService: GeneralSettingsService, private weekdayService: WeekdaysService, private countriesService: CountriesService, private modalService: NgbModal, private titleCasePipe: TitleCasePipe, private fb: FormBuilder, private airportService: AirportsService, private toastService: ToastService, private parent: SettingsComponent, private route: ActivatedRoute, private router: Router, private cd: ChangeDetectorRef, private minsFromTimePipe: MinutesFromTimePipe, private timeFromMinutesPipe: TimeFromMinutesPipe) {
    this.airportId = Number((this.route.params as BehaviorSubject<any>).value.id) || null;
    const observables: Observable<any>[] = [
      this.generalSettingsService.getFlightServiceTypes(),
      this.acTypeService.fetchAcTypes(),
      this.countriesService.fetchCountries(),
    ];
    if (this.airportId) {
      observables.push(this.airportService.fetchAirports({
        id: this.airportId
      }));
      observables.push(this.airportService.fetchAirportTerminals(this.airportId));
      observables.push(this.airportService.fetchAirportContactCompanies(this.airportId));
      observables.push(this.airportService.fetchAirportSlotTimeTolerance(this.airportId));

    }
    forkJoin(observables).subscribe((results) => {
      this.flightServiceTypes = results[0];
      this.acTypes = results[1];
      this.countryList = results[2];
      if (this.airportId) {
        this.airport = results[3]?.[0];
        if (!this.airport) {
          this.router.navigate(['..'], { relativeTo: this.route });
          return;
        }
        this.airportTerminals = results[4];
        this.airportContacts = results[5];
        this.airportSlotTolerance = results[6]?.[0];
      }
      if (this.acTypes.length) {
        this.selectedAcType = this.acTypes[0];
        this.selectedAcTypeChanged();
      }

      const depEarlyMinutes = this.timeFromMinutesPipe.transform(this.airportSlotTolerance?.departureEarlyInMinutes || 0);
      const depLateMinutes = this.timeFromMinutesPipe.transform(this.airportSlotTolerance?.departureLateInMinutes || 0);
      const arrEarlyMinutes = this.timeFromMinutesPipe.transform(this.airportSlotTolerance?.arrivalEarlyInMinutes || 0);
      const arrLateMinutes = this.timeFromMinutesPipe.transform(this.airportSlotTolerance?.arrivalLateInMinutes || 0);
      this.slotTimeToleranceFormGroup = new FormGroup({
        departureEarlyInMinutes: new FormControl(depEarlyMinutes, Validators.required),
        departureLateInMinutes: new FormControl(depLateMinutes, Validators.required),
        arrivalEarlyInMinutes: new FormControl(arrEarlyMinutes, Validators.required),
        arrivalLateInMinutes: new FormControl(arrLateMinutes, Validators.required),
      });

      this.buildTable();

      this.timezoneFormGroup =  new FormGroup({
        id: new FormControl(undefined),
        countryTimezone: new FormControl(this.airport?.country || '', Validators.required),
        dstApplies: new FormControl(false, Validators.required),
        timezoneCode: new FormControl(''),
        utcWithoutDst: new FormControl('', Validators.required),
        utcWithDst: new FormControl('', Validators.required),

        dstStartMonth: new FormControl(''),
        dstStartTime: new FormControl(''),
        dstStartWeekday: new FormControl(''),
        dstStartWeekOfMonth: new FormControl(''),

        dstEndMonth: new FormControl(''),
        dstEndTime: new FormControl(''),
        dstEndWeekday: new FormControl(''),
        dstEndWeekOfMonth: new FormControl(''),
        utcWithDstOperator: new FormControl(''),
        utcWithoutDstOperator: new FormControl(''),
      });
      this.timezoneFormGroup.get('dstApplies').valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((applying) => {
        this.appliesDstChanged(applying);
      });

      if (this.airport) {
        this.patchForm(this.fb, this.airport);
        this.formGroup?.patchValue(this.airport);
      }

      this.fetchTimezoneData();
      //this.fetchCurfewTimes();

      this.handleStatusChanged();
    });
  }

  buildTable() {
    for (const acType of this.acTypes) {
        this.createTurnaroundTimesMapForAcType(acType.id);
    }
    this.getMinimumTurnaroundTimes();
  }

  selectedAcTypeChanged() {
    this.activeAcTypeId = this.selectedAcType?.id;
  }

  ngOnInit() {
    this.fetchMasterAirports();
    this.fetchMasterCountries();
    this.createForm(this.fb);

  }

  fetchMasterAirports()
  {
    this.airportService.fetchMasterAirports().subscribe((result) => {
      this.masterAirportsKV = {};
      for (const airport of result) {
        this.masterAirportsKV[airport.iata] = airport;
      }
    });
  }

  fetchMasterCountries()
  {
    this.countriesService.fetchCountries().subscribe((result) => {
      this.masterCountriesKV = {};
      for (const country of result) {
        this.masterCountriesKV[country.icao] = country;
      }
    });
  }

  createForm(fb: FormBuilder) {
    this.formGroup = SettingsAirportMainFormBuilder.constructForm(fb);
  }

  patchForm(fb: FormBuilder, airport: IAirport) {
    console.log('patch form');
    if (!airport) {
      console.log('patch form value is empty');

      return;
    }

    if (airport.terminals && airport.terminals.length > 0) {
      console.log('terminals found');
      const terminalsFormGroup = new FormGroup({});

      // Object.keys(airport.terminals).forEach((item: string) => {
      //   terminalsFormGroup.addControl(item, fb.group({
      //     code: [''],
      //     description: ['']
      //   }));
      // });
      for (const terminal of airport.terminals) {
        terminalsFormGroup.addControl(String(terminal.id), fb.group({
          name: terminal.name,
          alias: terminal.alias
        }));
      }

      this.formGroup?.setControl('terminals', terminalsFormGroup);
    }

    if (airport.curfewTimes && Object.keys(airport.curfewTimes).length > 0) {
      const curfewTimesFormGroup = new FormGroup({});

      Object.keys(airport.curfewTimes).forEach((item: string) => {
        curfewTimesFormGroup.addControl(item, fb.group({
          id: [''],
          dateFrom: [''],
          dateTo: [''],
          timeFrom: [''],
          timeTo: [''],
          isUtc: [false],
          mon: [false],
          tue: [false],
          wed: [false],
          thu: [false],
          fri: [false],
          sat: [false],
          sun: [false]
        }));
      });

      this.formGroup?.setControl('curfewTimes', curfewTimesFormGroup);
    }

    if (airport.contacts && Object.keys(airport.contacts).length > 0) {
      const contactsFormGroup = new FormGroup({});

      Object.keys(airport.contacts).forEach((item: string) => {
        contactsFormGroup.addControl(item, fb.group({
          id: [''],
          firstname: ['', Validators.required],
          lastname: ['', Validators.required],
          email: ['', Validators.required],
          phone: [''],
          mobile: [''],
          vhf: [''],
          company: ['', Validators.required],
          department: ['', Validators.required],
          remarks: [''],
          sita1: ['', Validators.pattern('[A-Za-z0-9]{7}')],
          sita2: ['', Validators.pattern('[A-Za-z0-9]{7}')]
        }));
      });

      this.formGroup?.setControl('contacts', contactsFormGroup);
    }
  }

  handleStatusChanged() {
    this.statusChanged.emit(this.formGroup?.status);
    this.formGroup?.statusChanges.pipe(takeUntil(this.destroySubject)).subscribe(item => this.statusChanged.emit(item));
  }

  ngOnDestroy(): void {
    this.destroySubject.next(null);
    this.destroySubject.complete();
  }

  openManageAirportContacts(id?: number) {
    const modalRef = this.modalService.open(AddEditAirportContactComponent, {size: 'xl' as 'lg'});
    const modal = modalRef.componentInstance as AddEditAirportContactComponent;
    modal.title = id ? 'Edit Airport Contact' : 'Add Airport Contact';
    modal.contactData = this.airportContacts.find((item) => item.id === id);
    modal.airportId = this.airportId;
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (reason) {
        this.fetchRelatedData();
      }
    });
  }

  openManageAirportTerminals(id?: number) {
    const modalRef = this.modalService.open(AddEditAirportTerminalDialogComponent, {size: 'xl' as 'lg'});
    const modal = modalRef.componentInstance as AddEditAirportTerminalDialogComponent;
    modal.title = id ? 'Edit Airport Terminal' : 'Add Airport Terminal';
    modal.terminalData = this.airportTerminals.find((item) => item.id === id) || { airportId: this.airportId, validFrom: null, validTo: null };
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (reason) {
        this.fetchRelatedData();
      }
    });
    //this.router.navigate(['terminal', id ? String(id) : 'add'], {relativeTo: this.route });
  }

  openManageAirportTimezone() {
    // TODO: Check if there is a country or airport id, otherwise should not open
    const modalRef = this.modalService.open(AddEditAirportTimezoneDialogComponent, {size: 'xl' as 'lg'});
    const modal: AddEditAirportTimezoneDialogComponent = modalRef.componentInstance;
    modalRef.componentInstance.title = 'Edit Airport Timezone';
    modalRef.componentInstance.readonly = false;
    if (this.airport?.id) {
      modal.airportId = this.airport.id;
    } else if (this.masterCountriesKV[this.masterAirportsKV[this.formGroup.value.iata]?.countryIcao]) {
      modal.masterCountryId = this.masterCountriesKV[this.masterAirportsKV[this.formGroup.value.iata].countryIcao].id;
    }
  }

  openManageAirportCurfewTimes() {
    const modalRef = this.modalService.open(ManageAirportCurfewTimeDialogComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.title = 'Manage Airport Curfew Times';
    modalRef.componentInstance.readonly = false;
    modalRef.componentInstance.airportId = this.airport?.id;

  }

  openEditSlotTimeTolerance() {
    const modalRef = this.modalService.open(AddEditAirportSlotTimeToleranceDialogComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.title = 'Edit Slot Time Tolerance';
    modalRef.componentInstance.airportId = this.airport?.id;
  }

  openEditMinimumTurnaroundTimes() {
    if (!this.airport) {
      return;
    }
    const modalRef = this.modalService.open(AddEditAirportTurnaroundTimesDialogComponent, {size: 'xxl' as 'lg'});
    modalRef.componentInstance.title = 'Edit ' + this.titleCasePipe.transform('' + this.generalSettings.turnaroundTimeType) + ' Turnaround Times';
    modalRef.componentInstance.airportId = this.airport?.id;
  }

  onSaveClick() {
    const obs: Observable<any>[] = [];
    // if(this.formGroup.valid) {
    //   obs.push(this.airportService.saveAirport({ ...this.formGroup.value, minTurnaroundTimeInMinutes: 0, id: this.airport?.id }))
    // }

    // if(this.curfewTimesFormGroup.valid) {
    //   obs.push(this.saveCurfewTimes());
    // }
    this.airportService.saveAirport({ ...this.formGroup.value, minTurnaroundTimeInMinutes: 0, id: this.airport?.id }).pipe((mergeMap(result => {
      if (!result.id) {
        return of(null);
      }
      this.airport = result;
      if(this.timezoneFormGroup.valid) {
        obs.push(this.saveTimezone());
      }
      if(this.slotTimeToleranceFormGroup.valid) {
        obs.push(this.saveSlotTimeTolerance());
      }
      if(obs.length) {
        return forkJoin(obs);
      }
    }))).subscribe(() => {
      this.isBusy = false;
      this.toastService.showSuccess('Data have been saved!');
      this.airportService.fetchAirports().subscribe(() => {});
    });
    // if (obs.length) {
    //   this.isBusy = true;
    //   forkJoin(obs).subscribe((results) => {
    //     this.isBusy = false;
    //     this.toastService.showSuccess('Data have been saved!');
    //   });
    //   return;
    // }

  }

  searchMasterAirport: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : Object.keys(this.masterAirportsKV).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  onSelect(evt: NgbTypeaheadSelectItemEvent<any>) {
    this.autoFill(evt.item);
  }

  blurEvent(evt: FocusEvent) {
   this.autoFill((evt.target as any)?.value);
  }

  autoFill(iata: string) {
    if (!iata || typeof iata !== 'string') {
      return;
    }
    iata = iata.toUpperCase();
    if (this.masterAirportsKV[iata]) {
      this.formGroup.patchValue({
        country: this.masterAirportsKV[iata].country,
        latitude: this.masterAirportsKV[iata].latitude,
        longitude: this.masterAirportsKV[iata].longitude,
        icao: this.masterAirportsKV[iata].icao,
        title: this.masterAirportsKV[iata].title,
      })
    }
  }

  fetchRelatedData() {


  }

  deleteContact(contactId: number) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    const contact = this.airportContacts.find((terminal) => terminal.id === contactId);
    if (!contact) {
      return;
    }
    modalRef.componentInstance.term = 'contact ' + contact.firstname + ' ' + contact.lastname;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.airportService.deleteAirportContactCompany(contactId).subscribe(() => {
        this.toastService.showSuccess(`Contact ${contact.firstname + ' ' + contact.lastname} has been deleted`);
        this.fetchRelatedData();
      });
    });
  }

  deleteTerminal(terminalId: number) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    const terminal = this.airportTerminals.find((terminal) => terminal.id === terminalId);
    if (!terminal) {
      return;
    }
    modalRef.componentInstance.term = 'terminal ' + terminal.name;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.airportService.deleteAirportTerminal(terminalId).subscribe(() => {
        this.toastService.showSuccess(`Terminal ${terminal.name} has been deleted`);
        this.fetchRelatedData();
      });
    });
  }

  appliesDstChanged(isApplying: boolean) {
    if (isApplying) {
      this.timezoneFormGroup?.get('utcWithDstTime')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstStartMonth')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstStartTime')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstStartWeekday')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstStartWeekOfMonth')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstEndMonth')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstEndTime')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstEndWeekday')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstEndWeekOfMonth')?.setValidators(Validators.required);
      this.cd.detectChanges();
    } else {
      this.timezoneFormGroup?.get('utcWithDstTime')?.clearValidators();
      this.timezoneFormGroup?.get('dstStartMonth')?.clearValidators();
      this.timezoneFormGroup?.get('dstStartTime')?.clearValidators();
      this.timezoneFormGroup?.get('dstStartWeekday')?.clearValidators();
      this.timezoneFormGroup?.get('dstStartWeekOfMonth')?.clearValidators();
      this.timezoneFormGroup?.get('dstEndMonth')?.clearValidators();
      this.timezoneFormGroup?.get('dstEndMonth')?.updateValueAndValidity();
      this.timezoneFormGroup?.get('dstEndTime')?.clearValidators();
      this.timezoneFormGroup?.get('dstEndWeekday')?.clearValidators();
      this.timezoneFormGroup?.get('dstEndWeekOfMonth')?.clearValidators();
      this.cd.detectChanges();
    }
    for (const ctrlName in this.timezoneFormGroup.controls) {
      this.timezoneFormGroup.get(ctrlName).updateValueAndValidity({ emitEvent: false, onlySelf: true });
    }
  }

  patchTimezoneForm(result: ITimezone) {
    if (!this.timezoneFormGroup) {
      return;
    }
    this.timezoneFormGroup.patchValue({
      id: result?.id,
      countryTimezone: result?.countryTimezone?.length ? result.countryTimezone : this.airport?.country || '',
      dstApplies: result?.dstApplies === 'YES',
      timezoneCode: result?.timezoneCode,
      utcWithoutDst: result?.utcWithoutDst?.replace('-', ''),
      utcWithDst: result?.utcWithDst?.replace('-', ''),

      dstStartMonth: result?.dstStartMonth,
      dstStartTime: result?.dstStartTime,
      dstStartWeekday: result?.dstStartWeekday,
      dstStartWeekOfMonth: result?.dstEndWeekOfMonth,

      dstEndMonth: result?.dstEndMonth,
      dstEndTime: result?.dstEndTime,
      dstEndWeekday: result?.dstEndWeekday,
      dstEndWeekOfMonth: result?.dstEndWeekOfMonth,
      utcWithDstOperator: result?.utcWithDst.startsWith('-') ? '-' : '',
      utcWithoutDstOperator: result?.utcWithoutDst.startsWith('-') ? '-' : '',
    });
    this.timezoneData = result || {};
  }

  async fetchTimezoneData() {
    if (!this.airportId) {
      return;
    }
    const result = await firstValueFrom(this.airportService.fetchAirportTimezones(this.airportId));
    if (result.length) {
      this.patchTimezoneForm(result[0]);
    }
  }

  fetchSlotTimeToleranceData() {
    this.airportService.fetchAirportSlotTimeTolerance(this.airportId).subscribe((result) => {
      this.airportSlotTolerance = result?.length ? result[0] : undefined;
      const depEarlyMinutes = this.timeFromMinutesPipe.transform(result?.length ? result[0]?.departureEarlyInMinutes : 0);
      const depLateMinutes = this.timeFromMinutesPipe.transform(result?.length ? result[0]?.departureLateInMinutes : 0);
      const arrEarlyMinutes = this.timeFromMinutesPipe.transform(result?.length ? result[0]?.arrivalEarlyInMinutes : 0);
      const arrLateMinutes = this.timeFromMinutesPipe.transform(result?.length ? result[0]?.arrivalLateInMinutes : 0);
      this.slotTimeToleranceFormGroup = new FormGroup({
        departureEarlyInMinutes: new FormControl(depEarlyMinutes, Validators.required),
        departureLateInMinutes: new FormControl(depLateMinutes, Validators.required),
        arrivalEarlyInMinutes: new FormControl(arrEarlyMinutes, Validators.required),
        arrivalLateInMinutes: new FormControl(arrLateMinutes, Validators.required),
      });
    });
  }

  fetchCurfewTimes() {
    this.airportService.fetchAirportCurfewTimes(this.airportId).subscribe((result) => {
      if (result.length) {
        this.curfewTimeData = result[0];
        if (this.curfewTimeData.weekdaysBooleanId) {
          this.weekdayService.getWeekdaysById(this.curfewTimeData.weekdaysBooleanId).subscribe((res) => {
            console.log("res: ", res)
            if(res.id) {
              this.mon = res.mon;
              this.tue = res.tue;
              this.wed = res.wed;
              this.thu = res.thu;
              this.fri = res.fri;
              this.sat = res.sat;
              this.sun = res.sun;
            }
          })
        }
      }
      this.curfewTimesFormGroup = new FormGroup({
        isUtc: new FormControl(this.curfewTimeData?.isUtc === false || false),
        validFrom: new FormControl(dateToNgbDate(this.curfewTimeData?.validFrom) || null),
        validTo: new FormControl(dateToNgbDate(this.curfewTimeData?.validTo) || null),
        weekdaysBooleanId: new FormControl(this.curfewTimeData?.weekdaysBooleanId || ''),
        closingTime: new FormControl(this.curfewTimeData?.closingTime || '', Validators.required),
        openingTime: new FormControl(this.curfewTimeData?.openingTime || '', Validators.required),
      });

    });
  }

  saveTimezone(): Observable<ITimezone> {
    const data = {...this.timezoneFormGroup.value};
    if (data.utcWithDstOperator === '-') {
      data.utcWithDst = '-'.concat(data.utcWithDst);
    }
    if (data.utcWithoutDstOperator === '-') {
      //data.utcWithoutDst *= -1;
      data.utcWithoutDst = '-'.concat(data.utcWithoutDst);
    }
    delete data.utcWithDstOperator;
    delete data.utcWithoutDstOperator;
    data.airportId = this.airportId;
    data.dstApplies = data.dstApplies ? 'YES' : 'NO';
    if (!data.id) {
      delete data.id;
    }
    if (!data.timezoneCode) {
      data.timezoneCode = 'UTC';
    }
    this.isBusy = true;
    return this.airportService.saveAirportTimezone(data);
  }

  saveSlotTimeTolerance(): Observable<ISlotTimeTolerance> {
    const data =  this.slotTimeToleranceFormGroup.value;
    data.departureLateInMinutes = this.minsFromTimePipe.transform(data?.departureLateInMinutes);
    data.departureEarlyInMinutes = this.minsFromTimePipe.transform(data?.departureEarlyInMinutes);
    data.arrivalLateInMinutes = this.minsFromTimePipe.transform(data?.arrivalLateInMinutes);
    data.arrivalEarlyInMinutes = this.minsFromTimePipe.transform(data?.arrivalEarlyInMinutes);
    data.airportId = this.airport.id;
    data.id = this.airportSlotTolerance?.id;
    if(!data.id)
    {
      data.id = undefined;
    }
    return this.airportService.saveAirportSlotTimeTolerance(data);
  }

  saveCurfewTimes(): Observable<ISlotTimeTolerance> {
    const data =  this.curfewTimesFormGroup.value;
    if (this.curfewTimeData?.id) {
      return this.airportService.saveAirportCurfewTime({
        id: this.curfewTimeData.id,
        weekdaysBooleanId: this.curfewTimeData.weekdaysBooleanId,
        closingTime: data.closingTime,
        openingTime: data.openingTime,
        validTo: data.validTo ? ngbDateToDayjs(data.validTo).toDate() : null,
        validFrom: data.validFrom ? ngbDateToDayjs(data.validFrom).toDate() : null,
        isUtc: !data.isUtc,
        isActive: true,
        airportId: this.curfewTimeData.airportId,
      });
    }

    return this.weekdayService.saveWeekdays({
      isActive: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
    }).pipe(mergeMap((result) => {
      console.log('Weekday saved:', result);
      console.log('saving curfew times...');
      return this.airportService.saveAirportCurfewTime({
        weekdaysBooleanId: result.id,
        closingTime: data.closingTime,
        openingTime: data.openingTime,
        validTo: data.validTo ? ngbDateToDayjs(data.validTo).toDate() : null,
        validFrom: data.validFrom ? ngbDateToDayjs(data.validFrom).toDate() : null,
        isUtc: !data.isUtc,
        isActive: true,
        airportId: this.airport.id,
      });
    }));
  }

  getDateString(date: NgbDate) {
    if (!date) {
      return 'none';
    }
    return date.day + '.' + date.month + '.' + date.year;
  }

  toggleWeekday(day: string) {
    if (!day) {
      return;
    }

    switch (day) {
      case 'mon':
        this.mon = !this.mon;
        break;
      case 'tue':
        this.tue = !this.tue;
        break;
      case 'wed':
        this.wed = !this.wed;
        break;
      case 'thu':
        this.thu = !this.thu;
        break;
      case 'fri':
        this.fri = !this.fri;
        break;
      case 'sat':
        this.sat = !this.sat;
        break;
      case 'sun':
        this.sun = !this.sun;
        break;
      default:
        break;
    }
  }

  createTurnaroundTimesMapForAcType(acTypeId: number) {
    for (const fType in FlightType) {
      this[FlightType[fType]][acTypeId] = {};
      for (const flightServiceTypeHeader of this.flightServiceTypes) {
        for (const flightServiceType of this.flightServiceTypes) {
          if (!this[FlightType[fType]][acTypeId][flightServiceTypeHeader.id]) {
            this[FlightType[fType]][acTypeId][flightServiceTypeHeader.id] = {};
          }
          this[FlightType[fType]][acTypeId][flightServiceTypeHeader.id][flightServiceType.id] = 0;
        }
      }
    }
  }

  getMinimumTurnaroundTimes(flightType?: FlightType) {
    this.airportService.fetchTurnaroundTimesByAirport(this.airportId, flightType).subscribe((result) => {
      this.turnaroundTimes = result;
      this.insertTurnaroundDataToObjects();
    });
  }

  insertTurnaroundDataToObjects() {
    for (const turnaroundTime of this.turnaroundTimes) {
      if(this[turnaroundTime.flightType]?.[turnaroundTime.acTypeId]?.[turnaroundTime.flightServiceTypeFrom]?.[turnaroundTime.flightServiceTypeTo] !== undefined) {
        this[turnaroundTime.flightType][turnaroundTime.acTypeId][turnaroundTime.flightServiceTypeFrom][turnaroundTime.flightServiceTypeTo] = turnaroundTime;
      }
    }
  }

  protected readonly FlightType = FlightType;

  editTurnaroundTimes() {
    this.router.navigate(['turnaround', this.activeAcTypeId], { relativeTo: this.route, state: [this.selectedAcType, this.airport, this.turnaroundTimes] })
  }

  searchCountry: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.countryList.map((country) => country.title).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  onCountryBlur() {
    this.formGroup.get('country').setValue(this.formGroup.value.country.toUpperCase());
    const country: IGlobalCountry = this.countryList.find((country) => country.title === this.formGroup.value.country);
    if (!country) {
      this.formGroup.get('country').setErrors({
        error: true,
      });
    } else {
      this.formGroup.patchValue({
        icaoCountry: country.icao,
      });
    }
  }
}
