
export interface IInterfaceMessageFilter {
  type?: string;
  subtype?: string;
  status?: boolean;
  category?: string;
  date?: Date | string;
  acRegistration?: string;
  flightNumber?: string;

}

export class InterfaceMessageFilter implements IInterfaceMessageFilter {
    type?: string;
    subtype?: string;
    status?: boolean;
    category?: string;
    date?: Date | string;
    acRegistration?: string;
    flightNumber?: string;


  constructor(val: IInterfaceMessageFilter) {
    this.type = val.type;
    this.subtype = val.subtype;
    this.status = val.status;
    this.category = val.category;
    this.date = val.date;
    this.acRegistration = val.acRegistration;
    this.flightNumber = val.flightNumber;
  }


}
