import {Component, Input, OnInit} from '@angular/core';
import {IAcType} from '../../../../shared/models/ac-type.model';
import {registerLocaleData, TitleCasePipe} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {TurnaroundType} from '../../../../shared/constants/turnaround-type.constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IGenericContainerObject} from '../../../../shared/models/genericContainerObject.model';
import {ISimpleData} from '../../../../shared/models/simpleData.model';
import { ViewAirportGlobalTurnaroundTimeDialogComponent } from '../../settings-airport-management/dialogs/view-airport-global-turnaround-time-dialog/view-airport-global-turnaround-time-dialog.component';
import { ISeatingConfigurationModel } from '../../../../shared/models/seating-configuration.model';

@Component({
  selector: 'app-settings-ac-type-management-list-item',
  templateUrl: './settings-ac-type-management-list-item.component.html',
  styleUrls: ['./settings-ac-type-management-list-item.component.scss']
})
export class SettingsAcTypeManagementListItemComponent implements OnInit {

  @Input() item: IAcType;
  @Input() flightServiceTypes: IGenericContainerObject<ISimpleData>;
  @Input() opened: boolean
  filteredSeats: IGenericContainerObject<ISeatingConfigurationModel> = {};

  @Input() turnaroundType: TurnaroundType = TurnaroundType.MINIMUM;

  constructor(private modalService: NgbModal, private titleCasePipe: TitleCasePipe) { }

  ngOnInit() {
    registerLocaleData(localeDe);
    for (const seat of this.item.seatingConfigurations) {
      if (seat.acRegistrationId === null) {
        this.filteredSeats[seat.id] = seat;
      }
    }
  }

  openViewAcTypeMinimalTurnaroundTimes() {
    const modalRef = this.modalService.open(ViewAirportGlobalTurnaroundTimeDialogComponent, { size: 'xxl' as 'lg'});
    const modal = modalRef.componentInstance as ViewAirportGlobalTurnaroundTimeDialogComponent;
    modal.title = 'View A/C Type ' + this.titleCasePipe.transform('' + this.turnaroundType) + ' Turnaround Times';
    modal.acTypeId = this.item?.id;
  }

}
