<div class="settings-container flex flex-column">
  <div class="settings-row font-size-20 d-flex gap-2">
<!--    <button type="button" class="btn add-button button-blue flex-basis-20 border-radius-5 d-flex justify-content-center align-items-center" (click)="deleteSchedules()">-->
<!--      <span *ngIf="isDeletingSchedules" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>-->
<!--      Delete schedules-->
<!--    </button>-->
<!--    <button type="button" class="btn add-button button-blue flex-basis-20 border-radius-5 d-flex justify-content-center align-items-center" (click)="deleteJobs()">-->
<!--      <span *ngIf="isDeletingJobs" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>-->
<!--      Delete jobs-->
<!--    </button>-->
    <button type="button" class="btn add-button button-blue flex-basis-20 border-radius-5 d-flex justify-content-center align-items-center" (click)="deletePairs()">
      <span *ngIf="isDeletingPairs" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
      Delete pairs
    </button>
    <button type="button" class="btn add-button button-blue flex-basis-20 border-radius-5 d-flex justify-content-center align-items-center" (click)="deleteLegs()">
      <span *ngIf="isDeletingLegs" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
      Delete legs
    </button>
    <button type="button" class="btn add-button button-blue flex-basis-20 border-radius-5 d-flex justify-content-center align-items-center both" (click)="deleteLegsPairsFirestore()">
      <span *ngIf="isDeletingLegsAndPairs" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
      Delete legs + pairs (SQL+Firestore)
    </button>
<!--    <button type="button" class="btn add-button btn-outline-danger flex-basis-20 border-radius-5 d-flex justify-content-center align-items-center" [disabled]="!isDeletingPairs" (click)="abortDeletion()">-->
<!--      Abort-->
<!--    </button>-->
  </div>
<!--  <div class="settings-row font-size-20 d-flex gap-2 mt-2">-->
<!--    <button type="button" class="btn add-button btn-outline-danger flex-basis-20 border-radius-5 d-flex justify-content-center align-items-center" [disabled]="isAlteringPairs" (click)="alterAllPairs(true)">-->
<!--      <span *ngIf="isAlteringPairs" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>-->
<!--      Add custom Process to all pairs-->
<!--    </button>-->
<!--    <button type="button" class="btn add-button btn-outline-danger flex-basis-20 border-radius-5 d-flex justify-content-center align-items-center" [disabled]="isAlteringPairs" (click)="alterAllPairs(false)">-->
<!--      <span *ngIf="isAlteringPairs" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>-->
<!--      Remove custom Process from all pairs-->
<!--    </button>-->
<!--  </div>-->
</div>
