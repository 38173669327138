import { Component, Input, OnInit } from '@angular/core';
import { IUser } from '../../models/user.model';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss']
})
export class UserPhotoComponent implements OnInit {

  @Input() user: IUser;
  @Input() size = 50;

  @Input() circleBackground?: string;
  @Input() textColor?: string;
  constructor() { }

  ngOnInit(): void {
  }

}
