import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-alert-list-item',
  templateUrl: './alert-list-item.component.html',
  styleUrls: ['./alert-list-item.component.scss']
})
export class AlertListItemComponent implements OnInit {

  @Input() isActive: boolean;
  @Input() pairKey: string;
  @Input() title: string;
  @Input() text: string;

  constructor() {
  }

  ngOnInit() {
  }

}
