import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-flights-select-flights',
  templateUrl: './flights-select-flights.component.html',
  styleUrls: ['./flights-select-flights.component.scss']
})
export class FlightsSelectFlightsComponent implements OnInit {

  @Input() flights = [1, 2, 3, 4, 5];
  selectFlight: boolean[];
  selectAll: boolean;
  selectAllCheckbox: boolean;

  constructor() {
  }

  ngOnInit() {
    this.selectFlight = this.flights.map(item => false);
  }

  changeAllSelections() {
    this.selectAll = !this.selectAll;
    this.selectAllCheckbox = !this.selectAll;
    this.selectFlight = this.flights.map(() => this.selectAll);
  }

  deactivateSelectAll() {
    if (this.selectAllCheckbox) {
      this.selectAll = false;
      this.selectAllCheckbox = false;
    }
  }
}
