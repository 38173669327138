import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimeService implements OnDestroy {

  initInterval$: Subscription;
  timeSubject = new BehaviorSubject(new Date());
  unsubscribe$ = new Subject();

  constructor() {
    this.initInterval$ = interval(1000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      const time = new Date();

      if (time.getSeconds() === 0) {
        this.timeSubject.next(time);
        this.initInterval$.unsubscribe();
        this.subscribeInterval();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getTimeObservable(): Observable<Date> {
    return this.timeSubject.asObservable();
  }

  private subscribeInterval() {
    interval(60000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      const time = new Date();
      this.timeSubject.next(time);
    });
  }
}
