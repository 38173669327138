import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewTypes} from '../../constants/viewTypes.constants';

@Component({
  selector: 'app-view-modal',
  templateUrl: './view-modal.component.html',
  styleUrls: ['./view-modal.component.scss']
})
export class ViewModalComponent implements OnInit {

  @Input() title?: string;
  @Input() viewType?: ViewTypes;
  @Input() data?: any;
  @Input() airportId?: number;
  @Output() saveClicked = new EventEmitter();

  viewTypes = ViewTypes;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal(reason: string) {
    this.activeModal.dismiss(reason);
  }

  nonClosingSaveAction(data: any) {
    this.saveClicked.emit(data);
  }
}
