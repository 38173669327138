import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewModalComponent} from '../../../shared/components/view-modal/view-modal.component';
import {ViewTypes} from '../../../shared/constants/viewTypes.constants';
import {TurnaroundType} from '../../../shared/constants/turnaround-type.constants';
import {ISettingsGeneral} from '../../../shared/models/settings-general.model';
import {take} from 'rxjs/operators';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import { EditGeneralSettingsDialogComponent } from './dialogs/edit-general-settings-dialog/edit-general-settings-dialog.component';
import { ManageDelayCodeDialogComponent } from './dialogs/manage-delay-code-dialog/manage-delay-code-dialog.component';
import { ManagePassengerClassesDialogComponent } from './dialogs/manage-passenger-classes-dialog/manage-passenger-classes-dialog.component';
import { ManageFlightServiceTypesDialogComponent } from './dialogs/manage-flight-service-types-dialog/manage-flight-service-types-dialog.component';
import { ManageMeasurementUnitsDialogComponent } from './dialogs/manage-measurement-units-dialog/manage-measurement-units-dialog.component';
import { ManageAirlineCodesDialogComponent } from './dialogs/manage-airline-codes-dialog/manage-airline-codes-dialog.component';
import { ViewTurnaroundThresholdDialogComponent } from './dialogs/view-turnaround-threshold-dialog/view-turnaround-threshold-dialog.component';
import { IGeneralSettingsModel } from '../../../shared/models/general-settings.model';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { IGenericContainerObject } from 'src/app/shared/models/genericContainerObject.model';
import { ISimpleData } from 'src/app/shared/models/simpleData.model';

@Component({
  selector: 'app-settings-general-settings',
  templateUrl: './settings-general-settings.component.html',
  styleUrls: ['./settings-general-settings.component.scss']
})
export class SettingsGeneralSettingsComponent implements OnInit {

  @Input() public generalSettings: IGeneralSettingsModel;
  @Input() passengerClasses: IGenericContainerObject<ISimpleData>;
  //@Output() generalSettingsChange = new EventEmitter<IGeneralSettingsModel>();
  turnaroundTypes = TurnaroundType;

  constructor(private modalService: NgbModal, private fns: AngularFireFunctions, private generalSettingsService: GeneralSettingsService) {
  }

  ngOnInit() {
  }

  doEdit() {
    const modalRef = this.modalService.open(EditGeneralSettingsDialogComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.title = 'Edit General Settings';
    modalRef.componentInstance.passengerClasses = this.passengerClasses;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.generalSettingsService.getGeneralSettings().subscribe((result) => {
        if(result?.length > 0) {
          Object.assign(this.generalSettings ?? {}, result[0]);
        }
      });
    });
    //modalRef.componentInstance.formType = FormTypes.SETTINGS_GENERAL_MAIN;
    // console.log('generalSettings');
    // console.log(this.generalSettings);
    // modalRef.componentInstance.data = this.generalSettings;
  }

  openViewTurnaroundThreshold() {
    // if (this.generalSettings.turnaroundTimeType !== TurnaroundType.STANDARD) {
    //   return false;
    // }
    // const modalRef = this.modalService.open(ViewModalComponent, {size: 'xl' as 'lg'});
    // modalRef.componentInstance.title = 'View Turnaround Threshold';
    // modalRef.componentInstance.viewType = ViewTypes.SETTINGS_VIEW_TURNAROUND_THRESHOLD;
    // const data = {
    //   // txLabel: this.generalSettings && this.generalSettings.turnaroundTimeType === TurnaroundType.STANDARD ?
    //   // 'Turnaround Threshold at ' + this.generalSettings.turnaroundThreshold : '',
    //   txLabel: 'Turnaround Threshold at ' + this.generalSettings.turnaroundThreshold,
    //   turnaroundType: this.generalSettings ? this.generalSettings.turnaroundTimeType : null
    // };
    // modalRef.componentInstance.data = data;
    const modalRef = this.modalService.open(ViewTurnaroundThresholdDialogComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.title = 'View Turnaround Threshold';
    modalRef.componentInstance.settings = this.generalSettings;
  }

  openViewPassengerClasses() {
    const modalRef = this.modalService.open(ManagePassengerClassesDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'View Passenger Classes';
  }

  openViewFlightServiceTypes() {
    const modalRef = this.modalService.open(ManageFlightServiceTypesDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'View Flight Service Types';
  }

  openViewDelayCodes() {
    const modalRef = this.modalService.open(ManageDelayCodeDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'View Delay Codes';
  }

  openViewMeasurementUnits() {
    const modalRef = this.modalService.open(ManageMeasurementUnitsDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'View Measurement Units';
  }

  openViewAirlineCodes() {
    const modalRef = this.modalService.open(ManageAirlineCodesDialogComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'View Airline Codes';
  }
}
