import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination-button',
  templateUrl: './pagination-button.component.html',
  styleUrls: ['./pagination-button.component.scss']
})
export class PaginationButtonComponent implements OnInit {
  @Input() text: string;
  @Input() disabled: boolean = false;
  @Input() width = '150px';
  @Output() onClick = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

}
