  <div class="container">

    <div class="row ps-3 pb-1 border-bottom">
      <div class="col-12 form-field-header colorBlack mt-3 mb-2">
        New information
      </div>
      <div class="col-9 form-field-header h-54 pb-3">
        Next information at
      </div>
      <div class="col-3 form-field-subheader">
        {{data?.pair?.departureLeg?.newInformation ? (data.pair.departureLeg.newInformation | date:'HH:mm') : '-'}}
      </div>
    </div>

    <div class="row ps-3 mt-3">
      <div class="col-12 form-field-header colorBlack">
        A-CDM Info
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="container my-3">
          <div class="row">
            <div class="col-6">
              <div class="container">
                <div class="row mb-3">
                  <div class="col-12 form-field-header colorBlack">
                    Arriving Flight
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    SIBT
                  </div>
                  <div class="col-6 form-field-subheader">
                    18:50
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    IBT
                  </div>
                  <div class="col-6 form-field-subheader">
                    18:50
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    Rwy
                  </div>
                  <div class="col-6 form-field-subheader">
                    03R
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    STATA
                  </div>
                  <div class="col-6 form-field-subheader">
                    AIRB
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 border-start">
              <div class="container">
                <div class="row mb-3">
                  <div class="col-12 form-field-header colorBlack">
                    Departing Flight
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    TOBT
                  </div>
                  <div class="col-6 form-field-subheader">
                    19:35
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    OBT
                  </div>
                  <div class="col-6 form-field-subheader">
                    19:35
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    SOBT
                  </div>
                  <div class="col-6 form-field-subheader">
                    19:35
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    TOT
                  </div>
                  <div class="col-6 form-field-subheader">
                    -
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    TSAT
                  </div>
                  <div class="col-6 form-field-subheader">
                    19:20
                  </div>
                </div>
              </div>
              <div class="container h-54">
                <div class="row">
                  <div class="col-6 form-field-header">
                    TTOT
                  </div>
                  <div class="col-6 form-field-subheader">
                    19:50
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row ps-3 mt-3">
      <div class="col-12 form-field-header colorBlack">
        &nbsp;
      </div>
    </div>
    <div class="row ps-3 border-top">
      <div class="col-12 form-field-header colorBlack mt-3 mb-2">
        General
      </div>
      <div class="col-9 form-field-header h-54">
        Connecting Passengers Information
      </div>
      <div class="col-3 form-field-subheader">
        C1/Y11
      </div>
    </div>
  </div>
