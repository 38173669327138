import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Subject } from 'rxjs';
import { ITimezone } from '../../../../../shared/models/timezone.model';
import { NgbActiveModal, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AirportsService } from '../../../../../services/airports.service';
import { NumericTimeStringAdapter } from '../../../../../shared/formatters/numeric-time-string-adapter';
import { ToastService } from '../../../../../services/toast.service';
import { IMasterCountriesTimezonesModel } from '../../../../../shared/models/master-countries-timezones.model';

@Component({
  selector: 'app-add-edit-airport-timezone-dialog',
  templateUrl: './add-edit-airport-timezone-dialog.component.html',
  styleUrls: ['./add-edit-airport-timezone-dialog.component.scss'],
  providers: [{provide: NgbTimeAdapter, useClass: NumericTimeStringAdapter}]
})
export class AddEditAirportTimezoneDialogComponent implements OnInit {

  form?: FormGroup;
  @Input() timezoneData?: ITimezone;
  @Input() title?: string;
  airportId?: number;
  masterCountryId?: number;
  isBusy = false;

  destroySubject = new Subject();

  constructor(private cd: ChangeDetectorRef, private activeModal: NgbActiveModal, private airportService: AirportsService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.fetchData();
    this.form = new FormGroup({
      id: new FormControl(undefined, Validators.required),
      countryTimezone: new FormControl('', Validators.required),
      dstApplies: new FormControl(false, Validators.required),
      timezoneCode: new FormControl('', Validators.required),
      utcWithoutDst: new FormControl('', Validators.required),
      utcWithDst: new FormControl('', Validators.required),

      dstStartMonth: new FormControl('', Validators.required),
      dstStartTime: new FormControl('', Validators.required),
      dstStartWeekday: new FormControl('', Validators.required),
      dstStartWeekOfMonth: new FormControl('', Validators.required),

      dstEndMonth: new FormControl('', Validators.required),
      dstEndTime: new FormControl('', Validators.required),
      dstEndWeekday: new FormControl('', Validators.required),
      dstEndWeekOfMonth: new FormControl('', Validators.required),
      utcWithDstOperator: new FormControl('', Validators.required),
      utcWithoutDstOperator: new FormControl('', Validators.required),
    });
  }

  ngOnDestroy() {
    this.destroySubject.next(null);
    this.destroySubject.complete();
  }

  appliesDstChanged(isApplying: boolean) {
    if (isApplying) {
      this.form?.get('utcWithDstTime')?.setValidators(Validators.required);
      this.form?.get('dstStartMonth')?.setValidators(Validators.required);
      this.form?.get('dstStartTime')?.setValidators(Validators.required);
      this.form?.get('dstStartWeekday')?.setValidators(Validators.required);
      this.form?.get('dstStartWeekOfMonth')?.setValidators(Validators.required);
      this.form?.get('dstEndMonth')?.setValidators(Validators.required);
      this.form?.get('dstEndTime')?.setValidators(Validators.required);
      this.form?.get('dstEndWeekday')?.setValidators(Validators.required);
      this.form?.get('dstEndWeekOfMonth')?.setValidators(Validators.required);
      this.cd.detectChanges();
    } else {
      this.form?.get('utcWithDstTime')?.clearValidators();
      this.form?.get('dstStartMonth')?.clearValidators();
      this.form?.get('dstStartTime')?.clearValidators();
      this.form?.get('dstStartWeekday')?.clearValidators();
      this.form?.get('dstStartWeekOfMonth')?.clearValidators();
      this.form?.get('dstEndMonth')?.clearValidators();
      this.form?.get('dstEndTime')?.clearValidators();
      this.form?.get('dstEndWeekday')?.clearValidators();
      this.form?.get('dstEndWeekOfMonth')?.clearValidators();
      this.cd.detectChanges();
    }
  }

  patchForm(result: IMasterCountriesTimezonesModel) {
    if (!this.form) {
      return;
    }
    this.form.patchValue({
      id: result?.id,
      countryTimezone: result?.countryTimezone ?? '',
      dstApplies: result?.dstApplies === 'YES',
      timezoneCode: result?.timezoneCode,
      utcWithoutDst: result?.utcWithoutDst,
      utcWithDst: result?.utcWithDst,

      dstStartMonth: result?.dstStartMonth,
      dstStartTime: result?.dstStartTime,
      dstStartWeekday: result?.dstStartWeekday,
      dstStartWeekOfMonth: result?.dstEndWeekOfMonth,

      dstEndMonth: result?.dstEndMonth,
      dstEndTime: result?.dstEndTime,
      dstEndWeekday: result?.dstEndWeekday,
      dstEndWeekOfMonth: result?.dstEndWeekOfMonth,
      utcWithDstOperator: '',
      utcWithoutDstOperator: '',
    });
    this.timezoneData = result || {};
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    const data = {...this.form.value};
    if (data.utcWithDstOperator === '-') {
      data.utcWithDst *= -1;
    }
    if (data.utcWithoutDstOperator === '-') {
      data.utcWithoutDst *= -1;
    }
    delete data.utcWithDstOperator;
    delete data.utcWithoutDstOperator;
    data.airportId = this.airportId;
    data.dstApplies = data.dstApplies ? 'YES' : 'NO';
    if (!data.id) {
      delete data.id;
    }
    if (!data.timezoneCode) {
      data.timezoneCode = 'UTC';
    }
    this.isBusy = true;
    this.airportService.saveAirportTimezone(data).subscribe((result) => {
      if (result?.id) {
        this.toastService.showSuccess('The timezone has been saved!');
        this.closeModal();
      }
      this.isBusy = false;
    });
  }

  async fetchData() {
    if (!this.masterCountryId && !this.airportId) {
      return;
    }
    let result;
    if (this.masterCountryId) {
      result = await firstValueFrom(this.airportService.fetchMasterAirportTimezone(this.masterCountryId));
    } else {
      result = await firstValueFrom(this.airportService.fetchAirportTimezones(this.airportId));
    }
    if (result.length) {
      this.patchForm(result[0]);
    }
  }
}
