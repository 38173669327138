import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IAlertsModel } from '../../../../shared/models/alerts.model';
import { IGenericContainerObject } from '../../../../shared/models/genericContainerObject.model';
import { IDelayModel } from '../../../../shared/models/delays.model';
import { IPairDetail } from '../../../../shared/models/pair-detail.model';
import { firstValueFrom, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PairsService } from '../../../../services/pairs.service';
import { PairLegTimesLogService } from '../../../../services/pair-leg-times-log.service';
import { LegDelaysLogService } from '../../../../services/leg-delays-log.service';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LegsService } from '../../../../services/legs.service';
import { GeneralSettingsService } from '../../../../services/general-settings.service';
import { TimeTypesService } from '../../../../services/time-types.service';
import { ToastService } from '../../../../services/toast.service';
import { IPairOverview } from '../../../../shared/models/pair-overview.model';
import { calculateETADate, dayjsToNgbDate, ngbDateToDayjs, ngbDateToFormat } from '../../../../shared/utils/utils';
import dayjs from 'dayjs';
import { takeUntil } from 'rxjs/operators';
import { AcTypesService } from '../../../../services/ac-types.service';
import { AlertService } from '../../../../services/alert.service';
import { AlertTypesEnum } from '../../../../shared/constants/alert-types.enum';
import { IAcType } from '../../../../shared/models/ac-type.model';
import { StatusType } from '../../../../shared/constants/turnaround-extend-request-status.constant';
import { TurnaroundService } from '../../../../services/turnaround.service';
import { AuthService } from '../../../../services/auth.service';
import { IRespondEtdExtension } from '../../../../shared/models/turnaround-ext-response.model';

@Component({
  selector: 'app-issue-etd-alert-type',
  templateUrl: './issue-etd-alert-type.component.html',
  styleUrls: ['./issue-etd-alert-type.component.scss']
})
export class IssueEtdAlertTypeComponent implements OnInit, OnDestroy {
  @Input() alert: IAlertsModel;
  @Input() timezone: string;
  @Input() dateFormat: string;
  @Input() delayCodes: IDelayModel[];
  pairOverviewModel: IPairOverview;
  pairDetail: IPairDetail;
  destroy$: Subject<any> = new Subject();
  delayInMinutes: number;
  showContent = false;
  form: FormGroup;
  isAccepting = false;
  isDeclining = false;
  acType: IAcType;
  alertTypes = AlertTypesEnum;
  constructor(private pairService: PairsService,
              private pairLegTimesLogService: PairLegTimesLogService,
              private legDelaysLogService: LegDelaysLogService,
              private activeModal: NgbActiveModal,
              private legService: LegsService,
              private generalSettingsService: GeneralSettingsService,
              public timeTypesService: TimeTypesService,
              private toastService: ToastService,
              private acTypeService: AcTypesService,
              public alertService: AlertService,
              private turnaroundService: TurnaroundService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.timeTypesService.getTimeTypes().subscribe((results) => {
      for (const result of results) {
        this.timeTypesService.timeTypesKV[result.identifier] = result;
      }
    });
    this.getPairDetail().then(() => {
      this.init();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  calculateNewEtd(): dayjs.Dayjs {
    switch(this.alertService.alertTypes[this.alert.alertTypeId].title) {
      case AlertTypesEnum.INADEQUATE_TURNAROUND_TIME:
        return dayjs(this.pairDetail?.arrivalLeg?.toa).utc().add(this.acType.defaultMinTurnaroundTimeInMinutes, 'minutes');
      case AlertTypesEnum.EARLY_DEPARTURE:
        return dayjs(this.pairDetail?.departureLeg?.ctot).utc().subtract(this.pairDetail?.departureLeg?.taxi, 'minutes');
      case AlertTypesEnum.REQUEST_FOR_EXTENSION:
        return dayjs(this.pairDetail?.departureLeg?.etd ?? this.pairDetail?.departureLeg?.std).utc(false).add(this.pairDetail.turnaround?.lastExtendEtdRequest.extensionInMinutes, 'minutes');
    }
  }

  async getPairDetail(): Promise<void> {
    switch(this.alertService.alertTypes[this.alert.alertTypeId].title) {
      case AlertTypesEnum.INADEQUATE_TURNAROUND_TIME:
        this.pairDetail = await firstValueFrom(this.pairService.getPairDetail(this.alert?.__pair__?.id));
        break;
      case AlertTypesEnum.EARLY_DEPARTURE:
        const pair = await firstValueFrom(this.pairService.getPairsByFilter({isActive:true, departureLegId: this.alert?.__leg__?.id}))
        this.pairDetail = await firstValueFrom(this.pairService.getPairDetail(pair[0]?.id));
        break;
      case AlertTypesEnum.REQUEST_FOR_EXTENSION:
        this.pairDetail = await firstValueFrom(this.pairService.getPairDetail(this.alert?.__pair__?.id));
        break;
    }
  }

  async init()
  {
    this.pairOverviewModel = this.pairDetail;
    this.pairOverviewModel.flightNumberDeparting = this.pairDetail.departureLeg?.flightNumber;
    this.pairOverviewModel.flightNumberArriving = this.pairDetail.arrivalLeg?.flightNumber;
    this.pairOverviewModel.airlineDesignator = this.pairDetail.arrivalLeg.airlineDesignator;
    const acTypes = await firstValueFrom(this.acTypeService.fetchAcTypes({ id: this.pairDetail.departureLeg.acTypeId }));
    if (!acTypes?.length) {
      return;
    }
    this.acType = acTypes[0];

    const newEtd = this.calculateNewEtd();
    this.form = new FormGroup({
      etdDate: new FormControl(dayjsToNgbDate(newEtd), Validators.required),
      newEtdTime: new FormControl(newEtd.format('HH:mm'), Validators.required),
      delayCode: new FormControl('', Validators.required),
    });
    if (this.alertService.alertTypes[this.alert.alertTypeId].title === AlertTypesEnum.REQUEST_FOR_EXTENSION) {
      this.form.get('etdDate').disable();
      this.form.get('newEtdTime').disable();
    }
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(_ => this.updateDelayTimespan(this.form.getRawValue()));
    this.form.patchValue({ delayCode: "" });
    this.showContent = true;
  }

  updateDelayTimespan(item: { etdDate: NgbDateStruct, newEtdTime: string, code: string }) {
    console.log('Update:', item);
    if (!item.etdDate || !item.newEtdTime
      || !dayjs([ item.etdDate.year, item.etdDate.month-1, item.etdDate.day]).isValid() || item.newEtdTime.length !== 5) {
      return;
    }
    const newDelayMoment = dayjs(ngbDateToFormat(item.etdDate, this.dateFormat) + ' ' + item.newEtdTime + '+00:00', this.dateFormat + ' HH:mmZ');
    const stdMoment = dayjs(this.pairDetail?.departureLeg?.std);
    this.delayInMinutes = newDelayMoment.diff(stdMoment, 'minutes');
  }

  async save() {
    if (this.alertService.alertTypes[this.alert.alertTypeId].title === AlertTypesEnum.REQUEST_FOR_EXTENSION) {
      return this.saveEtdExtension();
    }
    const leg = this.pairDetail.departureLeg;
    const formValue = this.form.getRawValue();
    this.isAccepting = true;
    const etdTimeSplit = formValue.newEtdTime.split(':');
    const newEtd = ngbDateToDayjs(formValue.etdDate).utc(true).hour(etdTimeSplit[0]).minute(etdTimeSplit[1]).toDate();
    const newEta = calculateETADate({...leg, etd: newEtd});
    const saveResult = await firstValueFrom(this.legService.saveLeg({...leg, etd: newEtd, tod: newEtd, eta: newEta} as any));
    if (!saveResult) {
      return;
    }
    this.toastService.showSuccess('Leg has been saved');
    const timeTypes = await firstValueFrom(this.timeTypesService.getTimeTypes());
    if (!timeTypes) {
      return;
    }
    const pairLegTimesLogId = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({isActive: true, legId: leg.id, timeTypeId: timeTypes.filter((item) => item.identifier === 'ETD')[0]?.id}));
    const delayResult = await firstValueFrom(this.legDelaysLogService.saveLegDelayLog({
      legId: leg.id,
      delayCodeId: formValue.delayCode,
      minutes: this.delayInMinutes,
      pairLegTimesLogId: pairLegTimesLogId[0]?.id ?? null,
      issueEtd: true,
    } as any));
    if (delayResult) {
      this.toastService.showSuccess('Leg delay has been saved');
    }
    this.closeModal();
  }

  async saveEtdExtension() {
    this.isAccepting = true;
    const leg = this.pairDetail.departureLeg;
    const formValue = this.form.getRawValue();
    const etdTimeSplit = formValue.newEtdTime.split(':');
    const newEtd = ngbDateToDayjs(formValue.etdDate).utc(true).hour(etdTimeSplit[0]).minute(etdTimeSplit[1]).toDate();
    const newEta = calculateETADate({...leg, etd: newEtd});
    const extensionRequest = this.pairDetail.turnaround.lastExtendEtdRequest;
    const data: IRespondEtdExtension = {
      leg: {...leg, etd: newEtd, tod: newEtd, eta: newEta.toDate() },
      turnaroundTimeLog: { reason: extensionRequest.reason, turnaroundTimeInMinutes: extensionRequest.extensionInMinutes, suggestedAt: new Date(), suggestedBy: extensionRequest.requestUserId, answeredAt: new Date(), answeredBy: this.authService.user.id, timeTypeId: this.timeTypesService.timeTypesKV['ETD'].id, pairId: this.authService.user.id, status: StatusType.ACCEPTED, delayCodeId: this.form.getRawValue().delayCode },
      pairId: this.pairDetail.id,
      accepted: true,
    };
    const saveResult = await firstValueFrom(this.turnaroundService.respondToRequest(data));
    if (saveResult) {
      this.toastService.showSuccess('ETD Extension request has been answered');
      this.closeModal();
    }
  }

  async declineEtdRequest() {
    this.isDeclining = true;
    this.turnaroundService.respondToRequest({ accepted: false, pairId: this.pairDetail.id }).subscribe(() => {
      this.closeModal();
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  saveClicked() {
    this.isAccepting = true;
    this.save().catch().finally(() => this.isAccepting = false);
  }

  protected readonly AlertTypesEnum = AlertTypesEnum;
}
