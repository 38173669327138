<div class="list-item-row">
  <div class="list-item-column flex-basis-15">
<!--      <img *ngIf="user?.photoUrl" src="{{user?.photoUrl}}" class="rounded-circle avatar">-->
<!--      <img *ngIf="!user?.photoUrl" src="./content/images/blankProfile.png" class="rounded-circle avatar">-->
      <app-user-photo [user]="user" [size]="50" circleBackground="#F2F2F2" textColor="black"></app-user-photo>
  </div>

  <div class="list-item-column flex-basis-20">
    <div class="text-block">{{user?.lastname}}</div>
    <div class="text-block">{{user?.firstname}}</div>
    <div class="text-block" *ngIf="opened">{{user?.username}}</div>
  </div>

  <div class="list-item-column flex-basis-25">
    <div class="text-block">{{user?.role | i18nSelect: userRole.DISPLAY_TITLE_MAP}}</div>
    <div class="text-block">{{user?.location| sanitizeLocation}}</div>
    <div class="text-block" *ngIf="opened">{{user?.country}}</div>
  </div>

  <div class="list-item-column flex-basis-25">
    <div class="text-block">{{user?.phone ? user?.phone : '-'}}</div>
    <div class="text-block">{{user?.email}}</div>
  </div>
</div>
