<form>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="container">
          <div class="row form-field-header h-54">
            <div class="col-12 flex">
              <div class="mb-3 form-check me-3">
                <input class="form-check-input" type="radio" name="flightType" id="oneflightTypeOption" value="one"
                       (click)="setFlightAmount('one')" checked>
                <label class="form-check-label" for="oneflightTypeOption" (click)="setFlightAmount('one')">One
                  Flight</label>
              </div>
              <div class="mb-3 form-check">
                <input class="form-check-input" type="radio" name="flightType" id="multipleflightTypeOption"
                       value="multiple" (click)="setFlightAmount('multiple')">
                <label class="form-check-label" for="multipleflightTypeOption" (click)="setFlightAmount('multiple')">Multiple
                  Flights</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Date
            </div>
            <div class="col-6" [ngClass]="{'mb-2': amount === 'multiple'}">
              <div class="mb-3" *ngIf="amount === 'one'">
                <div class="input-group">
                  <input class="form-control" placeholder="dd.mm.yyyy"
                         name="dp" [(ngModel)]="etdDate" ngbDatepicker #d="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"
                            (click)="d.toggle()" type="button"></button>
                  </div>
                </div>
              </div>
              <div *ngIf="amount === 'multiple'">
                <div class="flex-row-space-between-align-center">
                  <span>{{getDateString(fromDate)}} - {{getDateString(toDate)}}</span>
                  <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"
                          [ngClass]="{'active': drp}"
                          (click)="drp = !drp"></button>
                </div>

                <ngb-datepicker #dp *ngIf="drp" (select)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t"
                                outsideDays="hidden">
                </ngb-datepicker>


                <ng-template #t let-date let-focused="focused">
                  <span class="custom-day"
                        [class.focused]="focused"
                        [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)"
                        (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
            </div>
            <div class="col-6" *ngIf="amount === 'multiple'">
            </div>
            <div class="col-6 flex weekdays-container mb-3 pb-3" *ngIf="amount === 'multiple'">
              <button class="btn daySelector" [ngClass]="{'button-blue': mon, 'btn-outline-secondary' : !mon}"
                      (click)="toggleWeekday('mon')">Mon
              </button>
              <button class="btn daySelector" [ngClass]="{'button-blue': tue, 'btn-outline-secondary' : !tue}"
                      (click)="toggleWeekday('tue')">Tue
              </button>
              <button class="btn daySelector" [ngClass]="{'button-blue': wed, 'btn-outline-secondary' : !wed}"
                      (click)="toggleWeekday('wed')">Wed
              </button>
              <button class="btn daySelector" [ngClass]="{'button-blue': thu, 'btn-outline-secondary' : !thu}"
                      (click)="toggleWeekday('thu')">Thu
              </button>
              <button class="btn daySelector" [ngClass]="{'button-blue': fri, 'btn-outline-secondary' : !fri}"
                      (click)="toggleWeekday('fri')">Fri
              </button>
              <button class="btn daySelector" [ngClass]="{'button-blue': sat, 'btn-outline-secondary' : !sat}"
                      (click)="toggleWeekday('sat')">Sat
              </button>
              <button class="btn daySelector" [ngClass]="{'button-blue': sun, 'btn-outline-secondary' : !sun}"
                      (click)="toggleWeekday('sun')">Sun
              </button>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              A/C Type
            </div>
            <div class="col-6">
              <div class="mb-3">
                <select class="form-control form-select" id="search-flight-ac-type" placeholder="">
                  <option>A219</option>
                  <option>A320</option>
                  <option>B747</option>
                </select></div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              A/C Registration
            </div>
            <div class="col-6">
              <div class="mb-3">
                <select class="form-control form-select" id="search-flight-ac-registration" placeholder="">
                  <option>MB123</option>
                  <option>MC123</option>
                  <option>MD123</option>
                </select></div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Flight Number
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="search-flight-number" placeholder="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 border-start">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Departing Station
            </div>
            <div class="col-6">
              <div class="mb-3">
                <select class="form-control form-select" id="search-departing-flight-number" placeholder="">
                  <option>ATH</option>
                  <option>ZRH</option>
                  <option>VIE</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-6 form-field-header">
              STD
            </div>
            <div class="col-6">
              <ngb-timepicker [(ngModel)]="stdTime" [spinners]="false" name="stdTime"></ngb-timepicker>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-6 form-field-header">
              Arriving Station
            </div>
            <div class="col-6">
              <select class="form-control form-select" id="search-arriving-flight-number" placeholder="">
                <option>ATH</option>
                <option>ZRH</option>
                <option>VIE</option>
              </select>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-6 form-field-header">
              STA
            </div>
            <div class="col-6">
              <div class="container px-0">
                <div class="row">
                  <div class="col-12">
                    <ngb-timepicker [(ngModel)]="staTime" [spinners]="false" name="staTime"></ngb-timepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-6 form-field-header">
              Flight Service Type
            </div>
            <div class="col-6">
              <select class="form-control form-select" id="search-flight-service-type" placeholder="">
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
