import { Component, Input, OnInit } from '@angular/core';
import { ISimpleData } from '../../../../../shared/models/simpleData.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { ToastService } from '../../../../../services/toast.service';
import { AddEditDelayCodeDialogComponent } from '../add-edit-delay-code-dialog/add-edit-delay-code-dialog.component';
import { take } from 'rxjs/operators';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';
import { AddEditPassengerClassesDialogComponent } from '../add-edit-passenger-classes-dialog/add-edit-passenger-classes-dialog.component';

@Component({
  selector: 'app-manage-passenger-classes-dialog',
  templateUrl: './manage-passenger-classes-dialog.component.html',
  styleUrls: ['./manage-passenger-classes-dialog.component.scss']
})
export class ManagePassengerClassesDialogComponent implements OnInit {

  @Input() title?: string;
  @Input() passengerClasses?: { [p: number]: ISimpleData };
  @Input() readonly = true;
  selected?: string | any;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private generalSettingsService: GeneralSettingsService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.fetchPassengerClasses();
  }

  doAdd() {
    const modalRef = this.modalService.open(AddEditPassengerClassesDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Add Passenger Class';
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchPassengerClasses();
    })
  }

  doEdit(item: string | any) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(AddEditPassengerClassesDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Edit Passenger Class';
    modalRef.componentInstance.passengerClass = this.passengerClasses[item];
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchPassengerClasses();
    })
  }

  doDelete(item: string | any) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Passenger Class ' + this.passengerClasses[item].code;

    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.generalSettingsService.deletePassengerClass(item).subscribe((result) => {
        if(result) {
          this.toastService.showSuccess("The passenger class has been deleted.");
          this.fetchPassengerClasses();
        }
      });
    });

  }

  fetchPassengerClasses() {
    this.generalSettingsService.getPassengerClasses().subscribe((result) => {
      this.passengerClasses = {};
      for (const passengerClass of result) {
        this.passengerClasses[passengerClass.id] = passengerClass;
      }
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

}
