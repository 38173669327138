import {Component, Input, OnInit} from '@angular/core';
import {ISimpleItem} from '../../../../../models/simpleItem.model';
import { ILegDelaysLogModel } from '../../../../../models/leg-delays-log.model';
import { ISimpleData } from '../../../../../models/simpleData.model';
import { GeneralSettingsService } from '../../../../../../services/general-settings.service';

@Component({
  selector: 'app-tooltip-content-delays',
  templateUrl: './tooltip-content-delays.component.html',
  styleUrls: ['./tooltip-content-delays.component.scss']
})
export class TooltipContentDelaysComponent implements OnInit {

  @Input() departureDelays: { [p: number]: ILegDelaysLogModel } = {};
  @Input() arrivalDelays: { [p: number]: ILegDelaysLogModel } = {};
  delayCodes: { [id: number]: ISimpleData } = {};

  constructor(private generalSettingsService: GeneralSettingsService) {
  }

  ngOnInit() {
    for (const delayCode of this.generalSettingsService.delayCodes.value) {
      this.delayCodes[delayCode.id] = delayCode;
    }
    // this.departureDelays?.sort((a, b) => {
    //   return a.minutes > b.minutes ? -1 : 1;
    // });
    // this.arrivalDelays?.sort((a, b) => {
    //   return a.minutes > b.minutes ? -1 : 1;
    // });
  }


  orderObjectKeyValues(a: any, b: any): number {
    return a.value > b.value ? -1 : 1;
  }

}
