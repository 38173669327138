import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ITimeTypesModel } from '../shared/models/time-types.model';
import { IGenericContainerObject } from '../shared/models/genericContainerObject.model';

@Injectable({
  providedIn: 'root'
})
export class TimeTypesService {

  timeTypesKV: IGenericContainerObject<ITimeTypesModel> = {};

  constructor(private httpClient: HttpClient) { }

  getTimeTypes(filter: ITimeTypesModel = { isActive: true }): Observable<ITimeTypesModel[]> {
    return this.httpClient.post(`${environment.api_base_url}time-types/search`, filter, {}).pipe(
      map((item: IResponse<ITimeTypesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }
}
