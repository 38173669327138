import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { mergeMap, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private fireAuth: AngularFireAuth) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/api/')) {
      return this.fireAuth.idToken.pipe(take(1), mergeMap((result) => {
        return next.handle(request.clone({ setHeaders: { 'authorization': result } }));
      }));
    }
    return next.handle(request);
  }
}
