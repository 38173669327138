import { Injectable } from '@angular/core';
import { IUser } from '../shared/models/user.model';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { ILegDelaysLogBulkModel, ILegDelaysLogModel } from '../shared/models/leg-delays-log.model';
import { Arrayable } from '../shared/utils/arrayable.type';

@Injectable({
  providedIn: 'root'
})
export class LegDelaysLogService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getLegDelayLogs(filter: Arrayable<ILegDelaysLogModel> = { isActive: true }): Observable<ILegDelaysLogModel[]> {
    return this.httpClient.post(`${environment.api_base_url}leg-delays-log/search`, filter, {}).pipe(
      map((item: IResponse<ILegDelaysLogModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  // getLegDelayLogsForReports(): Observable<ILegDelaysLogModel[]> {
  //   return this.httpClient.post(`${environment.api_base_url}leg-delays-log/reports-search`, {}).pipe(
  //     map((item: IResponse<ILegDelaysLogModel[]>) => {
  //       if(item?.isError){
  //         console.log(item.message);
  //       }
  //       return item?.data || [];
  //     }),
  //     catchError((error) => {
  //       console.log(error);
  //       return of([]);
  //     })
  //   );
  // }

  getLegDelayLogById(id: number): Observable<ILegDelaysLogModel> {
    return this.httpClient.get(`${environment.api_base_url}leg-delays-log/${id}`, {}).pipe(
      map((item: IResponse<ILegDelaysLogModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        console.log(error);
        return of({});
      })
    );
  }

  deleteLegDelayLog(logId: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}leg-delays-log/${logId}`, {}).pipe(
      map((item: IResponse<ILegDelaysLogModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError(() => {
        this.toastService.showError("Error deleting leg delay log");
        return of({});
      })
    );
  }

  saveLegDelayLog(log: ILegDelaysLogModel): Observable<IUser> {
    return this.httpClient.post(`${environment.api_base_url}delays/create`, log, {}).pipe(
      map((item: IResponse<ILegDelaysLogModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError(() => {
        this.toastService.showError("Error saving pair-leg time log");
        return of({});
      })
    );
  }

  saveLegDelayLogBulk(log: ILegDelaysLogBulkModel): Observable<ILegDelaysLogBulkModel> {
    return this.httpClient.post(`${environment.api_base_url}delays/create/bulk`, log, {}).pipe(
      map((item: IResponse<ILegDelaysLogBulkModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError(() => {
        this.toastService.showError("Error saving leg delays");
        return of({});
      })
    );
  }
}
