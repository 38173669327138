import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IGenericContainerObject } from '../../../shared/models/genericContainerObject.model';
import { IAirport } from '../../../shared/models/airport.model';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';
import { take } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { IAcType } from '../../../shared/models/ac-type.model';
import { SlaGseFunctionService } from '../../../services/functions/sla-gse-function.service';
import { ProcessesService } from '../../../services/processes.service';
import { IProcessesModel } from '../../../shared/models/processes.model';
import { ToastService } from '../../../services/toast.service';
import { IGsesModel } from '../../../shared/models/gses.model';
import { GseService } from '../../../services/gse.service';
import { forkJoin } from 'rxjs';
import { AirportsService } from '../../../services/airports.service';
import { AcTypesService } from '../../../services/ac-types.service';
import { TimeFromMinutesPipe } from '../../../shared/pipes/time-from-minutes.pipe';
import { DefinedGses } from '../../../shared/constants/defined-gses';
import { ActivatedRoute, Router } from '@angular/router';

type GseTableData = {
  name: string;
  gseRequired: string;
  gseIcon: string;
  sla: string;
  maxTime: string;
  gseId: number;
}

@Component({
  selector: 'app-settings-sla-gse-management',
  templateUrl: './settings-sla-gse-management.component.html',
  styleUrls: ['./settings-sla-gse-management.component.scss']
})
export class SettingsSlaGseManagementComponent {

  allAcTypes: IAcType[];
  airports: IAirport[];
  processes: IProcessesModel[];
  gses: IGsesModel[];
  processIcon: IGenericContainerObject<string>;
  tableData: GseTableData[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private modalService: NgbModal, private fns: AngularFireFunctions, private slaGseFunctionService: SlaGseFunctionService, private processesService: ProcessesService, private toastService: ToastService, private gseService: GseService, private airportsService: AirportsService, private acTypesService: AcTypesService, private timeFromMinutesPipe: TimeFromMinutesPipe) {
    forkJoin([this.acTypesService.fetchAcTypes(), this.airportsService.fetchAirports(), this.processesService.fetchProcesses(), this.gseService.fetchGses()]).subscribe((results) => {
      this.allAcTypes = results[0];
      this.airports = results[1];
      this.processes = results[2];
      this.gses = results[3];
      this.processIcon = {};
      for (const gse of this.gses) {
        if (!this.processIcon[gse.processId]?.length) {
          this.processIcon[gse.processId] = gse.gseIdentifier;
        }
      }
      this.buildTableData();
    });
  }

  buildTableData() {
    const uniqueProcessIds: Set<number> = new Set();
    this.gses.forEach((gse) => {
      uniqueProcessIds.add(gse.processId);
    });
    const newData: GseTableData[] = [];
    for (const processId of uniqueProcessIds) {
      let defaultGse = this.gses.find((gse) => gse.processId === processId && gse.isNone === false && gse.acTypeId === null && gse.airportId === null);
      if (!defaultGse) {
        defaultGse = this.gses.find((gse) => gse.processId === processId && gse.acTypeId === null && gse.airportId === null);
      }
      newData.push({
        name: this.processes.find((process) => process.id === processId)?.title,
        gseRequired: defaultGse?.gseIdentifier?.length ? 'Yes' : 'No',
        gseIcon: defaultGse?.isNone ? '' : this.processIcon[defaultGse?.processId],
        sla: defaultGse?.isSla ? 'SLA' : 'Non SLA',
        maxTime: this.timeFromMinutesPipe.transform(defaultGse?.maximumProcessTimeInMinutes),
        gseId: defaultGse?.id,
      });
    }
    this.tableData = newData;
  }


  getIconClass(gseTypeName: string): string {
    return DefinedGses[gseTypeName]?.class || '';
  }

  doDelete(gseId: number) {
    if (!gseId) {
      return;
    }
    const gse = this.gses.find((gse) => gse.id === gseId);
    const process = this.processes.find((process) => process.id === gse?.processId);
    if (!process) {
      return;
    }

    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'SLA / GSE ' + process.title;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.processesService.deleteProcess(process.id).subscribe((res) => {
        if (res) {
          this.toastService.showSuccess('Process ' + process.title + ' has been deleted');
          this.processesService.fetchProcesses().subscribe(() => {});
          this.gseService.fetchGses().subscribe(() => {});
        }
      });
    });
  }

  // fetchProcesses(fetchGses = true) {
  //   this.processesService.fetchProcesses().subscribe((result) => {
  //     if (result) {
  //       this.processes = {};
  //       this.processIcons = {};
  //       for (const process of result) {
  //         this.processes[process.id] = process;
  //         this.processIcons[process.id] = [];
  //       }
  //       if (fetchGses) {
  //         this.fetchGses();
  //       }
  //     }
  //   });
  // }

  // fetchGses() {
  //   this.gseService.getGses({isActive: true, isNone: false}).subscribe((result) => {
  //     this.gses = {};
  //     for (const gse of result) {
  //       this.gses[gse.id] = gse;
  //       if (!this.processIcons[gse.processId].includes(gse.gseIdentifier)) {
  //         this.processIcons[gse.processId].push(gse.gseIdentifier);
  //       }
  //     }
  //   });
  // }

  editClicked(gseId: number) {
    this.router.navigate([gseId], { relativeTo: this.route });
  }

  addClicked() {
    this.router.navigate(['add'], { relativeTo: this.route });
  }
}
