<div class="item" [ngClass]="contentType">
  <div class="itemHeaderContainer rounded-circle"  [ngClass]="getBgCol()">
    <div *ngIf="slcMessage !== undefined && slcMessage === true" class="itemHeaderContainerSLC"></div>
    <app-indicator-dot *ngIf="indicator && !indicatorType"></app-indicator-dot>
    <app-indicator-text *ngIf="indicator && indicatorType === 'text'" [text]="indicatorText" size40="true"></app-indicator-text>
    <i *ngIf="textHeader" class="demo-icon rounded-circle item-header" [ngClass]="getCol()">{{textHeader}}</i>
    <!-- <i *ngIf="iconHeader" class="demo-icon rounded-circle item-header {{iconHeader}}"></i> -->
    <i *ngIf="iconHeader && iconHeader !== 'icon-icons8-synchronize'" class="demo-icon circle-icon {{iconHeader}} rounded-circle item-icon-header" [ngClass]="{ 'ta-in-progress': taInProgress }"></i>
<!--    <img *ngIf="iconHeader === 'icon-icons8-synchronize'" src="content/images/icons/refresh-icon.svg" width="30" height="30" [ngClass]="{ 'ta-in-progress': taInProgress }">-->
    <svg *ngIf="iconHeader === 'icon-icons8-synchronize'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.61 122.88" width="28" height="28" [ngClass]="{ 'ta-in-progress': taInProgress }">
      <path fill="#002060" d="M111.9,61.57a5.36,5.36,0,0,1,10.71,0A61.3,61.3,0,0,1,17.54,104.48v12.35a5.36,5.36,0,0,1-10.72,0V89.31A5.36,5.36,0,0,1,12.18,84H40a5.36,5.36,0,1,1,0,10.71H23a50.6,50.6,0,0,0,88.87-33.1ZM106.6,5.36a5.36,5.36,0,1,1,10.71,0V33.14A5.36,5.36,0,0,1,112,38.49H84.44a5.36,5.36,0,1,1,0-10.71H99A50.6,50.6,0,0,0,10.71,61.57,5.36,5.36,0,1,1,0,61.57,61.31,61.31,0,0,1,91.07,8,61.83,61.83,0,0,1,106.6,20.27V5.36Z"/>
    </svg>
  </div>
  <span class="item-text {{ textClass ?? '' }}">{{slcMessage !== undefined && slcMessage === true ? 'SLC' : text }}</span>
</div>
