import { Pipe, PipeTransform } from '@angular/core';
import {TitleCasePipe} from '@angular/common';

@Pipe({
  name: 'sanitizeLocation'
})
export class SanitizeLocationPipe extends TitleCasePipe implements PipeTransform {

  override transform(location: any, ...args: any[]): any {
    if (!location) {
      return '';
    }
    const sanitizedLocation = location.replace(/, |,| /g, ' ');
    return super.transform(sanitizedLocation);
    // return super.transform(h, '2.') + ':' + super.transform(m, '2.');
  }
}
