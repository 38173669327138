import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { IProcessesModel } from '../shared/models/processes.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { IAcType } from '../shared/models/ac-type.model';

@Injectable({
  providedIn: 'root'
})
export class ProcessesService {

  private _processes: BehaviorSubject<IProcessesModel[]> = new BehaviorSubject([]);
  get processes(): BehaviorSubject<IProcessesModel[]> {
    return this._processes;
  }
  constructor(private httpClient: HttpClient, private toastService: ToastService) {
    this.fetchProcesses().subscribe(() => {});
  }

  deleteProcess(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}processes/${id}`, {}).pipe(
      catchError((error) => {
        this.toastService.showError("Error deleting process");
        return of({});
      })
    );
  }

  fetchProcesses(filter: IProcessesModel = { isActive: true }): Observable<IProcessesModel[]> {
    return this.httpClient.post(`${environment.api_base_url}processes/search`, filter, {}).pipe(
      map((item: IResponse<IProcessesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IAcType[]) => {
        if (!filter.isActive || Object.keys(filter).length > 1) {
          return;
        }
        this._processes.next(value);
      })
    );
  }

  saveProcess(process: IProcessesModel): Observable<IProcessesModel> {
    let obs;
    if (process.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}processes/${process.id}`, process, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}processes/create`, process, {})
    }
    return obs.pipe(
      map((item: IResponse<IProcessesModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving process");
        return of(null);
      })
    );
  }
}
