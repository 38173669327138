import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { IUser } from '../shared/models/user.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { IPairTurnaroundTimeLogsModel } from '../shared/models/pair-turnaround-time-logs.model';

@Injectable({
  providedIn: 'root'
})
export class PairTurnaroundTimeLogsService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getPairTurnaroundTimeLogById(id: number): Observable<IPairTurnaroundTimeLogsModel> {
    return this.httpClient.get(`${environment.api_base_url}pair-turnaround-time-logs/${id}`, {}).pipe(
      map((item: IResponse<IPairTurnaroundTimeLogsModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        console.log(error);
        return of({});
      })
    );
  }

  deletePairLegTimeLog(logId: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}pair-turnaround-time-logs/${logId}`, {}).pipe(
      map((item: IResponse<IUser>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError(() => {
        this.toastService.showError("Error deleting pair turnaround time log");
        return of({});
      })
    );
  }

  savePairTurnaroundTimeLog(model: IPairTurnaroundTimeLogsModel): Observable<IPairTurnaroundTimeLogsModel> {
    let obs;
    if (model.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}pair-turnaround-time-logs/${model.id}`, model, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}pair-turnaround-time-logs/create`, model, {})
    }
    return obs.pipe(
      map((item: IResponse<IPairTurnaroundTimeLogsModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError(() => {
        this.toastService.showError("Error saving pair turnaround time log");
        return of({});
      })
    );
  }
}
