import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-creation-preview',
  templateUrl: './message-creation-preview.component.html',
  styleUrls: ['./message-creation-preview.component.scss']
})
export class MessageCreationPreviewComponent implements OnInit {
  @Input() text: string;
  constructor() { }

  ngOnInit(): void {
  }
}
