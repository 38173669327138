import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-edit-delete-row',
  templateUrl: './edit-delete-row.component.html',
  styleUrls: ['./edit-delete-row.component.scss']
})
export class EditDeleteRowComponent implements OnInit {

  @Input() noMarginTop: boolean;
  @Output() editClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
