import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbModule, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AsmUploadComponent } from './content/dummies/asm-upload/asm-upload.component';
import { DatabaseActionsComponent } from './content/dummies/database-actions/database-actions.component';
import { DummiesComponent } from './content/dummies/dummies.component';
import { AlertListItemComponent } from './content/flights/alerts/alert-list-item/alert-list-item.component';
import { AlertsComponent } from './content/flights/alerts/alerts.component';
import { FlightsComponent } from './content/flights/flights.component';
import { LoginComponent } from './content/global/login/login.component';
import { SettingsAcRegistrationMgmtListItemComponent } from './content/settings/settings-ac-registration-management/list-item/ac-registration-mgmt-list-item.component';
import { SettingsAcRegistrationMgmtComponent } from './content/settings/settings-ac-registration-management/settings-ac-registration-mgmt.component';
import { SettingsAcTypeManagementListItemComponent } from './content/settings/settings-ac-type-management/list-item/settings-ac-type-management-list-item.component';
import { SettingsAcTypeManagementComponent } from './content/settings/settings-ac-type-management/settings-ac-type-management.component';
import { SettingsAirportManagementListItemComponent } from './content/settings/settings-airport-management/list-item/settings-airport-management-list-item.component';
import { SettingsAirportManagementComponent } from './content/settings/settings-airport-management/settings-airport-management.component';
import { SettingsFlightScheduleMaintValidateSsimFileComponent } from './content/settings/settings-flight-schedule-maintenance/maint-validate-ssim-file/settings-flight-schedule-maint-validate-ssim-file.component';
import { SettingsFlightScheduleMaintenanceComponent } from './content/settings/settings-flight-schedule-maintenance/settings-flight-schedule-maintenance.component';
import { SettingsGeneralSettingsComponent } from './content/settings/settings-general-settings/settings-general-settings.component';
import { SettingsInterfaceManagementComponent } from './content/settings/settings-interface-management/settings-interface-management.component';
import { SettingsMenuItemComponent } from './content/settings/settings-menu-item/settings-menu-item.component';
import { SettingsSlaGseManagementListItemComponent } from './content/settings/settings-sla-gse-management/list-item/settings-sla-gse-management-list-item.component';
import { SettingsSlaGseManagementComponent } from './content/settings/settings-sla-gse-management/settings-sla-gse-management.component';
import { SettingsUserManagementListItemComponent } from './content/settings/settings-user-management/list-item/settings-user-management-list-item.component';
import { SettingsUserManagementComponent } from './content/settings/settings-user-management/settings-user-management.component';
import { SettingsComponent } from './content/settings/settings.component';
import { AppMainComponent, AppNavbarComponent, FooterComponent, HeaderComponent } from './layouts';
import { HHMMTimeStringAdapter } from './shared/formatters/hh-mm-time-string-adapter';
import { TimeFromMinutesPipe } from './shared/pipes/time-from-minutes.pipe';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AddEditAirportTerminalDialogComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-terminal-dialog/add-edit-airport-terminal-dialog.component';
import { AddEditAirportDialogComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-dialog/add-edit-airport-dialog.component';
import { ManageAirportTerminalDialogComponent } from './content/settings/settings-airport-management/dialogs/manage-airport-terminal-dialog/manage-airport-terminal-dialog.component';
import { ManageAirportTerminalDialogListItemComponent } from './content/settings/settings-airport-management/dialogs/manage-airport-terminal-dialog/list-item/manage-airport-terminal-dialog-list-item.component';
import { AddEditAirportTimezoneDialogComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-timezone-dialog/add-edit-airport-timezone-dialog.component';
import { ViewAirportTimezoneDialogComponent } from './content/settings/settings-airport-management/dialogs/view-airport-timezone-dialog/view-airport-timezone-dialog.component';
import { AddEditAirportContactComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-contact-dialog/add-edit-airport-contact-dialog.component';
import { ManageAirportContactDialogComponent } from './content/settings/settings-airport-management/dialogs/manage-airport-contact-dialog/manage-airport-contact-dialog.component';
import { ManageAirportContactDialogListItemComponent } from './content/settings/settings-airport-management/dialogs/manage-airport-contact-dialog/list-item/manage-airport-contact-dialog-list-item.component';
import { ViewAirportSlotTimeToleranceDialogComponent } from './content/settings/settings-airport-management/dialogs/view-airport-slot-time-tolerance/view-airport-slot-time-tolerance-dialog.component';
import { AddEditAirportSlotTimeToleranceDialogComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-slot-time-tolerance-dialog/add-edit-airport-slot-time-tolerance-dialog.component';
import { ManageAirportCurfewTimeDialogComponent } from './content/settings/settings-airport-management/dialogs/manage-airport-curfew-time-dialog/manage-airport-curfew-time-dialog.component';
import { ManageAirportCurfewTimeDialogListItemComponent } from './content/settings/settings-airport-management/dialogs/manage-airport-curfew-time-dialog/list-item/manage-airport-curfew-time-dialog-list-item.component';
import { AddEditAirportCurfewTimeDialogComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-curfew-time-dialog/add-edit-airport-curfew-time-dialog.component';
import { ViewAirportGlobalTurnaroundTimeDialogComponent } from './content/settings/settings-airport-management/dialogs/view-airport-global-turnaround-time-dialog/view-airport-global-turnaround-time-dialog.component';
import { ViewAirportMinimalTurnaroundTimeDialogComponent } from './content/settings/settings-airport-management/dialogs/view-airport-minimal-turnaround-time-dialog/view-airport-minimal-turnaround-time-dialog.component';
import { EditGeneralSettingsDialogComponent } from './content/settings/settings-general-settings/dialogs/edit-general-settings-dialog/edit-general-settings-dialog.component';
import { ManageDelayCodeDialogComponent } from './content/settings/settings-general-settings/dialogs/manage-delay-code-dialog/manage-delay-code-dialog.component';
import { ManageDelayCodeDialogListItemComponent } from './content/settings/settings-general-settings/dialogs/manage-delay-code-dialog/manage-delay-code-dialog-list-item/manage-delay-code-dialog-list-item.component';
import { AddEditDelayCodeDialogComponent } from './content/settings/settings-general-settings/dialogs/add-edit-delay-code-dialog/add-edit-delay-code-dialog.component';
import { ManageAirlineCodesDialogComponent } from './content/settings/settings-general-settings/dialogs/manage-airline-codes-dialog/manage-airline-codes-dialog.component';
import { ManageAirlineCodesDialogListItemComponent } from './content/settings/settings-general-settings/dialogs/manage-airline-codes-dialog/list-item/manage-airline-codes-dialog-list-item.component';
import { ManagePassengerClassesDialogComponent } from './content/settings/settings-general-settings/dialogs/manage-passenger-classes-dialog/manage-passenger-classes-dialog.component';
import { ManagePassengerClassesDialogListItemComponent } from './content/settings/settings-general-settings/dialogs/manage-passenger-classes-dialog/list-item/manage-passenger-classes-dialog-list-item.component';
import { AddEditPassengerClassesDialogComponent } from './content/settings/settings-general-settings/dialogs/add-edit-passenger-classes-dialog/add-edit-passenger-classes-dialog.component';
import { ManageFlightServiceTypesDialogListItemComponent } from './content/settings/settings-general-settings/dialogs/manage-flight-service-types-dialog/list-item/manage-flight-service-types-dialog-list-item.component';
import { AddEditFlightServiceTypesDialogComponent } from './content/settings/settings-general-settings/dialogs/add-edit-flight-services-types-dialog/add-edit-flight-service-types-dialog.component';
import { ManageFlightServiceTypesDialogComponent } from './content/settings/settings-general-settings/dialogs/manage-flight-service-types-dialog/manage-flight-service-types-dialog.component';
import { ManageMeasurementUnitsDialogComponent } from './content/settings/settings-general-settings/dialogs/manage-measurement-units-dialog/manage-measurement-units-dialog.component';
import { ManageMeasurementUnitsDialogListItemComponent } from './content/settings/settings-general-settings/dialogs/manage-measurement-units-dialog/list-item/manage-measurement-units-dialog-list-item.component';
import { AddEditMeasurementUnitsDialogComponent } from './content/settings/settings-general-settings/dialogs/add-edit-measurement-units-dialog/add-edit-measurement-units-dialog.component';
import { AddEditAirlineDesignatorsDialogComponent } from './content/settings/settings-general-settings/dialogs/add-edit-airline-designators-dialog/add-edit-airline-designators-dialog.component';
import { AddEditAirportTurnaroundTimesFormComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-turnaround-times-dialog/form/add-edit-att-form.component';
import { ViewTurnaroundThresholdDialogComponent } from './content/settings/settings-general-settings/dialogs/view-turnaround-threshold-dialog/view-turnaround-threshold-dialog.component';
import { AddEditTurnaroundThresholdDialogComponent } from './content/settings/settings-general-settings/dialogs/add-edit-turnaround-threshold-dialog/add-edit-turnaround-threshold-dialog.component';
import { AddEditAcRegistrationDialogComponent } from './content/settings/settings-ac-registration-management/dialogs/add-edit-ac-registration-dialog/add-edit-ac-registration-dialog.component';
import { AddEditAcTypeDialogComponent } from './content/settings/settings-ac-type-management/dialogs/add-edit-ac-type-dialog/add-edit-ac-type-dialog.component';
import { AddEditUserDialogComponent } from './content/settings/settings-user-management/dialogs/add-edit-user-dialog/add-edit-user-dialog.component';
import { AddEditSlaGseDialogComponent } from './content/settings/settings-sla-gse-management/dialogs/add-edit-sla-gse-dialog/add-edit-sla-gse-dialog.component';
import { SlaGseLevelActypeDialogComponent } from './content/settings/settings-sla-gse-management/dialogs/sla-gse-level-actype-dialog/sla-gse-level-actype-dialog.component';
import { SlaGseLevelAirportDialogComponent } from './content/settings/settings-sla-gse-management/dialogs/sla-gse-level-airport-dialog/sla-gse-level-airport-dialog.component';
import { SlaGseLevelGseDialogComponent } from './content/settings/settings-sla-gse-management/dialogs/sla-gse-level-gse-dialog/sla-gse-level-gse-dialog.component';
import { SlaGseLevelProcessDialogComponent } from './content/settings/settings-sla-gse-management/dialogs/sla-gse-level-process-dialog/sla-gse-level-process-dialog.component';
import { AlertListComponent } from './content/alerts/alert-list/alert-list.component';
import { AlertsNewComponent } from './content/alerts/alerts.component';
import { AlertListNewItemComponent } from './content/alerts/alert-list/alert-list-new-item/alert-list-new-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { EditPairDialogComponent } from './content/alerts/edit-pair-dialog/edit-pair-dialog.component';
import { RemindMeDialogComponent } from './content/alerts/remind-me-dialog/remind-me-dialog.component';
import { ReportsComponent } from './content/reports/reports.component';
import { NgChartsModule } from 'ng2-charts';
import { AddEditAirportTurnaroundTimesDialogComponent } from './content/settings/settings-airport-management/dialogs/add-edit-airport-turnaround-times-dialog/add-edit-airport-turnaround-times-dialog.component';
import { ReportListComponent } from './content/reports/report-list/report-list.component';
import { ChatComponent } from './content/chat/chat.component';
import { EditMessageDialog } from './shared/components/chat-area/edit-message-dialog/edit-message-dialog.component';
import { GeneralDateFormatter } from './shared/formatters/GeneralDateFormatter';
import { AuthInterceptor } from './auth.interceptor';
import { MultiAsmUploadComponent } from './content/dummies/multi-asm-upload/multi-asm-upload.component';
import { CtotUploadComponent } from './content/dummies/ctot-upload/ctot-upload.component';
import { ArcidUploadComponent } from './content/dummies/arcid-upload/arcid-upload.component';
import { NewChatButtonComponent } from './content/chat/new-chat-button/new-chat-button.component';
import { SimpleChatContactComponent } from './content/chat/simple-chat-contact/simple-chat-contact.component';
import { OverlapAlertTypeComponent } from './content/alerts/edit-pair-dialog/overlap-alert-type/overlap-alert-type.component';
import { IssueEtdAlertTypeComponent } from './content/alerts/edit-pair-dialog/issue-etd-alert-type/issue-etd-alert-type.component';
import { SimpleIssueEtdAlertTypeComponent } from './content/alerts/edit-pair-dialog/simple-issue-etd-alert-type/simple-issue-etd-alert-type.component';
import { NoActualTimesAlertTypeComponent } from './content/alerts/edit-pair-dialog/no-actual-times-alert-type/no-actual-times-alert-type.component';
import { MvtUploadComponent } from './content/dummies/mvt-upload/mvt-upload.component';
import { SettingsGeneralSettingsNewComponent } from './content/settings/settings-general-settings-new/settings-general-settings-new.component';
import { SettingsSlaGseEditFirstLevelComponent } from './content/settings/settings-sla-gse-management/settings-sla-gse-edit-first-level/settings-sla-gse-edit-first-level.component';
import { SlaGseEditAirportDialogComponent } from './content/settings/settings-sla-gse-management/dialogs/sla-gse-edit-airport-dialog/sla-gse-edit-airport-dialog.component';
import { ExitScreenComponent } from './content/exit-screen/exit-screen.component';
import { ExitScreenMvtComponent } from './content/exit-screen/exit-screen-mvt/exit-screen-mvt.component';
import { ExitScreenListComponent } from './content/exit-screen/exit-screen-list/exit-screen-list.component';
import { ExcelUploadComponent } from './content/dummies/excel-upload/excel-upload.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SeatConfigurationComponent } from './content/settings/settings-ac-type-management/dialogs/add-edit-ac-type-dialog/seat-configuration/seat-configuration.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SettingsComponent,
        FlightsComponent,
        SettingsMenuItemComponent,
        SettingsGeneralSettingsComponent,
        SettingsUserManagementComponent,
        SettingsUserManagementListItemComponent,
        SettingsAcTypeManagementComponent,
        SettingsAcTypeManagementListItemComponent,
        SettingsAcRegistrationMgmtComponent,
        SettingsAirportManagementComponent,
        SettingsAcRegistrationMgmtListItemComponent,
        SettingsAirportManagementListItemComponent,
        SettingsFlightScheduleMaintenanceComponent,
        SettingsInterfaceManagementComponent,
        SettingsSlaGseManagementComponent,
        SettingsSlaGseManagementListItemComponent,
        AppMainComponent,
        AppNavbarComponent,
        SettingsFlightScheduleMaintValidateSsimFileComponent,
        AlertsComponent,
        AlertListItemComponent,
        DummiesComponent,
        AsmUploadComponent,
        MultiAsmUploadComponent,
        DatabaseActionsComponent,
        LoginComponent,
        AddEditAirportTerminalDialogComponent,
        AddEditAirportDialogComponent,
        ManageAirportTerminalDialogComponent,
        ManageAirportTerminalDialogListItemComponent,
        AddEditAirportTimezoneDialogComponent,
        ViewAirportTimezoneDialogComponent,
        AddEditAirportContactComponent,
        ManageAirportContactDialogComponent,
        ManageAirportContactDialogListItemComponent,
        ViewAirportSlotTimeToleranceDialogComponent,
        AddEditAirportSlotTimeToleranceDialogComponent,
        EditGeneralSettingsDialogComponent,
        ManageDelayCodeDialogComponent,
        ManageDelayCodeDialogListItemComponent,
        AddEditDelayCodeDialogComponent,
        ManageAirportCurfewTimeDialogComponent,
        ManageAirportCurfewTimeDialogListItemComponent,
        AddEditAirportCurfewTimeDialogComponent,
        ViewAirportGlobalTurnaroundTimeDialogComponent,
        ViewAirportMinimalTurnaroundTimeDialogComponent,
        ManageAirlineCodesDialogComponent,
        ManageAirlineCodesDialogListItemComponent,
        ManagePassengerClassesDialogComponent,
        ManagePassengerClassesDialogListItemComponent,
        AddEditPassengerClassesDialogComponent,
        ManageFlightServiceTypesDialogComponent,
        ManageFlightServiceTypesDialogListItemComponent,
        AddEditFlightServiceTypesDialogComponent,
        ManageMeasurementUnitsDialogComponent,
        ManageMeasurementUnitsDialogListItemComponent,
        AddEditMeasurementUnitsDialogComponent,
        AddEditAirlineDesignatorsDialogComponent,
        AddEditAirportTurnaroundTimesFormComponent,
        AddEditAirportTurnaroundTimesDialogComponent,
        ViewTurnaroundThresholdDialogComponent,
        AddEditTurnaroundThresholdDialogComponent,
        AddEditAcRegistrationDialogComponent,
        AddEditAcTypeDialogComponent,
        AddEditUserDialogComponent,
        AddEditSlaGseDialogComponent,
        SlaGseLevelActypeDialogComponent,
        SlaGseLevelAirportDialogComponent,
        SlaGseLevelGseDialogComponent,
        SlaGseLevelProcessDialogComponent,
        AlertListComponent,
        AlertsNewComponent,
        AlertListNewItemComponent,
        EditPairDialogComponent,
        RemindMeDialogComponent,
        EditMessageDialog,
        ReportsComponent,
        ReportListComponent,
        ChatComponent,
        SimpleChatContactComponent,
        CtotUploadComponent,
        ArcidUploadComponent,
        NewChatButtonComponent,
        OverlapAlertTypeComponent,
        IssueEtdAlertTypeComponent,
        SimpleIssueEtdAlertTypeComponent,
        NoActualTimesAlertTypeComponent,
        MvtUploadComponent,
        ExitScreenComponent,
        ExitScreenMvtComponent,
        ExitScreenListComponent,
        SettingsGeneralSettingsNewComponent,
        SettingsSlaGseEditFirstLevelComponent,
        SlaGseEditAirportDialogComponent,
        ExcelUploadComponent,
        SeatConfigurationComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserModule,
        NgbModule,
        AppRoutingModule,
        SharedModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        FormsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireDatabaseModule,
        AngularFireFunctionsModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        HttpClientModule,
        FontAwesomeModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        NgChartsModule,
        DragDropModule,
        NgSelectModule,
    ],
    providers: [
        // { provide: FUNCTIONS_ORIGIN, useValue: 'us-central1' }
        {provide: NgbDateParserFormatter, useClass: GeneralDateFormatter},
        // {provide: NgbDateAdapter, useClass: GeneralDateStringAdapter},
        {provide: NgbTimeAdapter, useClass: HHMMTimeStringAdapter},
        TimeFromMinutesPipe,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
