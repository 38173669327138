import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import dayjs from 'dayjs';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  // @ts-ignore
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    let date = dayjs(value);
    if (timezone && (timezone === 'zulu' || timezone === '+0000')) {
      date = date.utc();
    }
    return date.format(format);
  }
}
