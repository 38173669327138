import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TurnaroundType } from '../../constants/turnaround-type.constants';

@Component({
  selector: 'app-settings-card',
  templateUrl: './settings-card.component.html',
  styleUrls: ['./settings-card.component.scss']
})
export class SettingsCardComponent implements OnInit {
  @Input() sectionTitle: string;
  @Input() addText: string;
  @Input() addTextTooltip: string;
  @Input() informationTooltip: string;
  @Output() onAddClicked = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }
    protected readonly TurnaroundType = TurnaroundType;
}
