import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { IDelayModel } from '../shared/models/delays.model';

@Injectable({
  providedIn: 'root'
})
export class DelayService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  addDelay(filter: IDelayModel): Observable<boolean> {
    return this.httpClient.post(`${environment.api_base_url}delays/create`, filter, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }

  deleteDelay(id: number): Observable<boolean> {
    return this.httpClient.delete(`${environment.api_base_url}delays/${id}`,{}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }
}
