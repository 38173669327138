import {States} from '../constants/state.constants';

export class StatesResolver {
  public static getBgColorClassByState(state: States) {
    switch (state) {
      case States.OK:
        return 'bgColorGreen';
      case States.WARNING:
        return 'bgColorYellow';
      case States.ERROR:
        return 'bgColorRed';
      default:
        return '';
    }
  }

  static getFontColorClassByState(state: States) {
    switch (state) {
      case States.OK:
        return 'colorBlack';
      case States.WARNING:
        return 'colorBlack';
      case States.ERROR:
        return 'colorWhite';
      default:
        return '';
    }
  }
}
