import {ITurnaround} from '../models/turnaround.model';
import {TurnaroundStatus} from '../constants/turnaround-status.constants';
import * as dayjs from 'dayjs';
import * as firebase from 'firebase/firestore';


export class RemainingTimeCalculator {
  public static calcRemainingTime(tod: string): number {
    if (!tod || !dayjs(tod).isValid()) {
      return 0;
    }

    return this.calcDurationInMinutesFromTimeAToTimeB(dayjs().format(), dayjs(tod).format());
  }

  public static calcTotalTime(turnaround: ITurnaround, tod: string) {

    if (!turnaround || !turnaround.currentState || !tod || !dayjs(tod).isValid()) {
      return 0;
    }

    let timeA = null;
    const timeB = tod;

    switch (turnaround.currentState) {
      case TurnaroundStatus.UPCOMING:
        return 0;
      case TurnaroundStatus.IN_PROGRESS:
        timeA = turnaround.lastStartedAt;
        break;
      case TurnaroundStatus.PAUSED:
        timeA = dayjs().format();
        break;
      case TurnaroundStatus.RESUMED:
        timeA = turnaround.lastResumedAt;
        break;
      case TurnaroundStatus.FINISHED:
        return 0;
      default:
        return 0;
    }

    if (timeA instanceof firebase.Timestamp) {
      timeA = timeA.toDate();
    }

    const resDuration: number = this.calcDurationInMinutesFromTimeAToTimeB(timeA, timeB);

    console.log('resulting time without passed time is: ', resDuration);

    console.log('passed time is: ', turnaround.passedTurnaroundTimeInMinutes);

    console.log('total time is: ', resDuration + (turnaround.passedTurnaroundTimeInMinutes || 0));

    return resDuration + (turnaround.passedTurnaroundTimeInMinutes || 0);
  }

  private static calcDurationInMinutesFromTimeAToTimeB(timeA: string, timeB: string): number {
    if (!timeA || !timeB) {
      return 0;
    }

    const timeAMoment = dayjs(timeA);
    const timeBMoment = dayjs(timeB);

    return timeBMoment.diff(timeAMoment, 'minutes');
  }
}
