import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { IAlertsModel } from '../../../shared/models/alerts.model';


@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  @Input() alerts: IAlertsModel[] = [];
  @Input() activePairIndex: string;
  @Output() setActivePair = new EventEmitter();

  isMaximized: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  alertClicked(alertIndex: string) {
    this.setActivePair.emit(alertIndex);
  }

  toggleIsMaximized() {
    this.isMaximized = !this.isMaximized;
  }

}
