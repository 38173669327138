<form [formGroup]="form">
  <div class="container">
    <div class="row mt-2">
      <div class="col-12 mb-3">
        Select the related leg of the memo you want to delete*
      </div>
      <div class="col-5 text-center">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="flightType" id="arrivingFlightOption" value="arrival" formControlName="flightType">
          <label class="form-check-label" for="arrivingFlightOption">Arrival leg</label>
        </div>
      </div>
      <div class="col-7 text-center">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="flightType" id="departingFlightOption" value="departure" formControlName="flightType">
          <label class="form-check-label" for="departingFlightOption">Departure leg</label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="form.getRawValue().flightType" class="container my-3 pt-3">
    <p>You are about to delete this {{form?.getRawValue().flightType}} leg related memo.</p>
    <p>Are you sure?</p>
  </div>
</form>
