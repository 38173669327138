<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
<form [formGroup]="formGroup">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="container h-54">
          <div class="row">
            <div class="col-6 form-field-header">
              Code
            </div>
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airline-code-code" placeholder="" formControlName="alias">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 border-start">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Description
            </div>
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airline-code-description" placeholder="" formControlName="name">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="formGroup?.disabled || formGroup.invalid || isBusy">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
