<div class="images-container">
  <ng-container *ngIf="images.length === 2">
    <img [src]="images[0]" class="rounded-circle avatar two-images img1">
    <img [src]="images[1]" class="rounded-circle avatar two-images img2">

  </ng-container>
  <ng-container *ngIf="images.length === 3">
    <img [src]="images[0]" class="rounded-circle avatar three-images img1">
    <img [src]="images[1]" class="rounded-circle avatar three-images img2">
    <img [src]="images[2]" class="rounded-circle avatar three-images img3">
  </ng-container>
</div>
