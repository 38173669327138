import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AirportsService } from '../../../../../services/airports.service';
import { ISlotTimeTolerance } from '../../../../../shared/models/slotTimeTolerance.model';

@Component({
  selector: 'app-view-airport-slot-time-tolerance-dialog',
  templateUrl: './view-airport-slot-time-tolerance-dialog.component.html',
  styleUrls: ['./view-airport-slot-time-tolerance-dialog.component.scss']
})
export class ViewAirportSlotTimeToleranceDialogComponent implements OnInit {

  @Input() title?: string;
  @Input() airportId?: number;
  slotTimeToleranceData?: ISlotTimeTolerance;
  constructor(private activeModal: NgbActiveModal, private airportsService: AirportsService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  fetchData() {
    this.airportsService.fetchAirportSlotTimeTolerance(this.airportId).subscribe((result) => {
      this.slotTimeToleranceData =  result[0] || {};
    });
  }
}
