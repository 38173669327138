import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export class FlightsIssueNewInformationFormBuilder {

  static constructForm(
    fb: FormBuilder): FormGroup {

    return fb.group({
      flightType: ['arrival', Validators.required],
      newInformationDate: ['', Validators.required],
      newInformationTime: ['', Validators.required],
      delayCode: ['', Validators.required]
    });
  }
}
