import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { IInterfaceMessageModel } from '../shared/models/interfaceMessage.model';


@Injectable({
  providedIn: 'root'
})
export class InterfaceMessageService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getInterfaceMessages(page = 1, filters: any): Observable<IInterfaceMessageModel[]> {
    return this.httpClient.post(`${environment.api_base_url}interface-message/search/${page}`, { isActive: true, filters }, {}).pipe(
      map((item: IResponse<IInterfaceMessageModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }
}
