import * as dayjs from 'dayjs';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { GeneralSettingsService } from '../../services/general-settings.service';

@Injectable()
export class GeneralDateFormatter extends NgbDateParserFormatter {
  constructor(private settingsService: GeneralSettingsService) {
    super();

  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const mdt = dayjs(value.trim(), this.settingsService.generalSettings.value.dateFormat);
      return { day: mdt.date(), month: mdt.month() + 1, year: mdt.year() };
    }
    return null;
  }
  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const mdt = dayjs([date.year, date.month - 1, date.day]);
    return mdt.isValid() ? mdt.format(this.settingsService.generalSettings.value.dateFormat.toUpperCase()) : '';
  }
}
