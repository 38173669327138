<!--<div cdkDropList (cdkDropListDropped)="drop($event)" class="set">-->
<!--  <div class="item" *ngFor="let item of items" cdkDrag>{{item.code}}{{item.description}}</div>-->
<!--</div>-->
<ng-container *ngIf="form">
  <div class="flex gap-2">
    <ng-content></ng-content>
    <div class="flex justify-content-between dark-blue skip-invalid-border flex-grow-1" [formGroup]="form">
      <div *ngFor="let passClass of this.passengerClasses" class="set">
        <div>
          {{passClass?.code}}
        </div>
        <ng-container [formGroupName]="passClass?.code">
          <input type="number" min="0" class="form-control seat-input" id="settings-ac-type-seating-config-{{passClass?.code}}" formControlName="description">
        </ng-container>
      </div>
      <div class="set">
        <div>
          Valid From
        </div>
        <div class="flex">
          <input class="form-control light-blue-border filter-background date-input"
                 name="dp" formControlName="validFrom" ngbDatepicker #dp="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar date-selector-btn"
                    (click)="dp.toggle()" type="button"></button>
          </div>
        </div>
      </div>
      <div class="set">
        <div>
          Valid To
        </div>
        <div class="flex">
          <input class="form-control light-blue-border filter-background date-input"
                 name="dp" formControlName="validTo" ngbDatepicker #dp2="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar date-selector-btn"
                    (click)="dp2.toggle()" type="button"></button>
          </div>
        </div>
      </div>
      <div class="set">
        <div>
          Operated By
        </div>
        <select class="form-control form-select" id="settings-ac-type-seating-config-airline-designator" formControlName="operatedBy">
          <ng-container *ngFor="let loc of airlineDesignators">
            <option [ngValue]="loc?.id">{{loc?.code}} {{loc?.description}}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
</ng-container>
