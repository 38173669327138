<div class="list-item-container"
     [ngClass]="{'opened' : opened && (!noEditDeleteBar || !noOpenedContent), 'no-hover': noEditDeleteBar && noOpenedContent}">
  <ng-content></ng-content>

  <ng-container *ngIf="opened && (!noEditDeleteBar || !noOpenedContent)">
    <app-edit-delete-row *ngIf="!noEditDeleteBar"
                         [noMarginTop]="noOpenedContent"
                         (editClicked)="editClicked.emit()"
                         (deleteClicked)="deleteClicked.emit()">
    </app-edit-delete-row>
  </ng-container>
</div>
