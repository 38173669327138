import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import { AsmService } from '../../../services/asm.service';
import { ToastService } from '../../../services/toast.service';
import { firstValueFrom } from 'rxjs';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'multi-app-asm-upload',
  templateUrl: './multi-asm-upload.component.html',
  styleUrls: ['./multi-asm-upload.component.scss']
})
export class MultiAsmUploadComponent implements OnInit {

  successIcon = faCheck;
  errorIcon = faX;
  isBusy = false;

  fileList: { file: File, status: 'success' | 'error' | 'processing' | 'pending' }[] = [];

  constructor(private modalService: NgbModal, private fns: AngularFireFunctions, private asmService: AsmService, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    this.fileList = [];
    for (let i = 0; i < files.length; i++) {
      this.fileList.push({ file: files.item(i), status: 'pending'});
    }
  }

  async getFileContent(file: File) {
    if (!file || file.size === 0) {
      return Promise.resolve(null);
    }
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        return resolve(fileReader.result);
      };
      fileReader.readAsText(file);
    });
  }
/*
  openValidationView() {
    const modalRef = this.modalService.open(SettingsFlightScheduleMaintenanceValidateSsimFileComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'Validate SSIM File and prepare upload';
    modalRef.componentInstance.fileContent = this.fileContent;
    modalRef.componentInstance.periodFrom = (this.fromDate && this.delimitFlights) ? dayjs(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day + ' +0000', 'YYYY-M-D Z').utc().format() : null;
    modalRef.componentInstance.periodTo = (this.toDate && this.delimitFlights) ? dayjs(this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day + ' +0000', 'YYYY-M-D Z').utc().format() : null;
  } */

async upload() {
  this.isBusy = true;
  for (const fileObj of this.fileList) {
    fileObj.status = 'processing';
    const fileContent = await this.getFileContent(fileObj.file);
    const result = await firstValueFrom(this.asmService.uploadASM({ fileContent, fileName: fileObj.file.name }));
    if (result) {
      this.toastService.showSuccess('ASM file has been processed successfully!');
    }
    fileObj.status = result ? 'success' : 'error';
  }
  this.isBusy = false;
}

}
