<div class="container">
  <div class="row">
    <div class="col-12">Departure Delay:</div>
    <div *ngIf="!departureDelays" class="col-12">None</div>
    <ng-container *ngIf="departureDelays">
      <ng-container *ngFor="let delay of departureDelays | keyvalue">
        <ng-container *ngIf="delayCodes[delay.value.delayCodeId].code !== '-'">
          <div class="col-6">{{delayCodes[delay.value.delayCodeId].code}}</div>
          <div class="col-6">{{delay.value.minutes | timeFromMinutes}}</div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="col-12 mt-3">Arrival Delay:</div>
    <div *ngIf="!arrivalDelays" class="col-12">None</div>
    <ng-container *ngIf="arrivalDelays">
      <ng-container *ngFor="let delay of arrivalDelays | keyvalue">
        <ng-container *ngIf="delayCodes[delay.value.delayCodeId].code !== '-'">
          <div class="col-6">{{delayCodes[delay.value.delayCodeId].code}}</div>
          <div class="col-6">{{delay.value.minutes | timeFromMinutes}}</div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
