import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditPairDialogComponent } from '../edit-pair-dialog/edit-pair-dialog.component';
import { firstValueFrom, interval, Subject, Subscription } from 'rxjs';
import { AlertService } from '../../../services/alert.service';
import { IAlertsModel } from '../../../shared/models/alerts.model';
import { AlertSeverity } from '../../../shared/constants/alert-severity.enum';
import { takeUntil } from 'rxjs/operators';
import dayjs from 'dayjs';
import { TimezoneService } from '../../../services/timezone.service';
import { IUser } from '../../../shared/models/user.model';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
})
export class AlertListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() severityFilters: AlertSeverity[];
  @Input() searchKeyword = '';
  @Input() user: IUser;
  @Input() severityChanged: EventEmitter<void>;
  @Output() setAlertIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeAlertChanged: EventEmitter<IAlertsModel> = new EventEmitter();
  activeAlertIndex = -1;
  resolvedAlerts = [];
  alerts: IAlertsModel[] = [];
  filteredAlerts = [];
  fetchAlertSubscription: Subscription;
  unsubscribe$ = new Subject();
  alertsToRemind: { [alertId: number]: number } = {};

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }



  constructor(private modalService: NgbModal, private alertService: AlertService, public timezoneService: TimezoneService) {
  }

  ngOnInit(): void {
    console.log('USER IS: ', this.user)
    const alertsToRemindStr = localStorage.getItem('alerts_to_remind');
    if (alertsToRemindStr) {
      this.alertsToRemind = JSON.parse(localStorage.getItem('alerts_to_remind'));
    }
    this.fetchAlerts();
    this.fetchAlertSubscription = interval(10000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.fetchAlerts();
    });
    this.severityChanged.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateFilteredList();
    });
  }

  fetchAlerts() {
    this.alertService.getAlerts().subscribe((results) => {
      this.alerts = results.filter((alert) => {
        return alert.alertTypeId !== 9 || !!alert.__pair__.__departureLegModel__
      });
      this.updateFilteredList();
      this.filteredAlerts.sort((a, b) => {
        return +b.id - +a.id;
    });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.severityFilter || changes.searchKeyword) {
      this.updateFilteredList();
    }
  }


  doSetAlertIndex(pairKey: number) {
    if (!pairKey || this.activeAlertIndex === pairKey) {
      this.activeAlertIndex = -1;
    } else {
      this.activeAlertIndex = pairKey;
    }
    this.setAlertIndex.emit(this.activeAlertIndex);
    if (this.activeAlertIndex === -1) {
      this.activeAlertChanged.emit(null);
      return;
    }
    const activeAlert = this.alerts.find((alert) => alert.id === this.activeAlertIndex);
    this.activeAlertChanged.emit(activeAlert);
  }

  editClicked(alert: IAlertsModel) {
        if(alert.alertTypeId === 1){
          const modalRef = this.modalService.open(EditPairDialogComponent, {size: 'lg'});
          const modal = modalRef.componentInstance as EditPairDialogComponent;
          modal.alert = alert;
        } else {
          const modalRef = this.modalService.open(EditPairDialogComponent, {size: 'xxl'});
          const modal = modalRef.componentInstance as EditPairDialogComponent;
          modal.alert = alert;
        }
  }

  async markAsResolvedClick(alertId: number) {
    const alert = this.alerts.find((x) => x.id === alertId);
    //await firstValueFrom(this.alertService.saveAlert({ ...alert, isResolved: true, resolvedAt: dayjs().utc().toDate(), isActive: false }));
    await firstValueFrom(this.alertService.markAsResolvedAlert({ ...alert, isResolved: true, resolvedAt: dayjs().utc().toDate(), isActive: false }))
    this.fetchAlerts();
  }

  updateFilteredList(): void {
    const search: string = this.searchKeyword;
    this.filteredAlerts.splice(0, this.filteredAlerts.length);
    this.filteredAlerts.push(...this.alerts.filter((x) => (this.alertsToRemind ? (this.alertsToRemind[x.id] == null || this.alertsToRemind[x.id] < dayjs().unix()) : true) && this.alertService.alertTypes[x.alertTypeId].description.toLowerCase().includes(search.toLowerCase()) && (this.severityFilters ? this.severityFilters.includes(this.alertService.alertTypes[x.alertTypeId].severity): true)));
  }

  remindLater(data: { id: number; hours: number; minutes: number }) {
    this.alertsToRemind[data.id] = dayjs().add(data.hours, 'hours').add(data.minutes, 'minutes').unix();
    localStorage.setItem('alerts_to_remind', JSON.stringify(this.alertsToRemind));
    this.updateFilteredList();
  }

  alertTrack(index: number, model: IAlertsModel): number {
    return model.id;
  }
}
