import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Equipment} from '../../../constants/equipment.constants';
import {interval, Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import {takeUntil} from 'rxjs/operators';
import {ViewModalComponent} from '../../view-modal/view-modal.component';
import {ViewTypes} from '../../../constants/viewTypes.constants';

@Component({
  selector: 'app-deprecated-leg-detail',
  templateUrl: './deprecated-leg-detail.component.html',
  styleUrls: ['./deprecated-leg-detail.component.scss']
})
export class DeprecatedLegDetailComponent implements OnInit, OnDestroy {

  @Input() isActive: boolean;
  @Input() flightPair: any; // IFlightPair
  @Input() equipmentList: Equipment[];
  @Input() timezone: string;

  unsubscribe$ = new Subject();

  turnaroundProgress = {text: '-', total: '-', percentage: 0};

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (this.flightPair && this.flightPair.arrivalLeg && this.flightPair.arrivalLeg.ata
      && this.flightPair.departureLeg && this.flightPair.departureLeg.atd) {
      const diff = dayjs(this.flightPair.departureLeg.atd).diff(this.flightPair.arrivalLeg.ata, 'seconds');
      this.turnaroundProgress = {text: 'finished', total: this.formatSecondsToTime(diff), percentage: 100};

    } else if (this.flightPair && this.flightPair.arrivalLeg && this.flightPair.departureLeg) {

      interval(1000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {

        this.turnaroundProgress = this.calcTurnaroundProgress(
          this.flightPair.arrivalLeg.ata,
          this.flightPair.departureLeg.std,
          this.flightPair.departureLeg.etd,
          this.flightPair.departureLeg.atd);
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  calcDateDifferenceInDays(date1: string, date2: string, tz: string): number {

    if (!date1 || !date2) {
      return 0;
    }

    let d1 = dayjs(date1);
    let d2 = dayjs(date2);

    if (d1 && d2 && d1.isValid() && d2.isValid()) {
      if (tz) {
        d1 = d1.utc();
        d2 = d2.utc();
      }
      return d2.diff(d1, 'days');
    }
    return 0;
  }

  calcTurnaroundRemainingTimeText(arrivalAta: string, departureStd: string, departureEtd: string, departureAtd: string): string {
    if (!arrivalAta || !dayjs(arrivalAta).isValid()) {
      return 'No arrival leg ATA given yet';
    }

    if (departureAtd && dayjs(departureAtd).isValid()) {
      const departureAtdDatetime = dayjs(departureAtd);
      const remainingSeconds = departureAtdDatetime.diff(dayjs(), 'seconds');

      if (0 >= remainingSeconds) {
        return 'finished';
      }

      return Math.floor(remainingSeconds / 60) + ':' + ('0' + (remainingSeconds % 60)).slice(-2) + ' remaining to ATD / TOBT';


    } else if (departureEtd && dayjs(departureEtd).isValid()) {
      const departureEtdDatetime = dayjs(departureEtd);
      const remainingSeconds = departureEtdDatetime.diff(dayjs(), 'seconds');

      if (0 >= remainingSeconds) {
        return 'finished although no ATD is given yet';
      }

      return Math.floor(remainingSeconds / 60) + ':' + ('0' + (remainingSeconds % 60)).slice(-2) + ' remaining to ETD';

    } else if (departureStd && dayjs(departureStd).isValid()) {
      const departureStdDatetime = dayjs(departureStd);
      const remainingSeconds = departureStdDatetime.diff(dayjs(), 'seconds');

      if (0 >= remainingSeconds) {
        return 'finished although no ATD is given yet';
      }

      return Math.floor(remainingSeconds / 60) + ':' + ('0' + (remainingSeconds % 60)).slice(-2) + ' remaining to STD';

    } else {
      return 'No time of departure given yet';
    }
  }

  calcTurnaroundProgress(arrivalAta: string, departureStd: string, departureEtd: string, departureAtd: string):
    { text: string, total: string, percentage: number } {
    let text = '-';
    let total = '-';
    let percentage = 0;

    if (!arrivalAta || !dayjs(arrivalAta).isValid()) {
      return {text: 'No arrival leg ATA given yet', total, percentage};
    }

    const arrivalAtaDatetime = dayjs(arrivalAta);

    if (departureAtd && dayjs(departureAtd).isValid()) {
      const departureAtdDatetime = dayjs(departureAtd);
      const remainingSeconds = departureAtdDatetime.diff(dayjs(), 'seconds');
      const totalSeconds = departureAtdDatetime.diff(arrivalAtaDatetime, 'seconds');

      total = this.formatSecondsToTime(totalSeconds);

      if (0 >= remainingSeconds) {
        text = 'finished';
        percentage = 100;
      } else {
        text = this.formatSecondsToTime(remainingSeconds) + ' remaining to ATD / TOBT';
        percentage = (1 - remainingSeconds / totalSeconds) * 100;
      }
    } else if (departureEtd && dayjs(departureEtd).isValid()) {
      const departureEtdDatetime = dayjs(departureEtd);
      const remainingSeconds = departureEtdDatetime.diff(dayjs(), 'seconds');
      const totalSeconds = departureEtdDatetime.diff(arrivalAtaDatetime, 'seconds');

      total = this.formatSecondsToTime(totalSeconds);

      if (0 >= remainingSeconds) {
        text = 'finished although no ATD is given yet';
        percentage = 100;
      } else {
        text = this.formatSecondsToTime(remainingSeconds) + ' remaining to ETD';
        percentage = (1 - remainingSeconds / totalSeconds) * 100;
      }
    } else if (departureStd && dayjs(departureStd).isValid()) {
      const departureStdDatetime = dayjs(departureStd);
      const remainingSeconds = departureStdDatetime.diff(dayjs(), 'seconds');
      const totalSeconds = departureStdDatetime.diff(arrivalAtaDatetime, 'seconds');

      total = this.formatSecondsToTime(totalSeconds);

      if (0 >= remainingSeconds) {
        text = 'finished although no ATD is given yet';
        percentage = 100;
      } else {
        text = this.formatSecondsToTime(remainingSeconds) + ' remaining to STD';
        percentage = (1 - remainingSeconds / totalSeconds) * 100;
      }

    } else {
      text = 'No time of departure given yet';
    }

    return {text, total, percentage};
  }

  orderObjectKeyValues(a: any, b: any): number {
    return a.value > b.value ? -1 : 1;
  }

  formatSecondsToTime(seconds: number): string {
    console.log('F-total' + seconds);
    if (seconds <= 0) {
      return null;
    }
    return Math.floor(seconds / 60) + ':' + ('0' + (seconds % 60)).slice(-2);
  }

  openMemo() {
    const modalRef = this.modalService.open(ViewModalComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'View Memo';
    modalRef.componentInstance.viewType = ViewTypes.FLIGHTS_VIEW_MEMO;
  }

  openChangeFlightDetails() {
    const modalRef = this.modalService.open(ViewModalComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.title = 'Change Flight Details';
    modalRef.componentInstance.viewType = ViewTypes.FLIGHTS_CHANGE_FLIGHT_DETAILS;
  }

  // openIssueETD() {
  //   const modalRef = this.modalService.open(SettingsAddEditModalComponent, {size: 'lg'});
  //   modalRef.componentInstance.title = 'Issue ETD';
  //   modalRef.componentInstance.formType = FormTypes.FLIGHTS_ISSUE_ETD;
  // }
  //
  // openIssueNI() {
  //   const modalRef = this.modalService.open(SettingsAddEditModalComponent, {size: 'lg'});
  //   modalRef.componentInstance.title = 'Issue new information';
  //   modalRef.componentInstance.formType = FormTypes.FLIGHTS_ISSUE_NEW_INFORMATION;
  // }

}
