import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IUser } from '../../../shared/models/user.model';
import { UserRoles } from '../../../shared/constants/user-roles.constants';
import { IMyChatGroups } from '../../../shared/models/my-chat-groups.interface';
import { TimezoneService } from '../../../services/timezone.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-advanced-chat-contact',
  templateUrl: './advanced-chat-contact.component.html',
  styleUrls: ['./advanced-chat-contact.component.scss']
})
export class AdvancedChatContactComponent implements OnInit, OnDestroy {
  @Input() activeIndex: number;
  @Input() user: IUser;
  @Input() chatGroup: IMyChatGroups;
  @Input() numberOfUnreadMessages: number;
  @Input() sentAreUnread: boolean;
  userRoleObject = UserRoles;
  timezone = 'zulu';
  destroy$ = new Subject();

  constructor(private timezoneService: TimezoneService) { }

  ngOnInit(): void {
    this.timezoneService.getTimezone().pipe(takeUntil(this.destroy$)).subscribe((tz) => {
      this.timezone = tz;
    });
  }

  ngOnDestroy() {
    this.destroy$.complete();
  }

}
