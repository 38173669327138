import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AirportsService } from '../../../../../services/airports.service';
import { ToastService } from '../../../../../services/toast.service';
import { NgbActiveModal, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { IAirportCurfewTime } from '../../../../../shared/models/airport-curfew-time.model';
import { DecimalPipe } from '@angular/common';
import { WeekdaysService } from '../../../../../services/weekdays-service';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-edit-airport-curfew-time-dialog',
  templateUrl: './add-edit-airport-curfew-time-dialog.component.html',
  styleUrls: ['./add-edit-airport-curfew-time-dialog.component.scss']
})
export class AddEditAirportCurfewTimeDialogComponent implements OnInit {

  title = "Add Airport Curfew Times";
  @Input() curfewTimeData?: IAirportCurfewTime;
  @Input() outerSaveClicked?: EventEmitter<any>;
  @Input() dateFormat = 'dd/mm/yyyy';
  @Output() saveClicked = new EventEmitter();
  formGroup: FormGroup;
  drp = false;
  isBusy = false;

  hoveredDate?: NgbDate;


  constructor(private decimalPipe: DecimalPipe, calendar: NgbCalendar, private weekdayService: WeekdaysService ,private airportService: AirportsService, private toastService: ToastService, private activeModal: NgbActiveModal, private generalSettingsService: GeneralSettingsService) {
    //this.curfewTimeData.validFrom = calendar.getToday();
    //this.curfewTimeData.validTo = calendar.getNext(calendar.getToday(), 'd', 1);
  }

  mon = false;
  tue = false;
  wed = false;
  thu = false;
  fri = false;
  sat = false;
  sun = false;

  unsubscribe$ = new Subject();
  ngOnDestroy() {
      this.unsubscribe$.next(undefined);
      this.unsubscribe$.complete();
    }

  ngOnInit() {
    this.generalSettingsService.generalSettings.pipe(takeUntil(this.unsubscribe$)).subscribe((settings) => {
      this.dateFormat = settings.dateFormat;
    });
    // if (this.form && this.form.get('dateFrom')) {
    //   this.validFrom = this.convertFromStringToNgbDate(this.form.get('dateFrom')?.value);
    // }

    // if (this.form && this.form.get('dateTo')) {
    //   this.validTo = this.convertFromStringToNgbDate(this.form.get('dateTo')?.value);
    // }

    if(this.curfewTimeData?.weekdaysBooleanId) {
      this.weekdayService.getWeekdaysById(this.curfewTimeData.weekdaysBooleanId).subscribe((res) => {
        console.log("res: ", res)
        if(res.id) {
          this.mon = res.mon;
          this.tue = res.tue;
          this.wed = res.wed;
          this.thu = res.thu;
          this.fri = res.fri;
          this.sat = res.sat;
          this.sun = res.sun;
        }
      })
    }


    this.formGroup = new FormGroup({
      isUtc: new FormControl(this.curfewTimeData?.isUtc || '', Validators.required),
      validFrom: new FormControl(this.convertToNgbDate(this.curfewTimeData?.validFrom) || null, Validators.required),
      validTo: new FormControl(this.convertToNgbDate(this.curfewTimeData?.validTo) || null, Validators.required),
      weekdaysBooleanId: new FormControl(this.curfewTimeData?.weekdaysBooleanId || '', Validators.required),
      closingTime: new FormControl(this.curfewTimeData?.closingTime || '', Validators.required),
      openingTime: new FormControl(this.curfewTimeData?.openingTime || '', Validators.required),
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.formGroup.get('validFrom').value && !this.formGroup.get('validTo').value) {
      this.formGroup?.patchValue({
        validFrom: date
      });
    } else if (this.formGroup.get('validFrom').value && !this.formGroup.get('validTo').value && !date.before(this.formGroup.get('validFrom').value)) {
      this.formGroup?.patchValue({
        validTo: date
      });
    } else {
      this.formGroup?.patchValue({
        validFrom: date,
        validTo: null
      });
    }
  }

  convertToNgbDate(date: Date) {
    if(!date) {
      return null;
    }
    return new NgbDate(date?.getFullYear(),date?.getMonth() + 1,date?.getDate());
  }

  isHovered(date: NgbDate) {
    return this.formGroup.get('validFrom').value && !this.formGroup.get('validTo').value && this.hoveredDate && date.after(this.formGroup.get('validFrom').value) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.formGroup.get('validFrom').value) && date.before(this.formGroup.get('validTo').value);
  }

  isRange(date: NgbDate) {
    return date.equals(this.formGroup.get('validFrom').value) || date.equals(this.formGroup.get('validTo').value) || this.isInside(date) || this.isHovered(date);
  }

  getDateString(date: NgbDate) {
    if (!date) {
      return 'none';
    }
    return date.day + '.' + date.month + '.' + date.year;
  }

  toggleWeekday(day: string) {
    if (!day) {
      return;
    }

    switch (day) {
      case 'mon':
        this.mon = !this.mon;
        break;
      case 'tue':
        this.tue = !this.tue;
        break;
      case 'wed':
        this.wed = !this.wed;
        break;
      case 'thu':
        this.thu = !this.thu;
        break;
      case 'fri':
        this.fri = !this.fri;
        break;
      case 'sat':
        this.sat = !this.sat;
        break;
      case 'sun':
        this.sun = !this.sun;
        break;
      default:
        break;
    }
    //this.formGroup?.controls[day].patchValue(!this.formGroup?.get(day)?.value);
    //this.formGroup?.get('weekdaysBooleanId')?.patchValue(dayId);
  }

  convertFromStringToNgbDate(date: string): NgbDate | undefined {
    if (!date) {
      return undefined;
    }
    let day = 0;
    let month = 0;
    let year = 0;

    if (date.includes('.') && date.split('.').length === 3) {
      const splitDate = date.split('.');
      day = parseInt(splitDate[0], 10);
      month = parseInt(splitDate[1], 10);
      year = parseInt(splitDate[2], 10);
    } else if (date.includes('-') && date.split('-').length === 3) {

      const splitDate = date.split('-');
      year = parseInt(splitDate[0], 10);
      month = parseInt(splitDate[1], 10);
      day = parseInt(splitDate[2], 10);
    }

    return new NgbDate(year, month, day);
  }

  convertFromNgbDateToString(date: NgbDate, format: string): string | null {
    if (!date) {
      return null;
    }
    switch (format) {
      case 'dd/mm/yyyy':
        return `${date.day}/${date.month}/${date.year}`;
      case 'yyyy/mm/dd':
        return `${date.year}/${date.month}/${date.day}`;
      default:
        return null;
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    this.isBusy = true;
    this.weekdayService.saveWeekdays({
      mon: this.mon,
      tue: this.tue,
      wed: this.wed,
      thu: this.thu,
      fri: this.fri,
      sat: this.sat,
      sun: this.sun,
    }).subscribe((res) => {
      if(res.id) {
        const data = {
          ...this.curfewTimeData,
          ...this.formGroup.value,
          validFrom: this.convertFromNgbDateToString(this.formGroup.get('validFrom').value, 'yyyy-mm-dd'),
          validTo: this.convertFromNgbDateToString(this.formGroup.get('validTo').value, 'yyyy-mm-dd'),
          weekdaysBooleanId: res.id,
        };

        this.airportService.saveAirportCurfewTime(data as any).subscribe((result) => {
          if(result?.id) {
            this.toastService.showSuccess("Curfew Time has been saved");
            // this.curfewTimeData.isUtc = data.name;
            // this.curfewTimeData.alias = data.alias;
            // this.curfewTimeData.name = data.name;
            // this.curfewTimeData.alias = data.alias;
            // this.curfewTimeData.name = data.name;
            // this.curfewTimeData.alias = data.alias;
            this.closeModal();
          }
          this.isBusy = false;
        });
      } else {
        this.isBusy = false;
      }
    });


  }
}
