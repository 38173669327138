import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fullWeekdayFromThreeChars'
})
export class FullWeekdayFromThreeCharsPipe implements PipeTransform {

  transform(value?: string, args?: any): any {
    if (!value) {
      return '-';
    }

    switch (value) {
      case 'MON':
        return 'Monday';
      case 'TUE':
        return 'Tuesday';
      case 'WED':
        return 'Wednesday';
      case 'THU':
        return 'Thursday';
      case 'FRI':
        return 'Friday';
      case 'SAT':
        return 'Saturday';
      case 'SUN':
        return 'Sunday';
      default:
        return '-';
    }
  }

}
