<div class="container request-container my-3 py-3 avitium-bg-color colorWhite light">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 text-center fs-30">
        Incoming Issue ETD request from Ramp
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center font-20">
        Please extend the departure time by <b>{{extendEtdRequest?.extensionInMinutes}} minutes</b>!
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-center font-20">
        Reason: {{extendEtdRequest?.reason}}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-center font-20">
        New ETD: <b>{{newEtd ? (newEtd | customDate:this.dateFormat + ' - HH:mm':timezone) : ''}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center font-20">
        <span>Select delay code: </span><ng-select formControlName="delayCode">
        <ng-option>-</ng-option>
        <ng-option *ngFor="let code of delayCodeList" [value]="code.id">{{code.code}} {{code.description}}</ng-option>
      </ng-select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 button-container">
        <div>
          <button class="btn btn-outline-danger" (click)="declineClicked()">Decline</button>
        </div>
        <div>
          <button class="btn btn-success" type="button" [disabled]="form.invalid || isBusy" (click)="acceptClicked()">
            {{ isBusy ? '' : 'Issue ETD' }}
            <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
