import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { States } from '../../constants/state.constants';
import { Equipment } from '../../constants/equipment.constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { ISimpleData } from '../../models/simpleData.model';
import { IPairOverview } from '../../models/pair-overview.model';
import { IPairDetail } from '../../models/pair-detail.model';
import { LegInformationWindowComponent } from '../leg-information-window/leg-information-window.component';

@Component({
  selector: 'app-leg',
  templateUrl: './leg.component.html',
  styleUrls: ['./leg.component.scss']
})
export class LegComponent implements OnInit {

  @Input() iconCheck: boolean;
  @Input() iconMemo: boolean;
  @Input() iconMessage: boolean;
  @Input() iconWarning: boolean;

  @Input() inEditPairMode: boolean;

  @Input() legState: States;
  @Input() indicatorMemo: boolean;
  @Input() indicatorMessage: boolean;
  @Input() indicatorWarning: boolean;
  @Input() indicatorInfo: boolean;

  @Input() flightPairKey: string;
  @Input() refreshSql: EventEmitter<any>;



  _pairOverview: IPairOverview;
  get pairOverview(): IPairOverview {
    if(!this._pairOverview) {
      return this.pairDetail as any as IPairOverview;
    }
    return this._pairOverview;
  }

  @Input('pairOverview')
  set pairOverview(val: IPairOverview) {
    if (!val) {
      return;
    }

    this._pairOverview = val;
  }

  _pairDetail: IPairDetail;
  get pairDetail(): IPairDetail {
    return this._pairDetail;
  }

  @Input('pairDetail')
  set pairDetail(val: IPairDetail) {
    if (!val) {
      return;
    }

    this._pairDetail = val;
    this.setPairDetail.emit(val);
  }

  @Output() setPairDetail = new EventEmitter<IPairDetail>();
  @Output() mainComponentClicked = new EventEmitter<void>();

  @Input() timezone: string;

  @Input() registrationNrState: States;
  @Input() flightFromNrState: States;
  @Input() etaState: States;
  @Input() turnaroundDurationState: States;
  @Input() flightToNrState: States;
  @Input() stdState: States;
  @Input() etdState: States;
  @Input() atdState: States;
  @Input() ctotState: States;

  @Input() passengerClasses: ISimpleData[];

  @Input() userRole = 'OPS_CONTROLLER';
  @Input() isFirestore = false;

  @ViewChild('pair') legElement: ElementRef;

  _isActive: boolean;

  get isActive(): boolean {
    return this._isActive;
  }

  @Input('isActive')
  set isActive(value: boolean) {
    if (value && !this._isActive) {
      this._isActive = value;
      this.legElement?.nativeElement?.scrollIntoView({behavior: 'smooth'});
    } else {
      this._isActive = value;
    }
    if(value && this.pairDetail) {
      this.setPairDetail.emit(this.pairDetail);
    }
  }

  @Output() deactivatePair = new EventEmitter();

  states = States;

  equipments = [...Equipment.LIST];

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
  }


  calcDateDifferenceInDays(date1: Date, date2: Date, tz: string): number {
    if (!date1 || !date2) {
      return 0;
    }

    let d1 = dayjs(date1);
    let d2 = dayjs(date2);
    if (d1 && d2 && d1.isValid() && d2.isValid()) {
      if (tz && tz.length === 5) {
        const hours = Number(tz.slice(1, 3));
        const minutes = Number(tz.slice(3, 5)) + hours * 60;
        d1 = d1.utcOffset(minutes);
        d2 = d2.utcOffset(minutes);
      }
      d1 = d1.startOf('day');
      d2 = d2.startOf('day');
      return Math.abs(d2.diff(d1, 'd'));
    }
    return 0;
  }

  calcDateDifferenceString(date1: Date, date2: Date, tz: string): string {
    const res = this.calcDateDifferenceInDays(date1, date2, tz);
    if (res === 0) {
      return '';
    }
    const sign = dayjs.utc(date1).isAfter(date2) ? '-' : '+';
    return sign + res;
  }

  getBgColor(isActive: boolean): string {
    const base = this.inEditPairMode ? 'custom-border ' : '';
    if (isActive) {
      return base + 'bg-baby-blue';
    }
    return base + 'bg-white';
  }

  toggleDetails() {
    if (this.inEditPairMode) {
      return;
    }
    this._isActive = !this.isActive;
    this.deactivatePair.emit();
  }

  openLegInformationModal() {
    const modalRef = this.modalService.open(LegInformationWindowComponent, {size: '500px'});
    const modal = modalRef.componentInstance as LegInformationWindowComponent;
    modal.pair = this.pairDetail || this.pairOverview;
  }

}
