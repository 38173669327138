export interface ExitScreenMessage {
    flightServiceType?: boolean,
    acType?: boolean,
    acConfig?: boolean,
    acRegistration?: boolean,
    sta?: boolean,
    std?: boolean,
    depAirport?: boolean,
    arrAirport?: boolean,
};

export const ASM_CNL_EXIT_TYPE:ExitScreenMessage = {
    flightServiceType: false,
    acType: false,
    acConfig: false,
    acRegistration: false,
    depAirport: true,
    arrAirport: true,
    sta: false,
    std: false,
};

export const ASM_CON_EXIT_TYPE:ExitScreenMessage = {
    flightServiceType: true,
    acType: true,
    acConfig: true,
    acRegistration: true,
    depAirport: true,
    arrAirport: true,
    sta: false,
    std: false,
};

export const ASM_RRT_EXIT_TYPE:ExitScreenMessage = {
    flightServiceType: true,
    acType: true,
    acConfig: true,
    acRegistration: true,
    depAirport: true,
    arrAirport: true,
    sta: true,
    std: true,
};

export const ASM_EQT_EXIT_TYPE:ExitScreenMessage = {
    flightServiceType: true,
    acType: true,
    acConfig: true,
    acRegistration: true,
    depAirport: false,
    arrAirport: false,
    sta: false,
    std: false,
};

export const ASM_NEW_EXIT_TYPE:ExitScreenMessage = {
    flightServiceType: true,
    acType: true,
    acConfig: true,
    acRegistration: true,
    depAirport: true,
    arrAirport: true,
    sta: true,
    std: true,
};

export const ASM_TIM_EXIT_TYPE:ExitScreenMessage = {
    flightServiceType: false,
    acType: false,
    acConfig: false,
    acRegistration: false,
    depAirport: true,
    arrAirport: true,
    sta: true,
    std: true,
};