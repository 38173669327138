import {Component, Input, OnInit} from '@angular/core';
import {IAcRegistration} from '../../../../shared/models/ac-registration.model';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {IGenericContainerObject} from '../../../../shared/models/genericContainerObject.model';
import {IAcType} from '../../../../shared/models/ac-type.model';
import * as dayjs from 'dayjs';
import { GeneralSettingsService } from '../../../../services/general-settings.service';

@Component({
  selector: 'app-settings-ac-registration-management-list-item',
  templateUrl: './ac-registration-mgmt-list-item.component.html',
  styleUrls: ['./ac-registration-mgmt-list-item.component.scss']
})
export class SettingsAcRegistrationMgmtListItemComponent implements OnInit {

  @Input() item: IAcRegistration;
  @Input() acTypes: IGenericContainerObject<IAcType>;
  @Input() opened: boolean;
  dayjsObj?: dayjs.Dayjs;

  constructor(private settingsService: GeneralSettingsService) { }

  ngOnInit() {
    registerLocaleData(localeDe);
  }

  getFormattedDeliveryDate(): string {
    if(this.item?.deliveryDate) {
      if(this.dayjsObj && !this.dayjsObj.isSame(this.item.deliveryDate, 'date')) {
        console.log('different date!');
      }
      else if(!this.dayjsObj) {
        this.dayjsObj = dayjs(this.item.deliveryDate);
      }
      return this.dayjsObj.format(this.settingsService.generalSettings.value.dateFormat.toUpperCase());
    }
    return '';
  }
}
