/* export enum Equipment {
  DEFAULT = 'DEFAULT',
  STAIRS = 'STAIRS',
  CLEANING = 'CLEANING',
  CATERING = 'CATERING',
  FUELING = 'FUELING',
  CONVEYOR = 'CONVEYOR',
  LUGGAGE_CART = 'LUGGAGE_CART',
  SCISSOR_LIFT = 'SCICCOR_LIFT',
  BUS = 'BUS',
  FIRE_TRUCK = 'FIRE_TRUCK',
  DEICING = 'DEICING'
} */

export class Equipment {
  static DEFAULT = 'DEFAULT';
  static STAIRS = 'STAIRS';
  static CLEANING = 'CLEANING';
  static CATERING = 'CATERING';
  static FUELING = 'FUELING';
  static CONVEYOR = 'CONVEYOR';
  static LUGGAGE_CART = 'LUGGAGE_CART';
  static HIGH_LOADER = 'HIGH_LOADER';
  static BUS = 'BUS';
  static FIRE_TRUCK = 'FIRE_TRUCK';
  static DEICING = 'DEICING';
  static JETWAY = 'JETWAY';
  static PUSHBACK = 'PUSHBACK';
  static WATER_SERVICE = 'WATER_SERVICE';
  static ASU = 'ASU';
  static GPU = 'GPU';
  static MARSHALING = 'MARSHALING';
  static TOWING = 'TOWING';
  static AMBULANCE = 'AMBULANCE';
  static TOWER = 'TOWER';
  static WC = 'WC';


  static LIST = [
    Equipment.DEFAULT,
    Equipment.STAIRS,
    Equipment.CLEANING,
    Equipment.CATERING,
    Equipment.FUELING,
    Equipment.CONVEYOR,
    Equipment.LUGGAGE_CART,
    Equipment.HIGH_LOADER,
    Equipment.BUS,
    Equipment.FIRE_TRUCK,
    Equipment.DEICING,
    Equipment.JETWAY,
    Equipment.PUSHBACK,
    Equipment.WATER_SERVICE,
    Equipment.ASU,
    Equipment.GPU,
    Equipment.MARSHALING,
    Equipment.TOWING,
    Equipment.AMBULANCE,
    Equipment.TOWER,
    Equipment.WC,
  ];
}
