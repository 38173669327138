import { Component, Input, OnInit } from '@angular/core';
import { GeneralSettingsService } from '../../../../../../services/general-settings.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-delay-codes-dialog-list-item',
  templateUrl: './manage-delay-code-dialog-list-item.component.html',
  styleUrls: ['./manage-delay-code-dialog-list-item.component.scss']
})
export class ManageDelayCodeDialogListItemComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private generalSettingsService: GeneralSettingsService) { }

  @Input() item: any;
  @Input() opened?: boolean;
  @Input() shallNotOpen?: boolean;

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {

  }
}
