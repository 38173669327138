import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlightDetails} from '../../../constants/flight-details.constants';
import {IFlightPair} from '../../../models/flight-pair.model';
import {SettingsService} from '../../../../services/settings.service';
import {ISimpleData} from '../../../models/simpleData.model';
import { IGenericContainerObject } from '../../../models/genericContainerObject.model';
import { GeneralSettingsService } from '../../../../services/general-settings.service';

@Component({
  selector: 'app-flights-change-flight-details',
  templateUrl: './flights-change-flight-details.component.html',
  styleUrls: ['./flights-change-flight-details.component.scss']
})
export class FlightsChangeFlightDetailsComponent implements OnInit {

  @Input() data?: {pair: IFlightPair, timezone: string};
  @Output() closeWindow = new EventEmitter();

  flightDetailsConstants = FlightDetails;
  selectedMenuItem?: string;

  delayCodes: IGenericContainerObject<ISimpleData>;
  passengerClasses?: IGenericContainerObject<ISimpleData>;

  constructor(private settingsService: SettingsService, private generalSettingsService: GeneralSettingsService) {
  }

  ngOnInit() {
    this.generalSettingsService.getDelayCodes().subscribe((result) => {
      if(result) {
        this.delayCodes = {};
        for (const delayCode of result) {
          this.delayCodes[delayCode.id] = delayCode;
        }
      }
    });
    this.generalSettingsService.getPassengerClasses().subscribe((result) => {
      if(result) {
        this.passengerClasses = {};
        for (const passengerClass of result) {
          this.passengerClasses[passengerClass.id] = passengerClass;
        }
      }
    });
  }

  setSelectedMenuItem(selection: string) {
    if (this.selectedMenuItem === selection) {
      this.selectedMenuItem = '';
    } else {
      this.selectedMenuItem = selection;
    }
  }

}
