import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { ILegsModel } from '../shared/models/legs.model';

@Injectable({
  providedIn: 'root'
})
export class LegsService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getLegs(filter: ILegsModel = { isActive: true }): Observable<ILegsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}legs/search`, filter, {}).pipe(
      map((item: IResponse<ILegsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getLegsForReports(filter: any): Observable<ILegsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}legs/reports-search`, filter, {}).pipe(
      map((item: IResponse<ILegsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  saveLeg(leg: ILegsModel): Observable<ILegsModel> {
    let obs;
    if (leg.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}legs/${leg.id}`, leg, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}legs/create`, leg, {})
    }
    return obs.pipe(
      map((item: IResponse<ILegsModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError(() => {
        this.toastService.showError("Error saving leg");
        return of(null);
      })
    );
  }

  deleteAll(): Observable<boolean> {
    return this.httpClient.delete(`${environment.api_base_url}legs/testing/all`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }

  deleteAllAndFirestore(): Observable<boolean> {
    return this.httpClient.delete(`${environment.api_base_url}legs/testing/all/firestore`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }

  returnToRampAirborne(model: {leg: ILegsModel, si: string, oldLeg: ILegsModel}): Observable<ILegsModel> {
    console.log('I am here in the return to ramp');
    return this.httpClient.post(`${environment.api_base_url}legs/returntorampairborne`, model).pipe(
      map((item: IResponse<ILegsModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        console.log(error);
        return of(null);
      })
    );
  }

  divertFlight(model: {leg: ILegsModel, si: string, oldLeg: ILegsModel}): Observable<ILegsModel> {
    console.log('Flight Diversion');
    return this.httpClient.post(`${environment.api_base_url}legs/diversion`, model).pipe(
      map((item: IResponse<ILegsModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        console.log(error);
        return of(null);
      })
    );
  }
}
