<div class="rounded-left mx-3 rounded-lg leg-outer">
  <div class="leg-left-part border-color-dark-gray text-center">
    28 FEB 19
  </div>
  <div class="icon-area">
    <app-icon-text-item iconHeader="icon-icons8-airplane" text="OE-MCB"
                        [state]="registrationNrState"></app-icon-text-item>

    <app-icon-text-item textHeader="#" text="NR651"
                        [state]="departingStationState"></app-icon-text-item>

    <app-icon-text-item iconHeader="icon-icons8-airplane-take-off" text="ZRH"
                        [state]="departingStationState"></app-icon-text-item>

    <app-icon-text-item textHeader="STD" text="11:45" [state]="stdState"></app-icon-text-item>

    <app-icon-text-item iconHeader="icon-icons8-airplane-landing" text="VIE"
                        [state]="arrivalStationState"></app-icon-text-item>

    <app-icon-text-item textHeader="STA" text="13:15" [state]="staState"></app-icon-text-item>

    <app-icon-text-item textHeader="FST" text="COM" [state]="flightServiceTypeState"></app-icon-text-item>
    <app-icon-text-item textHeader="A/C" text="B-52" [state]="acTypeState"></app-icon-text-item>
  </div>
</div>
