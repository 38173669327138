import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { AirportsService } from '../../../../../services/airports.service';
import { TurnaroundTimesService } from '../../../../../services/turnaround-times.service';
import { FlightType } from '../../../../../shared/constants/flight-types.constants';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { ISimpleData } from '../../../../../shared/models/simpleData.model';
import { ITurnaroundTimes, TurnaroundTimes } from '../../../../../shared/models/turnaround-times.model';
import { TimeFromMinutesPipe } from '../../../../../shared/pipes/time-from-minutes.pipe';

@Component({
  selector: 'app-view-airport-minimal-turnaround-time-dialog',
  templateUrl: './view-airport-minimal-turnaround-time-dialog.component.html',
  styleUrls: ['./view-airport-minimal-turnaround-time-dialog.component.scss']
})
export class ViewAirportMinimalTurnaroundTimeDialogComponent implements OnInit {

  flightServiceTypes10 = ['A', 'B', 'C', 'D', 'E', 'A', 'B', 'C', 'D', 'E'];
  flightServiceTypes1 = ['A', 'B', 'C', 'D', 'E', 'A', 'B', 'C', 'D', 'E', 'A', 'B', 'C', 'D', 'E'];
  flightServiceTypes20 = ['A', 'B', 'C', 'D', 'E', 'A', 'B', 'C', 'D', 'E', 'A', 'B', 'C', 'D', 'E', 'A', 'B', 'C', 'D', 'E'];

  @Input() flightServiceTypes?: { [p: number]: ISimpleData };
  @Input() turnaroundTimes?: { [key: number]: { [key: number]: ITurnaroundTimes } } | undefined;


  constructor(private timeFromMinutesPipe: TimeFromMinutesPipe) {
  }

  ngOnInit() {}

  getSpecificTurnaroundTime(key1: number, key2: number): string {
    console.log('turnaround times:', this.turnaroundTimes);
    if (!this.turnaroundTimes || !key1 || !key2) {
      return '00:00';
    } else if (this.turnaroundTimes[key1] && this.turnaroundTimes[key1][key2]) {
      return this.timeFromMinutesPipe.transform(this.turnaroundTimes[key1][key2].timeInMinutes);
    }
    return '00:00';
  }
}
