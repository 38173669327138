import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tooltip-content-pax',
  templateUrl: './tooltip-content-pax.component.html',
  styleUrls: ['./tooltip-content-pax.component.scss']
})
export class TooltipContentPaxComponent implements OnInit {

  @Input() pax: {code: string, amount: string }[];

  constructor() {
  }

  ngOnInit() {
  }
}
