import {Component, OnInit} from '@angular/core';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {SectionGlobalService} from '../../../../services/section-global.service';
import {SettingsService} from '../../../../services/settings.service';

@Component({
  selector: 'app-flights-search-flights',
  templateUrl: './flights-search-flights.component.html',
  styleUrls: ['./flights-search-flights.component.scss']
})
export class FlightsSearchFlightsComponent implements OnInit {

  amount = 'one';

  drp = false;

  mon = false;
  tue = false;
  wed = false;
  thu = false;
  fri = false;
  sat = false;
  sun = false;

  stdTime: any;
  staTime: any;

  etdDate: Date;

  airportList: string[] = [];

  hoveredDate?: NgbDate;

  fromDate?: NgbDate;
  toDate?: NgbDate;

  constructor(private sectionGlobalService: SettingsService, calendar: NgbCalendar) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
  }

  ngOnInit(): void {
    this.sectionGlobalService.getAirportsBySearchTermOnlyOnce('COO').then( data => {
      console.log('x');
      console.log(data);
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && !date.before(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = undefined;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  getDateString(date: NgbDate) {
    if (!date) {
      return 'none';
    }

    return date.day + '.' + date.month + '.' + date.year;
  }

  setFlightAmount(value: string) {
    this.amount = value;
  }

  toggleWeekday(day: string) {
    switch (day) {
      case 'mon': {
        this.mon = !this.mon;
        break;
      }
      case 'tue': {
        this.tue = !this.tue;
        break;
      }
      case 'wed': {
        this.wed = !this.wed;
        break;
      }
      case 'thu': {
        this.thu = !this.thu;
        break;
      }
      case 'fri': {
        this.fri = !this.fri;
        break;
      }
      case 'sat': {
        this.sat = !this.sat;
        break;
      }
      case 'sun': {
        this.sun = !this.sun;
        break;
      }
    }
  }

}
