import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { IUser } from '../../models/user.model';
import { UserRoles } from '../../constants/user-roles.constants';
import { ChatService } from '../../../services/chat.service';

@Component({
  selector: 'app-vcard',
  templateUrl: './vcard.component.html',
  styleUrls: ['./vcard.component.scss']
})
export class VcardComponent implements OnInit {

  @Input() indicated: boolean;
  @Input() user: IUser;
  @Output() openChatAction = new EventEmitter<IUser>();
  userRoleObject = UserRoles;
  numberOfUnreadMessages = 0;
  constructor(public chatService: ChatService) { }

  ngOnInit() {
    const existingGroup = Object.values(this.chatService.messageGroups).find((group) => group.userId === this.user.id);
    if (existingGroup) {
        this.numberOfUnreadMessages = this.chatService.myMessageGroups[existingGroup.chatGroupId]?.numberOfUnreadMessages ?? 0;
    }
  }

}
