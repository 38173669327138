import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Observable, of} from 'rxjs';
import {ISimpleData} from '../shared/models/simpleData.model';
import {ISettings} from '../shared/models/settings.model';
import {IGenericContainerObject} from '../shared/models/genericContainerObject.model';
import {IPairProcessAndEquipment} from '../shared/models/pairProcessAndEquipment.model';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {take} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private fireDb: AngularFireDatabase, private fireFunc: AngularFireFunctions, private httpClient: HttpClient) {
  }

  getPassengerClasses(): Observable<ISimpleData[]> {
    return this.fireDb.list<ISimpleData>('settings/general/passengerClasses').valueChanges();
  }

  getDelayCodes(): Observable<ISimpleData[]> {
    return this.fireDb.list<ISimpleData>('settings/general/delayCodes').valueChanges();
  }

  getSettings(): Observable<ISettings | null> {
    return this.fireDb.object<ISettings>('settings').valueChanges();
  }

  getAirportsBySearchTermOnlyOnce(searchTerm: string): Promise<any> {
    if (!searchTerm) {
      return new Promise<string[]>(() => []);
    }

    searchTerm = searchTerm.toUpperCase();

    return this.fireDb.list('settings/airports').query
      .orderByKey()
      .startAt(searchTerm)
      .endAt(searchTerm + '\uf8ff')
      .once('value').then(data => {
        console.log('data');
        console.log(data.val());
        if (data && data.val()) {
          return Object.keys(data.val());
        }
        return [];
      }).catch(error => {
        console.log(error);
        return [];
      });
  }

  getAllProcessesStream() {
    return this.fireDb.list('settings/slaAndGse').valueChanges();
  }

  /*
  getProcessStream(processKey: string) {
    if (!processKey) {
      return of(null);
    }
    return this.fireDb.object(`setttings/slaAndGse/${processKey}`);
  } */

  getAllProcessKeysPromise() {
    return this.fireDb.list('settings/slaAndGseKeyList').query.once('value');
  }

  setPairProcesses(
    pairReferenceKey?: string,
    processAndEquipmentContainer?: IGenericContainerObject<IPairProcessAndEquipment>): Observable<any> {

    if (!pairReferenceKey) {
      return of({
        data: {
          success: false
        }
      });
    }

    const data = {
      pairReferenceKey,
      processesAndGse: processAndEquipmentContainer
    };

    const callable = this.fireFunc.httpsCallable('flightsChangeFlightDetailsProcessesAndGse');
    const data$ = callable(data);

    return data$.pipe(take(1));
  }

  getProcessByAcTypeAndAirportOrDefault(process: string, acType?: string, airport?: string): Promise<any> {
    return new Promise<any>(((resolve) => {
      if (!process) {
        console.log('Either process, acType or airport is empty!');
        resolve(null);
      }

      return this.getProcessByParams(process, acType, airport)
        .catch(() => this.getProcessByParams(process, acType)
          .catch(() => this.getProcessByParams(process)
            .catch(() => resolve(null))
            .then((res) => resolve(res)))
          .then((res) => resolve(res)))
        .then((res) => resolve(res));
    }));

    /*
    this.fireDb.list(`settings/slaAndGse/${process}/acTypes/${acType}/airports/${airport}`)
      .query.once('value')
      .catch(error => console.log('very detail catch error: ', error))
      .then(res => {
        if (res && res.val()) {
          console.log(process + ' very detail then res: ', res.val());
        } else {
          console.log(process + ' very detail empty res or val()');
        }
      })
      .finally(() => console.log('very detail - ', new Date()));

    this.fireDb.list(`settings/slaAndGse/${process}/acTypes/${acType}/airports/Default`)
      .query.once('value')
      .catch(error => console.log('slight detail catch error: ', error))
      .then(res => {
        if (res && res.val()) {
          console.log(process + ' slight detail then res: ', res.val());
        } else {
          console.log(process + ' slight detail empty res or val()');
        }
      })
      .finally(() => console.log('slight detail - ', new Date()));

    this.fireDb.list(`settings/slaAndGse/${process}/acTypes/Default/airports/Default`)
      .query.once('value')
      .catch(error => console.log('general catch error: ', error))
      .then(res => {
        if (res && res.val()) {
          console.log(process + ' general then res: ', res.val());
        } else {
          console.log(process + ' general empty res or val() - ', new Date());
        }
      });

    return this.fireDb.list('settings/slaAndGse').valueChanges(); */
  }

  getProcessByParams(process: string, acType?: string, airport?: string): Promise<any> {
    return new Promise<any>(((resolve, reject): Promise<void> | undefined => {

      if (!process) {
        console.log('Process is empty!');
        reject();
      }

      if (acType) {
        if (airport) {
          this.fireDb.list(`settings/slaAndGse/${process}/acTypes/${acType}/airports/${airport}`)
            .query.once('value')
            .catch(error => {
              console.log('very detail catch error: ', error);
              reject();
            })
            .then(res => {
              if (res && res.val()) {
                console.log(process + ' very detail then res: ', res.val());
                resolve(res.val());
              } else {
                console.log(process + ' very detail empty res or val()');
                reject();
              }
            });
        } else {
          this.fireDb.list(`settings/slaAndGse/${process}/acTypes/${acType}/airports/Default`)
            .query.once('value')
            .catch(error => {
              console.log('slight detail catch error: ', error);
              reject();
            })
            .then(res => {
              if (res && res.val()) {
                console.log(process + ' slight detail then res: ', res.val());
                resolve(res.val());
              } else {
                console.log(process + ' slight detail empty res or val()');
                reject();
              }
            });
        }
      } else {
        return this.fireDb.list(`settings/slaAndGse/${process}/acTypes/Default/airports/Default`)
          .query.once('value')
          .catch(error => {
            console.log('general catch error: ', error);
            reject();
          })
          .then(res => {
            if (res && res.val()) {
              console.log(process + ' general then res: ', res.val());
              resolve(res.val());
            } else {
              console.log(process + ' general empty res or val() - ', new Date());
              reject();
            }
          });
      }
      reject();
      return undefined;
    }));
  }
}
