<div class="list-item-column">
  <div class="list-item-row-header" [ngClass]="{'opened' : opened}">
    <div class="flex-basis-25 text-block">{{item?.firstname + ' ' + item?.lastname}}</div>
    <div class="flex-basis-50 text-block text-center">{{item?.company}}</div>
    <div class="flex-basis-25 text-block text-end">{{item?.department}}</div>
  </div>

  <ng-container *ngIf="opened">
    <div class="list-item-row">
      <div class="flex-basis-25 text-block">Phone</div>
      <div class="flex-basis-25 text-block">{{item?.phone}}</div>

      <div class="flex-basis-25 text-block">VHF Frequency</div>
      <div class="flex-basis-25 text-block">{{item?.vhf}}</div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-25 text-block">Mobile</div>
      <div class="flex-basis-25 text-block">{{item?.mobile}}</div>

      <div class="flex-basis-25 text-block">Email</div>
      <div class="flex-basis-25 text-block">{{item?.email}}</div>
    </div>

    <div class="list-item-row">
      <div class="flex-basis-25 text-block">Remarks</div>
      <div class="flex-basis-75 text-block">{{item?.remarks}} </div>
    </div>

  </ng-container>
</div>
