<div class="container-fluid filter-sticky">
  <div class="row">
    <app-execution-filter [user]="currentUser" [dateFilter]="routePath === '/flights/all'"></app-execution-filter>
  </div>
  <div class="row">

  </div>
</div>
<div class="flex flex-row flex-wrap mb-3">
  <div style="flex-grow: 1;">
    <ng-container *ngIf="routePath === '/flights'">
<!--      [legList]="pairs$ | async"-->
      <app-leg-list
        [pairDetailsList]="this.firestorePairs$ | async"
        [timezone]="timezone$ | async"
        [passengerClasses]="passengerClasses$ | async"
        [userRole]="currentUser.role"
        [activePairIndex]="activePairIndex"
        (resetPairIndex)="setActivePairIndex('')"
        (setPairIndex)="setActivePairIndex($event)"
        (getFlightsBefore)="getPreviousFlights($event)"
        (getFlightsAfter)="getNextFlights($event)"
        (setActiveFlight)="setSelectedFlight($event)"
      ></app-leg-list>
    </ng-container>
    <ng-container *ngIf="routePath === '/flights/all'">
      <app-leg-list-sql
        [pairOverviewList]="pairsSql$ | async"
        [timezone]="timezone$ | async"
        [passengerClasses]="passengerClasses$ | async"
        [userRole]="currentUser.role"
        [activePairIndex]="activePairIndex"
        (resetPairIndex)="setActivePairIndex('')"
        (setPairIndex)="setActivePairIndex($event)"
        (getFlightsBefore)="getPreviousSQLFlights()"
        (getFlightsAfter)="getNextSQLFlights()"
        [hasNextPage]="hasNextPageSql"
        [page]="sqlPage"
        (setActiveFlight)="setSelectedFlight($event)"
        [refreshSql]="refreshListener"
      ></app-leg-list-sql>
    </ng-container>

  </div>
  <app-small-chat-contacts-container class="chatContainer" [showContacts]="!!this.activePairIndex" [flightStations]="flightStations"
                                      [chatUser]="chatUser"></app-small-chat-contacts-container>
</div>

