import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IASMModel } from '../shared/models/asm.model';
import { IResponse } from '../shared/models/IResponse.model';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class CtotService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  uploadCTOT(ctotData: IASMModel): Observable<boolean> {
    return this.httpClient.post(`${environment.api_base_url}ctot/upload`, ctotData, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError(() => {
        this.toastService.showError("Error uploading CTOT file");
        return of(null);
      })
    );
  }
}
