<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<ng-container [ngSwitch]="alertService.alertTypes[this.alert.alertTypeId].title">
  <app-overlap-alert-type [timezone]="timezoneService.getTimezone() | async" [alert]="alert" [dateFormat]="dateFormat" [delayCodes]="delayCodes" *ngSwitchCase="alertTypes.OVERLAPPING_SECTORS"></app-overlap-alert-type>
  <app-issue-etd-alert-type [timezone]="timezoneService.getTimezone() | async" [alert]="alert" [dateFormat]="dateFormat" [delayCodes]="delayCodes" *ngSwitchCase="alertTypes.INADEQUATE_TURNAROUND_TIME"></app-issue-etd-alert-type>
  <app-issue-etd-alert-type [timezone]="timezoneService.getTimezone() | async" [alert]="alert" [dateFormat]="dateFormat" [delayCodes]="delayCodes" *ngSwitchCase="alertTypes.EARLY_DEPARTURE"></app-issue-etd-alert-type>
  <app-issue-etd-alert-type [timezone]="timezoneService.getTimezone() | async" [alert]="alert" [dateFormat]="dateFormat" [delayCodes]="delayCodes" *ngSwitchCase="alertTypes.REQUEST_FOR_EXTENSION"></app-issue-etd-alert-type>
  <app-simple-issue-etd-alert-type [timezone]="timezoneService.getTimezone() | async" [alert]="alert" [dateFormat]="dateFormat" [delayCodes]="delayCodes" *ngSwitchCase="alertTypes.UNPAIRED_SECTOR"></app-simple-issue-etd-alert-type>
  <app-no-actual-times-alert-type [alert]="alert" [delayCodes]="delayCodes" *ngSwitchCase="alertTypes.NO_ACTUAL_TIMES_ON_SECTOR"></app-no-actual-times-alert-type>
</ng-container>
