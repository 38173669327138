import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../services/toast.service';
import { TurnaroundService } from '../../../../../services/turnaround.service';

@Component({
  selector: 'app-confirm-resume-turnaround-dialog',
  templateUrl: './confirm-resume-turnaround-dialog.component.html',
  styleUrls: ['./confirm-resume-turnaround-dialog.component.scss']
})
export class ConfirmResumeTurnaroundDialogComponent implements OnInit {
  title?: string;
  pairId?: number;
  constructor(private activeModal: NgbActiveModal, private turnaroundService: TurnaroundService, private toastService: ToastService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    this.turnaroundService.resumeTurnaround({ pairId: this.pairId }).subscribe((res) => {
      if (res) {
        this.toastService.showSuccess('Turnaround process has been resumed!');
        this.closeModal();
      }
    });
  }

}
