import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../../../services/toast.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { take } from 'rxjs/operators';
import { AirportsService } from '../../../../../services/airports.service';
import { SlaGseLevelAirportDialogComponent } from '../sla-gse-level-airport-dialog/sla-gse-level-airport-dialog.component';
import { IAcType } from '../../../../../shared/models/ac-type.model';
import { GseService } from '../../../../../services/gse.service';
import { IGsesModel } from '../../../../../shared/models/gses.model';
import { IProcessesModel } from '../../../../../shared/models/processes.model';
import { IAirport } from '../../../../../shared/models/airport.model';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';

@Component({
  selector: 'app-sla-gse-level-actype-dialog',
  templateUrl: './sla-gse-level-actype-dialog.component.html',
  styleUrls: ['./sla-gse-level-actype-dialog.component.scss']
})
export class SlaGseLevelActypeDialogComponent implements OnInit {

  @Input() title?: string;
  formGroup?: FormGroup;
  isNew?: boolean;
  isSaved = !this.isNew;
  acTypeId?: number;
  acTypeList: IGenericContainerObject<IAcType>;
  gseList: IGenericContainerObject<IGsesModel>;
  process: IProcessesModel;
  airportList?: IGenericContainerObject<IAirport>;
  airportsWithException: IGenericContainerObject<IAirport>;

  constructor(private toastService: ToastService, private activeModal: NgbActiveModal, private fb: FormBuilder, private modalService: NgbModal,
              private airportsService: AirportsService, private gsesService: GseService) { }

  ngOnInit(): void {
    if(!this.airportList) {
      console.log('no airport list found');
      return;
    }
    this.updateList();

    this.isSaved = !this.isNew;
    this.formGroup = this.fb.group({
      acTypeName: ['', Validators.required]
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    if (this.formGroup?.valid) {

      const model: IGsesModel = {
        title: 'none',
        isSla: true,
        isNone: true,
        acTypeId: this.acTypeList[this.formGroup?.value.acTypeName]?.id,
        gseIdentifier: '',
        airportId: null,
        validTo: null,
        validFrom: new Date(),
        processId: this.process?.id,
        maximumProcessTimeInMinutes: 0,
      };
      this.gsesService.saveAcTypeException(model).subscribe((result) => {
        if(result) {
          this.isNew = false;
          this.isSaved = true;
          this.toastService.showSuccess('AC Type exception has been saved');
          this.gseList[result.id] = result;
          this.acTypeId = this.acTypeList[this.formGroup?.value.acTypeName]?.id;
          this.fetchExceptions().subscribe((result) => {
            for (const key in this.gseList) {
              delete this.gseList[key];
            }
            for (const gse of result) {
              this.gseList[gse.id] = gse;
            }
            this.updateList();
          })
        }
      });
    }
  }

  addAirport() {
    if(!this.isSaved)
    {
      return;
    }

    const modalRef = this.modalService.open(SlaGseLevelAirportDialogComponent, { size: 'lg' });
    modalRef.componentInstance.title = `Add SLA / GSE ${this.process?.title} / ${this.acTypeList[this.acTypeId]?.alias ?? 'Default A/C Types'} / new Airport`;
    const modal = modalRef.componentInstance as SlaGseLevelAirportDialogComponent;
    modal.isNew = true;
    modal.process = this.process;
    modal.gseList = this.gseList;
    modal.airportList = this.airportList;
    modal.acTypeList = this.acTypeList;
    modal.acTypeId = this.acTypeId;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchExceptions().subscribe((result) => {
        for (const key in this.gseList) {
          delete this.gseList[key];
        }
        for (const gse of result) {
          this.gseList[gse.id] = gse;
        }
        this.updateList();
      })
    });
  }

  editAirport(airportId?: number) {
    if(!this.isSaved)
    {
      console.log('not saved!');
      return;
    }

    if (!airportId && airportId !== 0 || !this.airportList || !this.airportList[airportId] && airportId !== 0) {
      console.log(`airport ${airportId} was not found! `, this.airportList);
      return;
    }

    const airport = this.airportList[airportId];

    const airportText = airportId === 0 ? 'Default Airports' : airport?.title;

    const modalRef = this.modalService.open(SlaGseLevelAirportDialogComponent, { size: 'lg' });
    modalRef.componentInstance.title = `Add SLA / GSE ${this.process?.title} / ${this.acTypeId === 0 ? 'Default A/C Types' : this.acTypeList[this.acTypeId]?.alias} / ${airportText}`;
    const modal = modalRef.componentInstance as SlaGseLevelAirportDialogComponent;
    modal.isNew = false;
    modal.process = this.process;
    modal.acTypeId = this.acTypeId;
    modal.acTypeList = this.acTypeList;
    modal.airportList = this.airportList;
    modal.airportId = airportId;
    modal.gseList = this.gseList;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchExceptions().subscribe((result) => {
        for (const key in this.gseList) {
          delete this.gseList[key];
        }
        for (const gse of result) {
          this.gseList[gse.id] = gse;
        }
        this.updateList();
      })
    });
    return;
  }

  deleteItem(airportId?: number) {
    if (!this.process || !airportId) {
      console.log('cannot delete airport because one or more vars are empty');
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Exception for airport ' + this.airportList[airportId].title;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.gsesService.deleteGses(this.process.id, this.acTypeId, airportId).subscribe((result) => {
        if(result) {
          this.fetchExceptions().subscribe((result) => {
            for (const key in this.gseList) {
              delete this.gseList[key];
            }
            for (const gse of result) {
              this.gseList[gse.id] = gse;
            }
            this.updateList();
          });
        }
      })
    });
  }

  fetchExceptions()
  {
    return this.gsesService.fetchGses({ processId: this.process.id, acTypeId: this.acTypeId !== 0 ? this.acTypeId : undefined, isActive: true });
  }

  updateList()
  {
    this.airportsWithException = {};
    for (const gse of (Object.values(this.gseList) as IGsesModel[])) {
      if(gse.acTypeId === this.acTypeId || !gse.acTypeId && this.acTypeId === 0) {
        if(gse.airportId) {
          this.airportsWithException[gse.airportId] = this.airportList[gse.airportId];
        }
        else {
          this.airportsWithException[0] = { title: 'Default', iata: 'Default', id: 0 };
        }
      }
    }
  }

}
